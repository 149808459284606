.search {
    &__header {
        &-background {
            height: 60px;
            background-color: $color__primary;
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            z-index: -100;
        }
    }
}