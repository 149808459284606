.variant-picker {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  margin-top: $space-standard;
  margin-left: calc(-1 * $space-standard);
  margin-right: calc(-1 * $space-standard);

  &__text {
      flex: 0 0 25%;
      max-width: 25%;
      position: relative;
      width: 100%;
      padding-right: $space-standard;
      padding-left: $space-standard;

      p {
        color: $color__gray-order;
        font-size: $text__body-base;
        font-weight: normal;
      }
  }

  &__dropdown {
    flex: 0 0 75%;
    max-width: 75%;
    position: relative;
    width: 100%;
    padding-right: $space-standard;
    padding-left: $space-standard;
    background-color: $color__white;

        &__container {
          display: flex;
          align-items: center;
          position: relative;
            select {
              appearance: none;
              display: block;
              width: 100%;
              color: $color__gray-order;
              background-color: $color__white;
              border: 1px solid $color__input-border;
              box-shadow: $shadow__search-card;
              line-height: $line-height-xxl;
            }
            svg {
              background-color: $color__white;
              position: absolute;
              right: $space-sm;
              top: calc(($space-standard + $space-md) / 2);
              pointer-events: none;
            }
          }
    
  }
}