.contact-preferences {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: 100%;

  &__container {

    @include page-container(sm);

    .checkbox-wrapper__label-text {
      margin-left: 0;
    }

    .checkbox-wrapper {
      padding-bottom: $space-md;

      &__check {
        background-color: $color__white;
        margin-left: $space-xl;
        margin-right: $space-md;
        margin-bottom: $space-xl;
        &:focus {
          outline: $outline__normal;

        }
      }
    }

    hr {
      margin-bottom: $space-md;

    }

    button {
      font-weight: bold;
      padding: $space-standard 0;
      text-transform: uppercase;
      border-radius: $radius-xs;
      max-width: 100%;
    }

  }

  .checkbox-wrapper input {
    display: block;
  }
}