.rewards-banner-old {
    display: block;
    padding: $space-xs 0 $space-xs 0;
    background-image: url('/images/bubbles2.svg');
    background-color: $color__white;
    background-repeat: no-repeat;
    background-size: cover;

    &__section {
        max-width: $space-max-width;
        margin: auto;
        display: flex;

        >* {
            padding: 0 $space-standard;
        }
    }

    &__first-section {
        flex: 1;
        display: flex;
        justify-content: space-between;

        @include breakpoint-max(sm) {
            flex: 0 0 33.33333%;
        }
    }

    &__second-section {
        flex: 1;
        display: flex;
        justify-content: space-between;

        @include breakpoint-max(sm) {
            flex-direction: column;
            padding: 0 $space-standard 0 0;
            justify-content: flex-start;
        }
    }

    &__title {
        font-size: $text__body-xxl;
        color: $color__black-light;
        font-weight: bold;
        margin-top: $space-standard;

        @include breakpoint-max(sm) {
            font-size: $text__body-base;
            margin-top: $space-xs;

            &--desktop {
                display: none;
            }
        }

        @include breakpoint(sm) {
            &--mobile {
                display: none;
            }
        }
    }

    &__subtitle {
        font-size: $text__body-xl;
        color: $color__black-light;
        font-weight: normal;
        display: inline-block;
        margin-top: $space-standard;

        @include breakpoint-max(sm) {
            font-size: $text__body-sm;
            margin-top: $space-xxs;
        }

        &-arrow {
            display: inline;
            width: 20px;
            vertical-align: bottom;
            margin-left: $space-md;
            height: $space-standard;

            @include breakpoint (sm) {
                SVG {
                    width: 20px;
                    height: $space-standard;
                }
            }
        }
    }

    &__box {
        width: 90px;
        height: 60.7px;
        display: inline;
        border: 1px solid $color__off-white;

        @include breakpoint-max(sm) {
            border: none;
            width: 100%;
            height: 65px;

            &--desktop {
                display: none;
            }
        }

        @include breakpoint(sm) {
            &--mobile {
                display: none;
            }
        }
    }
    &:focus {
        outline-offset: -2px;
    }
}

.rewards-banner {
    &__section{
        display: flex;
        justify-content: center;
        width: 100%;
        max-width: $space-max-width;
        padding: 0px $space-standard;
        margin: 0 auto;

        @include breakpoint-max(sm) {
            background: transparent;
        }

        @include breakpoint-max(lg) {
            padding: 0px;
        }
    
        &__container {
            background: linear-gradient(180deg, #E5D4ED 0%, rgba(219, 186, 226, 0) 100%);
            display: flex;
            justify-content: center;
            width: 100%;
            gap: $space-xs;
            min-height: 120px;
            padding-top: $space-md;
            margin-bottom: $space-md;

            @include breakpoint-max(sm) {
                background: transparent;
                padding-top: $space-standard;
                margin-bottom: $space-standard + $space-xxxs;
            }

            &__inner-container{
                display: flex;
                max-width: 100%;
                justify-content: center;
                align-items: center;
                align-self: stretch;
                gap: $space-xl;
                @include breakpoint-max(sm) {
                    flex-direction: column;
                    width: 100%;
                    gap: $space-md;
                }

                &__reward{
                    display: flex;
                    max-height: 96px;
                    align-items: center;
                    justify-content: center;
                    gap: $space-xs;
                    border-radius: $radius-sm;

                    @include breakpoint-max(sm) {
                        max-width: 90%;
                    }

                }

                &__order{
                    display: flex;
                    align-items: center;
                    border-radius: $radius-sm;
                    border: 1.5px solid var(--pOpshelf-Secondary, #C691D0);
                    background: $color__white;
                    position: relative;

                    @include breakpoint-max(sm) {
                        width: 90%;
                    }
                    @include breakpoint-max(xs) {
                        flex-direction: column;
                        width: 90%;
                    }
                    
                }
            }
        }

        &__total-rewards{
            width: 88px;
            height: 88px;
            display: flex;
            flex-direction: column;
            gap: $space-xs;
            margin: $space-xxxs 0px;
        }
    
        &__details {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            justify-content: center;
            gap: $space-xs;
            width: 2 * $space-xxxxl;
            margin: $space-xxxxs 0px;

            h1{
                font-weight: bold;
                line-height: $line-height-lg;
                font-size: $text__body-lg;
                white-space: nowrap;
            }

            button {
                width: 158px;
                background-color: $color__primary; 
                color: $color__white;
                border: none;
                border-radius: $radius-xs;
                padding: $space-xs $space-md;
                font-size: $text__body-xs;
                line-height: $line-height-base;  
                text-align: center;
            }
        }

        &__badge {
            width: 178px
        }

        &__point-balance {
            width: 208px;
            gap: $space-xs
        }

        &__sign-in {  
            gap: $space-standard;
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;
            margin: $space-standard 0px $space-md;
            width: 100%;

            @include breakpoint-max(sm) {
                flex-direction: column;
                gap: $space-xs;
                margin: $space-standard $space-standard $space-md;
            }

            h1 {
                font-size: $text__body-base;
                line-height: $line-height-base;
                color: $color__black-light;
                text-align: center;
                font-weight: bold;

                @include breakpoint-max(sm) {
                    font-size: $text__body-md;
                    line-height: $line-height-md;
                }
            }
           
        }
        &__button {
            gap: $space-xs;
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;
       }
       &__button-register {
            height: $space-lg;
            min-width: 120px;
            background-color: $color__primary; 
            color: $color__white;
            border: none;
            border-radius: $radius-xs;
            gap: $space-xs;
            padding: $space-xs;
            font-weight: bold;                    
            font-size: $text__body-sm;                 
        }

        &__button-signin {
            height: $space-lg; 
            min-width: 120px;
            color: $color__primary;
            border: 1.5px solid $color__primary;
            border-radius: $radius-xs;
            gap: $space-xs;
            padding: $space-xs;
            font-weight: bold;
            font-size: $text__body-sm;
        }

        &-subheading-badge {
            color: $color__gray-order;
            line-height: $line-height-xs;
            font-size: $text__body-xs;
        }
        &-subheading-points {
            color: $color__black;
            font-size: $text__body-md;
            line-height: $line-height-md;
        }
        &-subheading-default {
            color: $color__primary;
            line-height: $line-height-xs;
            font-size: $text__body-xs;
        }
    }
}