.checkout-second-step {
  &__form-text {
    font-weight: 700;
    margin: $space-md 0;
    width: 100%;
    text-align: left;
  }

  .input-wrapper {
    margin-bottom: $space-sm;

    &:last-of-type {
      margin-bottom: 0;
    }

    &.bottom-divider {
      border-bottom: $border__gray;
      padding-bottom: ($space-md + $space-xxs);
    }
  }
}

input.pop-form__input.pop-form__input-phone {
  margin-bottom: 0;
}
