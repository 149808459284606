// GLOBAL MODAL STYLES
ion-modal {
    
    // Desktop
    &::part(content) {
        @include z-index(quaternary);
        border-radius: $radius-md;
        height: calc(100% - $space-xxxl);
        width: calc(100% - $space-md);
        max-height: 90%;
        height: auto;
        overflow: auto;

        @include breakpoint(xs) {
            max-height: 75%;
            max-width: 335px;
        }
    }

    &::part(backdrop) {
        @include z-index(quinary);
    }

    &.modal--fullscreen {
        &::part(content) {
            max-width: 400px;
            min-height: 500px;
            
            @include breakpoint-max(sm) {
                border-radius: 0;
                width: 100vw;
                height: 100vh;
                max-width: 100%;
                max-height: 100%;
            }
        }
        
        .modal-wrapper {
            @include breakpoint-max(sm) {
                height: 100vh;
            }
        }
    }

    &.modal--filters {
        justify-content: flex-end;

        &::part(content) {
            width: 350px;
            max-width: 80%;
            height: 100vh;
            max-height: 100%;
            border-radius: 0 !important;
        }

        .modal-wrapper {
            height: 100vh;
        }

        .ion-page {
            height: 100%;
        }
    }

    &.modal--search {
        display: flex;
        justify-content: flex-end;
        align-items: flex-start;

        &::part(content) {
            border-radius: 0 !important;
            width: 100%;
            max-width: 100%;
            height: 100%;
            max-height: 100%;

            @include breakpoint(sm) {
                height: auto;
                width: 475px;
                max-width: 80%;
                max-height: 700px;
            }
        }

        section {
            padding: 0 !important;
        }

        .modal-wrapper {
            @include breakpoint-max(sm) {
                height: 100vh;
            }
        }
    }

    &.modal--large {
        &::part(content) {  
            max-width: 90%;

        }
    }

    &.modal--center {

        section h3,
         h6 {
            text-align: center !important;
        }
    }
    &.modal--store__flyout {
        justify-content: flex-start;

        &::part(content) {
            width: 350px;
            height: 100vh;
            max-height: 100%;
            border-radius: 0 !important;

            @include breakpoint-max(sm) {
                width: 90%;
            }
        }

        .modal-wrapper {
            height: 100vh;
        }

        .ion-page {
            height: 100%;
        }
    }

    
    section {
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: $space-xl $space-md;
        box-sizing: border-box;
        position: relative;

        i,
        svg {
            margin-bottom: $space-standard;
        }

        h3,
         h6 {
            margin-bottom: $space-standard;
        }

        h3 {
            font-size: 32px;
            font-weight: bold;
            line-height: 40px;
        }

        p {
            text-align: center;
            margin-bottom: $space-standard;

            &.bold-modal-text {
                font-weight: 700;
            }

            &:last-of-type {
                margin-bottom: $space-standard;
            }
        }

        ul {
            margin-bottom: $space-xl;
            padding: 0 $space-md;
            list-style: disc;
            list-style-position: outside;

            li {
                &:not(&:last-of-type) {
                    margin-bottom: $space-standard;
                }
            }
        }

        button {
            max-width: 100%;
            width: 100%;
        }

        .btn__primary {
            max-width: 100%;
        }

        .btn__secondary {
            margin-top: $space-xs;
            max-width: 100%;
        }

        .close {
            position: absolute;
            top: $space-standard;
            right: $space-standard;
        }
    }

    .ion-page {
        position: relative;
        display: block;
        contain: content;
    }
}

ion-slide {
    width: 100% !important;
}