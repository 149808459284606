.social-share {
  > p {
    margin-bottom: 0 !important;
  }

  &__icons {
    display: flex;
    flex-direction: row;
    margin: $space-standard 0;
    width: 100%;
  }

  &__icon {
    display: block;
    width: $space-xxl;
    height: $space-xxl;
    margin: 0 auto;

    button {
      height: 100%;
      width: 100%;
      background-color: $color__gray-order;
      border-radius: 50%;
      background-repeat: no-repeat;
      background-position: center;
    }

    svg {
      margin-bottom: 0;
    }
  }

  .form-wrap {
    width: 100%;
  }

  .input-wrap input[type='text'] {
    margin-top: $space-standard;
    padding: 11px 34px 11px 10px !important;
  }
}
