.search-modal {
    &__header {
        display: flex;
        align-items: center;
        height: 56px;
        background-color: $color__primary;

        .search-modal__predictive-search {
            width: 100%;
            display: flex;
            align-items: center;
            background-color: $color__white;
            border: 1px solid $color__gray-toolbar-border;
            margin-right: $space-standard;
            border-radius: $radius-xs;

            div {
                width: 100%;
                flex-grow: 1;

                input[type="text"] {
                    width: 100%;
                    height: $space-xl;
                    padding-left: $space-sm;
                    padding-right: $space-sm;
                    margin-left: $space-xxs;
                    color: $color__black;
                    font-weight: bold;
                    border: none;
                }
            }

            button {
                flex-shrink: 0;
            }

            input:focus {
                outline-offset: -2px;
            }

            button:focus {
                outline-offset: -2px;
            }
        }

        .back-arrow {
            margin: 0 $space-sm;
        }
    }

    .swiper {
        &-wrapper {
            padding: $space-standard 0 $space-md 0;
        }
    }
}