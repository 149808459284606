.map-detail-wrap {
  padding-top: 40px;
  padding-bottom: 20px;
}
.map-detail-item {
  margin-bottom: 20px;
  label {
    font-size: 16px;
    font-weight: bold;
    color: #000;
    padding: 0;
    margin: 0 0 5px 0;
  }
  span {
    font-size: 14px;
    font-weight: bold;
    color: #636363;
    padding: 0;
    margin: 0;
  }
  p {
    font-size: 16px;
    line-height: 22px;
    margin-bottom: 20px;
    strong {
      font-weight: bold;
    }
  }
  table {
    width: 100%;
  }
}
.map-detail-item label,
.map-detail-item span,
.map-detail-item a {
  display: block;
}
.map-detail-item .rsvpd{
  position: inherit;
  text-align: left;
}
.map-area {
  height: 200px;
  width: 100%;
  margin-bottom: 10px;
  background-size: cover;
  background-position: center;
}

.schedule {
  &__heading {
    line-height: $line-height-xl;
    font-weight: bold;
    P {
      margin-bottom: 0;
    }
  }
  &__table {
    display: flex;
    margin-bottom: $space-md;
    p {
      margin-bottom: 0;
      line-height: $line-height-xl;
    }
    &-time {
      margin-left: $space-xxxxl;

    }
  }
  .bold-style {
    font-weight: bold;
  }
}