nav {
  color: #333;
}

nav ul {
  padding: 0px;
}

nav ul li {
  list-style-type: none;

  border-top: 1px solid #e7e7e7;

  padding-top: 10px;
  padding-bottom: 10px;

  padding-left: 20px;
  font-weight: bold;

  color: #333333;
}
