.pop_hero_background_image {
  width: 100%;
  position: absolute;
  left: 0;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}
.toolbar-container {
  padding: 0px;
}
#home-hero {
  &:focus-within {
    outline: $outline__black-heavy !important;
    outline-offset: -1px;
  }
}
.hero-home {
  width: 100%;
  margin-bottom: $space-md;
  position: relative;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  &__container {
    @media (max-width: 768px) {
      left: 0;
    }
    &-image {
      &--mobile {
        @include breakpoint(sm) {
          display: none;
        }
      }

      &--desktop {
        display: none;
        @include breakpoint(sm) {
          display: block;
        }
      }

      img {
        width: 100%;
        height: 100%;
      }
    }
  }
  &__content {
    top: 0;
    left: 0;
    position: absolute;
    padding: $space-xl $space-standard $space-xl 15%;
    width: 100%;
  }
  &__title {
    color: $color__gray-toolbar;
    font-size: 50px;
    font-weight: bold;
    line-height: $line-height-xxxxxxl;
    margin-bottom: $space-md;
    text-transform: lowercase;
  }
  &__subtitle {
    color: $color__gray-toolbar;
    font-size: $text__header-xxl;
    font-weight: bold;
    line-height: $line-height-xxxl;
    margin-top: $space-xxs;
    margin-bottom: $space-md;
    overflow-wrap: break-word;
    padding-bottom: $space-md;
    span {
      color: $color__gray-toolbar;
      font-size: $text__body-base;
      font-weight: bold;
      line-height: $line-height-xxxl;
    }
  }
  .visible-xs {
    display: none;
  }
}

.hero-home-btn {
  &__explore {
    width: auto !important;
    padding: $space-standard $space-md !important;
    -webkit-border-radius: $radius-xs;
    -moz-border-radius: $radius-xs;
    border-radius: $radius-xs;
    padding: $space-standard 0;
    font-size: $text__body-sm;
    font-weight: bold;
    background-color: $color__gray-toolbar !important;
    color: $color__black;
    font-size: $text__body-sm;
    cursor: pointer;
    text-transform: uppercase;
  }
}

.hero-image {
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}

.hero-home__image {
  object-fit: cover;
}

@media (max-width: 576px) {
  .pop_hero_support_image {
    top: 45%;
    position: relative;
    transform: translateY(-50%);
  }
  .hero-home {
    height: auto;
    &__content {
      min-height: 300px;
    }
    &__subtitle {
      font-size: $text__body-lg;
      font-weight: bold;
      line-height: $line-height-lg;
      margin-top: $space-xxs;
      margin-bottom: $space-standard;
    }
    .visible-xs {
      display: block;
    }
    .pop-button-full {
      width: 100%;
      padding-left: 0;
      padding-right: 0;
    }
    h1 {
      font-size: $text__header-xxl;
      font-weight: bold;
      line-height: $line-height-xxxl;
      margin-bottom: 0;
    }
  }

  .mob-no-left {
    padding-left: 0;
  }
}

.hero-variant-2 {
  &__wrapper {
    width: 100%;
    margin-bottom: $space-md;
    position: relative;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    padding: $space-md 0px;
    background-image: url(/images/bg.jpg);
  }

  &__container {
    @media (max-width: 768px) {
      flex-direction: column;
      align-items: center;
    }
    width: 100%;
    padding: 0px $space-standard;
    &-image {
      width: 100%;
      position: relative;
      overflow: visible;
      @media (min-width: 768px) {
        max-width: 420px;
        display: flex;
        align-self: center;
      }
      z-index: 100;

      &--mobile {
        @include breakpoint(sm) {
          display: none;
        }
      }

      &--desktop {
        display: none;
        @include breakpoint(sm) {
          display: block;
        }
      }
    }

    &-details {
      z-index: 99;
      display: flex;
      flex-direction: column;
      justify-content: center;
      border-radius: $radius-sm;
      background-color: $color__gray-toolbar;
      max-width: 400px;
      @media (max-width: 768px) {
        width: calc(100% - $space-md);
        margin-top: -$space-sm;
        padding: $space-md $space-standard $space-standard $space-standard;
      }

      @media (min-width: 768px) {
        flex-grow: 1;
        display: flex;
        align-self: center;
        padding: $space-md $space-md $space-md $text__header-xxl;
        margin: $space-sm 0px;
        margin-left: -$space-sm;
      }
    }
  }

  &__image {
    border-radius: $space-sm;
  }

  &__button {
    padding: 0 $space-md !important;
    margin-top: $space-standard;

    &:hover {
      color: $color__white;
    }

    &-desktop {
      width: fit-content;
      display: none;

      @include breakpoint(sm) {
        display: flex;
        align-items: center;
      }
    }

    &-mobile {
      position: absolute;
      width: auto !important;
      right: $space-md;
      bottom: -$space-lg;
      padding: $space-standard $space-standard !important;
      display: table;

      @include breakpoint(sm) {
        display: none;
      }
    }
  }
  &__above-text {
    margin-top: $space-xxs;
    height: auto;
    opacity: 0.9;
    color: $color__gray-order;
    font-size: $text__body-sm;
    font-weight: 900;
    letter-spacing: 0;
    line-height: $line-height-xs;
    overflow-wrap: break-word;
  }
  &__title {
    color: $color__black;
    text-transform: uppercase;
    font-size: $text__body-xxl;
    font-weight: 900;
    letter-spacing: -0.72px;
    line-height: $line-height-xxxl;
    margin-top: $space-xxs;
  }
}

.hero1 {
  &__container {
    &-content {
      position: absolute;
      top: 50%;
      padding-left: 5%;
      transform: translateY(-50%);

      @media (max-width: 768px) {
        left: 0;
      }

      @include breakpoint(xs) {
        padding-left: 15%;
      }
    }

    &-image {
      width: 100%;

      &--mobile {
        min-height: 380px;
        @include breakpoint(sm) {
          display: none;
        }
      }

      &--desktop {
        min-height: 380px;
        display: none;
        @include breakpoint(sm) {
          display: block;
        }
      }

      > span {
        height: 100% !important;
      }

      img {
        min-height: 380px !important;
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
  }
}

.hero-variant-3 {
  &__image {
    height: 100%;
    width: 100%;
    max-width: 1140px !important;
    &-wrapper {
      width: 100%;
      display: flex;
      justify-content: center;

      &--mobile {
        @include breakpoint(sm) {
          display: none;
        }
      }

      &--desktop {
        display: none;
        @include breakpoint(sm) {
          display: block;
        }
      }
    }
  }
}

#home-hero {
  margin: 0 calc(-1 * $space-page-gutters);
  @include breakpoint(lg) {
    margin: 0;
  }
}
