.content-carousel {
    display: block;
    @include swiper-pagination-gradient();

    &--vertical {
        @include breakpoint-max(xs) {
            .swiper {
                -webkit-mask-image: none;
                mask-image: none;
            }
            .swiper-wrapper {
                flex-direction: column;
                padding-bottom: 0;
            }
            .swiper-slide {
                width: 100% !important;
                flex: 1;
                margin-bottom: $space-standard;
            }
            .swiper-pagination {
                display: none;
                visibility: hidden;
            }
        }
    }
}
