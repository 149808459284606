.error-page {
    padding: 100px $space-standard;
    margin: auto;
    text-align: center;
    max-width: 100%;
    display: block;
    
    @include breakpoint-max(sm) {
        max-width: 500px;
    }

    @include breakpoint(sm) {
        flex: 0 0 33%;
        max-width: 33%;
    }

    h2 {
        font-size: $text__body-lg;
        font-weight: bold;
        margin-bottom: $space-md;
        margin-top: $space-md;
        padding-top: $space-standard;
    }

    .btn__primary {
        @include breakpoint(xs) {
            max-width: none;
        }

    }

    img {
        margin-bottom: $space-md;
        width: 100%;
    }
}