.sweeps {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    background-image: url(/images/bubbles-bg.svg);
    background-size: 100%;
    &__breakpoint {
        @include breakpoint(xs) {
            width: 400px;
        }
    }
    &__image-header {
        display:flex;
        position: relative!important;
        width: 100%!important;
        height: auto!important;
        img {
            min-width: 50%!important;
            max-width: $space-max-width !important;
        }
    }
    &__body {
        display: flex;
        justify-content: center;
        flex-direction: column;
        width: 100%;
        margin: 0 auto;
        float: none;
        padding: $space-standard;
        padding-top: $space-xl;
    }
    &__intro {
        text-align: center;
        margin: 0 auto;
        float: none;
        width: 100%;
    }
    &__title {
        text-align: center;
        font-size: $text__body-xxl;
        font-weight: bold;
        padding-bottom: $space-md;
        width: 100%;
        margin: 0 auto;
        float: none;
    }
    &__subtitle {
        box-sizing: border-box;
        text-align: center;
        padding-bottom: $space-lg;
        width: 90%;
        margin: 0 auto;
        float: none;
    }
    &__form {
        form {
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: column;
        }
    }
    &__field {
        display: flex;
        justify-content: center;
        width: 75%;
        margin: 0 auto;
        @media (max-width: 350px) {
            width: 100%;
        }
    }
    &__label {
        color: $color__gray;
        font-size: $text__body-sm;
        font-weight: normal;
        width: 100%;

    }
    &__input {
        width: 100%;
        padding: $space-xs;
        border: 1px solid $color__input-border;
        border-radius: $radius-xs;
        color: $color__black;
        margin-top: $space-xxs;
        margin-bottom: $space-sm;

    }
    &__opt-in {
        display: flex;
        padding: $space-xl 0px;
        padding-top: $space-lg;
        align-items: flex-start !important;
        width: 90%;
    }
    &__label-checkbox {
        width: 100%;
        font-size: $text__body-xs;
        vertical-align: top;
        margin-left: $space-xs;
        line-height: $line-height-sm;
    }
    &__input-checkbox {
        margin-top: $space-xxxs;
        box-sizing: content-box;
        width: 20px;
    }
    &__submit {
        &-container {
            display: flex;
            justify-content: center;
            width: 85%;
            input {
                width: 100%;
                max-width: 100%;
                &:disabled {
                    border: 1px solid $color__gray-lightest;
                    background-color: $color__gray-lightest;
                    color: $color__gray-darkest;
                }
                margin-bottom: $space-xs;
                border: 0;
            }
        }
    }
    &__terms-conditions {
        display: flex;
        justify-content: center;
        align-items: center;
        height: $space-xl;
        padding-bottom: $space-md;
        margin-top: $space-xs
        a {
            font-size: $text__body-sm;
        }
    }
    &__success {
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        height: 100%;
        margin: 0 auto;
        float: none;
        min-height: 80vh;

        img {
            margin-bottom: $space-standard;
        }

        p {
            text-align: center;
            font-size: $text__body-xl;
            padding-top: 0;

            &:first-of-type {
                margin-bottom: $space-xs;
            }
        }
    }

    &__error-label {
        width: 100%;
        padding-bottom: $space-sm;
        text-align: center;
        display: block;
        color: $color__red;
    }
    .robocop {
        opacity: 0;
        position: absolute;
        top: 0;
        left: 0;
        height: 0;
        width: 0;
        z-index: -1;
    }
}