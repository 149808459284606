.pwa-popup {
  padding: $space-lg $space-standard;
  .btn__secondary {
    margin-top: $space-sm;
  }
  .popup-text {
    margin: calc($space-xs + $space-sm) 0;
    color: $color__gray-darkest;
    font-family: sofia-pro, sans-serif;
    font-weight: normal;
    font-size: $text__body-base;
    display: block;
    text-align: center;
    width: 100%;
  }
}
.pwa-popup-safari {
  svg {
    margin: auto;
    display: block;
    color: $color__blue;
  }
}
