.resetpassword-form {
    position: relative;
    padding: 0 16px 32px;
    padding-left: 16px;
    margin: 0 auto;
    margin-top: 32px;
    width: 100%;
    max-width: 492px;
    h1 {
        margin-top: 20px;
        font-size: 16px;
        line-height: 1.2;
    }

    &__current {
        padding-bottom: $space-standard;
        font-size: $text__body-sm;
        label {
            color: $color__gray;
            display: block;
            margin-bottom: 5px;
        }
        .password-field {
            margin-top: $space-xxs;
        }
        input {
            border: 1px solid $color__input-border;
            background-color: $color__white;
            width: 100%;
            border-radius: $radius-xs;
            padding: $space-sm;
            box-shadow: -1px 4px 10px 6px rgba(0, 0, 0, 0.06);
        }
        .eyeball {
            width: $space-md;
            height: $space-md;
            float: right;
            position: relative;
            top: -35px;
            right: $space-sm;
            background-repeat: no-repeat;
            background-size: contain;
        }
    }

    &__new {
        padding-bottom: $space-standard;
        font-size: $text__body-sm;
        label {
            color: $color__gray;
            display: block;
            margin-bottom: 5px;
        }

        .password-checklist {
            font-size: 12px;
            padding: 0 0 0 20px;
            li {
                cursor: default;
                list-style: none;
                position: relative;
                margin-bottom: 10px;
                color: #000;
            
                &.checkmark {
                &::before {
                    content: "";
                    width: $space-standard;
                    height: $space-standard;
                    -webkit-border-radius: $space-standard;
                    -moz-border-radius: $space-standard;
                    border-radius: $space-standard;
                    border: 1px solid $color__gray-light;
                    position: absolute;
                    left: -20px;
                    top: 1px;
                }
            
                &.checkmark-done {
                    color: #008a25;
            
                    &::before {
                    content: "";
                    width: $space-standard;
                    height: $space-standard;
                    -webkit-border-radius: $space-standard;
                    -moz-border-radius: $space-standard;
                    border-radius: $space-standard;
                    border: 1px solid $color__gray-light;
                    position: absolute;
                    left: -20px;
                    top: 1px;
                    background-color: #008a25;
                    background-image: url(/images/icons/check-registration.svg);
                    background-repeat: no-repeat;
                    background-position: center;
                    }
                }
            
                &.checkmark-done-error {
                    color: red;
            
                    &::before {
                    content: "";
                    width: $space-standard;
                    height: $space-standard;
                    -webkit-border-radius: $space-standard;
                    -moz-border-radius: $space-standard;
                    border-radius: $space-standard;
                    border: 1px solid red;
                    position: absolute;
                    left: -20px;
                    top: 1px;
                    background-color: red;
                    background-image: url(/images/icons/check-registration.svg);
                    background-repeat: no-repeat;
                    background-position: center;
                    }
                }
                }
            }
        }

        .password-field {
            margin-top: $space-xxs;
            .error-label {
                padding-bottom: 20px;
                margin-top: 10px;
                text-align: center;
                display: block;
                font-size: $text__body-base;
                color: $color__red;
            }
        }
        input {
            border: 1px solid $color__input-border;
            background-color: $color__white;
            width: 100%;
            border-radius: $radius-xs;
            padding: $space-sm;
            box-shadow: -1px 4px 10px 6px rgba(0, 0, 0, 0.06);
        }
        .eyeball {
            width: $space-md;
            height: $space-md;
            float: right;
            position: relative;
            top: -35px;
            right: $space-sm;
            background-repeat: no-repeat;
            background-size: contain;
        }
    }

    &__button {
        padding-bottom: 15px;
        .btn__primary {
            font-weight: bold;
        }
    }
}