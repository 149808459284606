.shop-switch-picker{
    &__color-picker {
        max-width: 100%;
        position: relative;
        padding-right: $space-standard;
        padding-left: $space-standard;
        display: flex;
        flex-wrap: wrap;
        margin-right: -$space-standard;
        margin-left: -$space-standard;
        margin-bottom: $space-xs;

        &__label{
            color: $color__gray-order;
            margin-top: $space-standard;
            font-size: $text__body-base;
            font-weight: 300;
            margin-right: $space-xs;
        }

        &__value{
            color: $color__gray-order;
            margin-top: $space-standard;
            font-size: $text__body-base;
            font-weight: bold;
            margin-right: $space-xs;
        }
    }
}
