.order-title {
  background-color: $color__primary;
  display: flex;
  width: 100%;
  height: 59px;

  &__wrapper {
    display: flex;
    width: 100%;
    max-width: $space-max-width;
    margin: auto;
    padding: 0 $space-standard;
  }

  @include breakpoint-max(sm) {
    margin-bottom: $space-standard;
  }

  label {
    display: flex;
    align-items: center;
    font-size: $text__body-base;
    font-weight: bold;
    color: $color__white;
    margin: 0;
    text-transform: lowercase;

    &:first-of-type {
      flex: 1;
    }

    span {
      font-size: $text__body-lg;
      color: $color__white;
      font-weight: bold;
      text-transform: lowercase;

      @include breakpoint-max(sm) {
        font-size: $text__body-lg;
      }
    }
  }
}
