.footer {
  a, #clarip-do-not-sell-link {
    &:focus {
      outline: $outline__white;
      outline-offset: $space-xxxxs;
    }
  }

  &--std {
    background-color: $color__primary;
  }

  &--auth {
    border-top: 1px solid #ccc;
    width: 100%;
    display: flex;
    justify-content: space-between;
    padding: 10px 25px;

    @media (max-width: 576px) {
      flex-direction: column;
    }

    a, #clarip-do-not-sell-link {
      display: inline-block;
      margin-right: 15px;
      font-size: 15px;

      &:focus {
        outline: $outline__normal;
        outline-offset: 6px;
      }
    }

    p {
      font-size: 15px;
    }

    @media (max-width: 800px) {
      a, #clarip-do-not-sell-link {
        display: block;
        text-align: center;
        margin-bottom: 10px;
      }

      p {
        text-align: center !important;
        line-height: 20px;
      }
    }
  }

  &--hide {
    display: none;
  }

  &__top {
    padding: 40px 20px 20px 20px;
    min-height: 200px;
    display: flex;
    max-width: 1160px;
    margin: auto;

    @media (max-width: 576px) {
      flex-direction: column;
      text-align: center;
    }
  }

  &__brand {
    height: 30px;
  }

  &__social-media {
    text-align: left;
    margin: 40px 0 35px;
    cursor: auto;

    @media (max-width: 576px) {
      text-align: center;
      margin: 40px 0 35px;
    }
  }

  &__social-media-icon {
    width: 30px;
    height: 30px;
    display: inline-block;
    margin-right: 20px;
    background-repeat: no-repeat;
    width: $space-md;
    height: $space-md;
    display: inline-block;

    &:last-child {
      margin-right: 0;
    }
  }

  &__section {
    flex: 1;
    padding: 0;
    list-style-type: none;
    margin: 0;
  }

  &__page-item {
    padding-bottom: 10px;
    display: flex;
    width: 100%;
    font-weight: bold;
    font-size: 16px;
    line-height: $line-height-md;
    align-items: center;

    @media (max-width: 576px) {
      justify-content: center;
    }

    a, #clarip-do-not-sell-link {
      color: $color__white;

      &:hover {
        color: $color__white;
        cursor: pointer;
      }
    }

    img {
      height: $space-standard;
      margin-left: $space-xs;
    }
  }

  &__bottom {
    color: #fff;
    background-color: black;
    text-align: center;
    padding: 30px 0;

    p {
      padding: 0px;
      margin: 0px;
      font-weight: bold;
      line-height: 24px;
      color: #ffffff;
      font-size: 14px;
    }
  }
}