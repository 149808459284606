ion-card {
  margin-left: 5px;
  margin-right: 5px;
}

.pop-card {
  margin: 0;
  cursor: pointer;
  margin-bottom: 10px;
}

.category-image-wrap {
  width: 100%;
  position: relative;

  &:after {
    content: "";
    display: block;
    padding-bottom: 100%;
  }
}

// #category-image-focus {
//   &:focus {
//     outline: $outline__normal;
//     outline-offset: $space-xxxxs;
//   }
// }

.category-image {
  position: absolute;
  width: 100%;
  height: 100%;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  border-radius: 50%;
  overflow: hidden;
  border: $outline__circle-image;

  &:focus {
    outline: $outline__normal;
    outline-offset: $space-xxxs;

    @include breakpoint (md) {
      outline-offset: -1px;
    }
  }

  img {
    height: 100% !important;
    width: 100% !important;
    object-fit: cover;
  }
}

.pop-sub-categories {
  margin-bottom: $space-standard;
  width: 144px;
  cursor: pointer;

  p {
    font-size: $space-standard;
    line-height: $line-height-md;
    font-weight: 500;
    text-align: center;
    color: $color__black;
    margin-top: $space-xs;
    text-transform: capitalize;
    width: 100%;
    margin-bottom: $space-xs;
  }

  ion-card-header {
    padding: 10px;

    .arrow {
      width: 20px;
      height: 15px;
      float: right;
      background-image: url("/images/icons/arrow.svg");
      background-repeat: no-repeat;
      background-position: center;
      margin-top: 14px;
    }
  }
  &-focus:focus {
    outline: none;
    .pop-sub-categories {
      outline: $outline__normal;
    }
  }
}


.swiper-slide .pop-sub-categories {
  margin-bottom: 0;
}

.circle-image-wrapper {
  cursor: default;
}

/* // Small devices (landscape phones, 576px and up) */
@media (max-width: 576px) {}