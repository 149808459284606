.write-review {
  display: flex;
  justify-content: center;
  flex-direction: column;
  margin-top: $space-lg;

  > h1 {
    @include page-container(lg);
    padding: 0 $space-lg !important;
    font-size: $text__body-lg;
    font-weight: bold;
  }

  &__wrapper {
    @include page-container(lg);
    
    .write-review-select-star-wrap {
      margin-bottom: 15px;
    }

    .p-w-r {
      .pr-btn-add-tag {
        a {
          @extend .btn__secondary;
          display: flex;
          align-items: center;
          color: $color__primary !important;
          text-decoration: none;
          margin-top: $space-xs;

          &:after {
            color: $color__primary;
          }
          &:focus {
            outline: $outline__normal !important;
            outline-offset: 2px;
            box-shadow: none;
          }
        }
        .pr-btn-review:focus {
          outline: $outline__normal !important;
          outline-offset: 2px;
          box-shadow: none;
        }
      }

      .input-add-tag {
        display: flex;
        flex-direction: column;

        .form-control {
          margin-bottom: $space-standard;
        }

        .input-group-btn {
          width: 100%;
          .pr-btn-default {
            height: 46px;
            width: auto;
            &:hover {
              background-color: $color__primary-dark;
            }

            @include breakpoint(lg) {
              max-width: fit-content;
            }
            div {
              color: $color__white;
              height: auto;
              margin: $space-sm $space-xxxxl;
            }
          }
        }
      }

      .tag-group {
        .pr-label-control {
          &.checked {
            border-radius: 24px;
            background-color: $color__primary-light;
            text-align: center;
            border: 0;
            margin-top: $space-sm;
            box-shadow: 0 0 0 1px $color__primary !important;

            span {
              &:first-of-type {
                text-transform: capitalize;
                color: $color__black;
                font-size: $text__body-base;
                letter-spacing: $letter__spacing-sm;
                line-height: $line-height-xl;
                text-align: center;
                font-weight: normal;
                padding: $space-sm 0;
              }
            }
          }
        }
      }

      .pr-alert-container {
        margin-top: $space-lg !important;
      }
    }
    a.pr-underline {
      text-decoration: none !important;
      color: $color__primary !important;

      &:focus {
        outline: $outline__normal !important;
        outline-offset: 2px;
        box-shadow: none;
      }
    }

    .pr-clear-all-radios {
      color: $color__primary !important;
    }
    .pr-subscript a {
      color: $color__primary !important;

      &:focus {
        outline: $outline__normal !important;
        outline-offset: 2px;
        box-shadow: none;
      }
    }
    .p-w-r .pr-alert_list {
      padding-top: $space-xl;
    }
    .pr-submit {
      margin-bottom: $space-standard;
    }
  }
}
