.new-user {
    h1{
        font-size: $text__body-md;
        text-align: center;
        color: $color__black-light;
        margin-bottom: $space-standard;
        padding: 0 $space-standard;
    }
    
    h2{
        font-size:  $text__body-md;
        margin-bottom: $space-xs;
        color: $color__black;
    }

    &__container {
        padding: $space-xl $space-lg;
        padding-bottom: $space-md;
    }

    &__close {
        z-index: 1000;
        margin-top: $space-xs;
        &:hover {
            cursor: pointer;
        }
    }
    &__pickup-container{
        flex: 0 0 100%;
        max-width: 100%;
        margin: 0px;
        position: relative;
        display: flex;
        flex-wrap: wrap;
    }

    &__pickup-order-text{
        font-size: $space-sm !important;
        line-height: $line-height-sm !important;
        padding-left: 0px;
        display: flex;
        max-width: 50%;
        text-align: left;
        margin-bottom: $space-xs !important;
        @include breakpoint-max(xs){
            font-size: $text__body-xxs !important;
        }
    }

    &__map-container{
        border: $border__gray;
        flex: 100%;
        max-width: 375px;
        margin: auto;
    }

    &__pickup{
        display: flex;
        max-width: 100%;
        margin-bottom: $space-sm;
    }

    &__btn-container{
        padding-left: $space-md;
        flex: 50%;
        max-width: 50%;
    }

    &__pick-it-up-btn{
        @extend .btn__primary;
        padding: $space-xs $space-standard;
        max-width: 100%;
        font-size: $text__body-sm;
        line-height: $space-standard;
        margin-bottom: $space-xxs;
    }

    &__confirm-your-store-btn {
        @extend .btn__primary;          
        max-width: 100%;
    }

    &__text {
        display: none;
        font-size: $text__body-md;
        color: #000 !important;
        font-weight: lighter !important;
    }

    &__header {
        font-weight: bold !important;
        font-size: $text__body-xxl;
        margin: $space-sm 0 $space-standard;
        color: $color__black !important;
        text-align: center;
    }

    &__card{
        width: 100%;
        margin-bottom: $space-standard;
        border: 1px solid $color__gray-lighter;
        border-radius: $space-xs;
        box-shadow: 0 $space-xxs $space-md 1px $color__gray-lighter;
    }

    &__pickup-model{
        display: flex;
        flex-wrap: wrap;
        margin-right: $space-standard;
        margin-left: $space-standard;
    }

    &__pickup-text{
        font-size: $text__body-md;
        margin-bottom: $space-standard!important;
        text-align: left;
        font-weight: bold;
        margin-top: $space-xs;
        color: black !important;
        img{
            margin-top: $space-xxxxs;
            margin-right: $space-xxs;
            float: left;
            width: $space-md;
            opacity: 0.6;
        }
    }

    &__image {
        height: 200px;
        width: calc(100% + 40px);
        margin-left: -space-md;
        margin-right: -space-md !important;
    }

    &__address {
        margin-bottom: $space-lg;
        padding-bottom: $space-md;
        font-size: $text__body-md;
        border-bottom: 1px solid $color__primary-light;
        font-weight: bolder !important;
        color: $color__black;
        text-align: center;
        width: 100%;
        padding-left: $space-md;
        padding-right: $space-md;
    }

    &__map-area {
        height: 140px;
        width: 100%;
        margin-bottom: 10px;
        background-size: cover;
        background-position: center;
    }

    .map-area {
        margin-bottom: 0;
    }

    .swiper-slide {
        visibility: hidden;
        }
        .swiper-slide.swiper-slide-active {
        visibility: visible;
        }

    &__error {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding: $space-lg;
        padding-top: $space-xxxxl;

        .alert-full {
            width: $space-xxl;
            height: $space-xxl;
            margin-bottom: $space-md;
        }

        h5, p {
            text-align: center;
        }

        h5 {
            font-weight: bold;
        }

        p {
            margin: $space-md 0;
            &:last-of-type {
                margin-bottom: $space-xl;
            }
        }

        button {
            width: 100%;
            max-width: 100%;
        }
    }
}


