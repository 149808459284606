.popover {
    &__container {
        display: flex;
        &-item {
            font-size: $text__body-base;
            display: -webkit-box;
            -webkit-line-clamp: 2;
            width: auto;
        }
        &-close {
            height: calc($space-xs* 2.5);
            width: calc($space-xs* 2.5);
            padding: 0px;
            cursor: pointer;
            svg {
                height: calc($space-xs* 2.5);
                width: calc($space-xs* 2.5);
            }
        }
    }
}
.tooltipCart {
    --offset-y: -25px;
}

// GLOBAL popover STYLES
ion-popover {
    --offset-y: -7px;
    // Desktop
    &::part(content) {
        background-color: $color__primary-light;
        border: 1.5px solid black;
        padding: $space-sm;
        width: 280px;
    }

    &::part(arrow) {
        overflow: visible;
        z-index: 100;
        &:after {
            background-color: $color__primary-light;
            border-top: 1.5px solid black;
            border-left: 1.5px solid black;
            top: 1px;
            height: 18px;
            width: 18px;
        }
    }
}