.add-to-cart {
    flex: auto;
    flex-shrink: 0;
    position: relative;
    &__hidden-div {
        position: absolute;
        height: 1px;
        width: 1px;
        top: 0;
        left: -2px;
        overflow: hidden;
    }
    span {
        font-size: $text__body-md;
        line-height: $line-height-xxl;
        width: 28px;
        height: 24px;
    }
    &__new-item {
        margin: auto;
        max-width: 120px;
        min-width: 120px;
    }
    &__button {
        align-items: center;
        @extend  .btn__secondary;
        min-width: 90px;
        max-width: 120px;
        height: 32px;
        padding: $space-xxxxs;
        border: $outline__heavy;
        display: flex;
        justify-content: space-between;
        button {
            padding: 0;
            width: $space-md;
            height: $space-md;
        }
        &-minus__animated {
            height: $space-md !important;
            width: $space-md !important;
            border: $outline__heavy !important;
            background-color: $color__white !important;
            border-top: $outline__white-heavy !important;
            transition: all 500ms !important;
            border-radius: $radius-round !important;
            color: $color__white !important;
            border-top-color: #fff;
            animation: spinner 0.6s linear infinite;
            transition-property: margin-left;
            left: calc(50% - 12px);
            position: absolute;
        }
        &-plus__animated {
            height: $space-md !important;
            width: $space-md !important;
            border: $outline__heavy !important;
            background-color: $color__white !important;
            border-top: $outline__white-heavy !important;
            transition: all 500ms !important;
            border-radius: $radius-round !important;
            color: $color__white !important;
            border-top-color: #fff;
            animation: spinner 0.6s linear infinite;
            transition-property: margin-right;
            right: calc(50% - 12px);
            position: absolute;
        }    
        p {
            font-size: $text__body-md;
            margin-bottom: 0;
        }
    }
}
.btn__primary-animated{
    display: flex;
    float: none;
    border: $outline__heavy !important;
    color: #87189d !important;
    background-color: #ffffff !important;
    border-top: 2px solid white !important;
    -webkit-transition: all 0.2s !important;
    transition: all 0.2s !important;
    border-radius: 50% !important;
    width: 32px !important;
    height: 32px !important;
    color: white !important;
    -webkit-animation: spin 0.75s linear infinite;
    animation: spinner 0.75s linear infinite;
    flex: none !important;
    padding: 0 !important;
    min-width: 0px !important;
}
.product-card__vertical {
    .add-to-cart {
        &__button {
            width: 120px;

            button {
                padding: 0;
            }
        }
    }
}
.cart-delete-item {
    right: $space-standard;
    bottom: 10px;
    position: absolute;
    font-weight: bold;
    color: $color__primary;
    background-color: $color__white;
}

  @keyframes spinner {
    to {
      transform: rotate(360deg);
    }
  }