.recently_viewed {
    &_title {
        text-align: left;
        font-size: $text__body-lg;
        font-weight: bold;
        margin-bottom: $space-standard;     
        text-transform: lowercase;
        @include breakpoint (xs){        
            text-align: center;
        }
    }
    @include swiper-pagination-gradient();
}