.reward-container {
    min-width: $space-xxxxl + $space-xs;
    height: $space-xxxxl + $space-xs;
    border-radius: $radius-round;
    background: $color__primary;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: $space-xxs;

    &__content {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
   

        &-dollar {
            font-size: $text__body-xxl;
            font-weight: bold;
            line-height: $line-height-xxl;
            color: $color__white;
        }

        &-amount {
            font-size: $text__header-xxl + $text__header-xxl;
            font-weight: bold;
            line-height: $line-height-xl + $line-height-xl + $line-height-lg;
            color: $color__white;
            margin-bottom: $space-xxxs + $space-xxs;
        }

        &-discount {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;

            &-percent {
               display: flex;
               align-items: center;
               justify-content: center;
                h1 {
                    font-size: $text__body-xxl + $text__body-xxl + $text__body-xxs;
                    font-weight: bold;
                    line-height: $line-height-xxxxxxl;
                    letter-spacing: $letter__spacing-md - $letter__spacing-xl;
                    color: $color__white;
                }

                span {
                    font-size: $text__body-xxl;
                    line-height: $line-height-xxl;
                    color: $color__white;
                    padding-left: $space-xxxs;
                }
            }

            &-off {
                font-size: $text__body-md;
                line-height: $line-height-md;
                color: $color__white;
                margin-bottom: $space-xxs;
            }
        }
    }
}