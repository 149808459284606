.product-list-page {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    grid-column-gap: $space-md;
    row-gap: $space-standard;
    margin-bottom: $space-lg;

    &__grid {
        grid-template-columns: repeat(2, 1fr);
        @include breakpoint-max (xs){
            grid-column-gap: $space-xs;
        }
    }

    @include breakpoint(xs) {
        grid-template-columns: repeat(2, 1fr);
    }

    @include breakpoint(sm) {
        grid-template-columns: repeat(3, 1fr);
    }

    @include breakpoint(md) {
        grid-template-columns: repeat(4, 1fr);
    }
}