.sweeps-rules {
    &__body {
        padding: 0 $space-standard;
        @include page-container(lg);
    }

    &__title {
        text-align: center;
        font-weight: bold;
    }

    &__subtitle {
        text-align: center;
        font-weight: bold;
    }

    &__address {
        display: flex;
        justify-content: center;
        padding: 0 $space-standard;
    }

    p {
        margin-bottom: $space-standard;

        span {
            font-weight: bold;
        }
    }

    a {
        text-decoration: underline;
    }
}