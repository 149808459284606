.main-carousel {
  &--mobile {
    object-fit: contain;

    @include breakpoint(sm) {
      display: none;
    }

    .swiper {
      padding: $space-md 0 !important;
      height: 50vh;
      
      &-slide {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        div {
          height: 100%;

          img {
            height: 100%;
            width: 100%;
            object-fit: contain;
          }
        }
      }
    }

    .swiper-pagination {
      bottom: -5px !important;
      z-index: 1000;
      &-bullet {
        &:focus {
          outline: $outline__normal;
        }
      }
    }
  }

  &--desktop {
    object-fit: contain;
    display: none;

    @include breakpoint(sm) {
      display: block;
    }

    .swiper {
      &-slide {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
      }

      &-button {
        &-next {
          &:after {
            display: none !important;
          }
        }

        &-prev:after {
          display: none !important;
        }
      }

      &-pagination {
        &-pdp {
          display: flex;
          justify-content: center;
          flex-wrap: wrap;

          &-wrap {
            @include breakpoint(xs) {
              justify-content: left;
            }
          }

          .swiper-pagination-bullet {
            height: 60px !important;
            width: 60px !important;
            border: 2px solid $color__off-white;
            border-radius: 4px !important;
            background-color: #FFFFFF !important;
            margin-right: $space-xs;
            margin-bottom: $space-xs;
            opacity: 1;
            object-fit: cover;
            &-active {
              border: $outline__heavy;
              opacity: 1;
            }

            &:hover {
              border: $outline__heavy;
            }
          }
        }
      }
    }
  }
}

.quickview {
  &__modal {
    &-images {
      .main-carousel {
        &__figure {
          height: 332px;
        }
      }
    }
  }
}

.pdp-carousel {
  .main-carousel {
    &__figure {
      @media (min-width: 576px) {
        height: 555px;
      }
    }
  }
}

.enable-zoom{
  &:hover {
    cursor: crosshair !important;
    background-size: 300%;
  }

  &:hover img {
    opacity: 0;
    cursor: crosshair !important;
  }
}

.main-carousel {
  &__figure {
    background-repeat: no-repeat;
    background-size: 0%;
    margin: 1em 40px;

    & img {
      display: block;
      max-width: 100%;
      pointer-events: none;
      max-height: 100%;
      object-fit: contain;
    }
  }
}

.carousel {
  text-align: center;

  &__product {
    &-container {
      &-mobile {
        width: 95%;
        margin-bottom: 10px;
      }
    }

    &-img {
      &-mobile {
        width: 100%;
      }
    }

    &-modal {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 100%;
      width: 100%;
      flex-direction: column;

      &-carousel {
        display: flex;
        flex-direction: row;
        width: 100%;
        overflow-x: auto;

        ::-webkit-scrollbar {
          display: none;
        }
      }

      &-zoom {
        height: 35px;
        width: 195px;
        opacity: 0.75;
        border-radius: 6px;
        background-color: #1c283b;
        display: flex;
        justify-content: center;
        align-items: center;
      }

      &-text {
        height: 14px;
        color: #ffffff;
        font-size: 12px;
        font-weight: 900;
        letter-spacing: 0.26px;
        line-height: 14px;
        text-align: center;
        margin-bottom: 0px !important;
      }
    }
  }
}