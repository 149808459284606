.product-card {
  &__container:focus-within {
    .product-card {
      outline: $outline__normal !important;
      outline-offset: -2px;
  
    }
  }
  position: relative;
  padding: $space-sm;
  padding-bottom: $space-standard;
  border: $border__gray-lighter;
  border-radius: $radius-xs;
  background-color: $color__white;
  box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.05);
  width: auto;
  
  &__details {
    &-number {
      position: absolute;
      left: $space-standard;
      top: 7%;
      height: $space-md;
      width: $space-md;
      background-color: $color__primary;
      border-radius: $radius-round;
      text-align: center;
      font-weight: bold;
      vertical-align: center;
      color: $color__white;
      z-index: 100;
      line-height: $line-height-base;
      font-size: $text__body-sm;
      padding-top: $space-xxxxs;
    }
    &-image {
      height: 200px;
      position: relative;
      margin-bottom: $space-xs;

      img {
        object-fit: contain;
      }

      button {
        display: none;
        z-index: 9999;
        position: absolute;
        @extend .btn__secondary;
        font-size: $text__button-sm;
        padding: $space-xxs $space-standard;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        height: $space-lg;
        white-space: nowrap;
        @include breakpoint(lg) {
          display: block;
          opacity: 0;
        }
        &:focus {
          @include breakpoint(lg) {
            opacity: 1;
          }
        }
      }
      &:hover {
        button {
          @include breakpoint(lg) {
            opacity: 1;
          }
        }
      }
    }
    &-name {
      font-size: $text__body-base;
      font-weight: 900;
      letter-spacing: 0;
      line-height: $line-height-lg;
      @include line-clamp(2);
      margin-bottom: $space-xs;
      height: $space-xl;
    }

    &-rating {
      display: flex;
      height: $space-sm;
      p {
        height: 12px;
        width: 20px;
        margin-left: $space-xxxs;
        color: #636363;
        font-size: $text__body-xxs;
        font-weight: 300;
        letter-spacing: 0;
        line-height: 11.25px;
      }
      margin-bottom: $space-xxxs;
    }

    &-price {
      display: flex;
      &-default {
        color: #1f1f1f;
        font-size: $text__body-md;
        font-weight: 900;
        letter-spacing: 0;
        line-height: $line-height-lg;
        height: $line-height-lg;
      }
      &-discount {
        color: #636363;
        font-size: $text__body-xs;
        letter-spacing: 0;
        line-height: $line-height-base;
        margin-left: $space-xxs;
        margin-top: $space-xxxs;
        height: $space-standard;
      }
      margin-bottom: $space-xs;
    }

    &-variants {
      p {
        color: #636363;
        font-size: $text__body-xs;
        letter-spacing: 0;
        line-height: $line-height-base;
      }
      height: $space-standard;
      margin-bottom: $space-xs;
    }
  }

  &__availability {
    &__text {
      font-size: $text__body-sm;
    }
    &-fulfillment {
      p, span {
        font-size: $text__body-sm;
        letter-spacing: 0;
        line-height: $line-height-base;
        padding-left: 0;
        @include line-clamp(1);
        &.oos {
          color: $color__red;
        }
        span {
          display: none !important;
          &.oos {
            color: $color__red;
            padding-left: 0;
          }
        }
      }
      &-greencheck {
        display: flex;
        margin: auto;
        svg {
          width: calc($space-xxxs * 5);
          height: calc($space-xxxs * 5);
        }
        p {
          margin-left: $space-xxxs;
          font-size: $text__body-sm;
          letter-spacing: 0;
          line-height: $line-height-base;
          @include line-clamp(1);
          &.oos {
            color: $color__red;
          }
        }
        &__mobile-text {
          display: none !important;
        }
      }
      margin-bottom: $space-standard;
    }

    .btn__primary {
      padding: $space-xxs $space-xs;
      max-width: 120px;
      min-width: 120px;
      margin: auto;
      line-height: $line-height-md;
      height: 32px;
      font-size: $text__button-sm;
      letter-spacing: $letter__spacing-xxxs;
    }
    .btn__secondary {
      @extend .btn__secondary;
      color: #9230a8;
      padding: 0 $space-xs;
      max-width: 120px;
      min-width: 120px;
      margin: auto;
      line-height: $line-height-md;
      height: 32px;
      font-size: $text__button-sm;
    }
    &:hover {
      .btn__secondary {
        color: #540265;
      }
    }
    &__setbutton {
      display: flex;
    }
  }

  &__force-vertical {
    .product-card {
      &__details {
        display: flex;
        &-image {
          height: $space-xxxxl;
          width: $space-xxxxl;
          margin-right: $space-xs;
        }
        &-desc {
          flex-grow: 1;
        }
        &-metadata {
          display: flex;
          justify-content: space-between;
        }
        &-price {
          display: inline;
          text-align: right;
          p {
            margin-top: 0;
          }
        }
        &-variants {
          margin-top: $space-xxs;
        }
      }
      &__availability {
        display: flex;
        justify-content: space-between;
        button {
          margin: 0px;
        }
        &-fulfillment {
          p {
            span {
              display: inline !important;
            }
          }
          &-greencheck {
            &__mobile-text {
              display: inline !important;
              margin-left: 0px;
            }
          }
          margin-bottom: 0;
        }
        &__setbutton {
          flex: none;
          width: 120px;
          justify-content: center;
        }
      }
    }
  }

  &__vertical {
    @include breakpoint-max(xs) {
      padding: $space-standard;
      .product-card {
        &__details {
          display: flex;
          &-image {
            height: $space-xxxxl;
            width: $space-xxxxl;
            margin-right: $space-xs;
          }
          &-desc {
            flex-grow: 1;
            width: 190px;
          }
          &-metadata {
            display: flex;
            justify-content: space-between;
          }
          &-price {
            display: inline;
            text-align: right;
            p {
              margin-top: 0;
            }
          }
          &-variants {
            margin-top: $space-xxxs;
          }
        }
        &__availability {
          display: flex;
          justify-content: space-between;
          button {
            margin: 0px;
          }
          &-fulfillment {
            p {
              span {
                display: inline !important;
              }
            }
            &-greencheck {
              &__mobile-text {
                display: inline !important;
                margin-left: 0px;
              }
            }
            margin-bottom: 0px;
          }
          &__setbutton {
            flex: none;
            width: 120px;
            justify-content: center;
          }
        }
      }
    }
  }
}
