.category-page {
    min-height: calc(100vh - 334px) !important;
    display: flex;
    flex-direction: column;
    align-items: center;
    @include breakpoint(xs) {
        padding-top: 0px;
    }

    &__container {
        @include page-container(lg);

        &__header {
            margin: 0 calc(-1 * $space-md);
            @include breakpoint(lg) {
                margin: 0;
            }
            .general-image-hero__wrapper-margin {
                margin-top: 0;
            }
        }
    }

    &__header {
        width: 100%;
    }

    &__selectors {
        align-items: center;
        width: 100%;
        margin-bottom: $space-md;
        @include breakpoint(xs) {
            display: flex;
            justify-content: space-between;
        }
        &-container {
            display: flex;
            justify-content: space-between;
            @include breakpoint-max(xs) {
                margin-top: $space-md;
            }
            ion-menu-button {
                height: 29px;
                width: 92px;
                border-radius: $radius-xs;
                color: $color__primary;
                font-size: $text__button-sm;
                font-weight: bold;
                border: 1.5px solid $color__primary;
            }
        }
        &-icons {
            button {
                &:hover {
                    svg {
                        fill: $color__primary;
                    }
                }
            }
            button.activate {
                svg {
                    color: $color__primary;
                }
            }
            svg {
                transition: all 0.2s;
                fill: $color__gray-order;
            }
            @include breakpoint(xs) {
                display: none;
            }
        }
    }

    &__view-more {
        display: flex;
        justify-content: center;
        width: 100%;
        margin-bottom: $space-md;

        button {
            max-width: 320px;
        }
    }

    &__seo {
        #seo-copy-details {
            a:focus {
              outline: $outline__normal;
            }
          }
        @include page-container(lg);
    }
}
