.ion-page .qrcode {
    @include breakpoint-max(sm) {
        margin-top: $space-sm;
        padding-top: $space-xxxxl;
    }
}

.qrcode {
    padding: 56px 40px 56px 40px;
    p {
        font-size: $space-md;
        line-height: $line-height-xxl;
        padding-bottom: $space-md;
    }

    p .rewards-text {
        text-shadow: 0px 1px, 1px 0px, 1px 1px;
        font-size: $text__header-xxxl;
        line-height: $line-height-xxxxl;
        padding-bottom: $space-sm;
    }

    h4 {
        padding-top: $space-xl;
        padding-bottom: 32px;
        font-size: $text__body-lg;
        font-weight: 500;
    }

    & .rewards__close.btn__secondary {
        width: 85%;

        @include breakpoint-max(sm) {
            width: 80%;
        }
    }
}