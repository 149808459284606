.pop-pills-horizontal-slider {
  width: 100%;
  overflow: hidden;
  overflow-x: scroll;
  margin-bottom: 25px;
  -ms-overflow-style: none;
  &::-webkit-scrollbar {
    display: none;
  }
  ul {
    width: 1000px;
    padding-left: 10px;
    margin: 0px;
    li {
      list-style: none;
      width: auto;
      float: left;
      ion-badge {
        padding-right: 12px;
        padding-left: 12px;
        padding-top: 7px;
        padding-bottom: 7px;
        text-transform: capitalize;
        -webkit-border-radius: 20px;
        -moz-border-radius: 20px;
        border-radius: 20px;
      }
    }
  }
}

/* // Small devices (landscape phones, 576px and up) */
@media (max-width: 576px) {
}
