.skip-to-main {
    position: absolute;
    height: 1px;
    width: 1px;
    overflow: hidden;
    clip-path: inset(1px, 1px, 1px, 1px);
    font-family: 'sofia-pro', sans-serif;
    font-size: 14px;
    color: $color__primary;
    text-decoration: underline;
    outline: none;  

    &:focus,
    &:active {
      position: relative;
      display: flex;
      align-items: center;
      height: auto;
      width: auto;
      margin: $space-standard;
      overflow: visible;
      clip-path: none;

    }
}
