.nestedimage {
  display: flex;
  flex-direction: column;
  @include breakpoint(sm) {
    flex-direction: row
  }
  &__img {
    flex-grow: 1;
    @include breakpoint(sm) {
      flex-grow: 3;
    }
  }
  &__txt {
    display: block;
    width: 100%;
    flex-grow: 1;
    padding: $space-sm;
    text-align: left;
    white-space: pre-wrap;
  }
}
