.product-preview-card {
    &:focus-within {
        outline: $outline__normal;
    }

    &__container {
        height: 225px;
        width: 164px;
        box-sizing: border-box;
        border: $border__gray-lighter;
        display: flex;
        flex-direction: column;
        padding: $space-sm $space-sm;
        background-color: #ffffff;
        box-shadow: $shadow__search-card;
        margin-bottom: $space-xxxs;
        cursor: pointer;

        &-image {
            margin: 0 auto;
        }

        div:last-of-type {
            h6 {
                @include line-clamp(2);
                margin: 0 0 $space-xs;
                line-height: $line-height-lg;
                height: 2 * $line-height-lg;
            }

            svg {
                margin-bottom: $space-xxxs;
            }

            div {
                display: flex;
                justify-content: space-between;

                p {
                    margin-bottom: 0;
                }

                svg {
                    margin-bottom: 0;
                    position: absolute;
                    right: $space-sm;
                }
            }

        }
    }
}