.cart-total {
  &__title {
    margin-bottom: $space-sm;
    line-height: $space-standard;
    font-weight: 700;
  }
  .checkbox-wrapper {
    margin-bottom: $space-standard;
  }
  &__bag {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }
  &__bag-labeltext {
    margin-bottom: 0;
    margin-left: $space-sm;
  }
  &__btn {
    display: grid;
    place-items: center;
  }
  &__placed {
    width: 380px !important;
    &-disabled {
      width: 380px !important;
      display: none;
      @include breakpoint(xs) {
        display: inline;
        width: 100%;
      }
    }
  }
  &__order-original-total {
    > td {
      padding-top: $space-sm;
    }
  }
  &__order-reward {
    td {
      font-weight: 700;
    }
    > td {
      padding-top: 0;
    }
  }
  &__order-total {
    td {
      font-weight: 700;
    }
    > td {
      padding-top: $space-sm;
    }
  }
  table {
    width: 100%;
    line-height: 32px;
    margin-bottom: $space-lg;
  }
  th {
    text-align: left;
  }
  tr td:nth-child(2) {
    text-align: right;
  }
  @include breakpoint-max(md) {
    margin-top: $space-md;
  }
  &__loyalty-points {
    display: flex;
    justify-content: center;
  }
}
