.hidden-button {
    display: none;
}
.hidden {
    display: none;
}
.code-show {
    display: block !important;
}
.code-sent{
    font-size: 14px !important; 
    text-align: center;
    font-weight: bold;
    padding: 13px 0;
    color:$color__black;
    display: none;
}
.code-sent .icon-sent{
    width: 10px;
    height: 8px;
    display: inline-block;
    margin-right: 5px;
    background-image: url(/images/icons/check-registration-green.svg);
    background-repeat: no-repeat;
    background-position: center;
}

.registration-wrapper {
    max-width: 470px;
    margin: auto;
    padding: 0 $space-sm $space-lg;

    &__container {
        padding: 0 $space-xxxs;
    }

    .phone-registration {
        padding-bottom: $space-md;
    
        h1 {
            margin-bottom: 30px;
            margin-top: 20px;
            padding-top: 0px;
            font-size: $text__header-xxl;
            font-weight: 800; 
            text-transform: lowercase;
        }
    
        &__info {
            font-size: $text__body-sm;
            line-height: $line-height-base;
            margin-bottom: 14px;
            font-weight: 500;
        }
    }
    .input-wrapper {
        padding-bottom: 15px;
        input {
            border: 1px solid $color__input-border;
            margin-top: 0 !important;

            &:focus {
              outline: $outline__normal;
              outline-offset: 2px;
            }
        }
        .error-label {
            font-size: 15px;
            font-weight: 700;
            padding-bottom: 0;
        }
    }
    .input-wrap {
        margin-bottom: 25px;
    }
    
    .registration-phone__info {
        line-height: $line-height-base;
        margin-bottom: 14px;
        font-size: $text__body-xs;
        font-weight: 500;
    }
    
    .verify-number__button {
        padding-bottom: 15px;
        .btn__primary {
            font-weight: 700;
            margin-bottom: $space-xxxs;
            padding: $space-standard 0;
        }
    }
    .verification-info {
        h1 {
            padding-top: 20px;
            font-size: $text__header-xxl;
            font-weight: 800;
            margin-bottom: $space-lg;
        }
        p{
            font-size: 14px;
            line-height: $line-height-base;
            margin-bottom: 14px;
        }
    }
    .submit-button {
        padding-bottom: 18px;
    }
    .new-code {
        padding-bottom: 15px;
        margin: 0 !important;
        .btn__primary {
            margin-bottom: $space-xxxs;
        }
        .code-sent {
            display: block;
        }
    }

    .btn__primary {
        font-weight: 700 !important;
    }

    .having-trouble {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
      
        font-size: 15px;
        font-weight: 700;
        margin: 0 0 $space-md;
      
        &-link {
            cursor: pointer;
            outline: none;
            outline-offset: 0;
      
            &:hover {
                color: $color__primary-dark;
            }
        }
    }
      
    .active-slide {
        display: block;
      }
    .inactive-slide {
        display: none;
    }
    .swiper-wrapper {
        padding-bottom: 0;
    }
}
#register-needhelp-container {
    .btn__primary {
        font-weight: 700 !important;
    }
}