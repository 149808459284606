.maps {
    &__confirm-store {
        position: relative;
        height: 202px;
        span {
            max-height: 202px;
        }
        img {
            object-fit: cover;
            overflow: hidden;
        }
    }

    &__store-details {
        position: relative;

        span {
            max-height: 200px;
        }

        img {
            object-fit: cover;
            overflow: hidden;
        }
    }
    &__flyout {
        position: relative;
        height: 202px;
        span {
            max-height: 202px;
        }
        img {
            object-fit: cover;
            overflow: hidden;
        }
    }
}