ion-content ion-toolbar {
  --background: translucent;
}
/* 
 *{
border:1px solid red;;
} */

.pop-filter-cta {
  display: block;
  span {
    float: right;
  }
}

@media (max-width: 576px) {
  .hero-container-shop_overide .col {
    padding-left: 0px;
    padding-right: 0px;
  }
}
