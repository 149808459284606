.mini-product {
  a:focus {
    .mini-product-card {
      outline: $outline__normal;
    }
  }
  &-card {
    position: relative;
    padding: $space-xs;
    box-sizing: border-box;
    border: $border__gray-lighter;
    border-radius: $radius-xs;
    background-color: $color__white;
    width: 160px;
    margin: $space-xxxs 0;

    &__focus {
      margin-bottom: $space-md;
    }

    &__details {
      &-image {
        position: relative;
        height: $space-xxxl;
        width: $space-xxxl;
        margin: auto;
        margin-bottom: $space-xxs !important;

        img {
          object-fit: contain;
        }
      }

      &__product-name {
        p {
          color: $color__black;
          font-size: $text__body-xs;
          letter-spacing: $letter__spacing-xs;
          line-height: $line-height-base;
          margin-bottom: $space-xs;
          height: $space-lg;
          @include line-clamp(2);
        }
      }
    }

    &__availability {
      height: $line-height-xl;
      margin-bottom: $space-sm;

      &-price {
        padding: 0 $space-xxxs;

        p {
          color: $color__black;
          font-size: $text__body-md;
          font-weight: bold;
          letter-spacing: $letter__spacing-xs;
          line-height: $line-height-xl;
          text-align: center;
          padding-left: 0;
        }
      }

      &-setbutton {
        display: flex;
        justify-content: center;

        button {
          font-size: $text__body-sm;
          color: var(--ion-color-primary, #87189d);
          background-color: var(--ion-color-primary-contrast, #ffffff);
          font-weight: bold;
          width: auto;
          line-height: $line-height-base;
          text-transform: uppercase;
        }

        .btn__primary:hover:not(:disabled) {
          color: #ffffff;
        }

        .btn__secondary {
          height: $space-lg;
          padding: $space-xxs $space-xs;
          max-width: calc($space-xxl * 2);
          min-width: calc($space-xxl * 2);
        }

        .add-to-cart__button {
          margin: auto;
        }

        .btn__primary {
          @extend .btn__primary;
          padding: $space-xxs $space-xs;
          max-width: calc($space-xxl * 2);
          min-width: calc($space-xxl * 2);
          margin: auto;
          line-height: $line-height-md;
          height: $space-lg;
          font-size: $text__button-sm;
          letter-spacing: $letter__spacing-xxxs;
        }
      }
    }
  }
}