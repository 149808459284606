.text-alerts {
    &__current {
        margin-bottom: $space-md;
        text-align: left;
        p {
            display: block;
            color: $color__gray-order;
            margin-bottom: 0px;
            padding-bottom: $space-xxxs;
        }
        div {
            display: flex;
            justify-content: space-between;
            p {
                color: $color__black;
                font-size: $text__body-md;
                display: inline;
                margin-bottom: 0px;
            }
            a {
                font-weight: bold;
                display: inline;
            }
        }
        
    }
    &__no-current {
        font-size: $text__body-base;
        font-weight: bold;
        margin-bottom: $space-md;
    }
    &__new {
        &-heading {
            display: flex;
            justify-content:space-between;
            align-items: center;
            p {
                display: inline;
                font-size: $text__body-base;
                color: $color__black;
                font-weight: bold;
                padding-right: $space-xxs;
                margin-bottom: 0px;
            }
        }
        label {
            display: block;
            text-align: left;
            font-size: $text__body-sm;
            color: $color__gray-light;
            margin-bottom: 0px;
            padding-top: $space-xxs;
            font-weight: normal;
        }
        input {
            width: 100%;
            padding: $space-xs;
            border: 1px solid $color__input-border;
            border-radius: $radius-xs;
            color: $color__black;
            margin-bottom: $space-standard;
        }
        &-submit {
            button {
                outline: $outline__normal;
                @include breakpoint(xs) {
                    max-width: 100%;
                }
                @extend .btn__secondary
            }
        }
        &-consent {
            text-align: left;
            font-size: $text__body-xs;
            margin: $space-sm 0;
        }
    }
}