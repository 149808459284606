ion-content ion-toolbar {
  --background: translucent;
}

.pop-card-product{
  margin: 0 0 30px 0;
  cursor: pointer;
}

.pdp__instore-text {
  text-align: center;
  display: flex;
  justify-content: center;
  
}

.pdp__instore-label {
  color: #636363;
  font-size: 16px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 20px;
}
