
.order-lookup {
    @include page-container(sm);
    margin: 0 auto;
    &__help {
        border-top: $border__order-details;
        border-bottom: $border__order-details;
        padding: $space-md 0px;
        max-width: 100%;
        @include breakpoint(lg) {
            max-width: 460px;
        }

        h2 {
            color: #000000;
            font-size: $text__body-lg;
            font-weight: normal;
            letter-spacing: $letter__spacing-xs;
            line-height: $line-height-xxxl;
            margin-bottom: $space-sm;
        }
        p:first-of-type {
            font-size: $text__body-base;
            padding-bottom: $space-standard;
            line-height: $line-height-lg;
        }
        p {
            font-size: $text__body-sm;
            color: $color__black;
            background-color: white;
            padding-bottom: $space-standard;
            line-height: $line-height-lg;

        }
        p:last-of-type {
            font-size: $text__body-base;
            color: $color__black;
            padding-bottom: 0;
            line-height: $line-height-lg;

        }
        span {
            font-weight: bold;
        }
        &-phone {
            color: $color__black !important;
        }
    }
}