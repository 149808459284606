.tab-description {
    &__body {
        position: relative;
    }

    .tab-description__body {

        &--open {
            height: auto;
        }

        &--closed {
            max-height: 400px;
            overflow: hidden;

            &:after {
                content: '';
                position: absolute;
                width: 100%;
                height: 250px;
                left: 0;
                bottom: 0;
                display: block;
                background: rgb(255, 255, 255);
                background: linear-gradient(0deg,
                        rgba(255, 255, 255, 1) 0%,
                        rgba(255, 255, 255, 0) 100%);
            }
        }
    }

    &__section {
        padding: 30px 0;
        border-bottom: 1px solid #d8d8d8;
        line-height: 22px;
        font-size: $text__button-sm;

        &:last-of-type {
            border-bottom: none;
        }

        &:first-of-type {
            padding-top: 10px;
        }

        p:first-of-type {
            font-size: $text__body-sm;
            font-weight: bold;
            margin-bottom: 15px;
        }

        p {
            margin-bottom: 15px;
            word-break: break-word;
            white-space: pre-line;
        }

        ul {
            padding-left: 20px;
            list-style: disc;
        }

        table {
            width: 100%;

            thead {
                text-align: left;
                font-size: $text__body-sm;
                font-weight: bold;
                padding-bottom: $space-xs;
            }
        }

        td {
            border-bottom: 1px solid #d8d8d8;
            padding: 7px;
        }

        strong {
            font-weight: bold;
        }
    }

    &__show-more-less-button {
        position: relative;
        width: 100%;

        @include breakpoint(xs) {
            width: 33.33%;
            margin: auto;
        }
    }
}
