ion-content ion-toolbar {
  --background: translucent;
}

.home-content:last-of-type{
  margin-bottom: 40px;
  margin-top: $space-xl !important;
}

.home-content h2{
  font-size: 22px;
  font-weight: bold;
  text-align: center;
  margin-bottom: 30px;
  margin-top: 30px;
  text-transform: lowercase;
}

@media (max-width: 576px) {

  .home-content h2{
    font-size: 17px;
    font-weight: bold;
    text-align: left;
    margin-bottom: 20px;
    margin-top: 20px;
  }

}