// QUICK FIX

// .pr-rd-flag-review-container, .pr-rd-images{
//   display: none;
// }

// .pr-thumbs-icon-down svg{
//   transform: rotate(-180deg) !important;
// }



#pr-image-display,
.pr-accessible-text,
.pr-snippet-rating-decimal,
.pr-review-snapshot-histogram,
.pr-snippet-review-count,
.pr-review-snapshot-block-recommend,
.pr-review-snapshot-header-intro,
.pr-review-snapshot-snippets-headline,
.pr-review-snapshot-snippets .pr-snippet-stars-container,
p.pr-rd-author-location,
section.pr-review-snapshot-block.pr-review-snapshot-block-recommend,
section.pr-review-snapshot-block.pr-review-snapshot-block-histogram,
section.pr-review-snapshot-block.pr-review-snapshot-block-recommend,
header.pr-review-snapshot-header.pr-review-snapshot-content-block,
.p-w-r .pr-review-display .pr-rd-image-container figcaption,
p.pr-rd-review-product.pr-rd-bold {
  display: none !important;
}

.p-w-r input[type=radio]:focus {
  border: none !important;
  outline: none !important;
  box-shadow: none !important;
}

.p-w-r input:focus+label.pr-btn {
  outline: none !important;
}

a.pr-snippet-write-review-link {
  &:focus {
    outline: $outline__white !important;
    outline-offset: -1px !important;
  }
}

.p-w-r {

  .pr-media-modal {
    width: 100vw !important;
    height: 100vh !important;
  }

  .pr-media-carousel {
    .button {
      position: absolute !important;
    }

    .carousel__footer {
      position: absolute !important;
      bottom: 56px !important;
    }
  }

  .pr-rd-sort {
    position: relative !important;
    background-image: none !important;
  }
}

.p-w-r .pr-snippet div {
  vertical-align: top !important;
  margin-top: 1px !important;
}


.pop-review {
  .stars {
    display: inline-block;
    width: 15px;
    height: 15px;
    margin-right: 1px;
    background-image: url(/images/icons/star-1.svg);
    background-repeat: no-repeat;
    background-size: contain;
  }

  .stars-empty {
    display: inline-block;
    width: 15px;
    height: 15px;
    margin-right: 1px;
    background-image: url(/images/icons/star-2.svg);
    background-repeat: no-repeat;
  }
}

.pr-accordion-btn,
.pr-accordion-btn span {
  color: #87189d !important;
}

.p-w-r .pr-accordion .pr-accordion-btn .pr-caret-icon__line {
  stroke: #87189d !important;
}

.pr-review {
  border-bottom: 1px solid #ccc !important;
  padding: 20px 0 !important;
}

.review-count {
  font-size: $text__body-xs;
  color: $color__gray-order;
  margin-top: $space-xxxs;
}

/**
 * PowerReviews, Copyright 2020
 * HEAD | 886027bdb | 7/15/2020
 */
/*******************************************/
/** POWER REVIEWS STYLE OVERRIDE TEMPLATE **/
/*******************************************/

/**
 * The following high-level CSS selectors can be utilized
 * to create a custom style theme for all PowerReviews 
 * content.
 *
 * NOTE: If you wish to hide PowerReviews content,
 * please contact your PowerReviews Client Success
 * Representative.  
 */

/**
* TYPOGRAPHY
* --------------------------------------------------
* Global Color & Typography Definitions
*/
.p-w-r,
.p-w-r span,
.p-w-r p,
.p-w-r h1,
.p-w-r h2,
.p-w-r h3,
.p-w-r h4,
.p-w-r h5,
.p-w-r h6 {
  font-family: 'sofia-pro', sans-serif !important;
}

.p-w-r h2 {
  margin-top: 8px !important;
  margin-left: 0 !important;
  float: left !important;
  width: 100% !important;
}

.p-w-r .form-control:focus {
  outline: 1px solid #ccc;
}

.p-w-r p.pr-rd-description-text,
p.pr-rd-bottomline,
.pr-rd-images figcaption,
.pr-rd-helpful-action-legend {
  color: #000 !important;
  font-size: 14px !important;
  clear: both;
}

.pr-rd-images img {
  margin-bottom: 10px;
}

.pr-rd-reviewer-details.pr-rd-inner-side-content-block {
  line-height: 20px !important;
}

p.pr-rd-author-submission-date,
p.pr-rd-author-nickname {
  font-size: 14px !important;
}

p.pr-rd-details.pr-rd-author-submission-date span,
p.pr-rd-details.pr-rd-author-submission-date time,
p.pr-rd-author-nickname span {
  color: #636363 !important;
}

.p-w-r a {}

.p-w-r .pr-review-display.pr-rd-display-tablet .pr-rd-main-footer {
  border-bottom: none !important;
}

.pr-rd-flag-review-btn {
  line-height: normal !important;
}

.p-w-r :focus {
  box-shadow: none !important;
}

.pr-media-card-footer-flagging a {
  color: #87189D !important;
}

button.pr-btn.pr-btn-default.pr-flag-review-btn.pr-flag-review-btn-cancel {
  margin-top: 0 !important;
}

section.pr-review-snapshot-block.pr-review-snapshot-block-snippet {
  border-right: none !important;
  width: 100% !important
}

.p-w-r .pr-review-snapshot .pr-review-snapshot-simple .pr-review-snapshot-block {
  align-items: flex-start !important;
  justify-content: left !important;
}

.p-w-r .pr-review-snapshot .pr-review-snapshot-simple .pr-review-snapshot-block {
  min-height: 90px !important;
}

.p-w-r .pr-review-snapshot.pr-review-enhanced .pr-review-snapshot-simple {
  margin: 0 !important;
}

/**
* FORM LABELS
* --------------------------------------------------
* .p-w-r .pr-control-label > span // Selector that targets all field labels.
*/
.p-w-r .pr-control-label>span {}

/**
 * BUTTON GROUPS
 * --------------------------------------------------
 * .p-w-r .pr-btn // Selector that targets all buttons.
 * .p-w-r .btn-toggle .pr-btn-default // Selector that targets all unselected toggle buttons.
 * .p-w-r .btn-toggle .pr-btn-default.active // Selector that targets the selected toggle button.
 * .p-w-r .pr-btn-add-tag-submit // Selector that targets the "Add" button that appears aside the custom tag text input field.
 * .p-w-r .pr-btn-fileinput // Selector that targets the file input button.  
 * .p-w-r .pr-btn-fileinput span // Selector that targets the file input button text.
 * .p-w-r .pr-btn-delete // Selector that targets the image preview delete button.
 */
.p-w-r .pr-btn {}

.p-w-r .pr-btn-add-tag-submit {}

.p-w-r .pr-btn-fileinput {}

.p-w-r .pr-btn-fileinput span {}

.p-w-r .pr-btn-delete {}

/**
* STARS
* Rating Stars can be customized by overriding the 
* background-image property of the 5 Star-related
* CSS classes
* --------------------------------------------------
* .pr-star-v4-0-filled    // Custom star image - filled 0%
* .pr-star-v4-25-filled   // Custom star image - filled 25%
* .pr-star-v4-50-filled   // Custom star image - filled 50%
* .pr-star-v4-75-filled   // Custom star image - filled 75%
* .pr-star-v4-100-filled  // Custom star image - filled 100%
*/
.p-w-r {
  .pr-star-v4-0-filled {
    display: inline-block !important;
    width: 15px !important;
    height: 15px !important;
    margin-right: 3px !important;
    background-image: url(/images/icons/star-2.svg) !important;
    background-repeat: no-repeat !important;
    background-size: contain !important;
  }

  .pr-review-display {
    .pr-snippet-rating-decimal {
      line-height: 13px !important;
    }
  }

  .pr-helpful-btn {
    border: none !important;
    padding: 0 10px !important;
    width: 55px !important;
    margin-top: 10px !important;
    background: transparent !important;
  }
}

.p-w-r .pr-star-v4-25-filled {
  display: inline-block !important;
  width: 15px !important;
  height: 15px !important;
  margin-right: 3px !important;
  background-image: url(/images/icons/star-25.svg) !important;
  background-repeat: no-repeat !important;
  background-size: contain !important;
}

.p-w-r .pr-star-v4-50-filled {
  display: inline-block !important;
  width: 15px !important;
  height: 15px !important;
  margin-right: 3px !important;
  background-image: url(/images/icons/star-50.svg) !important;
  background-repeat: no-repeat !important;
  background-size: contain !important;
}

.p-w-r .pr-star-v4-75-filled {
  display: inline-block !important;
  width: 15px !important;
  height: 15px !important;
  margin-right: 3px !important;
  background-image: url(/images/icons/star-75.svg) !important;
  background-repeat: no-repeat !important;
  background-size: contain !important;
}

.p-w-r .pr-star-v4-100-filled {
  display: inline-block !important;
  width: 15px !important;
  height: 15px !important;
  margin-right: 3px !important;
  background-image: url(/images/icons/star-1.svg) !important;
  background-repeat: no-repeat !important;
  background-size: contain !important;
}

//hide the recommend to a friend div
.pr-snippet-stars-reco-reco {
  display: none !important;
}

.pr-snippet-slider-root {
  display: none !important;
}

.pr-rd-review-total {
  display: none !important;
}

.pr-rd-review-position {
  display: none !important;
}

#pr-rd-main-header .pr-rd-right {
  float: left !important;
  display: block !important;
  width: 100% !important;
}

/*
write button 
*/
.p-w-r .pr-snippet-write-review-link {
  border: 1px solid #87189d !important;
  background-color: #87189d !important;
  color: #fff !important;
  -webkit-border-radius: 6px !important;
  -moz-border-radius: 6px !important;
  border-radius: 6px !important;
  margin-bottom: 5px;
  padding: 15px 16px !important;
  text-transform: uppercase !important;
  font-size: 15px !important;
  font-weight: bold !important;
  cursor: pointer !important;
  text-decoration: none !important;
  display: inline-block !important;
}

.pr-btn-default {
  border: 1px solid #87189D !important;
  background-color: #87189D !important;
  color: #fff !important;
  -webkit-border-radius: 6px !important;
  -moz-border-radius: 6px !important;
  border-radius: 6px !important;
  margin-bottom: 5px !important;
  padding: 16px !important;
  text-transform: uppercase !important;
  font-size: 15px !important;
  font-weight: bold !important;
  cursor: pointer !important;
  text-decoration: none !important;

  &:hover {
    background-color: $color__primary-dark !important;
  }
}

.pr-file-input-btn-group .pr-btn-default {
  border: 1px solid #87189D !important;
  background-color: #fff !important;
  color: #87189D !important;
  -webkit-border-radius: 6px !important;
  -moz-border-radius: 6px !important;
  border-radius: 6px !important;
  margin-bottom: 5px !important;
  padding: 16px !important;
  text-transform: uppercase !important;
  font-size: 15px !important;
  font-weight: bold !important;
  cursor: pointer !important;
  text-decoration: none !important;
}

.pr-rd-show-more,
.pr-rd-to-top,
.pr-flag-review-btn-cancel {
  border: 1px solid #87189D !important;
  background-color: #fff !important;
  color: #87189D !important;
  -webkit-border-radius: 6px !important;
  -moz-border-radius: 6px !important;
  border-radius: 6px !important;
  margin-bottom: 5px !important;
  padding: 16px !important;
  text-transform: uppercase !important;
  font-size: 15px !important;
  font-weight: bold !important;
  cursor: pointer !important;
  text-decoration: none !important;
  display: inline-block !important;
  margin-top: 20px !important;
}

.pr-rd-show-more .pr-cross-icon__line {
  stroke: #87189D !important;
}

.pr-rd-to-top .pr-caret-icon__line {
  stroke: #87189D !important;
}

.pr-review-snippet-container {
  height: 50px !important;
}

select#pr-rd-sort-by {
  margin-left: 0 !important;
  margin-top: 10px !important;

  &:focus {
    outline: $outline__heavy;
  }
}

figure.pr-rd-image-container:focus {
  outline: $outline__normal;

}

ion-card {
  .pr-review-snippet-container {
    height: inherit !important;
  }

  .p-w-r {
    margin: 0;
  }

  .pr-snippet-rating-decimal {
    display: none !important;
  }
}

.pr-rd-flag-review-container a {
  color: #87189d !important;
  margin-top: 10px !important;
  display: inline-block !important;
}

.p-w-r {
  .pr-rd-sort {
    -webkit-appearance: none !important;
    display: block !important;
    width: 100% !important;
    padding: 11px 10px !important;
    font-size: 14px !important;
    color: #636363 !important;
    border: 1px solid #cccccc !important;
    word-break: normal !important;
    box-shadow: -1px 4px 10px 6px rgba(0, 0, 0, 0.06) !important;
    line-height: 24px !important;
    background: url(/images/icons/carrot-down.svg) no-repeat 97.5% center !important;
    -webkit-border-radius: 6px !important;
    -moz-border-radius: 6px !important;
    border-radius: 6px !important;
  }

  .pr-review-display {
    .pr-rd-main-header {
      margin-bottom: 20px !important;
      padding: 0 0 20px 0 !important;
      background-color: transparent !important;
      border-bottom: 1px solid #ccc !important;
    }

    .pr-rd-footer {
      padding-bottom: 0px !important;
    }
  }
}

@media (max-width: 576px) {
  .p-w-r {
    .pr-rd-sort {
      background: url(/images/icons/carrot-down.svg) no-repeat 96.5% center !important;
    }

    .pr-snippet-write-review-link {
      display: block !important;
      width: 100% !important;
      text-align: center !important;
      margin-top: 10px !important;
      margin-bottom: 20px !important;
    }
  }

  #pr-rd-main-header .pr-rd-right {
    width: 100% !important;
  }

  p.pr-rd-author-nickname {
    text-align: left !important;
  }

  p.pr-rd-details.pr-rd-author-submission-date,
  p.pr-rd-author-nickname {
    width: 100% !important;
    float: left !important;
    margin-bottom: 10px !important;
  }

  p.pr-rd-details.pr-rd-author-submission-date {
    margin-top: 10px !important;
  }

  .pr-snippet-read-and-write {
    float: left !important;
    width: 100% !important;
  }

  .pr-review-snippet-container {
    height: 110px !important;
  }

  .p-w-r {
    .pr-review-display {
      .pr-rd-pagination {
        border-top: none !important;
        border-bottom: none !important;
      }
    }
  }

  .pr-rd-flag-review-container {
    right: inherit !important;
    position: inherit !important;
  }
}



.p-w-r {
  .pr-rd-sort {
    &:after {
      background-image: url(/images/icons/carrot-down.svg);
      width: 14px;
      height: 8px;
      right: 11px;
      top: 22px;
      padding: 0 0 2px;
      position: absolute;
      pointer-events: none;
    }
  }

  .pr-rd-main-header {
    h1 {
      display: block !important;
      margin-top: 14px !important;
      margin-bottom: 10px !important;
    }
  }
}

/**
* TEXT INPUTS
* --------------------------------------------------
* .p-w-r .pr-textinput  // Text Input Styles
* .p-w-r .pr-textinput:focus // Focused Text Input Styles
*/
.p-w-r .pr-textinput {}

.p-w-r .pr-textinput:focus {}

.pr-rd-description-text,
.pr-rd-bottomline {
  font-size: 16px !important;
  margin-bottom: 20px !important;
  line-height: 24px !important;
}

/**
* TEXT AREAS
* --------------------------------------------------
* .p-w-r input[type="text"] // Text Area Styles
* .p-w-r input[type="text"]:focus // Focused Text Area Styles
*/
.p-w-r .pr-textarea {
  font-size: 50px;
}

.p-w-r .pr-textarea:focus {}

/**
* CHECKBOX/RADIO INPUT OPTION LABELS
* --------------------------------------------------
* .p-w-r .tag-label // Checkbox Radio Input Label Styles
*/
.p-w-r .pr-tag-label {}

/**
* ERROR STATES
* --------------------------------------------------
* .p-w-r .pr-has-error .control-label span // Form Field Label Error State Styles
* .p-w-r .pr-has-error .form-control // Text Input & Text Area Error State Styles
* .p-w-r .pr-has-error input[type="text"]:focus // Focused Text Input & Text Area Error State Styles
*/
.p-w-r .pr-has-error .pr-control-label span {}

.p-w-r .pr-has-error .form-control {}

.p-w-r .pr-has-error .pr-textinput:focus,
.p-w-r .pr-has-error .pr-textarea:focus {}

/**
* MOBILE STYLES
* --------------------------------------------------
*/
@media screen and (max-width: 767px) {

  /**
    * CHECKBOX/RADIO INPUT OPTION LABELS
    * --------------------------------------------------
    * .p-w-r .checkbox label, .p-w-r .radio label // Unselected Styles
    * .p-w-r .checkbox label.checked, .p-w-r .radio label.checked // Selected Styles
    */
  .p-w-r .checkbox label,
  .p-w-r .radio label {}

  .p-w-r .checkbox label.checked,
  .p-w-r .radio label.checked {}

  .p-w-r .checkbox label.checked span,
  .p-w-r .radio label.checked span {}

  /**
    * TAG GROUP 'ADD ITEM' BUTTON
    * --------------------------------------------------
    */
  .p-w-r .pr-btn-add-tag a {}
}

//Product Page Power Review CSS
.product-page .pr-snippet-write-review-link {
  display: none;
}

// .product-page{
//    padding-bottom: 10px;
// }

//power review component
.power-review-component .pr-snippet-read-and-write {
  display: none !important;
}

#pr-write>.p-w-r {
  padding: 0 15px !important;
  margin: 0 !important;
}

#pr-write input[type=text],
#pr-write textarea {
  border: 1px solid #ccc !important;
  background-color: #fff !important;
  width: 100% !important;
  border-radius: 6px !important;
  padding: 11px 10px !important;
  box-shadow: -1px 4px 10px 6px rgba(0, 0, 0, 0.06) !important;
  height: inherit !important;
}

#pr-write .pr-control-label,
#pr-write .pr-control-label span,
.pr-file-input-label {
  color: $color__gray !important;
  font-size: 14px !important;
  margin-bottom: 5px !important;
  font-weight: 500 !important;
}

#pr-write .pr-star-v4,
#pr-write .pr-star-v4-100-filled {
  width: 35px !important;
  height: 35px !important;
  margin-right: 15px !important;
}

#pr-write .pr-rating-stars {
  margin-top: 10px !important;
}

#pr-write button svg {
  display: none !important;
}

#pr-write button.pr-media-input-display-captionGroup-button svg {
  display: block !important;
}

#pr-write button.pr-media-input-display-captionGroup-button svg {
  border-radius: $radius-xs;
  background-color: $color__primary;
  font-size: $text__body-sm;
  font-weight: bold;
  letter-spacing: $letter__spacing-sm;
  line-height: $line-height-lg;
  text-align: center;
  text-transform: uppercase;
  color: $color__white;
  fill: $color__white;
  width: 100%;
  transition: all 0.2s;

  &:hover {
    background-color: $color__primary-dark;
  }

  @include breakpoint(xs) {
    max-width: fit-content;
  }
}

#pr-write button.pr-media-input-display-captionGroup-button svg path {
  color: $color__white;
}

#pr-write .p-w-r #pr-bottomline .sr-only {
  // position: inherit !important;
  height: inherit !important;
  margin-top: 15px !important;
  margin-left: 10px !important;
  padding: inherit !important;
  overflow: inherit !important;
  clip: inherit !important;
  border: inherit !important;
  float: inherit !important;
  padding-left: inherit !important;
  margin-right: inherit !important;
}

.btn-group-radio-vertical .pr-btn-default {
  background-color: #fff !important;
  color: #000 !important;
  border-radius: 6px !important;
  margin-bottom: 10px !important;
  padding: 12px 12px 12px 30px !important;
  text-transform: inherit !important;
  font-size: 15px !important;
  font-weight: 500 !important;
  cursor: pointer !important;
  text-decoration: none !important;
  border: 1px solid #f0f0f0 !important;
  box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.1) !important;
  cursor: pointer !important;
}

.p-w-r .pr-helpful-btn[aria-disabled] .pr-thumbs-fill,
.p-w-r .pr-helpful-active .pr-thumbs-cuff-fill {
  fill: #707070 !important;
}

.p-w-r .pr-helpful-active span {
  color: #707070 !important;
}

#pr-reviewdisplay {
  h2 {
    font-size: $text__body-base;
  }
}

.pr-rd-review-headline {
  &:focus {
    box-shadow: 0 0 0 1px $color__primary !important;
    border: 1px solid $color__primary;
  }
}