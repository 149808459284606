.home-hero {
  .sc-ion-searchbar-ios-h {
    padding: 0px;
    height: 40px;
  }
  .searchbar-left-aligned {
    &.sc-ion-searchbar-ios-h {
      .searchbar-input {
        &.sc-ion-searchbar-ios {
          padding: 10px 30px 10px 10px;
          border: 1px solid #ccc;
        }
      }
    }
  }
}

.searchbar-has-value {
  &.searchbar-has-focus {
    &.sc-ion-searchbar-ios-h {
      .searchbar-clear-button {
        &.sc-ion-searchbar-ios {
          display: block;
          background: #fff;
          -webkit-border-top-right-radius: 6px;
          -webkit-border-bottom-right-radius: 6px;
          -moz-border-radius-topright: 6px;
          -moz-border-radius-bottomright: 6px;
          border-top-right-radius: 6px;
          border-bottom-right-radius: 6px;
        }
      }
    }
  }
}
.searchbar-search-icon {
  &.sc-ion-searchbar-ios {
    color: #000;
    pointer-events: none;
  }
}

.home-hero {
  .sc-ion-searchbar-ios-h {
    padding: 0px;
    height: 40px;
  }
  .searchbar-left-aligned {
    &.sc-ion-searchbar-ios-h {
      .searchbar-input {
        &.sc-ion-searchbar-ios {
          padding: 10px 30px 10px 10px;
          border: 1px solid #ccc;
        }
      }
    }
  }
}

.searchbar-has-value {
  &.searchbar-has-focus {
    &.sc-ion-searchbar-ios-h {
      .searchbar-clear-button {
        &.sc-ion-searchbar-ios {
          display: block;
          background: #fff;
          -webkit-border-top-right-radius: 6px;
          -webkit-border-bottom-right-radius: 6px;
          -moz-border-radius-topright: 6px;
          -moz-border-radius-bottomright: 6px;
          border-top-right-radius: 6px;
          border-bottom-right-radius: 6px;
        }
      }
    }
  }
}
.searchbar-search-icon {
  &.sc-ion-searchbar-ios {
    color: #000;
    pointer-events: none;
  }
}

input[type="search"].sc-ion-searchbar-ios {
  &::-webkit-input-placeholder {
    color: #777777;
    font-size: 14px;
    font-weight: bold;
  }
  &:-ms-input-placeholder {
    color: #777777;
    font-size: 14px;
    font-weight: bold;
  }
  &::placeholder {
    color: #777777;
    font-size: 14px;
    font-weight: bold;
  }
}
.searchbar-search-icon {
  &.sc-ion-searchbar-ios {
    margin-left: calc(50% - 60px);
    right: 10px;
    left: initial;
    top: 0;
    position: absolute;
    width: 20px;
    height: 100%;
    contain: strict;
  }
}

.search {
  &__carousel {
    margin: 20px 0px;
  }

  &__selected-category {
    border-radius: 6px;
    background-color: $color__primary;
    height: fit-content;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 5px 10px;
    max-width: fit-content;
    &:hover {
      cursor: pointer;
    }

    &-text {
      height: 18px;
      color: #ffffff;
      font-size: 13px;
      font-weight: 900;
      letter-spacing: 0.25px;
      line-height: 18px;
      box-sizing: content-box;
      text-transform: uppercase;
    }

    &-icon {
      height: 8px;
      width: 8px;
      margin-right: 5px;
      background-image: url(/images/icons/close__white.svg);
      background-repeat: no-repeat;
      background-position: center;
    }

    &-wrapper {
      width: 100%;
      margin-bottom: 20px;
      padding-right: 15px;
    }
    svg {
      margin-right: $space-xxs;
    }
  }
}

h5.search-count__text {
  width: 100%;
}

