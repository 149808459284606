.stores {
   display: flex;
   flex-direction: column;
   height: calc(var(--app-height) - $height-header);

   @include breakpoint(lg) {
      height: 100%;
      min-height: 300px;
      max-height: calc(var(--app-height) - $height-header);
   }

   &__initial {
      @include page-container(lg);
      flex: 1;
      background-image: url("/images/map.png");
      background-size: cover;
      background-position: center;
      position: relative;

      &-search {
         @include center(true, true);
         background-color: $color__gray-toolbar;
         border-radius: $space-standard;
         border: $outline__white;
         max-width: 90%;
         width: 400px;

         label {
            color: $color__gray;
         }
      }
   }

   &__container {
      flex: 1;
      display: flex;
      flex-direction: column;
      height: 100%;

      @include breakpoint(lg) {
         flex-direction: row;
      }

      &-search-and-map {
         position: relative;
         display: flex;
         flex: 1;
         overflow: hidden;
      }

      &-search {
         padding: $space-standard 0;
         overflow-y: scroll;
         position: absolute;
         background-color: $color__white;
         top: 0;
         left: 0;
         right: 0;
         bottom: 0;
         transition: all 500ms;
         @include z-index(secondary);

         &--open {
            transform: translateY(0%);
         }

         &--closed {
            transform: translateY(100%);
         }

         @include breakpoint(lg) {
            position: relative;
            transform: none;
            flex: 0 400px;
         }
      }

      &-map {
         background-color: $color__gray-lighter;
         flex: 1;
      }

      &-toggle {
         display: none;
         height: $space-xxl;
         background-color: $color__white;
         color: $color__primary;
         cursor: pointer;
         font-weight: bold;
         border-top: 1px solid $color__black;
         @include z-index(primary);

         @include breakpoint-max(lg) {
            display: flex;
            align-items: center;
            justify-content: center;
         }
      }
   }
}

.store-search__main-header {
   margin: 10px 0 !important;
}