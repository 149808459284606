.orders-page {
  &__empty {
    display: flex;
    width: 100%;
    align-items: center;
    flex-direction: column;

    svg {
      margin-bottom: $space-standard;
    }
  }

  &__order-button {
    border: $border__gray-lighter;
    box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.1) !important;
    cursor: pointer;
    background-color: $color__white;
    width: 100%;
    text-align: left;
    padding: calc($space-xxxxs + $space-xs);
    vertical-align: middle;
    -webkit-border-radius: $radius-xs;
    -moz-border-radius: $radius-xs;
    border-radius: $radius-xs;
    margin-bottom: $space-sm;
    position: relative;
    padding: $space-standard;
    height: 100px;

    h2 {
      font-size: $text__body-base;
      margin-bottom: $space-xxxxs;
    }

    p {
      font-size: $text__body-md;
      line-height: $line-height-xxl;
      margin-bottom: $space-xxxs;

      span {
        font-size: $text__body-xs;
        color: $color__gray-dark;
        line-height: $line-height-lg;
        margin-left: $space-xxxs;
      }
    }

    h3 {
      font-size: $line-height-sm;
      color: $color__gray-dark;
    }

    svg {
      right: calc($space-standard + $space-xxxs);
      top: 50%;
      transform: translateY(-50%);
      cursor: pointer;
      position: absolute;
      width: calc($space-standard + $space-xxxs);
      height: $space-standard;
    }

    @include breakpoint(xs) {
      margin-bottom: $space-standard;
    }
  }
}