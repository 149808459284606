.orderplaced {
    padding-top: $space-xxl;
    padding-bottom: $space-xxl;
    @include page-container(sm);
    &__megaphone {
        svg{
            height: $space-xxxxl;
            width: $space-xxxxl;
        }
    }
    &__heading {
        text-align: center;
        h1 {
            margin: 0 auto;
            width: 60%;
            text-align: center;
            font-size: $text__header-xxl;
            font-weight: bold;
            padding-bottom: $space-md;
            @include breakpoint(sm) {
                width: 55%;
            }
        }
        h3 {
            text-align: center;
            padding-bottom: $space-md;
        }
    }
    &__status {
        padding: 0 $space-standard $space-standard ;
        p {
            padding-bottom: $space-xs;
            &:last-of-type {
                padding-top: $space-md;
            }
        }
        span {
            &:first-of-type {
                color: $color__gray-dark;
            }
        }
    }
    &__pickup-link {
        margin: $space-lg auto;
        color: $color__primary;
        display: block;
        font-size: $text__body-sm;
    }
    &__continue-shopping {
        display: flex;
        justify-content: center;
        button {
            color: $color__white;
            font-size: $text__body-sm;
            font-weight: bold;
            width: 100%;
            border-radius: $radius-xs;
            background-color: $color__primary;
            padding: $space-standard 0;
            max-width: 460px;
        }
    }
    &__continue-shopping-guest {
        display: flex;
        justify-content: center;
        display: inline-block;
        &__reg-now-btn{
            @extend .btn__primary;
            max-width: 100%;
            margin-bottom: $space-md;
            padding: $space-standard $space-xxl;
        }
        &__continue-shopping-btn{
            @extend .btn__secondary;
            max-width: 100%;
            padding: $space-standard $space-xxl;
        }
    }
    &__reg-form {
        background-color: $color__background-purple;
        padding: $space-md $line-height-xxxxxl 83px $line-height-xxxxxl;
        margin-top: 0px;
        margin-right: -$line-height-xxxxxl;
        margin-left: -$line-height-xxxxxl;
        max-width: 460px;
        @include breakpoint (xs){
            margin: 0 auto 183px auto;
            padding: $space-lg $space-md;
        }
    }
    form {
        h1 {
            display: none;
        }
    }
    &__registration-heading {
        h3 {
            font-size: $text__body-lg;
            font-weight: bold;
            padding-bottom: $space-sm;
        }
        p {
            padding-bottom: $space-md;
        }
    }
    p.text-center.switch-modal {
        display: none;
    }
    div.row.btn-social-wrap {
        display: none;
    }
}
