.featured-tile {
  margin-top: 20px;
  height: 360px;

  &__container {
    display: flex;
    justify-content: center;
  }

  &__image {
    height: 360px;
    width: 100%;
    max-width: 620px;

    &--mobile {
      @include breakpoint(sm) {
           display: none;
      }
    }

    &--desktop {
      display: none;
      @include breakpoint(sm) {
           display: block;
      }
    }
    
    img {
      max-height: 360px !important;
      object-fit: cover;
      border-radius: 10px;
    }
  }

  &__content-panel {
    position: relative;

    .bubbles {
      margin-left: -35px;
      margin-right: 35px;
    }
    &__bubbles-icon{
      width: 225px;
      height: 175px;
    }
    a.content {
      display: flex;
      flex-direction: column;
      justify-content: center;
      position: absolute;
      left: -40px;
      bottom: 32px;
      padding: 18px;
      min-width: 220px;
      min-height: 180px;
      background: #FFFFFF;
      border-radius: 10px;
      box-shadow: 0px 0px 10px 0px rgb(0, 0, 0, 0.1);

      h2.headline {
        font-size: 24px;
        font-weight: 600;
        text-align: left;
        margin: 0 0 16px;
        line-height: 24px;
        text-transform: lowercase;
        overflow-wrap: break-word;
      }

      p.body {
        font-size: 16px;
        line-height: 22px;
      }

      .link {
          display: flex;
          margin-top: $space-sm;
          SVG {
            width: 20px;
            height: $space-standard;
          }
          @include breakpoint-max(xs){
            right: 0;
            padding-right: $space-sm;
        }
      }
    }
  }
}

@include breakpoint-max(md) {
  .featured-tile {
    padding: 0;

    &__content-panel a.content {
      left: -59px;
    }

    .content {
      min-width: 208px;
    }

    .bubbles {
      margin-left: -25px;
      svg{
        width: 134px;
        height: 147px;
      }
      img {
        height: 210px !important;
      }
    }
  }
}
@include breakpoint-max(sm) {
  .featured-tile {
    height: 100%;

    &__container {
      flex-direction: column;
    }

    &__content-panel {
      display: flex;
      flex-direction: row-reverse;
      justify-content: center;
      margin: 0 auto;
      max-width: 300px;
      width: 100%;

      a.content {
        position: relative;
        min-width: 195px;
        min-height: 125px;
        width: 80%;
        height: 100%;
        bottom: 23px;
        left: 0;
        justify-content: flex-start;
        padding: 15px;

        h2.headline {
          font-size: 16px;
          margin-bottom: 4px;
          padding-right: 0;
        }
  
        p.body {
          font-size: 14px;
          padding-right: 24px;
        }
      }

      .bubbles {
        margin: -3px 0px 0px -10px;
        width: auto;
        z-index: 2;
        svg{
          width: 134px;
          height: 147px;
        }
        img {
          margin: 0;
          height: 100px !important;
          margin-bottom: 18px !important;
        }
      }
    }

    &__image {
      width: 100%;
      max-width: 100%;
      height: auto;
    }
  }
}

@media (max-width: 575px) {
  .featured-tile__content-panel {
    margin: 0 auto;
  }
}
