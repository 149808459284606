.login-page {
  position: relative;
  padding: 0 $space-standard $space-lg;
  padding-left: $space-standard;
  margin: 0 auto;
  margin-top: $space-lg;
  width: 100%;
  max-width: $space-max-width-sm;
  .close-button {
    position: absolute;
    top: 0;
    right: 0;
  }
  &__header {
    text-align: center;
    margin-bottom: $space-sm;
  }
  .login-form {
    h1 {
      padding-top: $space-standard;
      font-size: $text__header-xxl;
      font-weight: bold;
      margin-bottom: $space-lg;
    }
    &__email {
      padding-bottom: $space-standard;
      font-size: $text__body-sm;
      label {
        color: $color__gray;
      }
      input {
        margin-top: $space-xxs;
        border: 1px solid $color__input-border;
        background-color: $color__white;
        width: 100%;
        border-radius: $radius-xs;
        padding: 11px 10px;
        box-shadow: -1px 4px 10px 6px rgba(0, 0, 0, 0.06);
      }
    }
    &__password {
      padding-bottom: $space-standard;
      font-size: $text__body-sm;
      label {
        color: $color__gray;
      }
      .password-field {
        margin-top: $space-xxs;
      }
      input {
        border: 1px solid $color__input-border;
        background-color: $color__white;
        width: 100%;
        border-radius: $radius-xs;
        padding: $space-sm;
        box-shadow: -1px 4px 10px 6px rgba(0, 0, 0, 0.06);
      }
      .eyeball {
        width: $space-md;
        height: $space-md;
        float: right;
        position: relative;
        top: -35px;
        right: $space-sm;
        background-repeat: no-repeat;
        background-size: contain;
      }
    }
    .error-label {
      padding-bottom: $space-md;
      margin-top: $space-sm;
      display: block;
      text-align: center;
      color: $color__red !important;
      font-size: $text__body-base;
    }
    &__section {
      width: 100%;
      padding-bottom: $space-standard;
      display: flex;
      &-remember-me {
        align-items: center;
        display: flex;
        .checkbox-wrapper {
          font-size: $text__body-sm;
          line-height: $line-height-md;
          cursor: pointer;
          &:focus-within {
            outline: $outline__normal;
            outline-offset: $space-xxxs;
          }
          &__check {
            background-color: $color__white;
            svg {
              width: 9px !important;
            }
          }
        }

        .gigya-input-password {
          border: 1px solid #efe8e8;
          background-color: #fff;
          width: 100%;
          border-radius: 6px;
          padding: 11px 10px;
          margin-top: 10px;
          box-shadow: -1px 4px 10px 6px rgba(0, 0, 0, 0.06);
        }

        #forgot-password-login {
          font-size: $text__body-sm;
        }

        .form-group {
          margin-top: 30px;
        }

        .form-check {
          margin-top: 15px;
          margin-bottom: 30px;
        }

        .btn-sign-in {
          width: 100%;
          border-radius: 5px;
        }

        .form-group label {
          color: #b1b1b1;
        }

        .form-check-input {
          height: 20px;
          border: 1px solid #b1b1b1;
          width: 20px;
        }

        .float-right {
          float: right;
        }

        .form-check-label a {
          font-size: 16px;
          font-weight: bold;
        }

        .facebook_btn-width {
          border: 1px solid #ccc;
          background: transparent;
          border-radius: 5px;
          height: 40px;
          padding-top: 0px;
          width: 48%;
        }

        .error-button {
          border: 1px solid #e6e6e6 !important;
          background-color: #e6e6e6 !important;
          color: #474747 !important;
        }

        .error-label {
          width: 100%;
          padding-bottom: 20px;
          margin-top: 10px;
          text-align: center;
          display: block;
          color: $color__red;
        }

        .icon-eye-active {
          background-image: url("/images/icons/icon-eye-active.svg");
          cursor: pointer;
        }

        .icon-eye-inactive {
          background-image: url("/images/icons/icon-eye-inactive.svg");
          cursor: pointer;
        }

        .eyeball-pos {
          width: 24px;
          height: 24px;
          float: right;
          position: relative;
          top: -35px;
          right: 10px;
          background-repeat: no-repeat;
          background-size: contain;
        }
      }
      &-forgot-pass {
        margin-left: auto;
        a {
          color: $color__primary;
          font-size: $text__body-sm;
          font-weight: 700;
          &:focus {
            outline: $outline__normal;
          }
        }
      }
    }
    .signin-button {
      padding-bottom: $space-standard;
      button {
        border: 1px solid $color__gray-lightest;
        color: $color__white;
        background-color: $color__primary;
        width: 100%;
        border-radius: $radius-xs;
        margin-bottom: $space-xxxs;
        padding: $space-standard 0;
        font-size: $text__body-sm;
        font-weight: bold;
        &:disabled {
          background-color: $color__gray-lightest;
          color: $color__gray-darker;
        }
      }
    }
    .register-button {
      font-size: $space-standard;
      margin: 0 0 $space-md;
      text-align: center;
      label {
        color: $color__gray-order;
      }
      .register {
        font-size: $space-standard;
        font-weight: bold;
        color: $color__primary;
        padding-left: $space-sm;
        &:focus {
          outline: $outline__normal;
        }
      }
    }
  }
}
