.articles-page {
  @include page-container(lg);

  &__header {
    @include breakpoint-max(lg){
     margin-left: -$space-page-gutters;
     margin-right: -$space-page-gutters;
    }
  }

  &__list {
    display: flex;
    justify-content: center;
    flex-direction: column;

    @include breakpoint(sm) {
      flex-direction: row;
      flex-wrap: wrap;
    }
  }

  &__list-item {
    margin-bottom: $space-xl;

    @include breakpoint(sm) {
      flex: 0 0 25%;
      max-width: 25%;
      margin-bottom: $space-xxl;
      padding: 0 $space-standard;
    }
  }
}

ion-content {
  ion-toolbar {
    --background: translucent;
  }
}