hr {
  border-top: 1px solid #dedede;
}

.pop_select_option_error p {
  color: red;
}

.pop_product_size_picker {
  ul {
    padding-top: 10px;
    li {
      display: inline-block;
      margin-top: 30px;
      margin-bottom: 20px;
      margin-right: 1%;
      height: 47px;
      width: 50px;
      -webkit-border-radius: 6px;
      -moz-border-radius: 6px;
      border-radius: 6px;
      border: 1px solid #e7e7e7;
      box-shadow: -1px 4px 10px 6px rgba(0, 0, 0, 0.06);
      a {
        margin-top: 15px;
        text-align: center;
        display: block;
        width: 100%;
        color: $color__gray-dark;
      }
      &.active {
        border: 1px solid #87189d;
        background-color: $color__primary;
        a {
          color: #ffffff;
        }
      }
    }
  }
}

.pop-amount {
  font-size: 20px;
  padding-left: 4px;
}

div.carousel ul.thumbs {
  display: flex;
  flex-wrap: wrap;
  margin: 0 auto;
  transform: none !important;
  justify-content: left;
}

div.thumbs-wrapper .control-arrow {
  display: none;
}

.pdp-carousel div.carousel div.thumbs-wrapper {
  display: flex;
  flex-direction: column;
}

.regular-product-hero {
  width: 100%;
  position: relative;
}

.pop-card-list-view {
  @include breakpoint(xs) {
    padding-left: 0px;
  }
  
  .pr-snippet-read-and-write {
    display: none !important;
  }
  .pr-review-snippet-container {
    height: 20px !important;
    margin-top: 5px;
    margin-bottom: 15px;
  }
  .p-w-r {
    margin: 0 !important;
  }
  .pr-rating-stars {
    margin-right: 10px;
  }
  .pop-review {
    margin-bottom: 10px;
    span {
      font-size: 12px;
      color: #636363;
      margin-left: 5px;
      font-weight: bold;
    }
  }
}
.pop-card-list-view .pop-review .stars,
.pop-card-list-view .pop-review .stars-empty {
  width: 15px;
  height: 15px;
  background-size: contain;
  margin-right: 3px;
}

.product-select select,
.product-cta select {
  font-size: 16px;
  font-weight: bold;
  height: 49px;
}
.product-select {
  label {
    color: #636363;
    font-size: 15px;
    margin-top: 14px;
    margin-bottom: 0px;
    display: block;
  }
}

.product-location label.location,
.product-location label.stock {
  font-size: 16px;
  line-height: 17px;
  font-weight: bold;
  color: #000;
  padding: 0;
  margin: 0;
  // overflow: hidden;
  // text-overflow: ellipsis;
  // display: block;
  // -webkit-line-clamp: 1;
  // -webkit-box-orient: vertical;
  // max-height: 17px;
}
.product-location {
  .square-edit {
    width: 20px;
    height: 20px;
    background-color: transparent;
    background-image: url(/images/icons/square-edit.svg);
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
    float: right;
    margin-top: 12px;
  }
  span {
    font-size: 15px;
    font-weight: 500;
    color: #636363;
    padding: 0;
    margin: 0;
    height: 22px;
    display: inline-block;
    &.span-oos {
      color:  $color__red;
    }
  }
  a {
    font-weight: bold;
  }
}
.product-cta {
  margin-top: $space-xs;
}

.col-30,
.col-70 {
  position: relative;
  width: 100%;
}
.col-30 {
  flex: 0 0 30%;
  max-width: 30%;
  padding-right: 3px;
  padding-left: 15px;
}
.col-70 {
  flex: 0 0 70%;
  max-width: 70%;
  padding-right: 15px;
  padding-left: 3px;
  .pop-button-color {
    margin: 0;
  }
}

@media (max-width: 576px) {
  .regular-product-section {
    padding-top: 20px;
  }
  .pop-card-list-view .pr-review-snippet-container {
    margin-bottom: 15px;
  }
}
.picker-details{
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  margin-top: $space-standard;
  margin-left: -$space-standard;
  margin-right: -$space-standard;
}
.pop-option-label {
  color: $color__gray-order;
  font-size: $text__body-base;
  font-weight: 300;
}
.switch-image-size {
  height: 57px;
  width: 57px;
  border: 1px solid #e2d8d8;
  border-radius: 5px;
  margin-right: $space-xxs;
  margin-bottom: $space-xxs;
  background-color: transparent;
  padding: 0;
  overflow: hidden;

  &:hover {
    cursor: pointer;
  }

  &__selected {
    border-color: $color__primary;
  }
}

#switch-image-button{
  &:focus{
    outline: 1px dashed $color__primary !important;
    outline-offset: 2px;
  }
}

.pop-reg-price {
  font-size: 16px;
  color: $color__gray-dark;
  margin-left: 10px;
  b {
    font-size: 14px;
    color: $color__gray-dark !important;
    margin-left: 10px;
  }
}

// This is the css that controls the product drawer selector

.drawer-box {
  position: absolute;
  top: 100px;
  background-color: white;
  width: 100%;
  max-width: 500px;
  min-height: 90%;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
  text-align: center;
  overflow: auto;
  &::before {
    content: '';
    width: 70px;
    height: 4px;
    -webkit-border-radius: 3px;
    -moz-border-radius: 3px;
    border-radius: 3px;
    background-color: #d4d2d2;
    position: absolute;
    top: 8px;
    left: 50%;
    transform: translateX(-50%);
  }
}
.drawer-content {
  height: 100%;
  display: flex;
  -webkit-box-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  align-items: center;
  flex-direction: column;
  margin: 0 auto;
}
.outer-drawer {
  position: absolute;
  background-color: pink;
}
@media (max-width: 576px) {
  .drawer-box {
    top: 50px;
    width: 100%;
    min-height: 100%;
  }
  .drawer-content {
    margin: 20px auto 0;
  }

  .carousel-slider ul li.slide {
    width: 100%;
    position: relative;
  }
  .carousel-slider ul li.slide:after {
    content: '';
    display: block;
    padding-bottom: 100%;
  }
  .carousel-slider ul li.slide div {
    position: absolute;
    width: 100%;
    height: 100%;
  }
  .carousel-slider ul li.slide div img {
    height: 93% !important;
    width: 100% !important;
    object-fit: contain;
  }
}
