.pop-card-article {
  border-radius:$space-xxxs;
  overflow: hidden;
  -webkit-box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.1);
  -moz-box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.1);
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.1);
  cursor: pointer;
  position: relative;
  width: 100% !important;
  margin-bottom: $space-xxxxs;

  &__header {
    padding: $space-standard;
    position: relative;
    bottom: 0;
    left: 0;
    z-index: 100;
    background: $color__white;
    width: 100%;

    &-header {
      width: 90%;
      font-size: $text__body-base;
      line-height: $line-height-lg;
      margin: 0;
      color: $color__black;
      font-weight: bold;
      @include line-clamp(2);
      -webkit-box-orient: vertical;
      max-height: $space-xl;
    }

    &-subtitle {
      font-size: $text__body-sm;
      font-weight: bold;
      line-height: $line-height-md;
      color: $color__gray;
      padding: 0;
      margin: 0;
      margin-bottom: 0px;
      margin-top: $space-xxs;
      max-width: 90%;
      @include line-clamp(2);
      max-height: $space-xl;
      display: inline-block;
    }
  }

  button {
    width: 10%;
    position: absolute;
    right: $space-xs;
    bottom: $space-xs;
    padding: 0;
  }

  svg {
    width: 20px;
    height: $space-standard;
    position: absolute;
    right: $space-xs;
    bottom: $space-xs;
  }

  &__img {
    background-color: $color__white;
    flex: 1;
    position: relative;
    
    div {
      position: relative !important;
      img {
          position: static !important;
          height: auto !important;
          padding-bottom: $space-xxl !important;
          width: 100%;
      }
    }
  }
    &-focus:focus {
      .content-block__height-override {
        border: $outline__normal !important;
        outline-offset: -2px;
      }
  }
}

.vertical-desktop .col-12 .pop-card-article img {
  height: 500px;
}