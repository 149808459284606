.about-carousel {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: $space-md;
    @include breakpoint-max(md){
        height: 367px;
    }

    img {
        width: 100%;
        object-fit: cover;
        height: 100%;
        @include breakpoint(md) {
            width: auto;
        }
    }
    .swiper {
        @include breakpoint-max(md){
            position: unset;
        }
        &-wrapper {
            height: 375px !important;

            @include breakpoint(md) {
                height: 460px !important;
            }
        }
        &-slide {
            width: 279px;
            height: 372px;
            @include breakpoint(md) {
                width: auto !important;
                height: 460px;
                transition: all 200ms linear;
                transform: scale(0.8);

                &.swiper-slide-active {
                    transform: scale(1);
                }
            }
        }
    }
}
