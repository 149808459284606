
.seo-copy {
    li {
      list-style-type: disc;
      margin-left: $space-xl;
    }
    b {
      font-weight: bold;
    }
    h1 {
      display: block;
      font-size: $text__header-xxl;
      margin-top: $space-sm;
      margin-bottom: $space-sm;
      font-weight: bold;
    }
    h2 {
      display: block;
      font-size: $text__body-xxl;
      margin-top: $space-standard;
      margin-bottom: $space-standard;
      font-weight: bold;
    }
    h3 {
      display: block;
      font-size: $text__body-md;
      margin-top: $space-standard;
      margin-bottom: $space-standard;
      font-weight: bold;
    }
    &__hide-content{
      overflow: hidden;
      line-height: $line-height-base !important;
      font-size: $text__body-xs !important;
      height: $line-height-xxxxxxl;
      -webkit-mask-image: linear-gradient(to bottom, black 50%, transparent 100%);
      mask-image: linear-gradient(to bottom, black 50%, transparent 100%);
      a {
        text-decoration: underline; 
        color: black !important;
      }
    }
  
    &__show-content{
      line-height: $line-height-base !important;
      font-size: $text__body-xs !important;
      height: auto;
      a {
        text-decoration: underline; 
        color: black !important;
      }
      
    }
  
    &__show-more{
      padding-top: $space-standard;
      font-weight: bold;
      &-btn {
        cursor: pointer !important;
        border: none;
        padding: 0!important;
        color: #87189D;
        font-weight: bold;
        font-size: $text__body-sm;
      }
    }
  }
  