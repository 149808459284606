////////////////////////
// Variables
////////////////////////
:root {
    --icon-size-xs: calc(var(--icon-size-base) * 0.5); // 8px
    --icon-size-sm: calc(var(--icon-size-base) * 0.75); // 12px
    --icon-size-base: 16px;
    --icon-size-md: calc(var(--icon-size-base) * 1.5); // 24px
    --icon-size-lg: calc(var(--icon-size-base) * 2); // 32px
    --icon-size-xl: calc(var(--icon-size-base) * 2.5); // 40px
    --icon-size-xxl: calc(var(--icon-size-base) * 3.75); // 60px
}

////////////////////////
// Icon Sizes
////////////////////////
.icons {
    height: 100%;
    width: 100%;
    &-size {
        &--xs {
            height: var(--icon-size-xs);
            width: var(--icon-size-xs);
        }

        &--sm {
            height: var(--icon-size-sm);
            width: var(--icon-size-sm);
        }

        &--base {
            height: var(--icon-size-base);
            width: var(--icon-size-base);
        }

        &--md {
            height: var(--icon-size-md);
            width: var(--icon-size-md);
        }

        &--lg {
            height: var(--icon-size-lg);
            width: var(--icon-size-lg);
        }

        &--xl {
            height: var(--icon-size-xl);
            width: var(--icon-size-xl);
        }

        &--xxl {
            height: var(--icon-size-xxl);
            width: var(--icon-size-xxl);
        }
    }
}

////////////////////////
// Icon Inherit Color
////////////////////////
.icons {
    use {
        color: inherit;
        fill: currentColor;
    }
}

// --------------------------------

// (END) GLOBAL ICON STYLES

// --------------------------------
