.cart-changed-modal{
    &__bag {
        background-color: $color__primary;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: $space-xs;
        margin-bottom: $space-standard;

        svg {
            color: $color__white;
            margin-bottom: 0px;
        }
    }
}