.reward-summary {
  padding: 0;
  @include page-container(lg);

  &__points-section {
    padding-bottom: $space-md;
  }


  .reward-container1 {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      gap: $space-standard;
      border-top: 1px solid #E5D4ED;
      border-bottom: 1px solid #E5D4ED;
      padding: $space-md 0px;
      max-width: 1140px;

      @include breakpoint-max(xs) {
        flex-direction: column;
        align-items: center;
      }

      &-headings {
        display: flex;
        flex-direction: row;
        justify-content: space-between;

        @include breakpoint-max(xs) {
          display: contents;
        }
      }

      .reward-name {
        color: $color__black;
        font-size: $text__body-xxl;
        font-style: normal;
        font-weight: 400;
        line-height: normal;

        @include breakpoint-max(xs) {
          order: 1;
        }
      }

      .reward-earn {
        flex: 1;
        justify-content: center;
      }
      
      .reward-howtoearn {
        @include breakpoint-max(xs) {
          order: 3;
          width: 100%;
        }
      }

    .reward-section {
      padding: $space-md 0px ;
      display: flex;
      max-width: 1140px;
      gap: $space-standard;
      justify-content: center;
      flex-wrap: wrap;

      @include breakpoint-max(xs) {
        padding: 22px 0;
        order: 2;
      }

      .reward-title {
        width: 117px;
        color: $color__black;
        text-align: center;
        font-size: $text__body-xxl;
        font-style: normal;
        font-weight: bold;
        text-transform: uppercase;
        padding-bottom: $space-xs;
      }

      .reward-expiry-general {
        color: $color__gray-order;
        font-size: $text__body-xs;
      }

      .reward-expiry-date {
        color: $color__red;
        font-size: $text__body-xs;
      }

      .reward-info {
        color: $color__gray-order;
        font-size: $text__body-sm;
      }


      .reward-tile {
        width: 120px;
        height: 120px;
        border-radius: $radius-round;
        background: $color__primary;
        display: flex;
        justify-content: center;
        align-items: center;
        margin: $space-sm 0px;

        &__content {
          display: flex;
          flex-direction: row;
          justify-content: center;
          align-items: center;


          &-dollar {
            font-size: $text__header-xxl;
            font-weight: bold;
            color: $color__white;
          }

          &-amount {
            font-size:  calc($text__header-xxxl + $text__header-xxl + $text__body-lg);
            font-weight: bold;
            color: $color__white;
            margin-bottom: $space-standard;
            margin-right: $space-xs;
          }

          &-discount {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;

            &-percent {
              display: flex;
              align-items: center;
              justify-content: center;

              h1 {
                font-size: calc($text__header-xxxl + $text__body-xxl + $text__body-lg);
                font-weight: bold;
                letter-spacing: - $letter__spacing-xl;
                color: $color__white;
              }

              span {
                font-size: $text__header-xxl;
                line-height: $line-height-xxl;
                color: $color__white;
                padding-left: $space-xxxs;
              }
            }

            &-off {
              font-size: $text__body-xxl;
              line-height: $line-height-md;
              color: $color__white;
              margin-bottom: $space-xxs;
            }
          }
        }
      }

    }

    .reward-howtoearn {
      text-transform: uppercase;
      font-size: $text__body-sm;
    }

  }

  .badge-container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 16px;
    padding: $space-md 0px;
    padding-bottom: $space-xxxl;

    @include breakpoint-max(xs) {
      align-items: center;
    }
    

    .badge-name {
      color: $color__black;
      font-size: $text__body-xxl;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
    }

    .badge-section {
      display: flex;
      justify-content: center;
      align-items: flex-start;
      align-content: flex-start;
      gap: 22px;
      align-self: stretch;
      flex-wrap: wrap;

      .title {
        padding-top: $space-xs;
        color: #1F1F1F;
        text-align: center;
        font-size: $text__body-xs;
        font-style: normal;
        font-weight: bold;
        line-height: normal;
        text-transform: capitalize;
      }
    }
  }

  &__qrcode-wrapper {
    display: flex;
    justify-content: end;
    .summary-qrcode {
      display: flex;
      align-items: center;
      margin-bottom: $space-md;
      border: 1px solid $color__primary-light;
      cursor: pointer;
      p {
          max-width: $space-xl;
          padding-bottom: $space-xs;
      }
      svg {
          margin-bottom: $space-xxs;
      }
  }
  }
}