.gigya__tc-button {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.btn__primary {
    height: calc($space-xl + $space-xs);
    width: 100%;
    border-radius: $radius-xs;
    background-color: $color__primary;
    font-size: $text__body-sm;
    font-weight: bold;
    letter-spacing: $letter__spacing-sm;
    text-align: center;
    text-transform: uppercase;
    padding: 0 $space-md;
    color: $color__white;
    transition: all 0.2s;

    &:disabled {
        border: 1px solid $color__gray-lightest;
        background-color: $color__gray-lightest;
        color: #474747;
    }

    &:hover:not(:disabled):not(.btn__primary--no-outline) {
        background-color: $color__primary-dark;
    }

    @include breakpoint(xs) {
        max-width: fit-content;
    }

    &--full {
        max-width: 100%;
    }
}

.btn__secondary {
    height: calc($space-xl + $space-xs);
    width: 100%;
    border: 1.5px solid $color__primary;
    border-radius: $radius-xs;
    background-color: $color__white;
    font-size: $text__body-sm;
    font-weight: bold;
    letter-spacing: $letter__spacing-sm;
    text-align: center;
    text-transform: uppercase;
    padding: 0 $space-md;
    color: $color__primary;
    transition: all 0.2s;

    &:hover {
        color: $color__primary-dark;
        border-color: $color__primary-dark;
        background-color: $color__white;
    }

    @include breakpoint(xs) {
        max-width: fit-content;
    }

    &--full {
        max-width: 100%;
    }
}

.btn__link {
    color: $color__primary;
    font-size: $text__body-sm;
    font-weight: bold;
}

.btn--close {
    position: absolute;
    top: 0;
    right: 0;
}

@keyframes spinner {
    to {
        transform: rotate(360deg);
    }
}

.btn__animated {
    background-color: $color__white !important;
    border: 2px solid $color__primary !important;
    border-top-color: $color__white !important;
    -webkit-transition: all .2s !important;
    transition: all .2s !important;
    border-radius: 50% !important;
    width: 56px !important;
    height: 56px !important;
    color: $color__white !important;
    -webkit-animation: spin .75s linear infinite;
    animation: spinner .75s linear infinite;
}

/* TODO: remove the below styles 
after registration is refactored */
.pop-button {
    border-radius: $radius-xs;
    margin-bottom: $space-xxxs;
    padding: $space-standard 0;
    font-size: $text__body-sm;
    font-weight: bold;
}
.pop-button-primary {
    border: 1px solid $color__primary;
    background-color: $color__primary;
    color: $color__white;
}
.pop-button-full {
    width: 100%;
    cursor: pointer;
}
.btn-filter-outline {
    border: 1px solid $color__primary;
    background-color: $color__white;
    color: $color__primary;
}
.pop-button-disabled, 
button.pop-button:disabled,
button.pop-button[disabled] {
    border: 1px solid $color__gray-lightest;
    background-color: $color__gray-lightest;
    color: $color__gray-darker;
}
.btn__floating {
    display: flex;
    box-shadow: $shadow__floating-button;
    padding: $space-xs $space-standard;
    border-radius: $radius-xxl;
    gap: $space-xxxs;

    p {
        font-size: $text__body-sm;
        font-weight: bold;
        line-height: $line-height-sm;
        letter-spacing: $letter__spacing-none;
        color: $color__primary;
    }
    svg {
        color: $color__primary;
    }
}