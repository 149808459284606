.pop_card_block {
  margin: 3px;
}
.pop-card {
  display: block;
  margin-top: 0px;
  margin-bottom: 10px;
  cursor: pointer;
  ion-card-header {
    padding: 0px;
  }
  h3 {
    height: auto;
    color: #ffffff;
    font-weight: bold;
    position: absolute;
    padding-left: 10px;
  }
}
.ion-card {
  &.pop-card {
    display: block;
    cursor: pointer;
  }
}
.pop-card-tall {
  .pop-card {
    img {
      min-height: 300px;
    }
  }
}

/* Taller cards with personalized offers */
