.circle-card {
  width: 128px;
  margin-bottom: $space-standard;
  cursor: pointer;

  p {
    font-size: $text__body-base;
    line-height: $line-height-md;
    font-weight: bold;
    text-align: center;
    color: $color__black;
    margin: 0;
    text-transform: capitalize;
    width: 100%;
    margin-top: $space-standard;
  }

  &-image-wrap {
    width: 100%;
    position: relative;
    content: '';
    display: block;
  }

  &-image {
    position: relative;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    border-radius: 50%;
    overflow: hidden;
    width: 100%;

    img {
      object-fit: cover;
      border-radius: 50%;
      border: 1px solid $color__gray-lightest-border;
    }
  }

  &-focus:focus {
    .circle-card {
      outline: $outline__normal;
    }
  }

}