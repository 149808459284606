.main-menu {
  &::part(container) {
    width: 336px;

    @include breakpoint-max(xs) {
      width: 90%;
    }
  }

  ion-accordion ion-list {
    padding: 0;
  }

  // Allow scrolling with no scroll bar
  ion-content {
    --offset-bottom: auto !important;
    --overflow: hidden;
    overflow: auto;

    &::-webkit-scrollbar {
      display: none;
    }
  }

  &__close {
    position: absolute;
    right: $space-md;
    top: $space-standard;
    width: calc($space-standard + $space-xxxs);
    height: calc($space-standard + $space-xxxs);
    button {
      svg {
        width: calc($space-xxxs * 5);
        height: calc($space-xxxs * 5);
      }
    }

  }

  &__my-account {
    margin-top: $space-lg;
    width: 100%;

    label {
      font-size: $text__body-md;
      line-height: $line-height-xxxl;
      font-weight: bold;
      margin-bottom: $space-md;
      display: flex;
      justify-content: flex-start;
      padding: 0 $space-md;
      color: $color__gray-order;
      text-align: center;
      letter-spacing: $letter__spacing-xxs;
    }

    &__logo {
      width: 116px;
      background-image: url(/images/logo_color.svg);
      background-repeat: no-repeat;
      background-position: center;
      display: inline-block;
      margin-left: $space-xs;
      height: 26px;
    }

    &__buttons {
      display: flex;
      justify-content: center;
      margin-bottom: $space-md;

      .btn__secondary {
        max-width: 140px;
      }
    }

    .btn__secondary:nth-child(2) {
      margin-left: $space-xs;
    }
  }

  &__store {
    padding: $space-standard 0 $space-standard $space-md;
    position: relative;
    border-left: 4px solid #cbcbcb;
    border-bottom: 1px solid $color__gray-lightest;
    border-top: 1px solid $color__gray-lightest;

    &:hover {
      cursor: pointer;
    }

    &:focus {
      text-indent: 0;
      outline: $outline__normal;
    }

    p {
      font-size: $space-standard;
      line-height: 20px;
      display: block;

      &:focus {
        text-indent: 0;
        outline: $outline__normal;
      }
    }

    &-text {
      margin-bottom: $space-xs;
    }

    &-address {
      font-size: $text__body-base;
      margin-bottom: $space-xs;
      padding: 0;
      font-weight: bold;
      @include line-clamp(1);
      width: 86%;
      color: $color__primary;
      margin-left: $space-xxxs;
    }

    &-opening {
      font-size: $text__body-xs  !important;
      color: $color__gray-dark;
      margin: 0;
      padding: 0;
      font-weight: bold;
    }
  }

  &__list {
    &__item {
      min-height: calc($space-xl + $space-xs);
      height: 100%;
      font-size: $text__body-base;
      font-weight: bold;
      cursor: pointer;
      width: 100%;
      border-left: 4px solid $color__primary;
      border-bottom: 1px solid #e7e7e7;
      a {
        color: $color__black-light;
        display: inline-block;
        padding: $space-standard;
        width: 100%;
        &:focus-within {
          outline: $outline__normal;
          outline-offset: -1px;
        }
      }

      &-category {
        border-left: 0;
      }

      &-end-menu {
        border-left: 4px solid #cbcbcb;
      }
    }

    &__divider {
      width: 100%;
      height: 10px;
      background-color: $color__primary-light;
      border-left-color: $color__primary-light;
      border-radius: 0;
    }
  }

  &__accordion-header {
    @include no-ripple();

    :hover {
      color: $color__primary;
    }

    .ion-accordion-toggle-icon {
      transition: none !important;
      height: 20px;
      width: 20px;
    }

    ion-label {
      font-family: 'sofia-pro', sans-serif !important;
      font-weight: bold;
      margin: 0;
      cursor: pointer;

    }
    &::part(native):focus-within {
      outline: $outline__normal;
      outline-offset: -1px;
    }
  }

  &__sign-out-container {
    margin: $space-md;
  }

  &__menu-button-container {
    cursor: pointer;
  }

  &__menu-button {
    position: relative;
    left: 0;
    top: $space-xxs;
    height: $space-md;
    background-repeat: no-repeat;
    background-position: center;
    @include no-ripple();

    &::after {
      content: "MENU";
      font-weight: bold;
      font-size: $space-standard;
      width: 50px;
      display: inline-block;
      position: absolute;
      left: calc($space-xl + $space-sm);
      top: $space-xxxxs;
    }

    @include breakpoint-max(sm) {
      &::after {
        content: '';
      }
    }
  }
}