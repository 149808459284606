.store-search {
    @include page-container(lg);
    &__container {
        p{
            color: $color__gray;
            margin-bottom: $space-xxxs;
            font-size: $line-height-sm;
            line-height: $line-height-xl;
            font-weight: 300;
            display: block;
        }
    }

    &__loader {
        padding: $space-xl 0;
        text-align: center;
        margin: 0 $space-xxl;

        svg {
            margin-bottom: $space-standard;
        }
    }

    &__btn-close {
        position: absolute;
        right: $space-sm;
        top: $space-sm;
        z-index: 100;
        cursor: pointer;
    }

    &__main-header {
        display: flex;
        flex-direction: row;
        width: 100%;
        margin: $space-lg 0 $space-xxs 0;

        h2 {
            margin-top: $space-xxs;
            font-size: $text__body-xxl;
            font-weight: bold;
        }
    }

    &__use-my-location {
        display: flex;
        border-radius: 0 $space-xs $space-xs 0;
        padding: 0;
        margin-bottom: $space-standard;
    }

    &__location__text {
        color: $color__primary;
        margin-left: $space-xxxs;
        font-weight: bold;
        font-size: $text__body-sm;
    }

    &__location__text-animated {
        border: $space-xxxxs solid $color__primary ;
        color: $color__primary ;
        background-color: $color__gray-toolbar ;
        border-top: $space-xxxxs solid $color__gray-toolbar ;
        transition: width height .2s;
        border-radius: 50%;
        width: $space-standard ;
        height: $space-standard ;
        color: transparent;
        animation: spin 0.75s linear infinite;
    }

    &__message-text {
        font-size: $text__body-sm;
        font-weight: bold;
        color: $color__black;
        padding: 0;
        line-height: $line-height-lg;
        margin: auto;
    }

    &__list-item {
        padding: $space-sm $space-sm;
        border-bottom: 0.5px solid $color__input-border;
        display: flex;

        &--chosen {
            background-color: $color__light-blue;
        }

        &__content {
            text-align: left;

            &__label {
                font-size: $text__body-base;
                font-weight: bold;
                color: $color__black;
                padding: 0;
                line-height: $line-height-lg;
            }

            &__address {
                font-size: $text__body-sm;
                font-weight: bold;
                color: $color__gray-order;
                padding: 0;

                &-distance {
                    font-size: $text__body-sm;
                    color: $color__gray-order;
                }
            }

            &__btn-details {
                color: $color__primary;
                font-size: $text__button-sm;
                font-weight: bold;
                padding-left: 0;
            }

            &__btn-select {
                margin-left: auto;
                display: grid;
                align-items: center;

                &__selected {
                    display: flex;
                    align-items: baseline;
                    margin-right: $space-sm;

                    svg {
                        margin: auto $space-xs;
                    }

                    p {
                        font-size: $text__body-sm;
                        font-weight: bold;
                        color: $color__black;
                        line-height: $line-height-lg;
                        margin: auto;
                    }

                }

            }

            &-eligible {
                display: flex;
                flex-direction: row;
                align-items: center;
                justify-content: flex-start;

                p {
                    font-size: $text__body-sm;
                    margin-left: $space-xxxxs;
                    margin-bottom: 0;
                    color: $color__gray;
                }

                svg {
                    width: calc($space-xxxs * 5);
                    height: calc($space-xxxs * 5);
                }
            }

            &-ineligible {
                text-align: left;
                font-weight: 500;
                color: $color__red;
                margin-bottom: 0;
                color: $color__red;
                line-height: $line-height-base;
                font-size: $text__body-sm;
            }

            &-oos {
                text-align: left;
                color: $color__red !important;
                margin: 0;
                color: $color__red;
                font-size: $text__body-sm;
            }

            &-hidden {
                display: none;
            }
        }
    }

    &__header {
        display: flex;
        width: 100%;
        margin-bottom: $space-standard;

        &__text {
            flex: 1;
        }

        &__input {
            width: 80%;
            margin-bottom: $space-standard;
            margin-left: $space-standard;
        }

        &__button {
            margin-left: $space-md;
            margin-top: auto;

            button {
                border: $outline__normal;
                background-color: $color__primary;
                color: $color__white;
                border-radius: $radius-xs;
                padding: $space-standard $space-xl;
                font-size: $text__body-sm;
            }
        }
    }

    .input-wrapper input {
        padding-right: $space-sm;
    }
}