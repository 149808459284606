.tooltip {
    &__cardproduct {
        position: absolute;
        left: 10px;
        bottom: 57px;
        font-family: inherit;
        font-style: normal;
        font-weight: normal;
        line-height: 1.42857143;
        font-size: $text__body-xs;
        display: inline-block;
        width: calc(100% - 20px);
        display: flex;
        background-color: $color__primary-light;
        border-radius: $radius-xs;
        align-items: flex-start;
        border: $outline__black;
        z-index: 9999;
        @include breakpoint(xs) {
            width: calc(100% - 20px);
        }
        &::before {
            content: "";
            position: absolute;
            display: block;
            top: 100%;
            left: 50%;
            transform: translateX(-50%);
            margin-left: -5px;
            border: 14.5px solid transparent;
            border-color: black transparent transparent transparent;
        }
        &::after {
            content: "";
            position: absolute;
            top: 100%;
            left: 50%;
            transform: translateX(-50%);
            margin-left: -5px;
            border: 13px solid transparent;
            border-color: $color__primary-light transparent transparent transparent;
        }
        &-fulfillment-pickup {
            overflow: visible;
            padding: $space-xs;
            position: absolute;
            left: 20px;
            bottom: 67px;
            font-family: inherit;
            font-style: normal;
            font-weight: normal;
            line-height: 1.42857143;
            font-size: $text__body-xs;
            width: calc(100% - 40px);
            display: flex;
            background-color: $color__primary-light;
            border-radius: $radius-xs;
            align-items: flex-start;
            border: $outline__black;
            z-index: 9999;
            &::before {
                content: "";
                position: absolute;
                display: block;
                top: 100%;
                left: 85%;
                transform: translateX(6%);
                margin-left: -5px;
                border: 14px solid transparent;
                border-color: black transparent transparent transparent;
                @include breakpoint(xs) {
                    left: 91%;
                }
                @include breakpoint(sm) {
                    left: 82%;
                }
                @include breakpoint(lg) {
                    left: 89%;
                }
            }
            &::after {
                content: "";
                position: absolute;
                display: block;
                top: 100%;
                left: 84.75%;
                transform: translateX(10%);
                margin-left: -5px;
                border-width: 13px;
                border-style: solid;
                border-color: $color__primary-light transparent transparent transparent;
                @include breakpoint(xs) {
                    left: 91%;
                }
                @include breakpoint(sm) {
                    left: 82%;
                }
                @include breakpoint(lg) {
                    left: 89%;
                }
            }
        }
    }
    &__cartproduct {
        position: absolute;
        left:20px;
        bottom: 117px;
        font-family: inherit;
        font-style: normal;
        font-weight: normal;
        line-height: 1.42857143;
        font-size: $text__body-xs;
        display: inline;
        width: calc(100% - 40px);
        display: flex;
        background-color: $color__primary-light;
        border-radius: $space-xxs;
        outline: $outline__black;
        align-items: flex-start;
        z-index: 9999;
        cursor: pointer;
        &::before {
            content: "";
            position: absolute;
            display: block;
            top: 100%;
            left: 81%;
            transform: translateX(6%);
            margin-left: -5px;
            border: 14px solid transparent;
            border-color: black transparent transparent transparent;
            @include breakpoint(xs) {
                left: 88%;
                }
            @include breakpoint(sm) {
                left: 91%;
                }
            @include breakpoint(lg) {
                left:90%;
            }
        }

        &::after {
            content: "";
            position: absolute;
            top: 100%;
            left: 81%;
            transform: translateX(10%);
            margin-left: -5px;
            border-width: 13px;
            border-style: solid;
            border-color: $color__primary-light transparent transparent transparent;
            @include breakpoint(xs) {
                left:88%;
                }
            @include breakpoint(sm) {
                left: 91%;
                }
            @include breakpoint(lg) {
                left: 90%;
            }
        }
    }
    &__cart {
        position: absolute;
        left: 4%;
        top: 8%;
        z-index: 999;
        background-color: white;
        border: $border__gray;
        border-radius: $radius-sm;
        padding: $space-sm;
        @include breakpoint(sm) {
            left: 2%;
            top: 8%;
        }
        @media(min-width: 992px) {
            top: 8%;
        }
        @include breakpoint(md) {
            top: 8%;
        }
        &::before {
            content: "";
            position: absolute;
            display: block;
            bottom: 100%;
            left: 33%;
            transform: translateX(6%);
            margin-left: -5px;
            border-width: 14px;
            border-style: solid;
            border-color: transparent transparent #c8c7cc transparent;
        }
        &::after {
            content: "";
            position: absolute;
            display: block;
            bottom: 99%;
            left: 33%;
            transform: translateX(6%);
            margin-left: -5px;
            border-width: 14px;
            border-style: solid;
            border-color: transparent transparent white transparent;
        }
        &-zip {
            &-top {
                display: flex;
                flex-direction: row;
                justify-content: space-between;
                align-items: center;
                h1 {
                    color: $color__gray;
                }
            }
            &-bottom {
                display: flex;
                flex-direction: row;
                align-items: center;
                // div {
                //     width: 75%;
                // }
                button {
                    min-width: 100px;
                    background-color: $color__primary;
                    padding: $space-sm;
                    margin-left: $space-sm;
                    border-radius: $radius-xs;
                    color: white;
                    font-weight: bold;
                }
                input {
                    min-width: 125px;
                    border-radius: $radius-xxs;
                    border: 1px solid $color__gray-lightest-border;
                    box-shadow: $shadow__standard;
                    padding: $space-xs;

                }
            }
        }
    }
    &__close {
        padding: $space-xxxxs;
        display: inline-block;
        color: $color__black;
        font-size: $text__header-xxl;
        font-weight: normal;
        text-align: right;
        line-height: 62.5%;
        cursor: pointer;
    }

    &__label {
        padding: $space-xxxs;
        font-size: $text__body-sm;
        padding-left: $space-xs;
        color: $color__black;
        flex-basis: calc(100% - 20px)
    }
    &__anchor {
        left: 0;
        position: absolute;
        top: 0;
    }
}

.product-card{ 
    &__vertical {
        .tooltip {
            &__cardproduct {
                position: absolute;
                bottom: 67px;
                left: 25px;
                width: calc(100% - 50px);
                &::before {
                    left: 90%;
                    @include breakpoint(xs) {
                        left: 78%;
                    }
                }
                &::after {
                    left: 90%;
                    @include breakpoint(xs) {
                        left: 78%;
                    }
                }
            }
        }
    }
}

.quickview__modal {
    .tooltip__cardproduct {
        &-fulfillment-pickup {
            overflow: visible;
            padding: $space-xs;
            position: absolute;
            left: 25px;
            bottom: 67px;
            font-family: inherit;
            font-style: normal;
            font-weight: normal;
            line-height: 1.42857143;
            font-size: $text__body-xs;
            width: calc(100% - 50px);
            display: flex;
            background-color: $color__primary-light;
            border-radius: $radius-xs;
            align-items: flex-start;
            border: $outline__black;
            z-index: 9999;
            &::before {
                content: "";
                position: absolute;
                display: block;
                top: 100%;
                left: 81%;
                transform: translateX(2%);
                margin-left: -5px;
                border: 14px solid transparent;
                border-color: black transparent transparent transparent;
            }
            &::after {
                content: "";
                position: absolute;
                display: block;
                top: 100%;
                left: 80.75%;
                transform: translateX(10%);
                margin-left: -5px;
                border-width: 13px;
                border-style: solid;
                border-color: $color__primary-light transparent transparent transparent;
            }
        }
    }
}
