.benefits {

    &__section {
        width: 100%;
        max-width: $space-max-width-xl;

        &__banner {
            display: flex;
            width: 100%;
            height: auto;
            justify-content: center;
            align-items: center;

            @include breakpoint-max(xs) {
                display: none;
            }
        }

        &__banner-mobile {
            display: none;

            @include breakpoint-max(xs) {
                display: flex;
                width: 100%;
                height: auto;
                justify-content: center;
                align-items: center;
            }
        }
    }

    &__container{
        &__content {
            
            h1{
                @include breakpoint-max(xs) {
                    line-height: $line-height-xxxxl;
                    margin-bottom: $space-sm;
                }
            }
        }

        &__image-one, &__image-two{
            margin-bottom: calc($space-xs + $space-xl);

            @include breakpoint-max(xs) {
                display: none;
            }

            &-mobile{
                display: none;

                @include breakpoint-max(xs) {
                    display: flex;
                    width: 100%;
                    height: auto;
                    justify-content: center;
                    align-items: center;
                }
            }
        }

        &__columns{
            display: flex;
            gap: $space-md;

            @include breakpoint-max(sm) {
                flex-direction: column;
            }

            &__left , &__right{
                flex: 1;

                h1 {
                    color: $color__primary;
                    font-size: $text-h1;
                    font-style: normal;
                    font-weight: bold;
                    line-height: $space-xxl;
                    margin-bottom: $space-md;

                    @include breakpoint-max(sm) {
                        font-size: $text__header-xxl;
                        line-height: $line-height-xxxl;
                    }
                }

                p {
                    margin-bottom: $space-xl;
                }

                ul {
                    margin-left: $space-xl;
                    margin-bottom: calc($space-xs + $space-xl);
                }
            }
        }

        &__button-row{
            margin-bottom: calc($space-xs + $space-xl);
        }
    }
}