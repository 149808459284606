.checkout-first-step {
  width: 100%;

  &__reserve {
    display: block;
    margin-bottom: $space-xs;
    font-weight: 700;
  }

  &__store {
    margin-bottom: $space-md;
  }

  &__pickup-text {
    color: $color__gray-order;
    margin-bottom: $space-xs;
  }

  &__pickup-date-selector-wrapper {
    display: flex;
    flex-wrap: wrap;
    color: $color__gray-order;
    margin-bottom: $space-xs;
  }

  &__divider {
    margin: $space-md 0;
  }

  &__pickup-date-selector {
    display: block;
    position: relative;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    width: 100px;

    @include breakpoint(xs) {
      margin-right: $space-standard;
    }

    .checkmark {
      position: absolute;
      top: 0;
      left: 0;
      height: 100%;
      width: 100%;
      background-color: transparent;
      border-radius: 0;
      border: none;

      &:after {
        content: '';
        position: absolute;
        display: none;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        border-radius: $radius-xs;
        transform: rotate(0);
        background: $color__primary;
        z-index: 0;
      }
    }

    .selector-date {
      position: relative;
      z-index: 100;
      text-align: center;
      padding: $space-xxs;
      display: inline-block;
      font-size: $text__body-xs;
      font-weight: 700;
      text-align: center;
      line-height: $line-height-base;
      height: calc($space-xl + $space-xs);
      width: 100%;
      color: $color__gray;

      span {
        display: block;
        font-size: $line-height-base;
        font-weight: 700;
        color: $color__black;
      }
    }

    input {
      position: absolute;
      opacity: 0;
      cursor: pointer;

      &:checked {
        & ~ .checkmark {
          background-color: transparent;
          &:after {
            display: block;
            border: none;
          }
        }
      }

      &:checked {
        & ~ .selector-date {
          color: $color__white !important;
          span {
            color: $color__white !important;
          }
        }
      }
    }
  }

  &__select-pickup-time {
    border: $border__gray-lighter;
    box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.1);
    cursor: pointer;
    background-color: $color__white;
    width: 100%;
    margin-bottom: $space-xs;
    float: left;
    position: relative;

    &:last-of-type {
      margin-bottom: 0;
    }

    .radio-wrapper {
      cursor: pointer;
      -webkit-user-select: none;
      -moz-user-select: none;
      -ms-user-select: none;
      user-select: none;
      margin-bottom: $space-xxxs;
      padding: $space-standard;
      line-height: $line-height-md;

      &__label-text {
        flex: 1;
        margin-bottom: 0;
      }

      input[type="radio"] {
        &:disabled ~ p.radio-wrapper__label-text {
          color: $color__gray;
        }

        & ~ svg {
          display: none;
          width: calc($space-xs + $space-sm);
          height: calc($space-xs + $space-sm);
        }

        &:checked ~ svg {
          display: block;
        }
  
      }
    }

    .pickup-time-status {
      position: absolute;
      right: $space-xxxs;
      top: 11px;
      display: inline-block;

      &.pickup-time-unavailable {
        color: $color__gray;
        margin-top: $space-xxxs;
        font-size: $text__body-xs;
        margin-right: $space-standard;
        line-height: $line-height-lg;
      }
    }
  }
}
