.checkout-payment {
  .btn__secondary {
    margin-bottom: $space-md;
  }

  .payment-method-spinner{
    display: grid;
    align-items: center;
    justify-content: center;
    gap: $space-standard;
    padding: $space-md 0;

    .payment-method__button__animated{
      height: $space-lg;
      width: $space-lg;
      border: $outline__heavy;
      background-color: $color__white;
      border-top: $outline__white-heavy;
      border-radius: $radius-round;
      color: $color__white;
      border-top-color: $color__white;
      animation: spinner 0.6s linear infinite;
      margin: auto;
    }
  }


  .radio-wrapper {
    margin-bottom: $space-sm;

    .radio-wrapper__dot {
      margin-right: $space-sm;
    }

    label {
      display: flex;
      align-items: center;
      width: 100%;
      background-color: $color__white;
      box-shadow: $shadow__standard;
      padding: $space-standard;
      border-radius: $radius-sm;
      cursor: pointer;
    }

    span {
      display: flex;
      align-items: center;
      font-weight: bold;

      svg {
        height: calc($space-md + $space-xxs);
        width: calc($space-xl + $space-xxxs);
        margin-right: $space-sm;
      }

      &.expired {
        width: 100%;
      }

      &.expired-text {
        width: 100%;
        display: flex;
        justify-content: space-between;
        color: $color__red;

        span {
          color: $color__red;
          display: flex;
        }

        span:nth-of-type(2) {
          font-size: $text__body-sm;
        }
      }
    }

    &:last-of-type {
      margin-bottom: calc($space-md + $space-sm);
    }
  }

  .btn__add-payment {
    margin: 0 0 $space-md;
  }

  .btn__payment-next {
    margin: $space-md 0 0;

    @include breakpoint-max(md) {
      margin: $space-md 0;
    }
  }
}
