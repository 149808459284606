.terms-model-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
}
.terms-condition-modal {
    h1 {
        font-size: $text__body-xxl;
        font-weight: 800;
        line-height: $line-height-xl;
        text-align: left;
        margin-bottom: 20px;
    }
    label {
        font-size: $text__body-sm;
    }
    .input-wrap {
        margin-top: $space-md;
            a {
                border-radius: 2px;
                border: 1px solid $color__gray;
                line-height: 20px;

                &:focus-within {
                    outline: $outline__normal;
                    outline-offset: 0;
                }

                p {
                    font-size: $text__body-base;
                    margin: 10px;
                }
                span {
                    margin-right: $space-md;
                    display: flex;
                    align-items: center;
                    svg {
                        margin-bottom: 0px;
                    }
                } 
            }
        }
        .error-label {
            padding-bottom: 10px;
            margin-top: 0;
            display: block;
            text-align: center;
            color: $color__red !important;
            font-size: $text__body-sm;
            font-weight: 700;
        }
        .agree-button {
            margin-bottom: 10px;
            margin-top: $space-md;  
        }
        .btn__primary {
            font-weight: normal;
        } 
    }
