.order-status {
    border-radius: $radius-xxs;
    box-shadow: $shadow__order-details;
    width: 100%;
    &__bar {
        border-bottom: $border__order-details;
        padding: $space-standard $space-standard 0 $space-standard;
    }
    &__info {
        padding: $space-standard;
        p {
         font-size: $text__body-md;   
         line-height: $line-height-xxl;
         font-weight: lighter;
        }
        &-completed {
            padding: $space-standard;
            p {
                padding-bottom: $space-sm;
                font-size: $text__body-md;
                line-height: $line-height-xl;
            }
            h3 {
                font-weight: $font-weight__light;
                color: $color__gray-order;
                line-height: $line-height-xl;
            }
            p:last-of-type {
                padding-bottom: 0;
            }
        }
        &-title {
            margin-top: $space-xs;
            color: $color__gray-order;
            font-size: $text__body-base;
            letter-spacing: 0;
            line-height: $line-height-xl;
        }
        &-name {
            font-size: $text__body-md;
            padding-bottom: $space-xs;
        }
        h3 {
            color: $color__black;
            font-size: $line-height-md;
            font-weight: bold;
            letter-spacing: 0;
            line-height: $line-height-xxl;
        }
        &-directions {
            margin-top: $space-xxxs;
            margin-bottom: $space-xs;
            a {
                font-weight: bold;
                font-size: $text__body-sm;
                letter-spacing: 0.3px;
                line-height: $line-height-lg;
            }
        }
    }
    &__canceled {
        padding: $space-standard;
        border-bottom: $border__order-details;
        p {
            color: $color__red;
            font-size: $text__body-base;
            font-weight: normal;
            letter-spacing: 0;
            line-height: $line-height-xl;
        }
    }
    &__loyalty-points {
        padding: 0px $space-standard $space-standard;
    }
}