.about-me {
  &__header {
    &-icon {
      margin-right: $space-sm;
      width:  $space-lg;
      height:  $space-lg;

      svg {
        margin: auto;
        color: $color__white;
        width:  100%;
        height: 100%;
      }
    }

    &-text {
      width: 100%;
    }

    &-container {
      display: flex;
      margin-bottom: $space-sm;
      align-items: center;
    }

    &-text {
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: $space-xs;
      margin-bottom: $space-md;

      h1 {
        font-size: $text__body-xxl;
        line-height: $line-height-xxl;
        font-weight: bold;
        color: $color__black;
      }
      p {
        text-align: center;
        font-size: $text__body-base;
        line-height: $line-height-lg;
      }
    }
  }

  &__initial-error {
    border: 1px solid $color__red !important;
    color: $color__red;
  }
  &__composite-input-label {
    color: $color__gray;
    margin-bottom: $space-xxxs;
    font-size: $text__body-sm;
    line-height: $line-height-xl;
    font-weight: $font-weight__light;
  }

  &__birthday-input {
    display: flex;
    align-items: flex-end;
    gap: $space-sm;
  }

  &__birthmonth {
    flex: 1;
    .suffix {
      pointer-events: none;
    }
  }

  &__birthday {
    flex: 0 $space-xxxl;
    .suffix {
      pointer-events: none;
    }
  }

  &__input-section {
    margin-bottom: $space-standard;
  }

  &__checkbox {
    margin-bottom: $space-md;
  }

  &__error-message {
    font-size: $text__body-base;
    color: $color__red;
    margin: $space-sm 0 $space-md 0;
    display: block;
    text-align: left;
  }

  label {
    margin-bottom: $space-sm;
  }
}