.checkout-page {
  &__wrapper {
    padding: $space-standard 0;
    @include page-container(lg);
  }

  &__container {
    display: flex;
    flex-wrap: wrap;
    width: 100%;

    > form {
      display: flex;
      margin: 20px 0;
      width: 100%;

      @include breakpoint-max(md) {
        flex-direction: column;
      }
    }

    @include breakpoint(md) {
      flex-direction: row;
    }
  }

  &__guest {
    border-bottom: 1px solid $color__gray-progress-bar;
    margin-bottom: $space-md;
    width: 100%;

    h1 {
      margin-bottom: $space-sm;
      font-size: $text__body-md;
      font-weight: 600;
    }
    p {
      margin-bottom: $space-md;
      color: $color__black-light;
    }
    button {
      margin-bottom: $space-md;
      max-width: 100%;
    }
  }

  /* Left Panel */
  &__steps {
    display: flex;
    flex-wrap: wrap;
    flex: 100%;
    margin-bottom: $space-standard;

    > div {
      width: 100%;
    }

    @include breakpoint(md) {
      flex: 58%;
      max-width: 58%;
      margin-bottom: 0;
      margin-right: $space-standard;
    }
  }

  /* Right Panel */
  &__cart-total {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    flex: 100%;
    padding: 0 $space-standard;

    @include breakpoint(md) {
      flex: 0 0 42%;
      max-width: 42%;
      margin-left: 8%;
    }

    .pop-button-animated {
      margin: 0 auto;
    }
  }

  &__form-text {
    font-weight: 700;
    margin: $space-md 0;
    width: 100%;
    text-align: left;

    span {
      font-weight: normal;
    }

    a + span {
      margin: 0 $space-xxxs;
    }
  }

  .closed-step {
    background-color: #f5f5f5;
    border: 1px solid #d8d8d8;
    padding: 20px;
    line-height: 20px;
    position: relative;
    width: 100%;

    &:not(:last-of-type) {
      margin-bottom: 12px;
    }

    p,
    address {
      width: 80%;
    }

    > p:first-of-type {
      font-weight: 700;
      margin-bottom: 15px;
      display: block;
      position: relative;
      padding-left: 24px;

      &::before {
        content: url(/images/icons/check-green.svg);
        width: 13px;
        height: 15px;
        display: block;
        left: 0;
        top: 0;
        position: absolute;
      }
    }

    &--empty {
      > p:first-of-type {
        font-weight: 500;
        margin-bottom: 0;
        padding-left: 0;

        &::before {
          display: none;
        }
      }
    }

    .btn__link__checkout {
      color: #87189d;
      font-size: 14px;
      font-weight: bold;
      position: absolute;
      top: 24px;
      right: 24px;
      padding: 0;
    }
  }

  button[id^='btn-checkout-next'] {
    margin: $space-lg 0;
  }
}
