div.rte {
    padding: 0px ;
    width: 100%;
    text-align: left;
    line-height: 1.75;
    ul {
        list-style-type: disc;
        padding-left: 20px;
    }
    ol {
        list-style: decimal;
        padding-left: 20px;
    }
    p {
        i {
            font-style: italic;
        }
        b {
            font-weight: bold;
        }
    }
    h1 {
        display: block;
        font-size: $text__header-xxl;
        margin-top: $space-sm;
        margin-bottom: $space-sm;
        font-weight: bold;
    }
    h2 {
        display: block;
        font-size: $text__body-xxl;
        margin-top: $space-standard;
        margin-bottom: $space-standard;
        font-weight: bold;
        text-align: left;
        margin-left: auto;
    }
    h3 {
        display: block;
        font-size: $text__body-md;
        margin-top: $space-standard;
        margin-bottom: $space-standard;
        font-weight: bold;
    }
    h4 {
        display: block;
        font-size: $text__body-base;
        margin-top: $space-md;
        margin-bottom: $space-md;
        font-weight: bold;
    }
    h5 {
        display: block;
        font-size: $text__body-sm;
        margin-top: $space-md;
        margin-bottom: $space-md;
        font-weight: bold;
    }
    h6 {
        display: block;
        font-size: $text__body-xxs;
        margin-top: $space-lg;
        margin-bottom: $space-lg;
        font-weight: bold;
        text-align: left;
        margin-left: auto;
    }
}
