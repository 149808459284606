.newdevice-page {
  h1 {
    margin-bottom: 30px;
    margin-top: 20px;
    padding-top: 0px;
    font-size: 32px;
    font-weight: 800;
    text-transform: lowercase;
  }
  p {
    font-size: 13px;
    line-height: 16px;
    margin-bottom: 14px;
    font-weight: 500;
  }
  .btn__primary {
    margin-bottom: $space-standard;
  }
}
