:root {
    --star-size: 15px;
    --star-color: #959595;
    --star-background: #fc0;
  }
  
  .Stars {
    --percent: calc(var(--rating) / 5 * 100%);
    
    display: inline-block;
    font-size: var(--star-size);
    font-family: Times; // make sure ★ appears correctly
    line-height: 1;
    
    &::before {
      content: '★★★★★';
      letter-spacing: 3px;
      background: linear-gradient(90deg, var(--star-background) var(--percent), var(--star-color) var(--percent));
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
    }
  }