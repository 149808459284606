.search-wrap {
  background-color: $color__gray-toolbar;
  width: 100%;
  text-align: left;
  padding: $space-standard;
  border-radius: $radius-xs;
  margin-bottom: $space-sm;
  position: absolute;
  top: 0;
  transition: width 2s;
  width: 570px;
  @include z-index(primary);
  clear: left;
  right: 0;
}

.search-wrap-main {
  &.open {
    display: block;
  }

  &.closed {
    display: none;
  }
}

.pop-predictive-search {
  .rbt {
    width: 100%;
    margin: -48px auto 0;
  }

  input[type="text"] {
    background-color: #ffffff;
    border: 1px solid #e7e7e7 !important;
    height: 40px;
    border-radius: 6px;
    padding-left: 10px;
    padding-right: 45px;
    color: #000;
    font-weight: bold;
    position: absolute;
    width: 90%;
    right: 0;
    top: 11px;
  }

  input[type="text"].rbt-input-main {
    &::-webkit-input-placeholder {
      color: #777777;
      font-size: 14px;
      font-weight: bold;
      line-height: 26px;
    }

    &:-ms-input-placeholder {
      color: #777777;
      font-size: $text__body-sm;
      font-weight: bold;
      line-height: $line-height-xxxl;
    }

    &::placeholder {
      color: #777777;
      font-size: $text__body-sm;
      font-weight: bold;
      line-height: $line-height-xxl;
    }
  }

  &::before {
    content: "";
    position: absolute;
    width: 100%;
    height: 1px;
    background-color: #e7e7e7;
    right: 0px;
    top: 58px;
  }
}

.pop-predictive-search_btn {
  width: $space-standard;
  height: $space-standard;
  right: $space-sm;
  top: $space-md;
}

.rbt-input-hint {
  display: none;
}

.rbt-aux {
  display: none;
}

.recent_search {
  overflow: hidden;
  overflow-y: auto;
  white-space: nowrap;
  padding-top: $space-xxs;
}

.recent__search {
  &-term {
    &:hover {
      cursor: pointer;
    }
    &:focus-within {
      outline: $outline__normal;
      outline-offset: -2px;
    }
  
    button {
      &:focus {
        outline: 0 !important;
      }
    }
  }
}

.recent_search {
  ul {
    li {
      line-height: $line-height-xl;
      padding-bottom: $space-xxs;
      padding-top: $space-xxs;
      border-bottom: $border__gray;
      background-size: $space-standard;
      cursor: default;
      display: flex;
      align-items: center;
      justify-content: space-between;

      &:last-child {
        border-bottom: none;
      }

      button {
        font-size: $space-standard;
        max-width: 95%;
      }
    }
  }
}

.search_term_header {
  color: #9230a8;
  font-size: $text__body-md;
  font-weight: bold;
  letter-spacing: $letter__spacing-xxxs;
  line-height: $line-height-xxl;
  white-space: pre-wrap;
  padding-right: $space-xs;
  text-align: left;
}

.search-modal__predictive-search-button {
  background-color: $color__white;
  height: $space-lg;
  width: $space-lg;
  @include z-index(primary);
  cursor: pointer;

  svg {
    width: calc(($space-standard + $space-md) / 2);
    height: calc(($space-standard + $space-md) / 2);
  }
}

.category-menu-wrapper {
  display: inline;
  float: left;
  padding-right: $space-sm;
  padding-left: $space-standard;
  position: relative;
}


.inner-search-text {
  cursor: pointer;
}

.predictive-search__results {
  position: relative !important;
  display: block;
  margin-left: $space-standard;
  padding-bottom: $space-sm;

  @media (max-width: 576px) {
    max-height: 100% !important;
  }

  max-height: 500px !important;
  overflow-y: scroll !important;
  overflow-x: auto;
  will-change: unset !important;
  width: 100% !important;
  transform: none !important;
}

.menu.predictive-search__results li {
  padding-left: 0;
  padding-bottom: $space-md;
  list-style-type: none;
  height: auto;
}

ul.predictive-search__results li a mark,
.rbt-highlight-text {
  color: $color__black;
  font-weight: bold;
  background-color: transparent;
}

.close-search {
  height: calc(($space-xl + $space-xxl) / 2);
}

.search-wrap-overlay {
  background-color: $color__black;
  opacity: 0.5;
  position: fixed;
  width: 100vw;
  height: 100vh;
  left: 0;
  top: 0;
  z-index: 20;
}

@media (max-width: 576px) {
  .search-wrap-overlay {
    display: none;
  }

  .search-wrap {
    width: 100vw;
    height: 100vh;
  }

  .close-search {
    height: 45px;
  }

  .pop-predictive-search {
    input[type="text"] {
      background-color: $space-lg;
      border: 1px solid $color__gray-toolbar-border;
      height: $space-xl;
      border-radius: $radius-xs;
      padding-left: $space-sm;
      padding-right: $space-xl;
      color: $color__black;
      font-weight: bold;
      position: absolute;
      width: 90%;
      right: 0;
      top: $space-xs;
    }

    .rbt {
      width: 90%;
      margin: -44px auto 0;
    }
  }
}

.pop-predictive-search::before {
  background-color: transparent !important;
}

.recently_viewed_search {
  position: relative;
  padding: $space-standard $space-standard $space-sm;
  height: 100%;
  overflow-y: scroll;
  .swiper {
    width: 100%;
    margin-left: 0px;

    &__section {
      justify-content: flex-start;
    }
  }

  h3 {
    font-size: $text__body-base;
    color: $color__gray-recently-viewed;
  }

  .cards_recently_viewed {
    width: 100%;
    height: 240px;
    overflow: hidden;
    overflow-x: scroll;
    white-space: nowrap;

    .card_search_tile {
      // margin-top: 10px;
      margin-right: 10px;
      float: left;
      width: 120px;
      height: 120px;

      border: 1px solid #f0f0f0;
      // box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.1);
      background-color: $color__white;
      -webkit-border-radius: 8px;
      -moz-border-radius: 8px;
      border-radius: 8px;
    }
  }

  ul {
    padding-bottom: $space-md;
  }

  .swiper {
    &-slide {
      width: auto !important;
    }

    &-pagination {
      display: block !important;
    }
  }
}

.cards_horizontal_scroll {
  list-style: none;
  width: 100%;
  height: 130px;
  margin: 0;
  padding: 0;

  li {
    display: inline-block;
    height: 100%;
  }
}

.recently_viewed_search .cards_recently_viewed .card_search_tile img {
  width: 100%;
  padding: $space-xxs;
  height: 100%;
  object-fit: contain;
}

.category-child {
  padding-left: calc(($space-md + $space-md) / 2);
}

.empty-search {
  width: $space-xl;
  height: $space-xl;
  margin: auto;
  margin-bottom: $space-md;
  cursor: default;
}

.search-results {
  &__term {
    display: block;
    padding-bottom: $space-sm;
    padding-top: $space-sm;
    cursor: pointer;
    color: $color__primary;
    font-size: $text__body-base;
    font-weight: bold;
    letter-spacing: $letter__spacing-xxxs;
    border-bottom: $border__gray;
    padding-left: $space-xxs;
    &:focus {
      outline: $outline__normal;
      outline-offset: -2px;
    }

    &-child {
      padding-left: calc(($space-md + $space-md) / 2);
    }

    &-highlight {
      color: $color__black;
      &:focus-within {
        outline: $outline__normal;
      }
    }
  }

  &__category {
    &-name {
      color: $color__primary;
      text-transform: lowercase;
    }
  }

  &__null {
    margin-top: 16px;
  }

  &__suggestion {
    &-title {
      height: $space-sm;
      width: 119px;
      color: #1f1f1f;
      font-size: $text__body-base;
      letter-spacing: 0;
      line-height: $line-height-base;
      margin-top: calc(($space-md + $space-md) / 2);
      margin-bottom: $space-standard;
    }

    &-wrapper {
      overflow: hidden;

      .swiper {
        &-slide {
          width: auto !important;
        }

        &-pagination {
          display: block !important;
        }

        &__section {
          display: block;
        }
      }
    }
  }

  &__carousel {
    &-wrapper {
      padding: $space-standard;
    }
  }
}