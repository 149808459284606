.form-wrap .input-wrap label.label-form{
    color: $color__gray !important;
    font-size: 14px !important;
}

.regular-checkbox {
    cursor: pointer;
}

.password-checklist {
    margin: $space-standard 0;
}

.password-checklist li {
    margin-bottom: 12px;
    padding-left: $space-xxxs;
}

a {
    color: $color__primary;
}

.input-padding {
    padding: 10px !important;
}

.icon-eye-active {
    background-image: url("/images/icons/icon-eye-active.svg");
}
.icon-eye-inactive {
    background-image: url("/images/icons/icon-eye-inactive.svg");
}
.registration{
    padding: 0 $space-standard;
  }

.error-label {
    padding-bottom: 20px;
    margin-top: 10px;
    display: block;
    text-align: center;
    color: $color__red !important;
    font-size: $text__body-base;
}

.eyeball-pos {
    width: 24px;
    height: 24px;
    float: right;
    position: relative;
    top: -35px;
    right: 10px;
    background-repeat: no-repeat;
    background-size: contain;
}
.registration-success-modal{
    .swiper-wrapper{
      padding: 0px;
      height: auto !important;
    }
}
.registration-success{
    .icon-face{
        margin-bottom: 0;
    }
  }