.content-group{
    margin: $space-xl auto;
    width: 100%;
    &__header{
        position: relative;
        width: 100%;
        padding: 0 $space-standard;
        h2{
            font-size: $text__body-xl;
            font-weight: 700;
            text-align: center;
            margin: $space-lg 0;
            text-transform: lowercase;
            width: 100%;
            @include breakpoint-max(sm){
                font-size: $text__body-md;
                text-align: left;
                 margin: $space-md 0;
            }
        }
    }  
}
  