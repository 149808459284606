.four-panel {
  &-card:focus-within {
    outline: $outline__normal;
    outline-offset: -4px;
  }
  a:focus {
    outline: none;
  }

  display: flex;
  flex-wrap: wrap;
  height: 100% !important;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;

  &-card {
    display: flex;
    flex-direction: column;
    overflow: hidden;
    width: 50%;
    height: 50%;

    &__img {
      display: flex;
      width: 100%;
      ;
      flex: 1;
      position: relative;
      margin-top: $space-sm;

      img {
        max-width: 100%;
        @include center(true, true);
        object-fit: contain;
        max-height: 100%;
      }
    }

    &__title {
      display: flex;
      align-items: center;
      justify-content: center;
      padding: $space-sm 0;
      flex-shrink: 0;

      a {
        text-transform: lowercase;
        width: 90%;
        font-size: $text__body-lg;
        line-height: $line-height-xxxl;
        text-align: center;
        color: $color__black;
        font-weight: bold;
        @include line-clamp(1);

        @include breakpoint (sm) {
          font-size: $text__body-md;
          line-height: $line-height-xxl;
        }
      }
    }

    &:first-child {
      border-radius: $radius-xxs 0 0 0;
    }

    &:nth-last-child(2) {
      border-radius: 0 0 0 $radius-xxs;
    }

    &:nth-last-child(3) {
      border-radius: 0 $radius-xxs 0 0;
    }

    &:last-child {
      border-radius: 0 0 $radius-xxs 0;
    }
  }
}