.cart-product {
    padding: $space-sm;
    box-shadow: 0 5px 25px 1px rgba(55,58,64,0.14);
    border-radius: $radius-xs;
    display: flex;
    margin: $space-xs 0;
    width: 100%;

    &__content {
        width: 100%;
        display: flex;
        flex-direction: column;
    }

    &__image {
        flex-shrink: 0;
        margin-right: $space-sm;
        img {
            height: 100px;
            width: 100px;
            object-fit: contain;
        }
    }

    &__header {
        margin-bottom: $space-sm;
        display: flex;

        &__product-name{
            min-height: $space-xl;
            font-size: $text__body-base;
            line-height: $line-height-lg;
            @include line-clamp(3);
            margin-bottom: 0;
            margin-right: $space-sm;
            flex-grow: 1;

            @include breakpoint(lg) {
                height: $space-xl;
                @include line-clamp(2);
            }
        }
        &__product-name-oos{
            color: $color__gray;
        }

        &-delete {
            height: calc($space-xs* 2.5);
            width: calc($space-xs* 2.5);
            padding: 0px;
            flex-shrink: 0;
            svg {
                height: calc($space-xs* 2.5);
                width: calc($space-xs* 2.5);
            }
        }
    }

    &__price {
        display: flex;
        gap: $space-xs;
        flex-direction: row;

        &-final-price {
            font-size: $text__body-md;
            font-weight: bold;
            line-height: $line-height-lg;
            height: $line-height-lg;
            margin-bottom: $space-xs;
        }

        &-original-price {
            font-size: $text__body-sm;
            line-height: $line-height-md;
            color: $color__gray-brighter;
        }
    
    }
    
    &__meta {
        display: flex;
        width: 100%;
        justify-content: space-between;
        align-items: flex-start;
        p {
            margin-bottom: 0;
        }
        button {
            padding: 0;
        }
        &-stock {
            color: $color__gray;
            font-size: $text__body-sm;
            font-weight: bold;
            line-height: $line-height-lg;
        }

        &-warning {
            color: $color__red;
            font-size: $text__body-sm;
            font-weight: normal;
            line-height: normal;
        }
    }

    &__warnings {
        &--desktop {
            display: none;
            @include breakpoint(lg) {
                display: block;
            }
        }
        &--mobile {
            margin-top: $space-xs;
            button {
                padding: 0;
            }
            @include breakpoint(lg) {
                display: none;
            }
        }
    }

    .add-to-cart__button {
        width: 90px;
    }
}