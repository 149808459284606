ion-modal.quickview-modal {
    // Desktop
    &::part(content) {
        max-width: 800px !important;
        width: 100% !important;
    }
}

ion-modal.auto-height {
    --height: auto;
    .modal-wrapper {
        .ion-page {
            position: relative;
            contain: content;
            max-height: 90vh;
            min-height: 400px;

            overflow-y: scroll;
            .modal-content {
                overflow: auto;
            }
        }
    }
}

.quickview {
    &__price {
        font-weight: bold;
    }

    &__close {
        z-index: 100;
        width: 100%;
        text-align: right;
        display: flex;
        justify-content: flex-end;
        padding-right: 16px;
        padding-top: 16px;
        &-icon {
            box-sizing: border-box;
            height: 18.5px;
            width: 18.5px;
            cursor: pointer;
            svg {
                width: calc($space-xxxs * 5);
                height: calc($space-xxs * 3);
            }
        }
    }

    &__modal {
        display: flex;
        z-index: 100;
        padding: 13px 32px 32px 32px;

        &-details {
            width: 350px;
            button{
                max-width: 100%;
                label{
                    color: $color__primary-dark;
                }
                
            }

            .pdp-fulfillment__option {
                margin-bottom: $space-standard;
            }
        }

        &-images {
            flex-shrink: 0;
            max-width: 412px;
            padding-right: 32px;
            box-sizing: content-box;
        }
    }

    &__details {
        &-price {
            color: #000000;
            font-size: 20px;
            font-weight: 900;
            letter-spacing: 0.22px;
            line-height: 27px;
        }

        &-title {
            color: #000000;
            font-size: 16px;
            font-weight: 500;
            letter-spacing: 0.53px;
            line-height: 22px;
        }

        &-ratingcount {
            color: #636363;
            font-size: 12px;
            font-weight: 300;
            letter-spacing: 0;
            line-height: 15px;
            margin-left: 5px;
        }

        &-rating {
            display: flex;
            align-items: center;
            margin: $space-standard 0px;
        }

        &-divider {
            box-sizing: border-box;
            height: 1px;
            width: 272px;
            border: 1px solid #979797;
            opacity: 0.2;
            margin-top: 17px;
            margin-bottom: 23px;
        }

        &-inventory {
            display: flex;
            flex-direction: column;
            margin-bottom: 27.5px;
        }

        &-options {
            margin-bottom: $space-standard;
            width: 302px;
        }
    }

    &__options {
        &-container {
            flex-direction: column;
        }
        &-dropdown {
            width: 100%;
            padding-right: $space-standard;
            padding-left: $space-standard;
        }

        &-title {
            max-width: 100% !important;
            margin-bottom: 8px;
        }
        &-colortitle {
            margin-bottom: $space-standard;
        }
    }

    & .modal-wrapper {
        width: 780px;
        overflow-y: none;
    }
}
.btn__view-full-details{
    margin-top: $space-standard;
    .button-text {
        cursor: pointer;
    }
}

.quickview-bubbles-animation {
    height: 500px;
}
