.pop-horizontal-slider {
  width: 100%;
  height: 280px;
  overflow: hidden;
  overflow-x: scroll;
  -ms-overflow-style: none;
  &::-webkit-scrollbar {
    display: none;
  }
  ul {
    width: 1300px;
    padding: 0px;
    margin: 0px;
    li {
      list-style: none;
      width: 240px;
      margin-right: 15px;
      float: left;
    }
  }
}

/* // Small devices (landscape phones, 576px and up) */
@media (max-width: 576px) {
  .pop-mobile-slider-container .container {
    padding-right: 0px !important;
    padding-left: 0px !important;
    margin-right: auto;
    margin-left: auto;
  }
  .pop-horizontal-slider ul li:first-child {
    margin-left: 15px;
  }
}
