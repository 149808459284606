.widget-bopis {
    margin: 0px;
    border-radius: $radius-sm;
    overflow: hidden;
    display: flex;
    width: 100%;
    align-items: stretch;

    @include breakpoint-max(xs) {
        flex-direction: column;
    }

  &__status{
    display: flex;
    align-items: center;
    gap: $space-xs;
    flex: 1 0 0;
    padding: $space-standard;
    
    @include breakpoint-max(sm) {
        width: 60%;
    }

    @include breakpoint-max(xs) {
        width: 100%;
    }

    &__bg-icon1 {
        background-image: url('../../../src/svg-icons/fab-five-1.svg');
        background-repeat: no-repeat;
        width: 174.613px;
        height: 116.982px;
        transform: rotate(0deg);
        position: absolute;
        left: 0.00px;
        bottom: -38px;
        z-index: 10;

        &-remove{
            @include breakpoint-max(xs) {
                display: none;
            }
        }

        }

    &__location_icon {
        display: flex;
        width: $space-xl;
        height: $space-xl;
        min-width: $space-xl;
        justify-content: center;
        background-color: $color__primary;
        align-items: center;
        border-radius: $radius-round;
    }

    &__tile-store {
        display: flex;
        width: 75%;
        flex-direction: column;
        align-items: flex-start;
        flex: 1 0 0;
        
        span#store-title {
            font-size: $text__body-base;
            color: $color__black;
            font-weight: 400;
            letter-spacing: $letter__spacing-none;
            line-height: $line-height-base; /* 100% */
        }

        span#store-location {
            height: $space-standard;
            align-self: stretch;
            display: flex;
            justify-content: flex-start;
            overflow: hidden;
            color: $color__black-light;
            font-size: $text__body-base;
            font-weight: bold;
            letter-spacing: $letter__spacing-none;
            line-height: $line-height-base; /* 100% */
            margin: $space-xxxs 0px $space-xxxs;
            max-width: 100%;
        }
        
        span#store-location > span {
            display: block;
            text-overflow: ellipsis;
            white-space: nowrap;
            overflow: hidden;
        }
  
        span#store-time {
            color: $color__gray;
            font-size: $text__body-xs;
            font-style: normal;
            font-weight: 400;
            line-height: $line-height-xs; /* 100% */
        }
    } 
    
  }

  &__tile{
    display: flex;
    width: 100%;
    padding: 0px $space-md;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: $space-xxxs;
    align-self: stretch;
    background: linear-gradient(90deg, #E5D4ED 0%, #E5D4ED 100%);

    @include breakpoint-max(xs) {
        padding: $space-standard;
    }

    h1 {
        color: $color__black;
        text-align: center;
        font-style: normal;
        text-decoration-line: underline;
        font-size: $text__body-base;
        font-weight: bold;
        letter-spacing: $letter__spacing-none;
        line-height: $line-height-lg; /* 125% */
        text-transform: uppercase;
        white-space: nowrap;
    }
    }

  &__progress{
    display: flex;
    padding: $space-xxxs $space-md;
    flex-direction: column;
    align-items: center;
    gap: $space-xxxs;
    flex: 1 0 0;

    @include breakpoint-max(sm) {
        width: 40%;
    }

    @include breakpoint-max(xs) {
        width: 100%;
        padding: 0px $space-standard $space-standard $space-standard;
    }

    &__bg-icon2 {
        background-image: url('../../../src/svg-icons/fab-five-2.svg');
        background-repeat: no-repeat;
        width: 141.734px;
        height: 94.955px;
        transform: rotate(0deg);
        position: absolute;
        right: -$space-md;
        top: 0.00px;
        z-index: 1;
    }
  }

}
