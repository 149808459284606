.header {
  box-shadow: 0px 2px 16px 0px rgba(50, 50, 50, 0.5);
  ion-toolbar {
    @include page-container(lg);
  }

  ion-menu-button {
    font-size: 0px;
    line-height: normal;
    text-transform: uppercase;
    font-weight: bold;
    width: fit-content;
    border-radius: 0;
    --border-radius: 0px;
    position: relative;
    z-index: 1;

    @include breakpoint(md) {
      font-size: $text__body-sm;

      svg {
        margin-right: $space-xs;
      }
    }
  }

  ion-title {
    text-align: center;
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    ion-buttons {
      justify-content: center;
    }
    svg {
      height: $space-md;

      @include breakpoint(md) {
        height: $space-lg;
      }
    }
  }

  &__cart {
    &::part(native) {
      overflow: visible;
    }

    &-counter {
      position: absolute;
      background: $color__primary;
      top: -2px;
      right: calc(-1 * $space-xs);
      height: $space-standard;
      width: calc(($space-standard + $space-md) / 2);
      text-align: center;
      vertical-align: middle;
      border-radius: $space-xs;
      padding-top: $space-xxxxs;
      text-align: center;
      color: $color__white;
      font-weight: bold;
      font-size: $text__body-xxs;
    }
  }
}
