.news-gallery {
  &__grid {
    display: inline-grid;
    grid-template-columns: repeat(2, 1fr);
    width: 100%;
    padding: 0 $space-standard;
    @include breakpoint(md) {
      grid-template-columns: repeat(3, 1fr);
    }
    @include breakpoint(lg) {
      padding: 0;
    }
    grid-column-gap: 2%;

    &-item {
      margin-bottom: $space-standard;
      &-image {
        cursor: pointer;
        position: relative;
        &-main {
          height: 110px;
          border-radius: $radius-sm;
          width: 100%;
          object-fit: cover;
          &-video {
            opacity: 0.5;
          }
          @include breakpoint(sm) {
            height: 230px;
          }
        }
        &-play{
          position: absolute;
          margin: auto;
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;
          width: $space-xl;
          height: $space-xl;
        }
      }
      &-image {
        grid-template-columns: 4fr 0.5fr;
        grid-gap: $space-xxs;
      }
      &-description {
        display: flex;
        margin-top: $space-xxxs;
        margin-bottom: $space-md;
      }
      &-title {
        width : 100%;
        display: grid;
        cursor: default;
        margin-right: $space-xxs;
      }
      &-download {
        width: $space-xl;
        display: none;
        @include breakpoint(xs) {
          display: block;
          text-align: center;
        }
      }
    }
  }

  &__modal {
    &::part(content) { 
      border-radius: $radius-sm;
      @include breakpoint(sm) {
        width: 600px;
        height: 500px;
      }
    }

    .modal-shadow {
      display: none;
    }

    &-image {
      width: 100%;
      height: 100%;
      max-height: 80%;
      overflow: hidden;
      img {
        width: 100%;
        object-fit: contain;
      }
    }

    &-video {
      position: relative;
      width: 100%;
      // 16:9
      padding-bottom: 56.25%;

      iframe {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        border: 0;
      }
    }

    &-button {
      height: 40%;
      display: flex;
      align-items: center;
      justify-content: center;
      padding: $space-md;

      .btn__primary{
        padding: $space-sm $space-xxxxl;
      }

      a {
        &:hover {
          color: $color__white;
        }
      }
    }
  }
}