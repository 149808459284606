.article {
  &__wrapper {
    padding-top: 0;
    @include page-container(lg);
  }

  &__image-hero {
    @include breakpoint-max(lg) {
      margin-right: -$space-page-gutters;
      margin-left: -$space-page-gutters;
    }
  }

  &__container {
    margin: $space-md 0;
    @include breakpoint(md) {
      margin: $space-xxl 0;
    }
  }

  &__title {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;

    h1 {
      font-size: $text__body-base;

      @include breakpoint(md) {
        font-size: $text__body-md;
        font-weight: bold;
      }
    }
    margin-bottom: $space-md;
  }

  &__content {
    display: flex;
    flex-direction: column;
    @include breakpoint(md) {
      flex-direction: row;
    }

    &-content {
      flex-grow: 1;
      margin-bottom: $space-standard;
      @include breakpoint(md) {
        margin-right: $space-standard;
        margin-bottom: 0;
      }

      .rte {
        padding: 0;
      }

      &-block {
        margin-bottom: $space-md;
        max-width: 100%;
      @include breakpoint(xs){
        margin-bottom: $space-xl;
      }
        
      }
    }

    &-products {
      flex-shrink: 0;
      @include breakpoint(md) {
        width: 380px;
      }

      &-total {
        font-size: $text__body-sm;
        font-weight: bold;
        margin-bottom: $space-standard;

        span {
          font-weight: bold;
          font-size: $text__body-lg;
        }

        @include breakpoint (md) {
          font-size: $text__body-md;
          span {
            font-size: $text__body-md;
          }
        }
      }

      button {
        max-width: 100%;
        width: 100%;
      }

      &-card {
        margin-bottom: $space-standard;
      }
    }
  }
}
