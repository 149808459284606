.auth-page-foo {
  border-top: 1px solid #ccc;
  position: relative;
  padding: 10px;
  bottom: 0;
  width: 100%;

  a {
    display: inline-block;
    margin-right: 15px;
    font-size: 15px;
  }

  p {
    font-size: 15px;
  }
}

.auth-page-footer-reg {
  position: relative !important;
}

.auth-page-foo {
  border-top: 1px solid #ccc;
  position: relative;
  padding: 10px;
  bottom: 0;
  width: 100%;

  a {
    display: inline-block;
    margin-right: 15px;
    font-size: 15px;
  }

  p {
    font-size: 15px;
  }
}

.auth-page {
  .close {
    position: absolute;
    right: 0px;
    top: 0px;
  }
  &__container {
    padding-left: $space-sm;
    padding-right: $space-sm;
  }
}

.auth-wrap {
  padding: 0;

  h1 {
    font-weight: bold;
    color: #000000;
    font-size: 30px;
    margin-top: 20px;
    text-transform: lowercase;
  }

  h2 {
    font-weight: bold;
    color: #000000;
    font-size: 24px;
    margin-top: 40px;
    text-transform: lowercase;
  }
}

.auth-wrap #payment-form,
.auth-wrap iframe,
.auth-wrap body,
.auth-wrap #payment-form form,
.auth-wrap #payment-form form .form-wrap {
  overflow: hidden;
}

.forgot-password h1 {
  margin-bottom: 80px;
}

.sign-message {
  font-size: 14px;
  margin-top: 20px;
}

.link-sent {
  font-size: 14px !important;
  text-align: center;
  font-weight: bold;
  padding: 13px 0;
  color: #000000;
  .svg {
    width: $space-sm;
    height: $space-xs;
    display: inline-block;
    margin-right: 5px;
  }
}

.btn-social-wrap {
  display: none;
  padding: 20px 0;
}

.btn-social {
  width: 100%;
  border-radius: 6px;
  padding: 20px 10px;
  text-align: c;
  border: 1px solid #ccc;
  background-color: #fff;
}

.form-wrap h1,
.form-wrap h2 {
  font-size: 15px;
  font-weight: 500;
}

.form-wrap h1 span,
.form-wrap h2 span {
  color: #f40000;
}

.form-wrap {
  h1 {
    label {
      font-size: 14px;
      color: #636363;
      float: right;
    }
  }

  .input-wrap {
    padding-bottom: 15px;

    label {
      &.label-form {
        font-size: 15px;
        color: #000;
        display: block;
        margin-bottom: 5px;

        span {
          color: #f40000;
        }
      }

      &.label-form-error {
        display: none;
      }
    }

    p {
      &.edit-card-data {
        font-size: 18px;
        padding: 5px 0 0 10px;
      }
    }

    &.input-wrap-error {
      label {
        &.label-form-error {
          display: block;
          font-weight: bold;
        }
      }
    }
  }

  .label-error-payment-info {
    color: #f40000;
  }

  .input-wrap-error {
    color: #f40000;

    label {
      &.label-form-error {
        font-size: 15px;
        color: #f40000;
        display: block;
        margin-bottom: 5px;
        margin-top: 10px;
      }
    }
  }
}

.form-wrap .input-wrap input[type="text"],
.form-wrap .input-wrap input[type="number"],
.form-wrap .input-wrap input[type="password"],
.form-wrap .input-wrap input[type="email"],
.form-wrap .input-wrap input[type="tel"],
.form-wrap .input-wrap textarea,
select {
  border: 1px solid #ccc;
  background-color: #fff;
  width: 100%;
  border-radius: 6px;
  padding: 11px 10px !important;
  box-shadow: -1px 4px 10px 6px rgba(0, 0, 0, 0.06);
}

.regular-modal_share .input-wrap input[type="text"] {
  padding: 11px 34px 11px 10px !important;
}

.form-wrap .input-wrap-error input[type="text"],
.form-wrap .input-wrap-error input[type="number"],
.form-wrap .input-wrap-error input[type="password"],
.form-wrap .input-wrap-error input[type="email"],
.form-wrap .input-wrap-error input[type="tel"],
.form-wrap .input-wrap-error textarea,
.input-wrap-error select {
  border: 1px solid #f40000;
  background-color: #fff;
  width: 100%;
  border-radius: 6px;
  padding: 11px 10px !important;
  box-shadow: -1px 4px 10px 6px rgba(0, 0, 0, 0.06);
}

::-webkit-input-placeholder {
  /* Chrome/Opera/Safari */
  font-size: 14px;
}

::-moz-placeholder {
  /* Firefox 19+ */
  font-size: 14px;
}

:-ms-input-placeholder {
  /* IE 10+ */
  font-size: 14px;
}

:-moz-placeholder {
  /* Firefox 18- */
  font-size: 14px;
}

.password-checklist {
  font-size: 12px;
  padding: 0 0 0 20px;

  li {
    cursor: default;
    list-style: none;
    position: relative;
    margin-bottom: 10px;
    color: #000;

    &.checkmark {
      &::before {
        content: "";
        width: $space-standard;
        height: $space-standard;
        -webkit-border-radius: $space-standard;
        -moz-border-radius: $space-standard;
        border-radius: $space-standard;
        border: 1px solid $color__gray-light;
        position: absolute;
        left: -20px;
        top: 1px;
      }

      &.checkmark-done {
        color: #008a25;

        &::before {
          content: "";
          width: $space-standard;
          height: $space-standard;
          -webkit-border-radius: $space-standard;
          -moz-border-radius: $space-standard;
          border-radius: $space-standard;
          border: 1px solid $color__gray-light;
          position: absolute;
          left: -20px;
          top: 1px;
          background-color: #008a25;
          background-image: url(/images/icons/check-registration.svg);
          background-repeat: no-repeat;
          background-position: center;
        }
      }

      &.checkmark-done-error {
        color: red;

        &::before {
          content: "";
          width: $space-standard;
          height: $space-standard;
          -webkit-border-radius: $space-standard;
          -moz-border-radius: $space-standard;
          border-radius: $space-standard;
          border: 1px solid red;
          position: absolute;
          left: -20px;
          top: 1px;
          background-color: red;
          background-image: url(/images/icons/check-registration.svg);
          background-repeat: no-repeat;
          background-position: center;
        }
      }
    }
  }
}

.forgot-password {
  flex-grow: 1;
  text-align: right;
}

.email-checkmark {
  display: flex;
  label {
    width: fit-content;
    font-size: $text__body-sm !important;
    color: $color__black;
    display: inline-block;
    vertical-align: middle;
    line-height: $line-height-md;
    font-weight: normal;
    cursor: pointer;
    padding-left: $space-sm;

    a {
      font-weight: bold;
      float: right;
    }

    button {
      color: $color__primary;
      font-weight: bold;
      float: right;
    }
  }
}

.regular-checkbox {
  flex-shrink: 0;
  -webkit-appearance: none;
  background-color: #FFF;
  border: 1px solid $color__gray-light;
  padding: 9px;
  border-radius: 0px;
  display: inline-block;
  position: relative;
  width: 18px;
  height: 18px;
  vertical-align: middle;

  &:checked {
    background-color: #9230a8;
    border: 1px solid #9230a8;
    background-image: url(/images/icons/check-registration.svg);
    background-repeat: no-repeat;
    background-position: center;

    &:after {
      content: "";
      font-size: 14px;
      position: absolute;
      top: 0px;
      left: 3px;
      background-image: url(/images/icons/check-registration.svg);
      background-repeat: no-repeat;
      background-position: center;
    }
  }
}

.regular-checkbox:active,
.regular-checkbox:checked:active {}

.locked-modal {
  h1 {
    margin-top: 50px;
  }

  label {
    margin-bottom: 50px;
  }
}

.label-terms {
  font-size: 13px;
  line-height: 14px;
  font-weight: 500;
}

.switch-modal {
  font-size: 15px;
  color: #636363;
  margin: 0 0 25px;

  a {
    font-size: 15px;
    font-weight: bold;
  }

  button {
    font-size: 15px;
    font-weight: bold;
    line-height: 20px;
    overflow: visible;
  }
}

.show-password-icon {
  float: right;
  right: 10px;
  top: -30px;
  position: relative;
  z-index: 2;
  background-repeat: no-repeat;
  background-position: center;
  width: 19px;
  height: 14px;
  display: block;
}




.icon-copy {
  float: right;
  right: 10px;
  top: -33px;
  position: relative;
  z-index: 2;
  width: 20px;
  height: 20px;
  display: block;
  animation: copy .1s alternate ease-in;

  svg {
    width: 20px;
    height: 20px;
  }
}

@keyframes copy {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}


.icon-copy-check {
  float: right;
  right: 10px;
  top: -33px;
  position: relative;
  z-index: 2;
  width: 20px;
  height: 20px;
  display: block;
  animation: crescendo .2s alternate ease-out;

  svg {
    width: 20px;
    height: 20px;
    color: $color__primary;
  }
}

@keyframes crescendo {
  0% {
    transform: scale(.5);
  }

  100% {
    transform: scale(.8);
  }
}

.icon-input-check {
  float: right;
  right: 10px;
  top: -33px;
  position: relative;
  z-index: 2;
  width: 20px;
  height: 20px;
  display: block;
  svg {
    width: calc($space-xxxs * 5);
    height: calc($space-xxxs * 5);
  }
}

.regular-modal-flag .auth-wrap {
  padding: 20px 0;
}

.regular-modal-flag .modal-wrapper {
  height: 550px;
  width: 400px;
}

.regular-modal-flag .ratio-select label {
  margin-bottom: 10px;
}

.single-review {
  padding-bottom: 30px;
}

// textarea#w3mission {
//     min-height: 200px;
// }


/* Landscape */
@media only screen and (min-device-width: 375px) and (max-device-width: 812px) and (min-device-width: 813px) // and (-webkit-min-device-pixel-ratio: 3)

and (orientation: landscape) {
  .auth-page-foo {
    position: relative;

    a {
      display: block;
      text-align: center;
      margin-bottom: 10px;
      margin-right: 15px;
      font-size: 15px;
    }

    p {
      font-size: 15px;
      text-align: center !important;
      line-height: 20px;
    }
  }

  .auth-page {
    .close {
      position: absolute;
      right: $space-standard;
      top: -15px;
    }
  }


}

.auth-close {
  position: absolute;
  right: $space-standard;
  top: $space-lg;
}

.select-flex {
  display: flex;
  width: 80%;
}

.select-month {
  margin-right: 10px;
}

select:invalid {
  color: #757575 !important
}

.select-day {
  width: 75px;
}

.gigya__checkbox {
  &-field {
    margin-bottom: 20px;
    font-weight: 900;
  }

  &-container {
    margin-top: 10px;
  }

  &-title {
    color: $color__gray-order;
    font-size: 14px;
  }
}

#aboutme-save {
  margin-bottom: 58px;
  margin-top: -20px;
}

#register-needhelp-container {
  h6 {
    align-self: flex-start;
  }

  p {
    font-size: $text__body-sm;
  }
}
.signin-button {
  font-size: 15px;
  padding-top: $space-xxxs;
  margin: 15px 0 $space-md;
  text-align: center;
  label {
      color: $color__gray-order;
  }
  button {
    font-size: 15px;
    font-weight: 700;
    color: $color__primary;
    &:focus {
      outline-offset: -1.5px;
    }
  }
} 
.user-registration {
  text-size-adjust: none;
  .input-wrapper {
    padding-bottom: 15px;
    input {
      border: 1px solid $color__input-border;
      &:focus {
        outline: $outline__normal;
        outline-offset: 2px;
      }
    }
    .error-label {
      padding-bottom: 20px;
      margin-top: 10px;
      text-align: center;
      display: block;
      font-size: $text__body-base;
      color: $color__red;
    }
  }
  .terms-conditions {
    padding-bottom: $space-lg;
    label {
      font-size: 13px;
      line-height: 14px;
    }
    a {
      display: inline-block;
      &:focus {
        outline: $outline__normal;
        outline-offset: 0px;
      }
    }
  }
  .active-slide {
    display: block;
  }
  .inactive-slide {
    display: none;
  }
  h1 {
    padding-top: 20px;
    font-size: $text__header-xxl;
    font-weight: bold;
    margin-bottom: $space-lg;
  }
  &__phone {
    input {
      margin-top: $space-sm;
    }
    .registration-phone__info {
      margin-bottom: $space-xxxl;
      font-size: $text__body-xs !important;
      line-height: $line-height-base;
    }
  }
  &__info {
    padding-bottom: $space-md;
  }
  &__verification {
    a {
      cursor: pointer;
      color: $color__primary;
      &:focus {
        outline: $outline__normal;
        outline-offset: 2px;
      }
    }
    input {
      margin-top: 10px;
    }
    .having-trouble {
      text-align: right;
    } 
    .new-code {
      margin: 15px 0;
    }
    .change-number {
      text-align: center;
      font-size: 15px;
      font-weight: 700;
    }
  } 
  &__communication {
    .registration-info {
      margin-bottom: $space-standard;
    }
  }
  &__email-opt, &__phone-opt {
    margin: $space-lg 0;
    label {
      font-size: $text__body-sm;
      line-height: $line-height-lg;
    }
  }
}
.user-registration__form, 
.user-registration__phone, 
.user-registration__communication,
.user-registration__verification {
  padding: 0 $space-xxxs;
}