.search-page {
    min-height: calc(100vh - 334px) !important;
    padding-top: $space-xl;
    display: flex;
    flex-direction: column;
    align-items: center;

    &__container {
        @include page-container(lg);
    }

    &__header{
        width: 100%;
    }

    &__selectors {
        display: flex;
        justify-content: space-between;
        width: 100%;
        margin-bottom: $space-md;
        &-grid {
            @include breakpoint(xs) {
                display: none;
            }
        }
    }

    &__count {
        &-mobile {
            margin-bottom: $space-md;
            @include breakpoint(xs) {
                display: none;
            }
        }
        &-desktop {
            display: none;
            @include breakpoint(xs) {
                display: block;
            }
        }
    }

    &__empty {
        padding: $space-xl 0;
    }

    .carousel__parent {
        @include breakpoint(lg) {
            margin: 0;
            margin-bottom: $space-md;
        }
    }

    .swiper {
        button {
            padding: 0;
        }
        &-slide {
            width: auto;
        }
    }

    &__empty-search-text{
        font-size: $text__body-base;
        font-weight: bold;
        text-align: center;
    }

    &__view-more {
        display: flex;
        justify-content: center;
        width: 100%;
        margin-bottom: $space-md;

        button {
            max-width: 320px;
        }
    }
}