.fw {
  
}

.general-image-hero {
  -webkit-border-top-left-radius: 6px;
  -webkit-border-top-right-radius: 6px;
  -moz-border-radius-topleft: 6px;
  -moz-border-radius-topright: 6px;
  border-top-left-radius: 6px;
  border-top-right-radius: 6px;
  overflow: hidden;
  width: 100%;
  position: relative;

  @media (max-width: 576px) {
      margin-top: 0px;
      -webkit-border-top-left-radius: 0px;
      -webkit-border-top-right-radius: 0px;
      -moz-border-radius-topleft: 0px;
      -moz-border-radius-topright: 0px;
      border-top-left-radius: 0px;
      border-top-right-radius: 0px;
  }

  &__wrapper {
    width: 100%;
    &-margin {
      margin-top: 40px;
    }

    @media (max-width: 576px) {
      margin-top: 0px;
    }
  }

  &--mobile {
    @include breakpoint(sm) {
         display: none;
    }
  }

  &--desktop {
    display: none;
    @include breakpoint(sm) {
         display: block;
    } 
  }
}

