.my-information {
  display: flex;
  flex-direction: column;

  &__container {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;

    &-content{
      flex: 1;   
      p {
        &:first-of-type {
          color: $color__gray-order;
          font-size: $text__body-sm;
        }
        &:last-of-type {
          font-size: $text__body-base;
          margin-bottom: $space-standard;
          margin-top: $space-xs;
          text-indent: $space-sm;
          color: $color__black;
        }
      }
    }
    a {
      font-size: $text__body-sm;
      font-weight: bold;
    }
  }
  button {
    max-width: 100%;
    padding-top: $space-standard;
    padding-bottom: $space-standard;
  }
}