.reward_point_details {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    
    p {
        font-size: $text__body-sm;
        line-height: $line-height-xxl;
        color: $color__gray-order;
        margin-top: $space-standard;

        @include breakpoint-max(xs) {
            text-align: center;
            width: 336px;
            line-height: $line-height-xxl;
        }
    }
    &-pointer {
        display: flex;
        flex-direction: row;
        justify-content: center;
        gap: $space-md;

        @include breakpoint-max(xs) {
            flex-direction: column;
            align-items: center;
        }

        &__container {
            width: 240px;
            height: 240px;
            border-radius: $radius-round;
            box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.5);
            position: relative;
            display: flex;
            justify-content: center;
            align-items: center;

            @include breakpoint-max(xs) {
                margin-top: 70px;
            }

            &--inner-circle {
                position: relative;
                width: 180px;
                height: 180px;
                border-radius: $radius-round;
                background: $color__white;
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;

                span {
                    font-size: $text__header-xxl + $text__header-xxl + $text__body-base;
                    font-weight: bold;
                    line-height: $line-height-xxxl + $line-height-xxxl + $line-height-xxl;
                }
                p {
                    font-size: $text__body-sm;
                    line-height: $line-height-sm;
                    color: $color__gray-order;
                }
            }
        }

        &__details {
            display: flex;
            flex-direction: column;
            width: 226px;
            margin: $space-xs 0;

            @include breakpoint-max(xs) {
                text-align: center;
                align-items: center;
                width: 100%;
            }

            h1 {
                font-size: $text__body-lg + $text__body-lg;
                font-weight: 400;
                line-height: $line-height-lg + $line-height-lg;
                text-align: left;
                color: $color__black-light;
                margin-bottom: $space-standard;

                @include breakpoint-max(xs) {
                    text-align: center;
                    position: absolute;
                    top: $space-xxl;
                    left: 30%;
                    margin-bottom: $space-md;
                }
            }
            h2 {
                font-size: $text__body-lg + $text__body-xxs;
                font-weight: bold;
                line-height: $line-height-sm + $line-height-base;
                text-align: left;
                color: $color__black;
                margin-bottom: $space-standard;

                @include breakpoint-max(xs) {
                    text-align: center;
                }
            }
            &-count {
                display: flex;
                align-items: center;
                color: $color__primary;
                font-size: $text__body-xxl;
                line-height: $line-height-xl;
                letter-spacing: -1px;
                margin-bottom: $space-standard;

                &-value {
                    display: flex;
                    align-items: center;
                    text-align: center;

                    span:first-child {
                        font-size: $text__header-xxl;
                        line-height: $line-height-xxxl;
                        font-weight: bold;
                        color: $color__primary;
                    }
                    span:nth-child(2) {
                        font-size: $text__header-xxl + $text__header-xxl;
                        line-height: $line-height-xl + $line-height-xl;
                        font-weight: bold;
                        color: $color__primary;
                        position: relative;
                    }
                }
                b {
                    color: $color__primary;
                }
            }
            &-button {
                font-size: $text__body-xs;
                line-height: $line-height-base;
                width: 150px;
                padding: $space-xs $space-sm;
                color: $color__primary;
                text-transform: uppercase;
            }
        }

        &__details-error {
            align-items: center;
            justify-content: center;
        }
        &__error-message {
            text-align: center;
            width: 295px;
        }
    }
    &__info {
        @include breakpoint-max(xs) {
            width: 60%;
        }
    }
}