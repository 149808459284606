

.pop-select .item{
    border:1px solid rgb(187, 187, 187);
    box-shadow: 0px 0px 20px 0px rgba(0,0,0,0.1);
    border: 1px solid #F0F0F0;
    -webkit-border-radius: 3px;
    -moz-border-radius: 3px;
    border-radius: 3px;
}
/* 
Working on this styles */