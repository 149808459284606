.ucg__content-block {
  margin: $space-md 0;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  &__info {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-transform: lowercase;

    &-title {
      font-size: $text__body-lg;
      font-weight: 600;
      margin-bottom: $space-sm;
    }

    &-sub {
      font-size: $text__body-sm;
      padding: 0 $space-standard;
      overflow-wrap: anywhere;
      font-weight: 400;
      color: $color__gray;

      @include breakpoint-max(xs) {
        padding: 0;
      }
    }

    @include breakpoint-max(xs) {
      align-items: flex-start;
    }
  }

  &__sprinklr {
    width: 100%;
    overflow: hidden;
    max-width: 1140px;
    margin: 0 auto;
  }

  .swiper-button-prev,
  .swiper-button-next {
    &::after {
      content: ''
    }
  }
}

.swe_embed .swe_card.swe_card_vertical.swe_has_video .swe_player.swe_vine_player video {
  @include breakpoint-max(xs) {
    padding-top: $space-xl;
  }
}

.swe_embed .swe_card.swe_card_vertical.swe_has_image .swe_post_image_cover {
  @include breakpoint-max(xs) {
    height: 65vh !important;
  }
}