.rewards {
    max-width: $space-max-width-md;
    padding: $space-standard 0;
    @include page-container(md);
    @include breakpoint(lg) {
        padding: 0;
    }
    .btn__primary {
        margin: 0 auto;
        @include page-container(sm);
        display: block;
        margin-bottom: 146px;
    }
    label {
        display: block;
        font-size: $text__body-lg;
        text-align: center;
        padding-bottom: $space-md;
        font-weight: bold;
    }
    &__headline {
        font-size: $text__header-xxl;
        font-weight: bold;
        line-height: $line-height-xxl;
        text-transform: lowercase;
        overflow-wrap: break-word;
        text-align: center;
        padding-bottom: $space-xs;
    }
    &__heading {
        padding-top: $space-lg;
         h1 {
             text-align: center;
            font-size: $text__header-xxl;
            font-weight: bold;
            margin-bottom: $space-lg;
            line-height: $line-height-xxxl;
            span {
                font-size: $text__body-xl;
                font-weight: normal;
              }
        }
    }
    &__subhead {
        font-size: $text__body-xl;
        font-weight: normal;
        margin-bottom: $space-md;
        display: block;
        text-align: center;
    }
    &__instant-rewards {
        label {
            color: $color__primary;
            font-size: $text__body-md;
        }
        &-list {
            display: flex;
            align-items: center;
            margin-bottom: $space-xs;
            p {
                display: inline;
                align-items: center;
                margin-bottom: $space-md;
                margin-left: $space-sm;
                cursor: default;
            }
            p:last-of-type {
                margin-bottom: 0;
            }
            div {
                display: inline-flex;
                justify-content: center;
                align-items: center;
                flex: 0 0 auto;
                height: $space-lg !important;
                width: $space-lg !important;
                background-color: $color__primary;
                border-radius: $radius-round;
                font-weight: bold;
                color: $color__white;
                font-size: $text__body-base;
                // margin-bottom: $space-xs;
            }
        }
        .rewards__barcode {
            width: 300px;
            display: flex;
            justify-content: center;
            margin: 0 auto;
        } 
    }
    ul {
        display: flex;
        flex-direction: column;
        flex-wrap: wrap;
        text-align: left;
        list-style: none;
        line-height: $text__body-md;
        font-size: $text__body-sm;
        position: relative;
        div {
            margin-bottom: $space-xs;
            li {
                display: inline;
                align-items: center;
                margin-bottom: $space-md;
                margin-left: $space-sm;
                cursor: default;
            }
            div {
                display: inline-flex;
                justify-content: center;
                align-items: center;
                flex: 0 0 auto;
                height: $space-lg !important;
                width: $space-lg !important;
                background-color: $color__primary;
                border-radius: $radius-round;
                font-weight: bold;
                color: $color__white;
                font-size: $text__body-base;
            }
        }

        } 
    &__register {
        p:first-of-type {
            margin-bottom: $space-lg;
        }
        p {
            text-align: center;
            line-height: $line-height-xl;
            margin-bottom: $space-lg;
        }
    }
    &__icons {
        display: flex;
        justify-content: center;
        @include breakpoint(sm) {
            margin-bottom: $space-md;
        }
        label {
            font-size: $text__body-base;
            padding-top: $space-sm;
            color: $color__black;
            font-weight: normal;
        }
        div {
            height: 100px;
            padding: 0 $space-xxxs;
            @include breakpoint(xs) {
                height: 125px;
            }
            @include breakpoint(sm) {
                padding: 0 $space-standard;
                height: 100%;
            }
        }
    }
    &__box {
        margin: 0 auto;
        display: flex;
        flex-direction: column;
        justify-content: center;
        padding-bottom: $space-lg;
        // border-bottom: $border__gray-dark;
        div {
            img {
                object-fit: cover;
            }
        }
    }
    &__qrcode {
        float: right;
        margin-bottom: $space-md;
        border: 1px solid $color__primary-light;
        cursor: pointer;
        align-items: center;
        p {
            max-width: $space-lg;
        }
    }
    &__instructions {
        padding: $space-lg;
        margin: 0 $space-sm;
        label {
            color: $color__primary;
            margin-bottom: $space-standard;
        }
        &-list {
            display: flex;
            align-items: center;
            margin-bottom: $space-sm;
            div {
                display: inline-flex;
                justify-content: center;
                align-items: center;
                flex: 0 0 auto;
                height: $space-lg !important;
                width: $space-lg !important;
                background-color: $color__primary;
                border-radius: $radius-round;
                font-weight: bold;
                color: $color__white;
                font-size: $text__body-base;
            }
            p {
                display: inline;
                align-items: center;
                margin-left: $space-sm;
                cursor: default;
            }
        }
    }
    &__redeemed {
        &-subtitle {
            color: #1f1f1f;
            font-size: $text__body-base;
            font-weight: bold;
            letter-spacing: 0;
            line-height: $line-height-xl;
            text-align: center;
            margin-bottom: $space-md;
        }

        &-button {
            @include breakpoint(lg) {
                width: 70%;
                margin: 0 auto; 
            }
        }

        &-img {
            margin-bottom: $space-xl;
        }

        &-section {
            display: grid;
            grid-template-columns: repeat(2, 1fr); 
            row-gap: $space-md;
            column-gap: $space-sm;
            padding-bottom: $space-lg;
            max-width: $space-max-width-sm;
            margin: 0 auto;
            @include breakpoint(md) {
                max-width: $space-max-width-md;
                grid-template-columns: repeat(4, 1fr); 
            }
            div {
                margin: 0 auto;
            }
        }
    }
}
