.news {
  display: flex;
  flex-direction: column;
  align-items: center;
  &__tabs {
    margin-bottom: $space-md;
    width: 100%;
    border-bottom: $border__gray-lighter;
    padding: 0 $space-sm;
    display: flex;
    justify-content: space-between;
    @include breakpoint(sm) {
      display: block;
    }
    button {
      padding-bottom: $space-sm;
      font-size: $text__body-base;
      color: $color__gray-light;
      margin-right: $space-xxs;
      @include breakpoint(sm) {
        margin-right: $space-xxl;
      }

      &:last-of-type {
        margin-right: 0;
      }
      &.selected {
        border-bottom: 3px solid $color__black;
        color: $color__gray-dark;
      }
    }
  }

  &__body {
    @include page-container(lg);

    .rte {
      margin-bottom: $space-md;

      @include breakpoint(xs) {
        margin-bottom: 40px;
      }
    }
  }
}