.cart-page {
  &__wrapper {
    padding: $space-standard 0;
    @include page-container(lg);
  }

  &__container {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;

    >div {
      display: flex;
      margin: 20px 0;
      width: 100%;

      @include breakpoint-max(md) {
        flex-direction: column;
      }
      @include breakpoint(md) {
        align-items: flex-start;
      }
    }

    @include breakpoint(md) {
      flex-direction: row;
    }
  }

  /* Left Panel */
  &__cart {
    display: flex;
    flex-wrap: wrap;
    flex: 100%;
    margin-bottom: $space-standard;

    @include breakpoint(md) {
      flex: 58%;
      max-width: 58%;
      margin-bottom: 0;
      margin-right: $space-standard;
    }
  }

  /* Right Panel */
  &__cart-total {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    flex: 100%;
    padding: 0 $space-standard;

    @include breakpoint(md) {
      flex: 0 0 42%;
      max-width: 42%;
      margin-left: 8%;
    }

    .btn__primary--animated {
      margin: 0 auto;
    }
  }

  &__fulfillment {
    display: flex;
    width: 100%;
    margin-bottom: $space-sm;
    font-weight: 700;
    font-size: $text__body-base;
    line-height: $line-height-xxl;

    @include breakpoint(sm) {
      width: 58%;
    }

    svg {
      margin-right: $space-xxxs;
    }

    p {
      display: flex;
      align-items: center;
      font-size: $text__body-base;
      line-height: $line-height-xxl;
      margin-bottom: 0;
      width: 100%;
    }
  }

  &__location {
    display: flex;
    width: 100%;
    margin-bottom: $space-standard;

    label {
      width: 100%;
      font-size: $text__body-base;
      font-weight: 700;
      text-decoration: underline;
      cursor: pointer;
    }

    span {
      font-size: $text__body-base;
      font-weight: 700;
      color: $color__gray-order;
      padding: 0;
      margin: 0 0 $space-xxxs;
    }

    &-link {
      display: flex;
      flex-direction: column;
      width: 100%;
      padding: $space-sm;
      border: $border__gray;
      border-radius: $radius-xs;
      text-align: left;

      span {
        width: 100%;
        font-size: $text__body-base;
        white-space: nowrap;
        overflow: hidden;
        line-height: $line-height-md;
        @include line-clamp(1);
      }

      >span {
        font-size: $text__body-xs;
        line-height: $line-height-md;
      }
    }
  }

  &__card-product-tiles {
    display: flex;
    width: 100%;
    flex-wrap: wrap;

    >a {
      display: inherit;
      width: inherit;
    }
      &:focus {
        outline: $outline__normal;
      }

      button:focus {
        outline: $outline__normal;
        outline-offset: -1px;
      }
    }

    &__how-it-works {
      text-align: center;
      margin-bottom: 0;
      font-size: $text__body-sm;
      font-weight: 700;
      color: $color__primary;
      line-height: $line-height-xxl;

      @include breakpoint(md) {
        margin-bottom: $space-md;
      }
    }

    &__btn-proceed-checkout {
      @extend .btn__primary;
      text-align: center;
      align-self: center;
      font-size: $text__body-sm;
      max-width: 380px;

      @include breakpoint-max(sm) {
        max-width: 100%;
      }
      
    }

    &__stock-message {
      font-size: $line-height-sm;
      font-weight: 500;
      line-height: $line-height-sm;
      color: $color__gray-order;
      padding: 0;
      margin: 0;
      display: block;
      margin-top: $space-md;
      margin-bottom: $space-xs;
      width: 100%;
      max-width: fit-content;

      @include breakpoint-max(sm) {
        margin-bottom: $space-xxs;
      }
    }

    &__empty-cart {
      display: flex;
      flex-wrap: wrap;
      flex-direction: column;
      width: 100%;
      margin: $space-md auto 0 auto !important;

      @include breakpoint-max(sm) {
        margin-top: 0 !important;
      }

      &__store {
        margin: 0 auto;
        width: 100%;
        max-width: 480px;
      }

      &__contents {
        display: inherit;
        flex-direction: inherit;
        width: 100%;
        align-items: center;
        line-height: 20px;
        font-size: $text__body-base;
        font-weight: 700;

        p {
          text-align: center;
          margin-bottom: $space-standard;

          &:nth-of-type(1) {
            margin-bottom: 0;
          }
        }

        button.cart-page__how-it-works {
          margin-bottom: calc($space-xl - $space-xs);
        }
      }

      @include breakpoint(sm) {
        width: 40%;
      }

      &__alert-icon {
        margin: calc($space-xl - $space-xs) auto $space-md auto;
      }
    }

    &__btn-start-shopping {
      @extend .btn__primary;
      margin-bottom: calc($space-xl + $space-xs);
    }

    &__recently-viewed {
      margin: $space-standard auto $space-md;
      width: 100%;

      @include breakpoint (xs) {
        margin: $space-standard auto $space-xl;
      }
    }
  }