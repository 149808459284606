.page-title {
    background-color: $color__primary;
    text-align: center;
    width: 100%;
    display: block;
    padding-top: $space-sm;
    padding-bottom: $space-standard;
    text-transform: lowercase;
    margin-bottom: $space-standard;

    @include breakpoint(xs) {
        margin-bottom: $space-lg;
    }

    h1 {
        font-size: $text__body-lg;
        line-height: $line-height-xxl;
        font-weight: bold;
        color: $color__white;
        margin: 0;
    }
}