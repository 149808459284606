img[alt] {
  color: transparent;
}


h4.gigya-contact-help-information-format {
  font-size: 17px;
  margin-bottom: 10px;
}

h4.gigya-contact-help-information-format a {
  font-size: 17px;
  margin-top: 10px;
}

// Target IOS
.show-modal {
  background-color: rgba(0, 0, 0, 0.233);
}

a {
  color: $color__primary;
}
a:active {
  outline: none;
}
:active,
:focus {
  outline: none;
  -moz-outline-style: none;
}

a:hover {
  // color:#6F1784;
  color: #540265;
}

.pop_user_forms_onboarding h1 {
  padding-top: 20px;
  font-size: 32px;
  font-weight: 800;
  text-transform: lowercase;
  margin-bottom: $space-lg;
}
.header-md::after {
  left: 0;
  bottom: -5px;
  background-position: left 0 top -2px;
  position: absolute;
  width: 100%;
  height: 5px;
  background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAHBAMAAADzDtBxAAAAD1BMVEUAAAAAAAAAAAAAAAAAAABPDueNAAAABXRSTlMUCS0gBIh/TXEAAAAaSURBVAjXYxCEAgY4UIICBmMogMsgFLtAAQCNSwXZKOdPxgAAAABJRU5ErkJggg==);
  background-repeat: repeat-x;
  content: '';
}
.underline {
  text-decoration: underline;
}
.p14 {
  font-size: $text__body-sm;
  font-weight: bold;
  color: $color__primary;
  line-height: 1.75;
}

.pop-match-toggle {
  margin-right: 5px;
}
.p-med {
  font-size: 18px;
}
.p-med strong {
  font-weight: bold;
}
.collage-total {
  font-size: 16px !important;
}
.m-top-20 {
  margin-top: 20px !important;
}
.m-top-30 {
  margin-top: 30px !important;
}
.m-top-40 {
  margin-top: 40px !important;
}
.m-top-50 {
  margin-top: 50px !important;
}

.m-bottom-20 {
  margin-bottom: 20px !important;
}
.m-bottom-30 {
  margin-bottom: 30px !important;
}
.m-bottom-40 {
  margin-bottom: 40px !important;
}
.m-bottom-50 {
  margin-bottom: 50px !important;
}

@media (max-width: 576px) {
  .m-top-mobile-20 {
    margin-top: 20px !important;
  }
}

.float-right {
  float: right;
}

.primary {
  color: $color__primary;
}

.ion-color-primary {
  color: #ffffff;
}

.pop-mobile-slider-container {
  overflow: hidden;
  overflow-x: scroll;
}

.row.pop-mobile-slider-container {
  height: 30px;
}

.pop_none {
  display: none;
}

.clear {
  clear: both;
}
.clearfix::after {
  display: block;
  clear: both;
  content: '';
}
.ion-button,
button {
  --box-shadow: none;
  cursor: pointer;
}

.ion-header,
.ion-content {
  background-color: #ffffff;
}

.ion-header {
  position: sticky;
  top: 0;
}

a {
  text-decoration: none;
}

.ion-card {
  margin-bottom: 0px;
  margin-top: 10px;
}

.ios .ion-badge {
  text-transform: uppercase;
}

.text-center {
  text-align: center !important;
}

.flex-end {
  align-items: flex-end;
}

.stock-filters {
  padding: 20px;
}
// .filter-title {
//   margin-top: 6px;
// }
.no-padding {
  padding: 0;
}

/* Floating class */
.pop-float-right {
  float: right;
}

.pop-float-left {
  float: left;
}

/* Titles */

.section-wrap {
  padding: 59px 0;
}
.section-padding {
  padding: 40px 0;
}
.section-padding-20 {
  padding: 20px 0;
}
.section-padding-top {
  padding: 40px 0 0;
}
.section-padding-bottom {
  padding: 0 0 40px;
}
.section-padding-30 {
  padding-top: 30px;
}

.registration-header {
  padding-top: $space-lg;
  display: block !important;
}

.auth-wrap-modal {
  .close {
    position: absolute;
    right: 10px;
    top: 10px;
    z-index: 100;
    cursor: pointer;
  }
}
.regular-modal {
  .close {
    position: absolute;
    right: 10px;
    top: 10px;
    z-index: 100;
    cursor: pointer;
  }
}
.regular-modal {
  .modal-wrapper {
    -webkit-border-radius: 10px;
    -moz-border-radius: 10px;
    border-radius: 10px;
    position: relative;
  }
}
.regular-modal_registration-confirmation {
  .modal-wrapper {
    width: 500px;
    height: 300px;
  }
}
.regular-modal_pickup-alert {
  .modal-wrapper {
    width: 300px;
    height: 400px;
  }
}
.regular-modal_pickup-alert-two {
  .modal-wrapper {
    width: 300px;
    height: 320px;
  }
}
.regular-modal_itemoos {
  .modal-wrapper {
    width: 300px;
    height: 360px;
  }
}
.regular-modal_remove-item {
  .modal-wrapper {
    width: 300px;
    height: 340px;
  }
}
.regular-modal_locked-account {
  .modal-wrapper {
    width: 400px;
    height: 350px;
  }
}
.regular-modal_locked-account-locked {
  .modal-wrapper {
    width: 400px;
    height: 400px;
  }
}
.regular-modal_share {
  .modal-wrapper {
    width: 370px;
    height: 350px;
  }
}

.regular-modal_location-share {
  .modal-wrapper {
    width: 370px;
    height: 300px;
  }
}

.regular-modal-ver-one {
  .modal-wrapper {
    width: 370px;
    height: 350px;
  }
}
.regular-modal-ver-two {
  .modal-wrapper {
    width: 370px;
    height: 250px;
  }
}
.auth-wrap-modal-registration {
  .modal-wrapper {
    height: 570px;
    width: 400px;
  }
}

.how-it-works-modal {
  .modal-wrapper {
    height: 650px;
    width: 400px;
    -webkit-border-radius: 10px;
    -moz-border-radius: 10px;
    border-radius: 10px;
    .ion-page {
      overflow: hidden;
    }
  }
}

.auth-wrap-modal-signin .modal-wrapper {
  height: 500px;
  width: 400px;
}

.modal-wrapper p {
  margin-bottom: 20px;
}

/* Landscape */
@media only screen and (min-device-width: 375px) and (max-device-width: 812px) and (-webkit-min-device-pixel-ratio: 3) and (orientation: landscape) {
  .how-it-works-modal {
    .modal-wrapper {
      height: 100%;
      .ion-page {
        overflow: scroll;
      }
    }
  }
}

/* Landscape */
@media only screen and (min-device-width: 411px) and (max-device-width: 823px) and (-webkit-min-device-pixel-ratio: 3) and (orientation: landscape) {
  .how-it-works-modal {
    .modal-wrapper {
      height: 100%;
      .ion-page {
        overflow: scroll;
      }
    }
  }
}

@media (max-width: 576px) {
  .auth-wrap-modal-registration .modal-wrapper,
  .auth-wrap-modal-needhelp .modal-wrapper {
    height: 100%;
  }
  .regular-modal_locked-account-locked .modal-wrapper {
    -webkit-border-radius: 0px;
    -moz-border-radius: 0px;
    border-radius: 0px;
  }
  .how-it-works-modal {
    .modal-wrapper {
      -webkit-border-radius: 0px;
      -moz-border-radius: 0px;
      border-radius: 0px;
      .ion-page {
        overflow: scroll;
      }
    }
  }

  .auth-wrap-modal {
    .ion-page {
      overflow: scroll;
    }
  }
  .auth-wrap-modal-signin {
    .modal-wrapper {
      width: 90%;
      height: 90%;
    }
  }

  .regular-modal_pickup-alert-item {
    .modal-wrapper {
      width: 90%;
      // height: 60%;
    }
  }

  .regular-modal-flag {
    .modal-wrapper {
      height: 100%;
      width: 100%;
    }
  }
  .regular-modal_registration-confirmation {
    .modal-wrapper {
      width: 90%;
    }
  }
  .regular-modal_pickup-alert {
    .modal-wrapper {
      width: 90%;
      // height: 80%;
    }
  }
  .regular-modal_pickup-alert-two {
    .modal-wrapper {
      width: 90%;
      // height: 60%;
    }
  }
  .regular-modal_itemoos {
    .modal-wrapper {
      width: 90%;
      // height: 70%;
    }
  }
  .regular-modal_remove-item {
    .modal-wrapper {
      width: 90%;
      // height: 60%;
    }
  }
  .regular-modal_locked-account .modal-wrapper,
  .regular-modal_locked-account-locked .modal-wrapper {
    width: 100%;
    height: 100%;
  }
  .regular-modal_share {
    .modal-wrapper {
      width: 90%;
      height: 60%;
    }
  }
  .regular-modal-ver-one {
    .modal-wrapper {
      width: 90%;
      // height: 70%;
    }
  }
  .regular-modal-ver-two {
    .modal-wrapper {
      width: 90%;
      // height: 40%;
    }
  }

  .section-wrap {
    padding: 30px 0;
  }
  .section-padding {
    padding: 20px 0;
  }
  .section-padding-top {
    padding: 20px 0 0;
  }
  .section-padding-bottom {
    padding: 0 0 20px;
  }
}
/* Landscape */
@media only screen and (min-device-width: 375px) and (max-device-width: 812px) {
  // and (-webkit-min-device-pixel-ratio: 3) and (orientation: landscape) {
  .auth-wrap-modal-registration .modal-wrapper {
    height: 100%;
    width: 100%;
  }
}

.how-it-works-modal {
  ul {
    padding-left: 20px;
    li {
      list-style-type: disc;
      line-height: 24px;
      margin-bottom: 20px;
    }
  }
  label {
    font-weight: bold;
    color: #000000;
    font-size: 30px;
    margin-top: 40px;
    display: block;
    margin-bottom: 20px;
  }
}

@media (max-width: 576px) {
  .how-it-works-modal {
    ul {
      li {
        line-height: 22px;
        margin-bottom: 15px;
      }
    }
    label {
      font-size: 26px;
    }
  }
}
.ops-modal {
  margin-bottom: $space-standard;
}
.location-pin {
  margin-bottom: 10px;
}

// PROFILE
.text-black {
  color: $color__black;
}
.my-account-btn label {
  font-size: $text__body-base;
  font-weight: bold;
  display: inline-block;
  cursor: pointer;
}
.order-history-btn {
  border: 1px solid #f0f0f0;
  box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.1) !important;
  cursor: pointer;
  background-color: #fff;
  width: 100%;
  text-align: left;
  padding: 10px;
  vertical-align: middle;
  -webkit-border-radius: 6px;
  -moz-border-radius: 6px;
  border-radius: 6px;
  margin-bottom: $space-sm;
  position: relative;
  @include breakpoint(xs) {
    margin-bottom: $space-standard;
  }
}
.order-history-btn {
  padding: $space-standard;
}

.border-section {
  border-bottom: 1px solid $color__gray-light;
}

.cvv-details {
  border: 1px solid #f0f0f0;
  // box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.1) !important;
  background-color: #fff;
  width: 100%;
  text-align: left;
  padding: 15px;
  -webkit-border-radius: 6px;
  -webkit-border-bottom-right-radius: 0;
  -moz-border-radius: 6px;
  -moz-border-radius-bottomright: 0;
  border-radius: 6px;
  border-bottom-right-radius: 0;
  margin-bottom: 13px;
  position: relative;
  // display: none;
  transition: width 2s;
  label {
    font-size: 18px;
    font-weight: bold;
  }
  p {
    font-size: 14px;
  }
  .close {
    position: absolute;
    right: -1px;
    bottom: -30px;
    top: inherit;
    z-index: 100;
    cursor: pointer;
    // box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.1) !important;
    border-bottom: 1px solid #f0f0f0;
    border-left: 1px solid #f0f0f0;
    border-right: 1px solid #f0f0f0;
    background-color: #fff;
    -webkit-border-bottom-right-radius: 6px;
    -webkit-border-bottom-left-radius: 6px;
    -moz-border-radius-bottomright: 6px;
    -moz-border-radius-bottomleft: 6px;
    border-bottom-right-radius: 6px;
    border-bottom-left-radius: 6px;
  }
}
.cvv-details.open
{
  display: block;
  opacity: 1;
}
.cvv-details.closed
{
  display: none;
  opacity: 0.1;
}

.order-detail-wrap .card-image,
.card-image {
  width: 44px;
  height: 30px;
  display: inline-block;
  background-color: #ccc;
  margin-right: 10px;
  vertical-align: middle;
}

.product-on-details {
  border: 1px solid #f0f0f0;
  box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.1) !important;
  cursor: default;
  background-color: #fff;
  width: 100%;
  padding: 10px;
  margin-bottom: 10px;
  // float: left;
}
.product-on-details .product-on-details-image-wrap {
  width: 10%;
  display: inline-block;
  position: relative;
}
.product-on-details .product-on-details-content-wrap {
  width: 88%;
  float: right;
  display: inline-block;
  vertical-align: top;
}
.product-on-details .product-on-details-content-wrap label.product-title {
  font-size: 14px;
  line-height: 16px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: inline-block;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  max-height: 30px;
  height: 30px;
  margin-bottom: 5px;
  margin-top: 0;
}
.product-on-details .product-on-details-content-wrap label.product-saving {
  font-size: 13px;
  font-weight: 500;
  color: #1f1f1f;
  display: block;
  margin-top: 5px;
}
.product-on-details .product-on-details-content-wrap p {
  font-size: 14px;
  font-weight: bold;
}
.product-on-details .product-on-details-content-wrap p span.letter {
  font-size: 11px;
  font-weight: 500;
  color: #1f1f1f;
}
.product-on-details .product-on-details-content-wrap p span.quantity {
  font-size: 14px;
  font-weight: 500;
  float: right;
  color: #919191;
  cursor: default;
}
.product-on-details .product-on-details-content-wrap p span.quantity.oos,
button.out-of-stock {
  color: $color__red;
  background: transparent;
  padding: 0;
  text-transform: uppercase;
  font-weight: bold;
  height: 30px;
}
.product-on-details .product-on-details-image-wrap:after {
  content: '';
  display: block;
  padding-bottom: 100%;
}
.product-on-details .product-on-details-image-wrap .product-on-details-image {
  position: absolute;
  width: 100%;
  height: 100%;
}
.product-on-details .product-on-details-image-wrap .product-on-details-image img {
  height: 100% !important;
  width: 100% !important;
  object-fit: contain;
}

.regular-modal-wrap {
  padding: 20px 0;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  width: 100%;
  .close {
    right: 20px;
    top: 0;
    position: absolute;
  }
}
.regular-modal-wrap .icon,
.auth-wrap-modal .icon,
.icon-centered,
#account-locked-screen .icon {
  display: block;
  background-image: url(/images/icons/alert-full.svg);
  cursor: default;
  margin: 0px auto $space-md auto;
}

#account-locked-screen label.modal-message {
  margin-top: 20px;
  margin-bottom: 50px;
  margin-left: 48px;
  margin-right: 48px;
}

.regular-modal .modal-wrapper label,
.auth-wrap-modal label.modal-message {
  font-weight: bold;
  color: #333333;
  margin-bottom: 10px;
  display: block;
  font-size: 17px;
  line-height: 20px;
}

.flag-label {
  font-weight: bold;
  color: #333333;
  margin-bottom: 20px;
  display: block;
  font-size: 17px;
  line-height: 20px;
}
.regular-modal {
  &.regular-modal_locked-account {
    label {
      font-weight: bold;
      color: #333333;
      margin-bottom: 5px;
      display: block;
      font-size: 17px;
      span {
        color: #f40000;
      }
    }
    .input-wrap {
      padding-bottom: 10px;
    }
  }
}
#account-locked-screen .gigya-screen h1 {
  margin-bottom: 30px;
  text-align: center;
  margin-top: 50px;
  br {
    display: none;
  }
}
.input-wrap {
  &.ratio-select {
    label {
      font-weight: 500;
      color: #000000;
      margin-bottom: 1px;
      display: block;
      font-size: 15px;
      margin-left: 5px;
    }
    input {
      vertical-align: middle;
    }
  }
}

.icon-face {
  margin-bottom: $space-standard;
}

.icon-pin {
  width: 60px;
  height: 60px;
  border-radius: 50%;
  background-image: url(/images/location_pin.png);
  background-repeat: no-repeat;
  background-position: center;
  cursor: default;
}

.pop-title {
  text-align: center;
  font-size: 20px;
  padding: 15px 0;
  margin-top: 0;
  font-weight: bold;
  background-color: $color__primary;
  color: #fff;
}

.pop-sub-title {
  font-size: 22px;
  font-weight: bold;
  padding-bottom: 0;
  padding-top: 0;
  margin-top: 0;
  text-align: center;
  line-height: $line-height-xxxl;
}

.pop-sub-title-secondary {
  font-size: 16px;
  font-weight: normal;
  color: $color__gray-dark;
  padding: 0;
  margin: 0;
  height: $space-md;
  display: inline-block;
}
.regular-modal_locked{
  height: 350px !important;
}

@media (max-width: 576px) {
  .pop-sub-title {
    font-size: 17px;
    font-weight: bold;
    padding-bottom: 10px;
    padding-top: 10px;
    margin-top: 0;
    text-align: left;
  }
}

.pop-filter-count {
  display: flex;
  align-items: baseline;
  .filters__button-container {
    text-align: right;
  }
  h5 {
    font-size: 16px;
    width: 100%;
    line-height: 33px;
    display: inline-block;
    font-weight: bold;
    margin-top: 0;
    span {
      color: $color__primary;
    }
  }

  &__search--mobile {
    @include breakpoint(sm) {
         display: none;
    }
  }

  &__search--desktop {
    display: none;
    @include breakpoint(sm) {
         display: block;
    }
  }
}
.pop-filters,
.pop-filter-actions {
  margin-bottom: 25px;
}

.search-count h5 {
  display: inline;
}

@media (max-width: 576px) {
  .icon-grid {
    width: 20px;
    height: 20px;
    background-color: #fff;
    margin-right: 14px;
    margin-top: 7px;
    padding: 0;
    span {
      width: 9px;
      height: 9px;
      margin-right: 1px;
      margin-bottom: 1px;
      display: block;
      float: left;
      -webkit-border-radius: 2px;
      -moz-border-radius: 2px;
      border-radius: 2px;
    }
  }

  .icon-list {
    width: 20px;
    height: 20px;
    background-color: #fff;
    margin-top: 6px;
    padding: 0;
  }
  .icon-list span {
    width: 20px;
    height: 6px;
    margin-bottom: 1px;
    display: block;
    -webkit-border-radius: 2px;
    -moz-border-radius: 2px;
    border-radius: 2px;
  }

  .product-grid-view-horizontal .icon-grid span {
    border: 2px solid #87189d;
  }
  .product-grid-view-vertical .icon-grid span {
    border: 2px solid #636363;
  }
  .product-grid-view-horizontal .icon-list span {
    border: 2px solid #636363;
  }
  .product-grid-view-vertical .icon-list span {
    border: 2px solid #87189d;
  }
  .product-grid-view-vertical .col-6 {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .product-grid-view-vertical .col-6 {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .product-grid-view-vertical .pop-card-product {
    margin: 0 0 15px 0;
    box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.1);
    border: 1px solid #f0f0f0;
    padding: 10px;
    cursor: pointer;
  }
  .product-grid-view-vertical .pop-card-product .pop-product-img-wrap {
    width: 20%;
    float: left;
    position: relative;
    margin-top: 10px;
  }
  .product-grid-view-vertical .pop-card-product ion-card-header {
    padding: 0 0 0 10px;
    float: left;
    width: 80%;
    position: relative;
  }
  .product-grid-view-vertical .pop-card-product h5 {
    font-size: 15px;
    line-height: 23px;
    color: #000;
    font-weight: 500;
    margin-top: 0px;
    margin-bottom: 0;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    min-height: 48px !important;
  }
  .product-grid-view-vertical .pop-card-product h6 {
    margin-bottom: 6px;
  }
  .product-grid-view-vertical .pop-card-product button {
    padding: 0;
    text-align: left;
    width: auto;
    display: inline-block;
  }
  .product-grid-view-vertical .pop-product-img {
    padding: 0px;
  }
  .product-grid-view-horizontal .col-6:nth-child(odd) {
    padding-right: 5px;
  }
  .product-grid-view-horizontal .col-6:nth-child(even) {
    padding-left: 5px;
  }
}

.select-style {
  position: relative;
  background-color: #fff;
  &:after {
    content: '';
    background-image: url(/images/icons/carrot-down.svg);
    width: 14px;
    height: 8px;
    right: 11px;
    top: 22px;
    padding: 0 0 2px;
    position: absolute;
    pointer-events: none;
  }
  select {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    display: block;
    width: 100%;
    padding: 11px 10px;
    font-size: 14px;
    color: #636363;
    background-color: #ffffff;
    background-image: none;
    border: 1px solid #cccccc;
    -ms-word-break: normal;
    word-break: normal;
    -webkit-box-shadow: -1px 4px 10px 6px rgba(0, 0, 0, 0.06);
    box-shadow: -1px 4px 10px 6px rgba(0, 0, 0, 0.06);
    line-height: 24px;
  }
}
select {
  &::-ms-expand {
    display: none;
  }
}

.logo_color {
  margin-left: auto;
  margin-right: auto;
  width: 200px;
  height: 30px;
  background-image: url(/images/logo_color.svg);
  background-repeat: no-repeat;
  background-position: center;
}

.pop_main_header_logo {
  text-align: center;
  margin-top: $space-sm;
  padding: 0;
  img {
    max-width: 140px;
    cursor: pointer;
  }
}

.main_logo {
  position: relative;
  margin-top: 10px;
  width: 200px;
  height: 30px;
  background-repeat: no-repeat;
  background-position: center;
}

.ml-15 {
  margin-left: 15px;
}
.mr-15 {
  margin-right: 15px;
}

.logo {
  width: auto;
  height: 30px;
  background-image: url(/images/logo.svg);
  background-repeat: no-repeat;
  background-position: center;
}
.back {
  width: $line-height-md;
  height: $line-height-base;
  display: block;
  background-image: url(/images/icons/back.svg);
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
}
.back.back-header {
  margin-left: 15px;
}

.icon-center {
  margin: 0 auto;
}
.m-down-20 {
  margin-bottom: 20px;
}
.m-down-30 {
  margin-bottom: 20px;
}

.menu-span {
  color: #000;
  margin-left: 10px;
  font-weight: bold;
}
@media (max-width: 576px) {
  .menu-span {
    display: none;
  }
}

.close {
  width: calc($space-lg + $space-xxxxs);
  height: calc($space-xxs * 5);
  cursor: pointer;
}

.check_cart {
  position: relative;
  // left: 15%;
}

.check_cart:after {
  content: url(/images/icons/Check-purple.svg);
  text-align: center;
  width: 78px;
  display: inline-block;
}

.unchecked_cart:after {
  content: 'ADD TO CART';
}

.product-card {
  &__oos {
    color: $color__red !important;
    cursor: default;
    &:after {
      content: 'OUT OF STOCK';
    }
  }
}

.unchecked_all_cart:after {
  content: 'ADD ALL TO CART';
}
.confirm_store:after {
  content: 'Confirm your store';
}

.share {
  width: $space-lg;
  height: $space-md;
}


.alert-full {
  width: 42px;
  height: 42px;
  display: block;
}
.megaphone {
  width: 70px;
  height: 70px;
  background-image: url(/images/icons/megaphone.svg);
  background-repeat: no-repeat;
  background-position: center, middle;
  background-size: contain;
  display: block;
}

/* General cards styling */
.content-slider ion-card {
  margin-bottom: 15px;
}

.label-font-weight {
  font-weight: 900;
}
/*!---- Responsive Video -----*/
.flex-video {
  position: relative;
  padding-top: 25px;
  padding-bottom: 67.5%;
  height: 0;
  margin-bottom: 16px;
  overflow: hidden;
  &.widescreen {
    padding-bottom: 57.25%;
  }
  &.vimeo {
    padding-top: 0;
  }
}
.flex-video iframe,
.flex-video object,
.flex-video embed {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.popcard {
  h6 {
    color: #000;
    overflow: hidden;
    text-overflow: ellipsis;
    span {
      float: right;
    }
  }
}

.pop-search .searchbar-input {
  background-color: #fff;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  border-radius: 4px;
}

.pop_disable {
  background-color: #e7e7e7 !important;
}

.hero {
  min-height: 200px;
  background-color: #e7e7e7;
}

.events-location .change-store-icon {
  // top: 0;
  position: inherit;
  display: inline-block;
  width: $space-md;
  height: $space-md;
  right: inherit;
  top: inherit;
  margin-top: 0px;
  vertical-align: bottom;
  svg{
    width: calc($space-xxxs * 5);
    height: calc($space-xxxs * 5);
  }
}

@media (max-width: 576px) {
  .events-location .change-store-icon {
    position: absolute;
    display: block;
    width: $space-md;
    height: $space-md;
    right: 15px;
    top: 0px;
    margin-top: 0px;
    svg{
      width: calc($space-xxxs * 5);
      height: calc($space-xxxs * 5);
    }
  }
}

.pop-event-cart {
  margin-bottom: 30px !important;
  ion-card-header {
    padding: 10px;
  }
}

.rsvpd {
  font-size: 14px !important;
  font-weight: bold;
  position: absolute;
  right: 0;
  top: 0;
  .icon-sent {
    width: 10px;
    height: 8px;
    display: inline-block;
    margin-right: 5px;
    svg {
      width: $space-sm;
      height: $space-xs;
    }
  }
}

@media (max-width: 340px) {
  .pop-not-loggedin label {
    font-size: 15px;
  }
}

.title-section {
  text-align: center;
}

.pop-filters {
  ion-badge {
    text-transform: capitalize;
  }
  .col {
    margin-left: 15px;
    margin-bottom: 15px;
  }
}

/* Filters */

.pop-forms {
  .pop-label {
    color: $color__gray-dark;
  }
  .pop-input {
    border: 1px solid #e7e7e7;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    border-radius: 5px;
    -webkit-box-shadow: -1px 4px 21px 6px rgba(0, 0, 0, 0.3);
    box-shadow: -1px 4px 21px 6px rgba(0, 0, 0, 0.3);
  }
}

/* Alerts on forms*/

.pop-success-input {
  .native-input {
    border-bottom: 2px solid green;
  }
  em {
    font-size: 12px;
    color: rgb(3, 107, 43);
    padding: 5px 10px 10px 5px;
    background-color: rgba(153, 253, 153, 0.363);
    width: 100%;
  }
}
.pop-error-input {
  .native-input {
    border-bottom: 2px solid red;
    background-color: rgba(255, 0, 0, 0.37);
  }
  em {
    font-size: 12px;
    color: rgb(179, 9, 9);
    padding: 5px 10px 10px 5px;
    width: 100%;
  }
}

.pop-card-product {
  cursor: pointer;
  border: 1px solid #f0f0f0;
  -webkit-box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.1);
  -moz-box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.1);
  box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.1);
}

/* // Small devices (landscape phones, 576px and up) */
@media (max-width: 576px) {
  .event-page-wrap {
    padding: 0;
    .event-page-hero {
      margin-top: 0;
      height: 300px;
    }
    .event-page-hero-image {
      width: 100%;
      height: auto;
      margin-bottom: 20px;
    }
  }

  .event-page-ctas {
    padding-top: 0px;
  }
  .pop-card-product {
    margin: 0 0 10px 0;
  }

  .home-content {
    .col-6 {
      &:nth-child(odd) {
        padding-left: 5px;
      }
      &:nth-child(even) {
        padding-right: 5px;
      }
    }
  }
  .event-page-details {
    padding: 20px 0;
  }
}

.confirmation-wrap {
  .order__thanks {
    margin-bottom: 30px;
    text-align: center;
    font-size: 32px;
    font-weight: bold;
    display: block;
  }
  p {
    margin-bottom: 20px;
    line-height: 20px;
    strong {
      font-weight: bold;
      &.black {
        color: #000000;
      }
    }
  }
  span {
    font-size: 13px;
    color: $color__gray;
  }
  a {
    font-size: 16px;
    cursor: pointer;
  }
  .border-right {
    &::after {
      content: '';
      position: absolute;
      width: 1px;
      height: 32px;
      right: 10px;
      top: 0;
      background-color: #ccc;
    }
  }
}

.emptycartwrap strong {
  font-weight: bold;
}

.tile-saving {
  margin-top: 36px;
  border: 1px solid #f0f0f0;
  -webkit-box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.1);
  -moz-box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.1);
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.1);
  cursor: pointer;
  position: relative;
  background: #ffffff;
  .tile-saving-top {
    img {
      height: auto;
      width: 100%;
      -o-object-fit: contain;
      object-fit: contain;
    }
  }
  .tile-saving-info {
    padding: 15px;
    SVG {

    }
  }
  label {
    font-size: 16px;
    line-height: 20px;
    margin: 0;
    color: #000;
    font-weight: bold;
    &.saving-price {
      font-size: 20px;
    }
    &.saving-title {
      overflow: hidden;
      text-overflow: ellipsis;
      display: inline-block;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      max-height: 40px;
    }
  }
  span {
    font-size: 14px;
    font-weight: bold;
    line-height: 18px;
    color: #636363;
    padding: 0;
    margin: 0;
    display: block;
    margin-bottom: 7px;
    margin-top: 6px;
    max-width: 90%;
    overflow: hidden;
    text-overflow: ellipsis;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    max-height: 38px;
  }
}
.tile-saving-top-image {
  width: 66%;
  // height: 138px;
  margin-top: -36px;
  display: inline-block;
  padding-left: 10px;
  padding-right: 10px;
  padding-top: 46px;
}
.tile-saving-top-savings {
  width: 33%;
  display: inline-block;
  padding-top: 20px;
  padding-right: 10px;
  vertical-align: top;
  text-align: center;
  line-height: 18px;
  span {
    width: 100%;
    max-width: 100% !important;
    margin-bottom: 0 !important;
  }
}
.tile-saving-float {
  margin-top: 36px;
  img {
    margin-top: -40px;
  }
}

@media (max-width: 576px) {
  .tile-saving {
    margin-top: 0px;
  }
  .tile-saving-float {
    margin-top: 40px;
  }
  .vertical-mobile .tile-saving,
  .vertical-mobile .pop-card-article {
    margin-bottom: 15px;
  }
}
/* Landscape */
@media only screen and (min-device-width: 375px) and (max-device-width: 812px) {
  // and (-webkit-min-device-pixel-ratio: 3) and (orientation: landscape) {
  .vertical-mobile .tile-saving,
  .vertical-mobile .pop-card-article {
    margin-bottom: 15px;
  }
  .react-multi-carousel-dot-list {
    display: none !important;
  }
}

.pop-card-color-picker {
  margin: 0;
  box-shadow: none;
  border-bottom: 1px solid #e3e3e3;
  padding: 10px 20px;
  cursor: pointer;
  width: 100%;
  -webkit-border-radius: 0px;
  -moz-border-radius: 0px;
  border-radius: 0px;
  &:first-of-type {
    border-top: 1px solid #e3e3e3;
  }
}
.pop-card-color-picker-imgwrap {
  width: 25%;
  float: left;
  position: relative;
  &:after {
    content: '';
    display: block;
    padding-bottom: 100%;
  }
}
.pop-card-color-picker-img {
  position: absolute;
  width: 100%;
  height: 100%;
  img {
    height: 100% !important;
    width: 100% !important;
    -o-object-fit: contain;
    object-fit: contain;
  }
}

.pop-card-color-picker-contentwrap {
  padding: 0 0 0 20px;
  float: left;
  width: 75%;
  position: relative;
  text-align: left;
  label {
    &.product-title-picker {
      font-size: 16px;
      line-height: 23px;
      color: #000;
      font-weight: 500;
      margin-top: 10px;
      margin-bottom: 0px;
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 1;
      -webkit-box-orient: vertical;
    }
  }
}

.pop-card-color-picker-cta {
  float: left;
  width: 40%;
  position: relative;
  padding-top: 10px;
}
.pop-card-color-picker-info {
  .product-color-picker {
    font-size: 16px;
    color: #6c6c6c;
    font-weight: 500;
    display: block;
  }
  .product-price-picker {
    font-size: 20px;
    color: #000000;
    font-weight: bold;
    display: block;
  }
  .product-stock-picker {
    font-size: 16px;
    color: #1f1f1f;
    font-weight: 500;
    display: block;
  }
  .product-stock-picker-oos {
    color: $color__red !important;
  }
}

// .product-description-box td:last-child{
//   border-bottom: none;
// }

.content-slider ion-card {
  width: 96%;
}

.swiper-pagination-fraction,
.swiper-pagination-custom,
.swiper-container-horizontal > .swiper-pagination-bullets {
  height: 10px;
}

.pop-error-message {
  * {
    text-align: center;
  }
  img {
    margin-left: auto;
    margin-right: auto;
    width: 200px;
  }
  
}

iframe {
  width: 100%;
}

.map-list-wrap .container {
  min-height: 500px;
}

.mobile-confirm-store-model .modal-wrapper {
  @media (max-width: 576px) {
    width: 100% !important;
    height: 100% !important;
    max-width: 100% !important;
    max-height: 100% !important;
  }
}

.pop_modal_close {
  cursor: pointer;
  right: 0;
  margin-top: 15px;
  width: calc($space-lg + $space-xxxxs);
  height: calc($space-xxs * 5);
  background-repeat: no-repeat;
  margin-right: 10px;
  position: absolute;
  text-indent: -9999px;
}

.red span {
  background-color: red;
}

@keyframes fade-in {
  0% {
    opacity: 0;
  }
  42% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.pop_my_information {
  label {
    color: gray;
    font-size: 14px;
    padding-bottom: 5px;
    a {
      // float: right;
      // text-decoration: underline;
      font-weight: bold;
    }
    span {
      font-size: 16px;
      margin-bottom: 20px;
      margin-top: 8px;
      text-indent: 10px;
      color: #000;
      display: block;
    }
  }
  button {
    width: 100%;
    display: block;
    padding-top: 15px;
    padding-bottom: 15px;
  }
}

.pop_social_login_connected_accounts h6 {
  color: #a7a7a7;
  width: 100%;
  padding-top: 20px;
  padding-bottom: 20px;
}

.instore__text {
  color: #1d9a00 !important;
}

.drawer-content {
  z-index: 900 !important;
}

// .rbd-lll2zcb2yw-dr {
// }

input[type='color'],
input[type='date'],
input[type='datetime'],
input[type='datetime-local'],
input[type='email'],
input[type='month'],
input[type='number'],
input[type='password'],
input[type='search'],
input[type='tel'],
input[type='text'],
input[type='time'],
input[type='url'],
input[type='week'],
select,
select:focus,
textarea {
  font-size: 16px !important;
}

/* Portrait */
@media only screen and (min-device-width: 320px) and (max-device-width: 360px) and (-webkit-min-device-pixel-ratio: 2) and (orientation: portrait) {
  #having-trouble {
    width: 80%;
  }

  .having-trouble-link {
    width: 80%;
  }
}

// Added this class to the wrapper where the pop-up loads,
//to ensure the footer is not at the sample place as the header

.pop-target-min-height {
  height: calc(100% - 280px);
}

.pop-help-modal-content {
  padding: 20px;
  width: 100%;
}

.need-having-trouble-modal .modal-wrapper {
  -webkit-border-radius: 8px;
  -moz-border-radius: 8px;
  border-radius: 8px;
  width: 340px;
  height: 340px;
}

#register-phoneverify-container {
  .col12 {
    text-align: right;
    #having-trouble {
      text-decoration: none;
    }
  }
}

.having-trouble-link {
  text-decoration: none;
  line-height: 14px;
  display: block;
  position: inherit !important;
  width: 100% !important;
  clear: both;
  height: 17px;
  text-align: center !important;
}

.need-having-trouble-modal .modal-wrapper .ion-page {
  overflow: hidden;
}

#register-phoneverify-container .col12 {
  height: 30px;
  width: 96%;
  position: relative;
}

#register-phoneverify-container .col12 .having-trouble-link {
  text-decoration: underline;
  line-height: 14px;
  height: 30px;
  float: right;
}

.text-decoration {
  text-decoration: underline;
}
.text-right {
  text-align: right !important;
}
.label-terms {
  font-size: 13px !important;
  line-height: 14px;
  font-weight: 500;
  width: 85%;
}
.font-14 {
  font-size: 14px;
}
.font-normal {
  font-weight: normal !important;
}

.pop__button-filters {
  position: sticky;
  bottom: 0px;
  padding: 0px 10px 10px 10px;
  background: white;
}

@media (max-width: 576px) {
  .pop__button-filters {
    bottom: 50px;
  }
}

.filter__content-container {
  height: calc(100% - 47px);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  @include z-index(primary);
}

@media (max-width: 576px) {
  .pdp-carousel .carousel {
    display: none;
  }
}

@media (min-width: 576px) {
  .carousel.carousel-slider .control-arrow {
    top: 0;
    color: #f6f6f6;
    font-size: 20px !important;
    bottom: 0;
    padding: 5px;
    height: 40px;
    width: 40px;
    border-radius: 30px;
    margin-bottom: auto;
    margin-top: auto !important;
    opacity: 1 !important;
  }
  .control-arrow:not(.control-disabled) {
    background: $color__primary !important;
  }
  .carousel .control-next.control-arrow:before {
    border-left: 10px solid #fff;
  }
  .carousel .control-prev.control-arrow:before {
    border-right: 10px solid #fff;
  }
  .control-disabled {
    display: block !important;
    background: #aeb1ae !important;
  }
  .carousel .control-arrow:before,
  .carousel.carousel-slider .control-arrow:before {
    display: inline-block;
    border-top: 8px solid transparent;
    border-bottom: 8px solid transparent;
    content: '';
  }
}

.general-page-wrap{
  p {
    margin-bottom: $space-standard;
  }
  ul{
    li{
      margin-bottom: $space-standard;
      line-height: $space-md;
    }
  }
  ol{
    li{
      margin-bottom: $space-standard;
      line-height: $space-md;
    }
  }
}
