.hover {
  &:hover {
    cursor: pointer;
  }
}

@mixin line-clamp($line-clamp-boundary) {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: $line-clamp-boundary;
  -webkit-box-orient: vertical;
}

$breakpoints: (
  xs: 32rem,
  // ~512px
  sm: 48rem,
  // ~768px
  md: 64rem,
  // ~1024px
  lg: 71.25rem,
  // ~1140px
  xl: 90rem // ~1440px
  ) !default;

@mixin breakpoint($breakpoint, $logic: false) {
  @if($logic ) {
    @media #{$logic} and (min-width: map-get($map: $breakpoints, $key: $breakpoint)) {
      @content;
    }
  }

  @else {
    @media (min-width: map-get($map: $breakpoints, $key: $breakpoint)) {
      @content;
    }
  }
}

@mixin breakpoint-max($breakpoint, $logic: false) {
  @if($logic ) {
    @media #{$logic} and (max-width: map-get($map: $breakpoints, $key: $breakpoint)) {
      @content;
    }
  }

  @else {
    @media (max-width: map-get($map: $breakpoints, $key: $breakpoint)) {
      @content;
    }
  }
}

//Z-Index
$z-index: (
  primary: 9000,
  secondary: 8000,
  tertiary: 7000,
  quaternary: 6000,
  quinary: 5000,
  base: 1,
  hide: 0,
);

@function z-index($key) {
  @return map-get($z-index, $key);
}

@mixin z-index($key) {
  z-index: z-index($key);
}

@mixin redaction {
  color: transparent !important;
  background-color: $color__gray-lightest-border;
  border-radius: $radius-xxs;
  user-select: none;
  pointer-events: none;
  display: block;
  height: 20px;
}

////////////////////////
// Center any element vertically or horizontally
////////////////////////
@mixin center($horizontal: true, $vertical: true) {
  position: absolute;

  @if ($horizontal and $vertical) {
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  @else if ($horizontal) {
    left: 50%;
    transform: translate(-50%, 0);
  }

  @else if ($vertical) {
    top: 50%;
    transform: translate(0, -50%);
  }
}

@mixin no-ripple() {
  --ripple-color: transparent;
  --background-activated: transparent;
  --background-activated-opacity: transparent;
  --background-focused: transparent;
  --background-focused-opacity: transparent;
  --background-hover: transparent;
  --background-hover-opacity: transparent;
}

@mixin negate-ionic() {
  --background-hover: #ffffff !important;
}

////////////////////////
// Create a page container
////////////////////////
$page-width: (
  sm: $space-max-width-sm,
  md: $space-max-width-md,
  lg: $space-max-width,
);

@mixin page-container($size) {
  padding-left: $space-page-gutters;
  padding-right: $space-page-gutters;
  margin: 0 auto;
  width: 100%;
  max-width: map-get($map: $page-width, $key: $size);
}

@mixin swiper-pagination-gradient() {
  .swiper {
    padding: 0 $space-page-gutters;
    margin: 0 calc(-1 * $space-page-gutters);

    @include breakpoint(lg) {
      margin: 0;
      padding: 0 calc($space-xl + $space-xs);
      -webkit-mask-image: linear-gradient(to left, transparent 0%, black 10%, black 90%, transparent 100%);
      mask-image: linear-gradient(to left, transparent 0%, black 10%, black 90%, transparent 100%);
    }
  }

  .swiper-button-lock+.swiper {
    -webkit-mask-image: none;
    mask-image: none;

    @include breakpoint(lg) {
      padding: 0;
    }

    .swiper-wrapper {
      justify-content: center;
    }
  }
}

@mixin about__stores-carousel() {
  border-radius: $radius-xxs + $radius-xs + $radius-sm;
  border: 4.5px solid rgba(255, 255, 255, 0.50);
  box-shadow: 2.25px 4.5px 13.5px 0px rgba(0, 0, 0, 0.08), 0.563px 1.125px 3.375px 0px rgba(0, 0, 0, 0.08);
  backdrop-filter: blur(56.25px);
  margin: 0 $space-sm + $space-xs;
  z-index: 99;
  height: 100px !important;
  width: 100px !important;

  a {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;

    p:first-of-type {
      color: $color__white;
      font-size: $text__header-xxl;
      line-height: $line-height-xl;
      padding: $space-xxs;
    }

    p:last-of-type {
      color: $color__white;
      padding: $space-xxs;

    }
  }
}
@mixin about__stores-table () {
  border-radius: $radius-xxs + $radius-xs + $radius-sm;
  border: 4.5px solid rgba(255, 255, 255, 0.50);
  box-shadow: 2.25px 4.5px 13.5px 0px rgba(0, 0, 0, 0.08), 0.563px 1.125px 3.375px 0px rgba(0, 0, 0, 0.08);
  backdrop-filter: blur(56.25px);
  margin-right: $space-sm + $space-xs;
  z-index: 99;
}

@mixin about__stores-table-rows () {
  display: flex;
  justify-content: space-between;
  margin-bottom: $space-xl;
  span {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    border-radius: $radius-sm;
    a {
      height: 100px !important;
      width: 100px !important;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      height: 100%;
      p:first-of-type {
        color: $color__white;
        font-size: $text__header-xxl;
        line-height: $line-height-xl;
        padding: $space-xxs;
      }
      p:last-of-type {
        color: $color__white;
        padding: $space-xxs;
      }
    }
  }
}
