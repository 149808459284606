.swe_posts_container {
    .swiper-wrapper {
        @include breakpoint(sm) {
            column-gap: $space-standard;
        }
    }
    .swe_post_image {
        opacity: 1 !important;

        &:hover {
            opacity: 1 !important;

            .swiper-button-next {
                opacity: 1 !important;
            }
        }
    }

    .swiper-pagination {
        position: relative;

        button {
            height: $space-xs  !important;
            width: $space-xs  !important;
            border-radius: $radius-round  !important;
            background-color: #D8D8D8 !important;
            padding: 0 4px !important;
        }

        .swiper-visible-switch {
            opacity: .2 !important;
        }

        .swiper-active-switch {
            background: $color__primary  !important;
            opacity: 1 !important;
        }
    }

    .swiper-button-next {
        position: relative;
        right: 0px;
        display: flex;
        background: $color__primary  !important;
        width: $space-xl;
        height: $space-xl;
        border-radius: $radius-round;
        right: 1px !important;
        opacity: 1 !important;

        @include breakpoint-max(lg) {
            display: none;
        }

        &:hover {
            opacity: 1 !important;
            background: $color__primary-dark  !important;
        }

        &:before {
            display: block;
            content: '' !important;
            background-image: url('/images/icons/navigation-arrow-next.svg');
            background-size: $space-standard $space-standard;
            background-repeat: no-repeat;
            background-position: center;

        }
    }

    .swiper-button-prev {
        position: relative;
        right: 0px;
        display: flex;
        background: $color__primary  !important;
        width: $space-xl;
        height: $space-xl;
        border-radius: $radius-round;
        left: 1px !important;
        opacity: 1 !important;

        @include breakpoint-max(lg) {
            display: none;
        }

        &:hover {
            opacity: 1 !important;
            background: $color__primary-dark  !important;
        }

        &:before {
            display: block;
            content: '' !important;
            background-image: url('/images/icons/navigation-arrow-prev.svg');
            background-size: $space-standard $space-standard;
            background-repeat: no-repeat;
            background-position: center;

        }
    }

    .swe_state_disabled {
        background: $color__gray-disabled  !important;

        &:hover {
            background: $color__gray-disabled  !important;
        }
    }

    .swe_post_sliding_container {
        @include breakpoint(sm) {
        top: 200px !important;
        }
    }

    .swe_post_social_link {
        display: none;
    }

    .swe_post_icon_close {
        @include breakpoint-max(sm) {
            position: relative !important;
        }
    }
}