.reset-password {
    @include page-container(lg);
    padding-bottom: $space-xl;
    padding-bottom: $space-standard;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;

    @include breakpoint-max(sm) {
        padding-bottom: $space-standard;
    }
    h1 {
        padding-top: 20px;
        font-size: $text__header-xxl;
        font-weight: 800;
        margin-bottom: $space-lg;
    }
    &__wrapper {
        padding-bottom: 15px;
    }
    input {
        border: 1px solid $color__input-border;
        &:focus {
            outline: $outline__normal;
            outline-offset: 2px;
        }
    }
    
    .error-label {
        font-size: $text__body-base;
    }
    .eyeball {
        width: $space-md;
        height: $space-md;
        float: right;
        position: relative;
        top: -35px;
        right: $space-sm;
        background-repeat: no-repeat;
        background-size: contain;
    }

    &__form {
        flex: 1;
        @include breakpoint(sm) {
            flex: 0 0 33.333%;
        }

        h1 {
            padding-top: 20px;
            font-size: $text__header-xxl;
            font-weight: 800;
            text-transform: lowercase;
            margin-bottom: $space-lg;
        }
    }
    .create-password {
        padding-bottom: 15px;
    }
} 
