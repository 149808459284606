/* POPDG Web Registration Css */
.sign-in__header{
    font-size: 32px;
    font-weight: bold;
}

.facebook_btn-width{
    width: 40%;
}

a, a:hover{
    color:#333
  } 
   
.form-control:focus {       
    border-color: #fff !important; 
    box-shadow:none !important;
    border-bottom: 1px solid #efefef !important;
}

.login-details-forgot_password {     
    color: rgba(135,59,162,1) !important;
}

.btn-sign-in{
    background-color: rgba(135,59,162,1);
    font-weight: bold;
    color: #fff;
    height: 48px;
}

input[type=radio] {
    width: 18px;
    height: 18px;
}

.form-containts{
    padding-top: 80px;
}

.fa-eye-slashv-color{
    color: rgba(135,59,162,1);
}
.gigya-input-text-width{
    width: 93%;
}
.gigya-input-text-border{
    border-top: 0px;
    border-left: 0px;
    border-right:0px;
    border-bottom: 1px solid #afadad;
    border-radius: 0px;
}
.gigya-forgote-password{
    margin-top:80px;
}
.password-change-text{
font-weight: bold;
font-size: 24px;
}
.password-change-smile-icon{
  width:75px;  
}
.popup-border{
    border-radius: 8px;
    border: 1px solid #afadad;
}
/* POPDG Web Registration Css end*/

/* Login page css start */

.auth-wrap {
    padding: 59px 0;
}
.auth-wrap h1 {
    font-weight: bold;
    text-transform: lowercase;
}
h1 {
    margin-top: 20px;
    font-size: 26px;
    line-height: 1.2;
}
.btn-social-wrap {
    padding: 20px 0;
}
.btn-social-f, .btn-social-g {
    background-repeat: no-repeat;
    background-position: 50%;
}

.btn-social {
    width: 100%;
    border-radius: 6px;
    padding: 20px 10px;
    text-align: c;
    border: 1px solid #ccc;
    background-color: #fff;
}
.btn-social-f{
    width: 100%;
    border-radius: 6px;
    padding: 8px 10px;
    text-align: c;
    border: 1px solid #ccc;
    background-color: #fff;
}
.g-width{
    width: 18px;
}
.f-width{
    width: 22px;
}

* {
    font-family: "Lato",sans-serif;
}
button {
    cursor: pointer;
}
button, ion-button {
    --box-shadow: none;
}
.form-wrap .input-wrap {
    padding-bottom: 15px;
}
.form-wrap .input-wrap label.label-form {
    font-size: 15px;
    color: #636363;
    display: block;
    margin-bottom: 5px;
}
.form-wrap .input-wrap input[type=email], .form-wrap .input-wrap input[type=password], .form-wrap .input-wrap input[type=text], .form-wrap .input-wrap textarea {
    border: 1px solid #ccc;
    background-color: #fff;
    width: 100%;
    border-radius: 6px;
    padding: 11px 10px;
    box-shadow: -1px 4px 10px 6px rgba(0,0,0,.06);
}
*, :after, :before, html {
    box-sizing: border-box;
}
.regular-checkbox {
    -webkit-appearance: none;
    background-color: #d8d8d8;
    border: 1px solid #ccc;
    padding: 9px;
    border-radius: 0;
    display: inline-block;
    position: relative;
    width: 18px;
    height: 18px;
    vertical-align: middle;
}
.regular-checkbox:checked {
    background-color: #9230a8;
    border: 1px solid #9230a8;
}
input[type=checkbox], input[type=radio] {
    padding: 0;
    box-sizing: border-box;
}
.email-checkmark label {
    font-size: 14px;
}
.email-checkmark label button {
    padding-bottom: $space-sm;
  }
.email-checkmark label a {    
    text-decoration: none;
    font-size: 13px;
    color: var(--ion-color-primary,#87189d);
    background-color: transparent;  
} 
.rememberme-checkmark{
    display: flex;
    width: 100%;
    &-btn{
        display: flex;
        width: 100%;
        justify-content: space-between;
        label{
            font-size: 14px;
            color: #000;
            display: inline-block;
            vertical-align: middle;
            line-height: 12px;
            margin-left: 6px;
            width: auto;
        }
        button{
            color: #87189D;
            font-weight: bold;
            float: right;
            padding-bottom: 12px;
            width: 100%;
        }
    }

    div {
        margin-left: auto;
        button {
            color: $color__primary;
            font-weight: bold;
            line-height: 18px;
        }
    }
}
.pop-button-color {
    border: 1px solid #87189d;
    background-color: $color__primary;
    color: #fff;
}
.pop-button-full {
    width: 100%;
}
.pop-button {
    font-weight: bold;
    padding: 10px 15px;
    text-transform: uppercase;
    border-radius: 6px;
}
.email-checkmark label a {
    font-weight: bold;
    float: right;
}
.switch-modal {
    font-size: 15px;
    color: #636363;
}
.switch-modal button {
    font-size: 15px;
    font-weight: bold;
    color: var(--ion-color-primary,#87189d);
}
.switch-modal a {
    font-size: 15px;
    font-weight: bold;
    color: var(--ion-color-primary,#87189d);
}
[tappable], button {
    cursor: pointer;
}
.regular-checkbox:checked, .regular-checkbox:checked:after {
    background-image: url(/static/media/check-registration.47c7d3f4.svg);
    background-repeat: no-repeat;
    background-position: 50%;
}
.regular-checkbox:checked:after {
    content: "";
    font-size: 14px;
    position: absolute;
    top: 0;
    left: 3px;
}
#gigya-terms-and-conditions-form .my-account-btn {
    box-shadow: none !important;
    border: $border__gray-light;
    border-radius: $radius-xs;
    padding: $space-standard;
    cursor: pointer;
  }
  
  #gigya-terms-and-conditions-form .my-account-btn:first-child {
    border-bottom: 0!important;
  }
  
  #gigya-terms-and-conditions-form .my-account-btn:focus {
    border: $outline__normal !important;
  }
  
  #gigya-terms-and-conditions-form .arrow {
    width: $space-md;
    height: $space-standard;
    background-image: url("/images/icons/arrow-right.svg");
    background-size: 100%;
    background-repeat: no-repeat;
    background-position: 50%,middle;
  }
/* Login page css End */



/* POPDG-Web-MyAccount Css Start */

.banner-label {
    padding: 20px;        
}
.border-account {
    border-style: solid;
}
.save-button {
    width: 100%;
    background-color: $color__primary;
    border: none;
    color: white;
    padding: 15px 32px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    margin: 4px 2px;
    cursor: pointer;
}
.bottom-border {
    border-bottom: 1px solid gray;
    width: 100%;
}

.main-container {
    max-width: 450px;
    font-size: 17px;
}

.checkbox-preferences{
    position: absolute;
    right: 50px;
    top: 15px;
}

// THE CLASS CONTAINE CAN'T BE TARGETED

// .container {
//     display: block;
//     position: relative;
//     padding: 20px;
//     cursor: pointer;
//     -webkit-user-select: none;
//     -moz-user-select: none;
//     -ms-user-select: none;
//     user-select: none;
//     border-bottom: 1px solid gray;
//   }
  
  /* Hide the browser's default checkbox */
  .container .default input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
  }

// Please use a class wrapper for this checkmark class because is breaking the css for the registration

  /* Custom checkbox */
// .checkmark {
//     position: absolute;
//     top: 22px;
//     right: 22px;
//     height: 25px;
//     width: 25px;
//     border: 1px solid;
//     background-color: #eee;
//   }

  /* Custom radio */
  .radio-checkmark {
    position: absolute;
    top: 22px;
    right: 22px;
    height: 25px;
    width: 25px;
    border: 1px solid;
    border-radius: 16px;
    background-color: #eee;
  }

  /* When the radio is checked, add a blue background */
  .container input:checked ~ .radio-checkmark {
    background-color:  #87189d;
  }
  
  /* When the checkbox is checked, add a blue background */
  .container input:checked ~ .checkmark {
    background-color:  #87189d;
  }
  
  /* Create the checkmark/indicator (hidden when not checked) */
  .checkmark:after {
    content: "";
    position: absolute;
    display: none;
  }
  
  /* Create the radio/indicator (hidden when not checked) */
  .radio-checkmark:after {
    content: "";
    position: absolute;
    display: none;
  }

  /* Show the checkmark when checked */
  .container input:checked ~ .checkmark:after {
    display: block;
  }

  /* Show the radio when checked */
  .container input:checked ~ .radio-checkmark:after {
    display: block;
  }
  
  /* Style the checkmark/indicator */
  .container .checkmark:after {
    left: 8px;
    top: 3px;
    width: 8px;
    height: 14px;
    border: solid white;
    border-width: 0 3px 3px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
  }
.edit-info{
    font-size: 15px;
    font-weight: bold;
    color: var(--ion-color-primary,#87189d);
    text-decoration: underline;
}
/* POPDG-Web-MyAccount Css Ends*/

/* POPDG-Web-MyInformation Css Ends*/
.text-font {
    border: 0px !important;
    font-size: 18px;
    font-weight: bold;
    color: black;
}
.edit-mode {
    box-shadow: 0px 0px 0px 0px rgba(0,0,0,0) !important;
}
/* POPDG-Web-MyInformation Css Ends*/

// Terms and Conditions Form
#gigya-terms-and-conditions-form {
    .email-checkmark {
        display: flex;
        input {
            margin-top: $space-xxxs;
        }
        label {
            line-height: normal;
        }
    }
}

/* Wrapper for all auth pages */
.auth-page {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    height: 100%;
    margin-top: $space-lg;

    &--has-title {
        margin-top: 0;
    }

    &__container {
        position: relative;
        padding: 0 $space-standard $space-lg;
        @include page-container(sm);
    }
  
    &__logo {
        text-align: center;
        margin-bottom: $space-sm;
    }
}