

@media (max-width: 576px) {
  .need-having-trouble-modal .modal-wrapper{
    width: 100% !important;
    height:100% !important;
  }
  .pop-match-toggle{
    margin-right:0px;
}

.show-modal{
  background-color:rgba(0, 0, 0, 0.233);
  }

  ion-back-button.back.md.button.back-button-has-icon-only.in-toolbar.ion-activatable.ion-focusable.hydrated {
    display:initial;
  }
}

@media (min-width: 768px) { 

  .how-it-works-modal {
    .modal-wrapper {
      height: 650px;
      .ion-page {
        overflow: scroll;
      }
    }
  }

  .show-modal{
    background-color:rgba(0, 0, 0, 0.233);
    }

}

@media (min-width: 992px) { 

  .need-having-trouble-modal .modal-wrapper{
    width: 340px;
    height:340px;
  }

 }

@media (min-width: 1200px) {

  .need-having-trouble-modal .modal-wrapper{
    width: 340px;
    height:340px;
  }

  .icon-grid, .icon-list{
    display: none;

  }

 }

 @media (max-width: 667px) {
  .how-it-works-modal {
    .modal-wrapper {
      height: 100%;
      .ion-page {
        overflow: scroll;
      }
    }
  }
 }

 @media (max-width: 736px) {
  .how-it-works-modal {
    .modal-wrapper {
      height: 100% !important;
      .ion-page {
        overflow: scroll;
      }
    }
  }
 }

 @media (max-width: 812px) {
  .how-it-works-modal {
    .modal-wrapper {
      height: 100%;
      .ion-page {
        overflow: scroll;
      }
    }
  }
 }