.filters {
    display: flex;
    flex-direction: column;
    height: 100%;

    .btn__primary--no-outline {
        margin-right: calc($space-xxs + $space-xxxs);
    }
    &__clear {
        font-size: $text__body-sm;
        font-weight: bold;
        background-color: $color__white;
        color: $color__primary;
        &:hover {
            color: $color__primary-dark;
        }
    }

    ion-header {
        ion-toolbar {
            font-weight: bold;
            font-family: sofia-pro, sans-serif !important;
            div {
                display: flex;
                flex-direction: row;
                justify-content: space-between;
                align-items: center;
                p {
                    color: $color__gray-order;
                    display: inline;
                }
            }
        }
    }
    ion-content {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        flex-grow: 1;
    }
    ion-footer {
        padding: 0 $space-standard;
        margin: $space-standard 0;
        div {
            display: flex;
            justify-content: center;
            button {
                position: sticky;
                width: 90%;
                bottom: 0; 
                &.loading {
                    text-align: center;
                }
            }
        }

        &:before {
            display: none;
        }
    }
    ion-label {
        text-transform: capitalize;
        font-family: sofia-pro, sans-serif !important;
        font-weight: 900;
    }
    ion-list {
        cursor: pointer;
    }
    &__item {
        margin-bottom: $space-standard;
        padding-left: $space-md;
        font-weight: bold;
    }
    &__button {
        width: calc($space-xxxxl + $space-sm);
        height: $space-md;
        border: 1.5px solid $color__primary;
        border-radius: $space-xxxs;
        p {
            color: $color__primary;
            font-weight: bold;
            letter-spacing: 0;
            text-transform: uppercase;
            font-size: $text__button-sm;
            padding-bottom: 1px;
        }
        &-bg {
            background-color: $color__primary;
            p {
                color: $color__white;
            }
        }
    }
}