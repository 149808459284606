.unauth-order-details {
    margin-bottom: $space-md;
    padding: $space-standard;
    box-shadow: $shadow__order-details;
    border-radius: $radius-xxs;
    max-width: 100%;
    @include breakpoint(lg) {
        max-width: 460px;
        margin-top: 0px;;
    }
    h1 {
        font-size: $text__body-md;
        font-weight: bold;
        padding-bottom: $space-standard;
    }
    h2 {
        padding-bottom: $space-md;
        color: $color__gray-darkest;
        line-height: $line-height-lg;

    }

    .input-wrapper {
        margin-bottom: $space-standard;
    }
    label {
        p {
            color: $color__gray-light;
            display: inline;
            font-size: $text__body-sm;
            font-weight: normal;
        }
    }

    p {
        color: $color__red;
        margin-top: $space-standard;
    }

    button {
        text-transform: uppercase;
        background-color: $color__primary;
        color: $color__white;
        transition: all 0.2s;
        font-size: $text__body-sm;
        font-weight: bold;
        width: 100%;
        background-color: $color__primary;
        color: white;
        padding: $space-standard;
        border-radius: $radius-xs;
        margin-bottom: $space-sm;
        &:hover, &:focus {
            background-color: $color__primary-dark;
        }
        &:disabled {
            border: 1px solid #e6e6e6;
            background-color: #e6e6e6;
            color: #474747;
        }
    }
}