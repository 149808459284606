.widget {
    position: relative;
    margin-bottom: $space-xl;

    a {
        width: 165px;
    }

    &-category, &-item {
        color: $color__primary;
    }

    &__heading {
        font-size: $text__body-lg;
        text-align-last: left;
        margin-top: 10px;
        &:first-child {
            margin-top: 20px;
        }
        margin-bottom: $space-sm;
        font-weight: bold;
    }

    &__related {
        &-list {
            text-align-last: left;
            button {
                font-weight: 800;
                width: auto;
                height: $space-xl;
                border: $outline__heavy;
                background-color: #ffffff;
                border-radius: $space-xs;
                margin-right: 10px;
                margin-bottom: 10px;
                text-transform: capitalize;
            }
            a {
                color: $color__primary;
                font-size: $text__body-sm;
                font-weight: bold;
                letter-spacing: 0.16px;
                line-height: $line-height-xxl;
            }
        }
    }

    &__products {
        &-desktop {
            display: none;
            @include breakpoint(lg) {
                display: flex;
            }
             a {
                .mini-product-card {
                    margin-right: $space-standard;

                }
            } 
        }
    }

    .pop-card-product {
        width: 175px;
        margin-right: 10px;
    }

    .swiper {
        &-slide {
          width: auto !important;
        }

        &__section {
            @include breakpoint(lg) {
                display: none;
            }
        }
    }
}
