
.news-contact{
    padding: 0 $space-standard;
    @include breakpoint(lg) {
        padding: 0;
    }
    h1 {
        font-weight: bold;
        font-size: 20px;
    }

    h1, p {
        margin-bottom: $space-xxs;
    }

    p, a {
        font-size: $space-standard;
        font-weight: normal;
    }

    div {
        margin-bottom: $space-md;
    }
}
