.edit-my-information {
    .auth-page__container {
        display: flex;
        flex-direction: column;
        margin: 0 auto;
        padding-bottom: $space-xl;
        input {
            margin-bottom: $space-sm;
        }
        button {
            max-width: 100%;
            margin-top: $space-md;
        }
        div {
            p {
                margin-top: $space-standard;
                color: $color__red;
                text-align: center;
            }
        }

    }
}