.ref-effect {
    cursor: pointer;
    z-index: 1;
}

.widget-title {
    h1 {
        text-align: center;
        font-size: $text__body-base;
        color: $color__black;
        font-weight: bold;
        letter-spacing: $letter__spacing-none;
        line-height: $line-height-lg; /* 125% */
        text-transform: uppercase;
        white-space: nowrap;
    }
}

.widget-section{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: $space-xxxs;
    width: 100%;
    height: 100%;
    margin: 0px;
}

.widget-bin {
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: center;
    gap: $space-xs;

    h1 {
        color: $color__black-light;
        text-align: center;
        font-size: $text__body-sm;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        white-space: nowrap;
    }

    &__location {
        display: flex;
        padding: $space-xxxs $space-standard;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: $space-xxxs;
        border-radius: $radius-sm+$radius-xxs;
        background: $color__primary;

        @include breakpoint-max(xs) {
            padding: $space-xs $space-md;
            border-radius: $radius-sm

        }
    }

    span#bin-number {
        color: $color__white;
        text-align: center;
        font-size: $text__body-base;
        font-style: normal;
        font-weight: 400;
        line-height: $line-height-lg; /* 125% */
    }

}

.widget-progress-bar{
    height: $space-standard;
    width: 100%;
    display: flex;
    gap: $space-xxxs;
    align-self: stretch;
    align-items: center;

    &__widget-dot {
        &-start {
            &:before {
                content: "";
                @include center(false, true);
                left: 0;
                @include z-index(primary);
                width: $space-standard;
                height: $space-standard;
                flex-shrink: 0;
                border-radius: $radius-md;
                background: $color__primary;
                box-shadow: 0px 2px 8px 0px rgba(55, 58, 64, 0.25);
                display: inline-block;
                transform: translateX(-66%, -50%);
            }
        }

        &-end {
            &:after {
                content: "";
                @include center(false, true);
                right: 0;
                @include z-index(primary);
                width: $space-standard;
                height: $space-standard;
                flex-shrink: 0;
                border-radius: $radius-md;
                background: $color__primary;
                box-shadow: 0px 2px 8px 0px rgba(55, 58, 64, 0.25);
                display: inline-block;
                transform: translate(66%, -50%);
            }
        }

        &-start {
            &-green {
                &:before {
                    content: "";
                    @include center(false, true);
                    left: 0;
                    @include z-index(primary);
                    width: $space-standard;
                    height: $space-standard;
                    flex-shrink: 0;
                    border-radius: $radius-md;
                    background: $color__primary;
                    box-shadow: 0px 2px 8px 0px rgba(55, 58, 64, 0.25);
                    display: inline-block;
                    transform: translateX(-66%, -50%);
                    background-color: $color__green-dark;
                }
            }
        }

        &-end {
            &-green {
                &:after {
                    content: "";
                    @include center(false, true);
                    right: 0;
                    @include z-index(primary);
                    width: $space-standard;
                    height: $space-standard;
                    flex-shrink: 0;
                    border-radius: $radius-md;
                    background: $color__primary;
                    box-shadow: 0px 2px 8px 0px rgba(55, 58, 64, 0.25);
                    display: inline-block;
                    transform: translate(66%, -50%);
                    background-color: $color__green-dark;
                }
            }
        }
    }

    &__widget-step-complete {
        position: relative;
        display: inline-block;
        height: $space-xxxs;
        width: 100%;
        flex: 1 0 0;
        flex-grow: 1;
        flex-basis: 0;
        min-width: 0;
        border: 1px hidden;
        border-radius: $radius-xs;
        background-color: $progress-bar__bar-color;

        &-green {
            position: relative;
            display: inline-block;
            height: $space-xxxs;
            width: 32%;
            flex: 1 0 0;
            flex-grow: 1;
            flex-basis: 0;
            min-width: 0;
            border: 1px hidden;
            border-radius: $radius-xs;
            background-color: $color__green;
        }
    }

    &__widget-step-incomplete {
        position: relative;
        display: inline-block;
        height: $space-xxxs;
        width: 100%;
        flex: 1 0 0;
        flex-grow: 1;
        flex-basis: 0;
        min-width: 0;
        border: 1px hidden;
        border-radius: $radius-xs;
        background-color: $color__gray-progress-bar;
    }

    &__widget-step-cancelled {
        position: relative;
        display: inline-block;
        height: $space-xxxs;
        width: 100%;
        flex: 1 0 0;
        flex-grow: 1;
        flex-basis: 0;
        min-width: 0;
        border: 1px hidden;
        border-radius: $radius-xs;
        background-color: $color__red;
    }

}

.progress-bar {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: $space-md;
    position: relative;

    &__title {
        p {
            color: $color__black-light;
            font-size: $text__body-base;
            letter-spacing: 0;
            line-height: $line-height-xl;
        }

        h1 {
            color: $color__black;
            font-size: $text__body-xxl;
            font-weight: bold;
            letter-spacing: 0;
            line-height: $line-height-xxl;
            margin-bottom: $space-sm;
        }

        &-arrival {
            p {
                padding-bottom: $space-sm;
            }
        }

        &-description {
            color: $color__black;
            margin-top: $space-sm;
            font-size: $text__body-sm;
            letter-spacing: 0;
            line-height: $line-height-lg;
        }
    }

    &__bin {
        display: flex;
        flex-direction: column;
        align-items: center;

        h3 {
            color: $color__black-light;
            font-size: $text__body-base;
            font-weight: bold;
            letter-spacing: 0;
            line-height: $line-height-xl;
            margin-bottom: $space-xs;
        }

        p {
            color: $color__black;
            font-size: $text__body-sm;
            letter-spacing: 0;
            line-height: $line-height-lg;
        }

        &-text {
            padding-bottom: $space-md ;
        }

        &-location {
            height: 44px;
            border-radius: $radius-xxs;
            background-color: $color__primary;
            padding: $space-xxxs $space-xs;
            margin-bottom: $space-xs;

            p {
                text-align: center;
                color: $color__white;
                font-size: $text__body-xxs;
                font-weight: normal;
                letter-spacing: 0;
                line-height: $line-height-xs;
            }

            h2 {
                text-align: center;
                color: $color__white;
                font-size: $text__body-md;
                font-weight: bold;
                letter-spacing: 0;
                line-height: $line-height-xxl;
            }
        }
    }

    &__QR {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: $space-standard 0;
        border-top: $border__order-details;
        margin-top: $space-standard;

        p {
            font-size: $text__body-base;
            font-weight: $font-weight__light;
            letter-spacing: 0;
            line-height: $line-height-xl;
            margin-right: $space-md;
        }
    }

    &__dot {
        &-start {
            &:before {
                content: "";
                @include center(false, true);
                left: 0;
                @include z-index(primary);
                height: $progress-bar__dot;
                width: $progress-bar__dot;
                background-color: $color__primary;
                border-radius: $radius-round;
                display: inline-block;
                transform: translateX(-66%, -50%);
            }
        }

        &-end {
            &:after {
                content: "";
                @include center(false, true);
                right: 0;
                @include z-index(primary);
                height: $progress-bar__dot;
                width: $progress-bar__dot;
                background-color: $color__primary;
                border-radius: $radius-round;
                display: inline-block;
                transform: translate(66%, -50%);
            }
        }

        &-start {
            &-green {
                &:before {
                    content: "";
                    @include center(false, true);
                    left: 0;
                    @include z-index(primary);
                    height: $progress-bar__dot;
                    width: $progress-bar__dot;
                    background-color: $color__primary;
                    border-radius: $radius-round;
                    display: inline-block;
                    transform: translateX(-66%, -50%);
                    background-color: $color__green-dark;
                }
            }
        }

        &-end {
            &-green {
                &:after {
                    content: "";
                    @include center(false, true);
                    right: 0;
                    @include z-index(primary);
                    height: $progress-bar__dot;
                    width: $progress-bar__dot;
                    background-color: $color__primary;
                    border-radius: $radius-round;
                    display: inline-block;
                    transform: translate(66%, -50%);
                    background-color: $color__green-dark;
                }
            }
        }
    }

    &__step {
        display: flex;
        position: relative;

        &-complete {
            position: relative;
            display: inline-block;
            height: $progress-bar__bar;
            flex-grow: 1;
            flex-basis: 0;
            min-width: 0;
            border: 1px hidden;
            border-radius: $radius-sm;
            margin-right: $space-xxs;
            background-color: $progress-bar__bar-color;

            &-green {
                position: relative;
                display: inline-block;
                height: $progress-bar__bar;
                flex-grow: 1;
                flex-basis: 0;
                min-width: 0;
                border: 1px hidden;
                border-radius: $radius-sm;
                margin-right: $space-xxs;
                background-color: $color__green;
            }
        }

        &-incomplete {
            position: relative;
            display: inline-block;
            height: $progress-bar__bar;
            flex-grow: 1;
            flex-basis: 0;
            min-width: 0;
            border: 1px hidden;
            border-radius: $radius-sm;
            margin-right: $space-xxs;
            background-color: $color__gray-progress-bar;
        }

        &-cancelled {
            display: inline-block;
            height: $progress-bar__bar;
            flex-grow: 1;
            flex-basis: 0;
            min-width: 0;
            border: 1px hidden;
            border-radius: $radius-sm;
            margin-right: $space-xxs;
            background-color: $color__red;
        }
    }
}