.bg-white {
    background-color: white;
    border-radius: 0px 10px 10px 0px;
}
.home-decor-section {
    padding: 20px;
    margin-left: -30px;
    width: fit-content;
    float: left;
    padding-right: 100px;
}
.color-img {
    background-color: gray;
    border-radius: 10px;
    height: 300px;
}

.hero-banner-height {
    height: inherit;
}

@media (max-width: 767px) {
    .color-img {
        height: 250px;
    }
    .home-decor-section {
        float: none;
        border-radius: 0px 0px 10px 10px !important;
    }
    .pop-button-primary__hero {
        float: right;
        margin-right: -80px;
        margin-top: -160px;
    }
}

@media (max-width: 575px) {
    .color-img {
        margin-left: 20px;
        margin-right: 20px;
    }
    .pop-button-primary__hero {
        width: 140px !important;
    }
}
