.aem-content {
    &__category-group {
        @include breakpoint(lg) {
            margin: 0;
            margin-bottom: $space-md;
        }

        @include z-index(base);

        h6 {
            font-size: $text__body-lg;
            font-weight: bold;
            text-align: center;
            margin-bottom: $space-standard;
            text-transform: lowercase;

            @include breakpoint-max(lg) {
                text-align: left;
            }
        }
    }

    &__content {
        @include page-container(lg);

        &-group {
            margin-bottom: $space-md;

            @include breakpoint(lg) {
                margin: 0;
                margin-bottom: $space-xl;
            }

            @include z-index(base);

            h2 {
                width: 100%;
                font-weight: bold;
                text-align: left;
                margin-bottom: $space-standard;
                text-transform: lowercase;
                font-size: $text__body-lg;

                @include breakpoint(sm) {
                    margin-left: 0;
                    text-align: center;
                }
            }

            &--desktop {
                @include breakpoint-max (sm) {
                    display: none;
                }
            }

            &--mobile {
                @include breakpoint (sm) {
                    display: none;
                }
            }
        }

        &-categories {
            margin-top: $space-sm;
            margin-bottom: $space-md;
            display: grid;
            grid-template-columns: repeat(auto-fit, minmax(144px, max-content));
            justify-content: center;
            gap: 0 $space-standard;

            &-button {
                height: 52px;
                display: flex;
                justify-content: center;
                margin-top: $space-standard;
            }

            &-link {
                @extend .btn__primary;
                padding: $space-standard $space-xxl;
                line-height: $line-height-sm;

                &:hover {
                    color: $color__white;
                }
            }

            @include breakpoint(lg) {
                margin-top: $space-lg;
            }
        }
    }
}

.swiper {
    @include breakpoint(lg) {
        margin: 0 !important;
    }

    &-slide {
        width: auto;
    }
}
