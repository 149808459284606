.product-details {
    &__wrapper {
        padding: $space-standard 0;
        @include page-container(lg);
    }

    &__container {
        display: flex;
        flex-direction: column;

        @include breakpoint(md) {
            flex-direction: row;
        }
    }

    &__images {
        width: 100%;
        margin-bottom: $space-standard;

        @include breakpoint(md) {
            width: 66%;
            flex-shrink: 0;
            margin-bottom: 0;
            margin-right: $space-standard;
        }
    }

    &__info {
        width: 100%;

        @include breakpoint(md) {
            width: 34%;
        }

        h1 {
            text-align: left;
            font-size: 16px;
            margin-bottom: $space-xxs;
            font-weight: normal;
        }

        &-reviews {
            display: flex;
            justify-content: flex-start;
            width: 100%;
            height: $space-md;
            margin-bottom: $space-standard;

            .product-page {
                .p-w-r .pr-snippet-read-and-write {
                    display: none !important;
                }
            }
        }

        &-price {
            display: flex;
            justify-content: space-between;
            width: 100%;

            p {
                font-weight: bold;
            }
        }
    }

    &__extra-info {
        width: 100%;

        @include breakpoint(md) {
            width: 75%;
            margin: auto;
        }
    }
}