.breadcrumb {
  @media (max-width: 576px) {
    display: none;
  }
  &__element {
    display: flex;
    padding-right: 10px;
    font-size: 14px;
    font-weight: bold;
    line-height: 18px;
    color: $color__gray !important;
    float: left;
  }

  &__separator {
    font-size: 12px;
    color: $color__gray !important;
    padding-left: 10px;
  }

  &__label {
    color: $color__gray !important;
    cursor: pointer;
    font-size: 13px;
  }
}

.nav__breadcrumb{
  width: 100%;
  display: none;
  flex-wrap: wrap;
  @include breakpoint(lg) {
    margin: $space-standard 0;
    display: flex;
  }
}
