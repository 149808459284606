.reward-pointer-container {
    min-width: $space-xxxxl + $space-xs;
    height: $space-xxxxl + $space-xs;
    border-radius: $radius-round;
    box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.5);
    position: relative;
    margin-top: $space-xxs;

    &__inner-circle {
        position: relative;
        width: $space-xxl + $space-xxs;
        height: $space-xxl + $space-xxs;
        border-radius: $radius-round;
        background: $color__white;
        top: 13%;
        left: 13%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

            span {
                font-size: $line-height-xxxl;
                font-weight: bold;
                line-height: $line-height-xxxl;
            }

            p {
                font-size: $letter__spacing-xl + $letter__spacing-xl;
                font-weight: bold;
                line-height: $letter__spacing-xl + $letter__spacing-xl;
                color: $color__gray-order;
            }
        }
}