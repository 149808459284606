.howtoearn {
  .howtoearn-header {
    background-color: $color__primary;
    text-align: center;
    width: 100%;
    display: block;
    padding-top: $space-sm;
    padding-bottom: $space-standard;
    text-transform: lowercase;
  
    h1 {
      font-size: $text__body-lg;
      line-height: $line-height-xxl;
      font-weight: bold;
      color: $color__white;
      margin: 0;
    }
  }
  .howtoearn-page {
    padding: 0;
    @include page-container(lg);
  
    .howtoearn-container {
      display: flex;
      justify-content: center;
      align-items: flex-start;
      align-content: flex-start;
      gap: $space-xl;
      align-self: stretch;
      flex-wrap: wrap;
    }
  
    .sign-container {
      background: linear-gradient(180deg, #FFF 32%, #E5D4ED 100%);
      width: 100%;
      display: flex;
      padding: $space-md;
      justify-content: center;
      align-items: center;
      gap: $space-standard;
  
      @media (max-width: 576px) {
        flex-direction: column;
        gap: $space-xs;
  
        h1 {
          color: $color__black-light;
          margin-left: $space-xl;
        }
      }
    }
  
    @media (max-width: 576px) {
      .howtoearn-container span:nth-child(1) {
        transform: translateX(-108px);
      }
  
      .howtoearn-container span:nth-child(2) {
        transform: translateX(80px);
      }
  
      .howtoearn-container span:nth-child(3) {
        height: auto;
        width: auto;
      }
  
      .howtoearn-container span:nth-child(4) {
        transform: translateX(-98px);
      }
    }
  
    .howtoearn-title {
      font-size: $space-xl;
      font-weight: 400;
      line-height: $space-xl;
      text-align: center;
      padding-bottom: $space-xl;

      svg {
        height: $space-xxxl;
        width: 205px;
        margin-top: $space-md;
        margin-bottom: $space-xl;
      }
    }
  
    .shop-now {
      padding-top: $space-xl;
      padding-bottom: $space-xl;
      text-align: center;
    }

    .faq-subtitle h3 {
      font-size: $text__body-xxl;
      font-weight: 400;
      line-height: $space-xl;
      text-align: center;
      padding-bottom: $space-xl;
    }
  
    .faq-container {
      display: flex;
      align-items: flex-start;
      align-content: flex-start;
      gap: $space-md;
      align-self: stretch;
      flex-wrap: wrap;
      margin-bottom: $space-md;
  
      @media (max-width: 576px) {
        flex-direction: column;
      }
    }
  
    .faq-item {
      flex: 45%;
  
      @media (max-width: 576px) {
        flex: 0 0 100%;
      }
    }
  
    .faq-item h3 {
      font-size: $text__body-base;
      font-weight: 800;
      line-height: $line-height-base;
    }
  
    .faq-item p {
      font-size: $text__body-sm;
      line-height: $line-height-sm;
      margin-top: $space-xs;
      padding-left: $space-standard;
      margin-bottom: $space-standard;
    }
  }
}