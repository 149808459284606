.profile {
    &__container {
        display: flex;
        justify-content: center;
        &__items {
            display: flex;
            flex-direction: column;
            width: 50%;
            @include breakpoint(md) {
              max-width: 460px;
          }
            @include breakpoint-max(xs) {
                width: 100%;
                margin: 0 $space-standard;
            }
        }
    }
    .profile-padding {
      padding: 0 0 $space-xl;
    }
    .hero-ribbon {
        background-color: $color__primary;
        text-align: center;
        width: 100%;
        display: block;
        padding-top: $space-sm;
        padding-bottom: $space-standard;
        margin-bottom: $space-lg;
        h1 {
          font-size: $text__body-lg;
          line-height: $line-height-xl;
          font-weight: bold;
          color: $color__white;
          margin: $space-xxxxs;
        }
      }

    .profile-btn {
      display: flex;
      box-shadow: $shadow__profile-button !important;
      cursor: pointer;
      svg {
          margin: auto;
      }
      background-color: $color__white;
      width: 100%;
      text-align: left;
      padding: $space-sm;
      vertical-align: middle;
      -webkit-border-radius: $radius-xs;
      -moz-border-radius: $radius-xs;
      border-radius: $radius-xs;
      margin-bottom: $space-standard;
      position: relative;
      align-items: center;
  } 
  .profile-btn__arrow {
    margin-left: auto;
    margin-right: $space-sm;
    cursor: pointer;
  }
  .profile-btn__label {
    font-size: $text__body-base;
    font-weight: bold;
    display: inline-block;
    line-height: normal;
    cursor: pointer;
  }
  .profile-btn__alert {
    margin-left: $space-sm;
  }
  .profile-btn__icon {
      width: $space-lg;
      height: $space-lg;
      background-color: $color__gray-bright;
      display: flex;
      -webkit-border-radius: $radius-xs;
      -moz-border-radius: $radius-xs;
      border-radius: $radius-xs;
      margin-right: $space-standard;
      background-repeat: no-repeat;
      background-position: center;
      vertical-align: middle;
      cursor: pointer;
  }
}