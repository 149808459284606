.locations {
    @include page-container(lg);

    &__heading {
        h1 {
            color: $color__primary;
            font-size: $text__body-xxl;
            margin-top: $space-md;
            margin-bottom: $space-md;
        }

        .breadcrumb__separator {
            text-transform: uppercase;
            padding: 0 $space-xs;
        }

        .breadcrumb__element {
            padding-right: $space-xs;
            font-size: $text__body-xs;
            float: none;

            span {
                color: $color__gray  !important;
                font-size: $space-sm;
                cursor: pointer;
            }
        }

        .nav__breadcrumb {
            width: 100%;
            flex-wrap: wrap;
            margin: $space-standard 0;
            display: flex;
            font-size: $text__body-xs;
        }

        &-nav {

            a,
            p {
                color: $color__gray;
            }

            a:hover {
                color: $color__primary;
            }

            a:focus {
                outline: $outline__normal;
                color: $color__primary;
            }
        }
    }

    &__list {
        margin-bottom: $space-md;
        display: flex;
        flex-wrap: wrap;

        &-item {
            margin: 0 0 $space-sm;
            flex: 0 0 50%;

            @include breakpoint(sm) {
                margin: 0 0 $space-standard;
                flex: 0 0 33%;
            }

            @include breakpoint(lg) {
                flex: 0 0 25%;
            }

            a {
                font-size: $text__body-base;
                color: $color__black;

                &:focus,
                &:hover {
                    color: $color__primary-dark;
                }
            }
        }
    }

    &-list {
        @include page-container(lg);
        padding-bottom: $space-standard;

        &__wrap {
            padding: $space-sm 0;
            border-bottom: 0.5px solid $color__input-border;
            display: flex;
            justify-content: space-between;

            &__store-details {
                position: relative;
                width: 100%;
                padding-right: $space-standard;
                flex: 60%;
                max-width: 60%;

                &-address {
                    font-size: $text__body-base;
                    font-weight: bold;
                    color: $color__black;
                    line-height: $line-height-lg;
                }

                &-state {
                    font-size: $text__body-sm;
                    font-weight: bold;
                    color: $color__gray-order;
                }
            }

            .btn-store-details {
                color: $color__primary;
                font-size: $text__button-sm;
                font-weight: bold;
            }
        }

        &__btn-view-details {
            margin-top: $space-xs;
            font-size: $text__body-sm;
            max-width: 100%;
            height: $space-xl;
            width: 100%;
            border-radius: $radius-xs;
            font-weight: bold;
            letter-spacing: $letter__spacing-sm;
            text-align: center;
            padding: 0 $space-md;
            color: $color__primary;
            transition: all 0.2s;
            border: $outline__normal;
        }
    }
}