
.thematic-page {
    min-height: calc(100vh - 334px) !important;
    display: flex;
    flex-direction: column;
    align-items: center;

    &__content{
        width: 100%;
        padding-right: $space-standard;
        padding-left: $space-standard;
        margin-right: auto;
        margin-left: auto;
        max-width: 1140px;
        &__title-spacing{
            display: flex;
            flex-wrap: wrap;
            margin: 0px;
            @include breakpoint-max(xs) {
                margin-top: 0px;
                margin-bottom: 0px;
                margin-left: -$space-standard;
                margin-right: -$space-standard;
            }

            nav {
                margin: $space-standard 0;
            }
        }

        &__selectors {  
            align-items: center;  
            width: 100%;
            margin-bottom: $space-md;
            @include breakpoint(xs) {
                display: flex;
                justify-content: space-between;
            }
            &-container {
                display: flex;
                justify-content: space-between;
            }
            &-grid {
                @include breakpoint(xs) {
                    display: none;
                }
            }
        }

        &__view-more-container{
            margin-bottom: $space-standard;
            
            &__view-more-btn{
                display: flex;
                flex-wrap: wrap;
                margin-right: -$space-standard;
                margin-left: -$space-standard;
                justify-content: center;

                &__width{
                    flex: 33%;
                    max-width: 100%;
                    text-align: center;
                    padding: 0 $space-standard;
                    @include breakpoint(xs) {
                        max-width: 33%;
                    }

                    .btn__secondary {
                        max-width: 100%;
                    }
                }
            }
        }

        &__card-container{
            flex-wrap: wrap;
            display: flex;
            margin-right: -$space-standard;
            margin-left: -$space-standard;
        }
    }
}