
.history-page {
  background-image: url(/images/history-bg.png);
  min-height: 100%;
  background-size: 107%;
  .pointshistory__title {
    background-color: $color__primary;
    text-align: center;
    width: 100%;
    display: block;
    padding-top: $space-sm;
    padding-bottom: $space-standard;
    text-transform: lowercase;
  
    h1 {
      font-size: $text__body-lg;
      line-height: $line-height-xxl;
      font-weight: bold;
      color: $color__white;
      margin: 0;
    }
  }

  .pointshistory {
    display: flex;
    justify-content: center;
    align-items: start;
    padding: $space-standard;
  
    &__container {
      display: flex;
      flex-direction: column;
      gap: $space-standard;
  
      &-title {
        p {
          font-size: $text__body-xs;
          line-height: $line-height-xs;
          color: $color__primary;
        }
      }
      &-card {
        width: 428px;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        box-shadow: 0px 3px 10px 0px #373a4026;
        padding: $space-sm $space-xs;
        border-radius: $radius-sm;
  
        @include breakpoint-max(sm) {
          width: 343px;
        }
  
        &-details {
          display: flex;
          flex-direction: column;
          gap: $space-xxxs;
  
          &-heading {
            font-size: $text__body-lg;
            font-weight: bold;
            line-height: $line-height-lg;
            color: $color__black;
          }
          p {
            span {
              font-size: $text__body-base;
              line-height: $line-height-base;
              color: $color__gray-order;
            }
          }
          &-heading-earned {
            color: $color__primary;
          }
        }
      }
  
      &-card:hover {
        cursor: pointer;
      }
      &-card-background-point {
        background: linear-gradient(
          73.95deg,
          #fff4f4 0%,
          rgba(255, 244, 244, 0) 100%
        );
      }
      &-card-background-certs {
        background: linear-gradient(
          73.95deg,
          #fdf4ff 0%,
          rgba(253, 244, 255, 0) 100%
        );
      }
    }
  }
  
  .history__container {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: $space-xxxxl $space-xxl 250px $space-xxl;
    gap: $space-standard;
    background-image: url(/images/history-bg.png);
    background-repeat: no-repeat;
    background-size: 104% 260%;
  
    &-message {
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: $space-md;
  
      p {
        font-size: $text__body-base;
        line-height: $line-height-xxl;
        color: $color__black-light;
      }
    }
    &-button {
      width: 343px;
      padding: 10px $space-standard;
      border-radius: $radius-sm;
      background-color: $color__primary;
      color: $color__white;
      font-size: $text__body-sm;
      line-height: $line-height-base;
    }
  }
}

