.help-page {
    @include page-container(lg);
    display: flex;
    justify-content: center;

    &__container {
        flex: 0 0 100%;
        max-width: 100%;

        @include breakpoint(xs) {
            flex: 0 0 50%;
            max-width: 50%;
        }
    }

    &__links {
        box-shadow: -1px 4px 10px 6px rgba(0, 0, 0, 0.06);
        border: 1px solid $color__gray-toolbar-border;
        -webkit-border-radius: $radius-xs;
        -moz-border-radius: $radius-xs;
        border-radius: $radius-xs;
        margin-bottom: $space-xl;

        p {
            padding: $space-standard;
            border-bottom: 1px solid $color__gray-toolbar-border;
            margin-bottom: 0px !important;

            a:hover {
                cursor: pointer;
            }
        }
    }

    a {
        text-decoration: underline;
    }

    p {
        margin-bottom: $space-standard;

        strong {
            font-weight: bold;
        }

        a {
            font-size: $text__body-base;
        }
    }

    h2 {
        font-size: $text__body-lg;
        font-weight: bold;
        margin-bottom: $space-md;
    }
}