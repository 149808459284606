/**SCSS Variables**/

//Colors
$color__primary: #87189D;
$color__primary-dark: #540265;
$color__primary-light: #DBBAE2;

//Background Colors
$color__background-purple: #F9F2FA;

//Input Fields
$color__input-bg: rgb(232, 240, 254);
$color__input-border: #ccc;
$color__input-disabled: #e7e7e7;
$color__input-disabled-text: #4a4a4a;

//Contrast Colors
$color__red: #EB0000;
$color__blue: #007DB3;
$color__light-blue: #daecf7;
$color__gray-toolbar: #ffffff;
$color__gray-toolbar-border: #e7e7e7;
$color__gray-recently-viewed: #1F1F1F;
$color__gray-order: #636363;
$color__gray-progress-bar: #D8D8D8;
$color__gray-disabled: #c8c7cc;
$color__gray-lightest-border: #E3E3E3;
$color__gray-lightest: #e6e6e6;
$color__gray-lighter: #f0f0f0;
$color__gray-light: #949494;
$color__gray: #757575;
$color__gray-dark: #666;
$color__gray-darker: #444;
$color__gray-darkest: #333;
$color__gray-bright: #efefef;
$color__gray-brighter: #515151;
$color__orange: #d66a0d;
$color__white: #FFF;
$color__off-white: #D8D8D8;
$color__black-light: #1F1F1F;
$color__black: #000;
$color__green: #64BC16;
$color__green-dark: #428C00;
$color__green-light: #008A25;


//Outlines and Borders
$outline__normal: 1px solid #87189D;
$outline__heavy: 2px solid #87189D;
$outline__white: 1px solid white;
$outline__white-heavy: 2px solid white;
$outline__black: 1px solid black;
$outline__black-heavy: 2px solid black;
$outline__circle-image: 1px solid #E5E5E5;
$border__gray-lighter: 1px solid #f0f0f0;
$border__gray-light: 1px solid #949494;
$border__gray: 1px solid #c8c7cc;
$border__gray-dark: 1px solid #c9c9c9;
$border__order-details: 1px solid #97979733;
$border__purple: 2px solid #87189d;

//Spacing
$space-xxxxs: 2px;
$space-xxxs: 4px;
$space-xxs: 6px;
$space-xs: 8px;
$space-sm: 12px;
$space-standard: 16px;
$space-md: 24px;
$space-lg: 32px;
$space-xl: 40px;
$space-xxl: 60px;
$space-xxxl: 72px;
$space-xxxxl: 80px;

//Page Spacing
$space-page-gutters: $space-standard;
$space-max-width-xl: 1440px + (2 * $space-page-gutters);
$space-max-width: 1140px + (2 * $space-page-gutters);
$space-max-width-lg: 1024px + (2 * $space-page-gutters);
$space-max-width-md: 620px + (2 * $space-page-gutters);
$space-max-width-sm: 460px + (2 * $space-page-gutters);

//Default heights
$height-header: 56px;

//Radius
$radius-xxs: 4px;
$radius-xs: 6px;
$radius-sm: 8px;
$radius-md: 16px;
$radius-lg: 40px;
$radius-xl: 60px;
$radius-xxl: 100px;
$radius-round: 50%; // 50%

//Shadow
$shadow__standard: 0 5px 25px 1px rgba(55, 58, 64, 0.14);
$shadow__order-details: 0 2px 10px 1px rgba(55, 58, 64, 0.14);
$shadow__search-card: 0 0 20px 0 rgba(0, 0, 0, 0.05);
$shadow__profile-button: 0px 0px 20px 0px rgba(0, 0, 0, 0.1);
$shadow__floating-button: 2px 4px 16px 0px rgba(0, 0, 0, 0.15);

//Progress Bar
$progress-bar__dot: 18px;
$progress-bar__bar: 10px;
$progress-bar__bar-color: #AC5DBA;

/* Ionic Variables and Theming. For more info, please see:
http://ionicframework.com/docs/theming/ */

/** Ionic CSS Variables **/
:root {
  /** primary **/
  --ion-color-primary: #87189D;
  --ion-color-primary-rgb: 56, 128, 255;
  --ion-color-primary-contrast: #ffffff;
  --ion-color-primary-contrast-rgb: 255, 255, 255;
  --ion-color-primary-shade: #87189D;
  --ion-color-primary-tint: #87189D;

  /** secondary **/
  --ion-color-secondary: #3dc2ff;
  --ion-color-secondary-rgb: 61, 194, 255;
  --ion-color-secondary-contrast: #ffffff;
  --ion-color-secondary-contrast-rgb: 255, 255, 255;
  --ion-color-secondary-shade: #36abe0;
  --ion-color-secondary-tint: #50c8ff;

  /** tertiary **/
  --ion-color-tertiary: #5260ff;
  --ion-color-tertiary-rgb: 82, 96, 255;
  --ion-color-tertiary-contrast: #ffffff;
  --ion-color-tertiary-contrast-rgb: 255, 255, 255;
  --ion-color-tertiary-shade: #4854e0;
  --ion-color-tertiary-tint: #6370ff;

  /** success **/
  --ion-color-success: #2dd36f;
  --ion-color-success-rgb: 45, 211, 111;
  --ion-color-success-contrast: #ffffff;
  --ion-color-success-contrast-rgb: 255, 255, 255;
  --ion-color-success-shade: #28ba62;
  --ion-color-success-tint: #42d77d;

  /** warning **/
  --ion-color-warning: #ffc409;
  --ion-color-warning-rgb: 255, 196, 9;
  --ion-color-warning-contrast: #000000;
  --ion-color-warning-contrast-rgb: 0, 0, 0;
  --ion-color-warning-shade: #e0ac08;
  --ion-color-warning-tint: #ffca22;

  /** danger **/
  --ion-color-danger: #eb445a;
  --ion-color-danger-rgb: 235, 68, 90;
  --ion-color-danger-contrast: #ffffff;
  --ion-color-danger-contrast-rgb: 255, 255, 255;
  --ion-color-danger-shade: #cf3c4f;
  --ion-color-danger-tint: #ed576b;

  /** dark **/
  --ion-color-dark: #222428;
  --ion-color-dark-rgb: 34, 36, 40;
  --ion-color-dark-contrast: #ffffff;
  --ion-color-dark-contrast-rgb: 255, 255, 255;
  --ion-color-dark-shade: #1e2023;
  --ion-color-dark-tint: #383a3e;

  /** medium **/
  --ion-color-medium: #92949c;
  --ion-color-medium-rgb: 146, 148, 156;
  --ion-color-medium-contrast: #ffffff;
  --ion-color-medium-contrast-rgb: 255, 255, 255;
  --ion-color-medium-shade: #808289;
  --ion-color-medium-tint: #9d9fa6;

  /** light **/
  --ion-color-light: #f4f5f8;
  --ion-color-light-rgb: 244, 245, 248;
  --ion-color-light-contrast: #000000;
  --ion-color-light-contrast-rgb: 0, 0, 0;
  --ion-color-light-shade: #d7d8da;
  --ion-color-light-tint: #f5f6f9;
}

@media (prefers-color-scheme: root) {
  /*
     * Dark Colors
     * -------------------------------------------
     */

  body {
    --ion-color-primary: #428cff;
    --ion-color-primary-rgb: 66, 140, 255;
    --ion-color-primary-contrast: #ffffff;
    --ion-color-primary-contrast-rgb: 255, 255, 255;
    --ion-color-primary-shade: #3a7be0;
    --ion-color-primary-tint: #5598ff;

    --ion-color-secondary: #50c8ff;
    --ion-color-secondary-rgb: 80, 200, 255;
    --ion-color-secondary-contrast: #ffffff;
    --ion-color-secondary-contrast-rgb: 255, 255, 255;
    --ion-color-secondary-shade: #46b0e0;
    --ion-color-secondary-tint: #62ceff;

    --ion-color-tertiary: #6a64ff;
    --ion-color-tertiary-rgb: 106, 100, 255;
    --ion-color-tertiary-contrast: #ffffff;
    --ion-color-tertiary-contrast-rgb: 255, 255, 255;
    --ion-color-tertiary-shade: #5d58e0;
    --ion-color-tertiary-tint: #7974ff;

    --ion-color-success: #2fdf75;
    --ion-color-success-rgb: 47, 223, 117;
    --ion-color-success-contrast: #000000;
    --ion-color-success-contrast-rgb: 0, 0, 0;
    --ion-color-success-shade: #29c467;
    --ion-color-success-tint: #44e283;

    --ion-color-warning: #ffd534;
    --ion-color-warning-rgb: 255, 213, 52;
    --ion-color-warning-contrast: #000000;
    --ion-color-warning-contrast-rgb: 0, 0, 0;
    --ion-color-warning-shade: #e0bb2e;
    --ion-color-warning-tint: #ffd948;

    --ion-color-danger: #ff4961;
    --ion-color-danger-rgb: 255, 73, 97;
    --ion-color-danger-contrast: #ffffff;
    --ion-color-danger-contrast-rgb: 255, 255, 255;
    --ion-color-danger-shade: #e04055;
    --ion-color-danger-tint: #ff5b71;

    --ion-color-dark: #f4f5f8;
    --ion-color-dark-rgb: 244, 245, 248;
    --ion-color-dark-contrast: #000000;
    --ion-color-dark-contrast-rgb: 0, 0, 0;
    --ion-color-dark-shade: #d7d8da;
    --ion-color-dark-tint: #f5f6f9;

    --ion-color-medium: #989aa2;
    --ion-color-medium-rgb: 152, 154, 162;
    --ion-color-medium-contrast: #000000;
    --ion-color-medium-contrast-rgb: 0, 0, 0;
    --ion-color-medium-shade: #86888f;
    --ion-color-medium-tint: #a2a4ab;

    --ion-color-light: #222428;
    --ion-color-light-rgb: 34, 36, 40;
    --ion-color-light-contrast: #ffffff;
    --ion-color-light-contrast-rgb: 255, 255, 255;
    --ion-color-light-shade: #1e2023;
    --ion-color-light-tint: #383a3e;
  }

  /*
     * iOS Dark Theme
     * -------------------------------------------
     */

  .ios body {
    --ion-background-color: #000000;
    --ion-background-color-rgb: 0, 0, 0;

    --ion-text-color: #ffffff;
    --ion-text-color-rgb: 255, 255, 255;

    --ion-color-step-50: #0d0d0d;
    --ion-color-step-100: #1a1a1a;
    --ion-color-step-150: #262626;
    --ion-color-step-200: #333333;
    --ion-color-step-250: #404040;
    --ion-color-step-300: #4d4d4d;
    --ion-color-step-350: #595959;
    --ion-color-step-400: #666666;
    --ion-color-step-450: #737373;
    --ion-color-step-500: #808080;
    --ion-color-step-550: #8c8c8c;
    --ion-color-step-600: #999999;
    --ion-color-step-650: #a6a6a6;
    --ion-color-step-700: #b3b3b3;
    --ion-color-step-750: #bfbfbf;
    --ion-color-step-800: #cccccc;
    --ion-color-step-850: #d9d9d9;
    --ion-color-step-900: #e6e6e6;
    --ion-color-step-950: #f2f2f2;

    --ion-toolbar-background: #0d0d0d;

    --ion-item-background: #000000;
  }


  /*
     * Material Design Dark Theme
     * -------------------------------------------
     */

  .md body {
    --ion-background-color: #121212;
    --ion-background-color-rgb: 18, 18, 18;

    --ion-text-color: #ffffff;
    --ion-text-color-rgb: 255, 255, 255;

    --ion-border-color: #222222;

    --ion-color-step-50: #1e1e1e;
    --ion-color-step-100: #2a2a2a;
    --ion-color-step-150: #363636;
    --ion-color-step-200: #414141;
    --ion-color-step-250: #4d4d4d;
    --ion-color-step-300: #595959;
    --ion-color-step-350: #656565;
    --ion-color-step-400: #717171;
    --ion-color-step-450: #7d7d7d;
    --ion-color-step-500: #898989;
    --ion-color-step-550: #949494;
    --ion-color-step-600: #a0a0a0;
    --ion-color-step-650: #acacac;
    --ion-color-step-700: #b8b8b8;
    --ion-color-step-750: #c4c4c4;
    --ion-color-step-800: #d0d0d0;
    --ion-color-step-850: #dbdbdb;
    --ion-color-step-900: #e7e7e7;
    --ion-color-step-950: #f3f3f3;

    --ion-item-background: #1e1e1e;

    --ion-toolbar-background: #1f1f1f;

    --ion-tab-bar-background: #1f1f1f;
  }

  ion-title.title-large {
    --color: white;
  }
}