.pdp-fulfillment {
    width: 100%;
    &__option {
        position: relative;
        width: 100%;
        border: 1px solid #e3e3e3;
        border-radius: 8px;
        background-color: #ffffff;
        box-shadow: 0 5px 25px 1px rgba(0, 0, 0, 0.1);
        padding: $radius-md;
        margin-top: $space-standard;

        &-header {
            display: flex;
            justify-content: space-between;
            align-items: center;
            margin-bottom: $space-xs;
            button{
                color: $color__primary;
                font-size: $text__body-xs;
                letter-spacing: 0;
                line-height: $line-height-lg;
            }
            &-pickup{
                display: flex;
                line-height: $space-lg;
                &-image {
                    width: $space-md;
                    height: $space-md;
                }
                p {
                    color: $color__black;
                    font-size: $text__body-sm;
                    font-weight: bold;
                    letter-spacing: 0;
                    line-height: $space-md;
                    padding-left: $space-xs;
                }
            }
            

            button {
                color: #87189d;
                font-size: 12px;
                letter-spacing: 0;
                line-height: $line-height-lg;
            }
        }

        &-stock {
            color: #000000;
            font-size: 14px;
            letter-spacing: 0;
            line-height: 20px;
            margin-bottom: $space-xxxs;
        }
        &-hidden {
            visibility: hidden;
        }

        &-footer {
            display: flex;
            justify-content: space-between;
            align-items: flex-end;
            button{
                flex: 1;
                max-height: 60px;
                font-size: $text__body-xs;
                min-width: auto;
                p{
                    @include line-clamp(1);
                }
            }
            &-address {
                text-decoration: underline;
                color: #000000;
                font-size: $text__body-sm !important;
                font-weight: bold;
                letter-spacing: 0;
                line-height: $line-height-lg;
                text-align: left;
                // margin-right: $space-xxs;
                padding-left: 0;
                word-break: break-all;
            }
            .btn__primary,
            .btn__secondary {
                padding: $space-xxxs $space-sm;
                height: $space-lg;
                max-width: 120px;
                line-height: 0px;
                font-size: $text__button-sm;
            }
            .add-to-cart{
                max-width: 120px;
                min-width: 120px;
                &__button {
                   padding: $space-xxxxs;
                  }
                  &__setbutton{
                    width: 120px;
                    justify-content: center;
                    display: flex;
                 }
            }
       }

        &-zipcode {
            display: flex;
            justify-content: space-between;
            height: $text__body-lg;
            p{
                margin-bottom: 0px;
            }
            p,
            button {
                color: #000000;
                font-size: 14px;
                letter-spacing: 0;
                line-height: 20px;
            }
            button {
                text-decoration: underline;
                font-weight: bold;
                padding: 0;
            }
        }

        &-speed {
            color: #000000;
            font-size: 14px;
            letter-spacing: 0;
            line-height: 20px;
            margin-bottom: 0 !important;
        }

        &-unavailable {
            color: #F40000;
            font-size: 14px;
            font-weight: bold;
            letter-spacing: 0;
            line-height: 20px;
            text-align: center;
            margin: $space-sm 0 $space-sm 0 !important;
        }
    }

    &__stock {
        &-oos {
            color: $color__red;
        }
    }
}
