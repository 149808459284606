.paymentmethods-page {
  &__content {
    display: flex;
    width: 100%;
    align-items: center;
    flex-direction: column;
    p {
      margin-top: $space-standard;
    }

    > svg[name=alert-full] {
      margin-bottom: $space-standard;
    }
  }

  &__add-payment {
    display: flex;
    width: 100%;
    align-items: center;
    flex-direction: column;
  }

  &__button-payment-method {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    border: 1px solid $color__gray-lighter;
    box-shadow: $shadow__profile-button;
    cursor: pointer;
    background-color: $color__white;
    width: 100%;
    height: 68px;
    text-align: left;
    padding: calc($space-xxxs + $space-xxs);
    border-radius: $radius-xs;
    margin-bottom: $space-sm;

    span {
      display: flex;
      align-items: center;
      font-size: $space-standard;
      font-weight: bold;
      width: 100%;

      svg {
        height: calc($space-md + $space-xxs);
        width: calc($space-xl + $space-xxxs);
        margin-right: calc($space-xxxs + $space-xxs);
      }

      &.expired-text {
        width: 100%;
        display: flex;
        color: $color__red;

        span {
          color: $color__red;
          display: flex;
        }

        span:first-of-type {
          white-space: nowrap;
        }

        span:nth-of-type(2) {
          justify-content: flex-end;
          margin-right: $space-lg;
          font-size: $text__body-sm;
        }
      }
    }

    @include breakpoint(xs) {
      margin-bottom: $space-standard;
    }
  }

  .btn__primary {
    margin-top: $space-md;
  }
}

.view-payment-method-modal {
  section {
    p,
    h6 {
      align-self: flex-start !important;
    }

    p.exp-label {
      margin-bottom: 5px;
      color: $color__gray;
      font-size: $text__body-sm;
    }

    p.exp-date {
      font-size: $text__body-md !important;
    }

    p.details {
      display: flex;
      width: 100%;

      svg {
        height: calc($space-md + $space-xxs);
        width: calc($space-xl + $space-xxxs);
        margin-right: calc($space-xxxs + $space-xxs);
      }
    }
  }
}
.exp-msg{
  font-size: $text__body-base;
  color: $color__red;
  font-weight: bold;
}
.oos{
  font-size: $text__body-md;
  padding-left: $space-xs;
  color: $color__red;
}
