.map-list-wrap input,
.map-modal input {
  border: 1px solid #ccc;
  width: 100%;
  -webkit-border-radius: 6px;
  -moz-border-radius: 6px;
  border-radius: 6px;
  padding: 15px 10px;
  height: calc($space-xl + $space-xs);
  -webkit-appearance: none;
  background-color: #ffffff;
  color: #000;
}
.map-list-wrap p { 
  line-height: $space-md;
}
.map-list-search {
  padding-bottom: 10px;
  .selected-button {
    display: flex;
    align-items: center;
    justify-content: center;

    button {
      padding: 0 $space-xl;
    }
  }
  label {
    margin-bottom: 5px;
    display: block;
    font-size: $text__body-base;
    color: #636363;
    padding: 0;
    text-align: left;
  }
}
.map-list-item {
  padding: $space-sm 0;
  border-bottom: 0.5px solid $color__input-border;
  .selected-button {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  &__content {
    text-align: left;
    .btn-store-details{
      color: $color__primary;
      font-size: $text__button-sm;
      font-weight: bold;
      padding-left: 0;
    }
    &-eligible {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: flex-start;
      p {
        font-size: $text__body-sm !important;
        margin-left: $space-xxxxs;
        margin-bottom: 0;
        color: $color__gray;
      }
      svg {
        width: calc($space-xxxs * 5);
        height: calc($space-xxxs * 5);
      }
    }
    &-ineligible {
      text-align: left;
      font-weight: 500;
      color: $color__red;
      margin-bottom: 0;
      color: $color__red;
      line-height: $line-height-base !important;
      font-size: $text__body-sm !important;
    }
    &-oos {
      text-align: left;
      color: $color__red;
      margin: 0;
      color: $color__red;
      font-size: $text__body-sm !important;
    }
    &-hidden {
      display: none;
    }
  }
  div {
    label {
      font-size: $text__body-base;
      font-weight: bold;
      color: #000;
      padding: 0;
      line-height: $line-height-lg;
    }
    span {
      font-size: 14px;
      font-weight: bold;
      color: #636363;
      padding: 0;
    }
    a {
      font-weight: bold;
    }
    .selected-store {
      display: flex;
      align-items: center;
      svg {
        margin-right: $space-xxs;
      }
      p {
        align-items: baseline;
      }
    }
  }
}
.map-list-item label,
.map-list-item span,
.map-list-item a {
  display: block;
}
.map-modal {
  .map-list-search {
    margin-top: 20px;
  }
}

.use-my-location {
  font-size: 14px;
  font-weight: 900;
  letter-spacing: 0;
  line-height: 14px;
  margin-right: 5px;
}

.location__hover:hover {
  cursor: pointer;
}

.location__text {
  color: #87189D !important;
  margin-left: 4px;
}

.location__text-animated {
  border: 2px solid #87189D !important;
  color: #87189D !important;
  background-color: #ffffff !important;
  border-top: 2px solid white !important; 
  transition: width height .2s !important;
  border-radius: 50% !important;
  width: 16px !important;
  height: 16px !important;
  color: transparent !important;
  animation: spin 0.75s linear infinite;
}

.location-error-modal .modal-wrapper{
  width: 350px;
}

.coming-soon {
    margin-bottom: 20px;
    font-size: 22px;
    font-weight: bold;
    width: 100%;
    text-align: center;
    float: left;
}

// Modal Style
.stores__modal {
  .map-list-wrap {
    padding: 10px;

    &__header {
      display: flex;
      flex-direction: row;
      width: 100%;
      margin: $space-lg 0 $space-md 0;
    }
  }

  h2 { 
    font-size: $text__body-xxl !important;
    font-weight: 700;
  }

  .close {
    position: absolute;
    right: 10px;
    top: 10px;
    z-index: 100;
    cursor: pointer;
  }

  section p {
    font-size: $text__body-base;
  }

  .btn-store-details {
    text-align: left;
    padding-left: 0;
    font-size: $text__button-sm;
    color: $color__primary;
    font-weight: 900;
    line-height: $line-height-md;
  }

  .btn__primary,
  .btn__secondary {
    max-width: auto;
    width: 100%;
    padding: 0;
  }

  .btn__secondary {
    margin-top: $space-xxxs;
  }

  .use-my-location {
    margin: $space-xxxs auto 0 calc($space-xl + $space-xs);
    width: auto;
  }
}