.categories {
    width: 100%;
    &__grid {
        display: grid;
        grid-template-columns: repeat(auto-fit, minmax(144px, 144px));
        justify-content: center;
        row-gap: $space-standard;
        column-gap: $space-standard;
        @include page-container(lg);
    }
}