.outer-section{
    .page-title{
        margin-bottom: 0;
    }
}

.career{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: $space-xl+$space-xs;

    @include breakpoint-max(sm) {
        gap: $space-xl;
        width: 100%;
    }

    &__section{
        width: 100%;
        max-width: $space-max-width-xl;

        &__hero-image {
            display: flex;
            width: 100%;
            height: auto;
            justify-content: center;
            align-items: center;
            @include breakpoint-max(xs) {
                display: none;
            }
        }

        &__hero-image-mobile {
            display: none;
            @include breakpoint-max(xs) {
                display: flex;
                width: 100%;
                height: auto;
                justify-content: center;
                align-items: center;
            }
        }
    }

    &__container{
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: $space-xl+$space-xs;
        max-width: $space-max-width-lg;

        @include breakpoint-max(sm) {
            gap: $space-xl;
            max-width: 100%;

        }

        &__content{
            gap: $space-md;
            align-self: stretch;
            padding: 0 $space-lg;

            @include breakpoint-max(sm) {
                gap: $space-standard;
            }

            h1{
                align-self: stretch;
                color: $color__primary;
                font-size: $text__header-xxxl+$text__body-lg;
                font-weight: bold;
                line-height: normal;

                @include breakpoint-max(sm) {
                    font-size: $text__header-xxl;
                }
            }

            &-text{
                p{
                    color: $color__black;
                    font-weight: $font-weight__medium;
                    line-height: 140%; /* 33.6px */
                }

                p:not(:last-child) {
                    margin-bottom: $space-md;
                  }
            }
        }

        &__button-row{
            display: flex;
            justify-content: center;
            align-items: center;
            gap: $space-xl;
            padding: 0 $space-lg;

            @include breakpoint-max(xs) {
                flex-direction: column;
                gap: $space-standard;
                align-self: stretch;
            }

            &-btn-element{
                width: 278px;
                max-width: unset;

                @include breakpoint-max(xs) {
                    width: 100%;
                }
            }
        }

        &__map-container{
            width: 100%;

            iframe {
                width: 100%;
                height: 600px;
            }
        }

        &__bottom-image {
            display: flex;
            width: 100%;
            height: auto;
            justify-content: center;
            align-items: center;
            margin-bottom: $space-xl+$space-xs;

            @include breakpoint-max(sm) {
                margin-bottom: $space-xl;
            }
        }
    }
}