.order-detail {
    @include page-container(sm);
    @include breakpoint(md) {
     @include page-container(lg);
     display: flex;
    }
    &__status {
        display: flex;
        flex-direction: column;
        align-items: center;
        @include breakpoint(md){
            flex: 1;
            margin-right: $space-xl;
        }
        &-pickup-helper {
            color: $color__primary;
            font-size: $text__body-sm;
            font-weight: bold;
            letter-spacing: $letter__spacing-xxs;
            line-height: $line-height-lg;
            text-align: center;
            margin-top: $space-md;
            @include breakpoint(md) {
                margin-bottom: $space-md;
            }
        }
        &-bar {
            width: 100%;
        }
    }
    &__meta {
        @include breakpoint(md){
            flex: 1;
            margin-left: $space-xl;
        }

        h1, h2, h3 {
            color: $color__gray-order;
            font-weight: $font-weight__light;
            letter-spacing: 0;
            line-height: $line-height-xl;
        }
        p {
            color: $color__black-light;
            font-weight: $font-weight__light;
            letter-spacing: $letter__spacing-sm;
            line-height: $line-height-xl;
            margin-bottom: $space-standard;
            margin-top: $letter__spacing-lg;
        }
        
        &-time {
            border-bottom: $border__order-details;
            padding: $space-md 0;
            @include breakpoint(md) {
                padding-top: 0;
            }
        }

        &-payment {
            border-bottom: $border__order-details;
            padding: $space-md 0px;
        }

        &-summary {
            @include breakpoint(md) {
                padding-bottom: $space-md;
                border-bottom: $border__order-details;
            }
            
            padding: $space-md 0px;
            div {
                display: flex;
                justify-content: space-between;

                p {
                    margin-bottom: $space-xxxs;
                }

                &:last-of-type {
                    p {
                        font-weight: bold;
                    }
                }
            }

            &-loyalty-points {
                display: flex;
                justify-content: center !important;
            }
        }

        &-loyalty-birthday p, &-loyalty-rewards p, &-loyalty-offers p {
            font-weight: 700;
        }

        &-loyalty-order-total p:nth-child(2) {
            font-weight: 700;
        }

        &-qr {
            display: flex;
            border-top: $border__order-details;
            border-bottom: $border__order-details;
            justify-content: center;
            align-items: center;
            margin-bottom: $space-md;
            @include breakpoint(md) {
                border-top: none;
            }
            p {
                line-height: $line-height-lg;
            }
            canvas {
                margin: $space-md;
                @include breakpoint-max(sm) {
                    height: auto !important;
                }
            }
        }

        &-refs {
            margin-bottom: $space-xxxxl;
            &-auth {
                display: flex;
                justify-content: space-between;
                margin-bottom: $space-xs;
            }
            &-barcode {
                width: 100%;
                display: flex;
                justify-content: center;
                align-items: center;
                flex-direction: column;
                margin-top: $space-standard;
                padding-top: $space-sm;
                @include breakpoint(md) {
                    border-top: none;
                    margin-top: 0;
                }
                p {
                    color: $color__black;
                    font-size: $text__body-sm;
                    font-weight: 400;
                    text-align: center;
                    line-height: $line-height-sm;
                }
                svg {
                    max-width: 100%;
                }
            }
        }
        &-help {
            border-top: $border__order-details;
            border-bottom: $border__order-details;
            padding: $space-md 0px;

            h2 {
                color: #000000;
                font-size: $text__body-lg;
                font-weight: normal;
                letter-spacing: $letter__spacing-xs;
                line-height: $line-height-xxxl;
                margin-bottom: $space-sm;
            }
            p:first-of-type {
                font-size: $text__body-base;
                padding-bottom: $space-standard;
                line-height: $line-height-lg;
            }
            p {
                font-size: $text__body-sm;
                color: $color__black;
                background-color: white;
                padding-bottom: $space-standard;
                line-height: $line-height-lg;

            }
            p:last-of-type {
                font-size: $text__body-base;
                color: $color__black;
                padding-bottom: 0;
                line-height: $line-height-lg;

            }
            span {
                font-weight: bold;
            }
            &-phone {
                color: $color__black !important;
            }
        }
    }
    &__cancel-button {
        display: flex;
        flex-direction: column;
        justify-content: center;
        button {
            border: 1.5px solid $color__primary;
            border-radius: $radius-xs;
            background-color: $color__white;
            font-size: $text__body-sm;
            font-weight: bold;
            letter-spacing: $letter__spacing-sm;
            line-height: $line-height-lg;
            text-align: center;
            text-transform: uppercase;
            padding: $space-sm $space-xxxxl;
            color: $color__primary;
            max-width: 100%;
            transition: all 0.2s;
            margin-bottom: $space-standard; 
            &:hover {
                color: $color__primary-dark;
                border-color: $color__primary-dark;
                background-color: $color__white;
            }
        }
        p {
            color: $color__black;
            font-size: $text__body-sm;
            text-align: center;
            padding-bottom: $space-lg;
            border-bottom: $border__order-details;
            font-weight: lighter;
            line-height: $line-height-lg;
        }
    }
    &__products {
        width: 100%;
        &-desktop {
            display: none;
            @include breakpoint(md) {
                display: block;
            }
            padding-bottom: $space-standard;
            margin-bottom: $space-standard;
        }
        &-mobile {
            margin-bottom: $space-standard;
            @include breakpoint(md) {
                display: none;
            }
            padding-bottom: $space-standard;
        }

        &-product {
            box-sizing: border-box;
            box-shadow: $shadow__order-details;
            border-radius: $radius-xxs;
            display: flex;
            padding: $space-standard;
            margin-bottom: $space-xs;

            img {
                height: $space-xxl;
                width: $space-xxl;
                @include breakpoint(md) {
                    height: 100px;
                    width: 100px;
                }
                object-fit: contain;
                margin-right: $space-xs;
            }
            &-details {
                width: 100%;
                display: flex;
                flex-direction: column;
                justify-content: space-between;
                p, h2 {
                    color: #1F1F1F;
                    font-size: $text__body-base;
                    font-weight: normal;
                    letter-spacing: 0;
                    line-height: $line-height-lg;
                    @include line-clamp(2);
                }
            }

            &-price {
                display: flex;
                justify-content: space-between;
                align-items: center;
                
                h2 {
                    font-weight: bold;
                    font-size: $text__body-md;
                }

                p {
                    color: $color__gray-order;
                    font-size: $text__body-sm;
                    font-weight: normal;
                    letter-spacing: 0.27px;
                    line-height: $line-height-lg;
                    &.order-detail__products-product-price-OOS {
                        color: $color__red;
                    }
                }
            }
        }
    }
    &__error-modal{
        width: 100%;
        text-align: center;
        &__ops{
            margin-bottom: $space-md;
        }
        p{
            margin: 0px $space-standard $space-lg $space-standard;
        }

        button{
            max-width: 100%;
            margin-bottom: 0;
        }
    }
}