#body {
    display: flex;
    flex-direction: column;
    height: 100%;
    justify-content: space-between;

    .main-content {
        flex: 1;
    }
}

.ion-page {
    overflow: hidden;
}

ion-content{
   --keyboard-offset: 0 !important;
}
ion-toolbar {
    --background: $color__white;
}

ion-header {
    width: 100%;
    z-index: 1015;
}

.height__full {
    height: 100%;
}

.inline {
    display: inline;
}

.margin {
    &__top {
        &-15 {
            margin-top: 15px;
        }
    }
}

.m {
    &__bottom {
        &-50 {
            margin-bottom: 50px;
        }
        &-30 {
            margin-bottom: 30px;
        }
    }
}

.image-next {
    position: relative;
    width: 100%;
    margin-bottom: 2px;
}

.image-next > div,
.image-next > span {
    position: relative !important;
    width: 100%;
    height: 100%;
}

.image-next > div img,
.image-next > span img {
    position: relative !important;
    width: 100% !important;
    height: auto !important;
    min-width: 50% !important;
    max-width: none;
}

.next {
    &__image {
        &-article {
            img {
                position: relative !important;
                padding-bottom: 70px !important;
            }
        }
    }
}

.content-block__height-override {
    position: relative;
    height: 0 !important;
    padding-bottom: calc(86.48% + $space-xxxl);
    cursor: pointer;
    .content-block__container {
        height: 100%;
        width: 100%;
        position: absolute;
        display: flex;
        flex-direction: column;
    }
}
