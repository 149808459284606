.accordion__button {
  &:before {
    float: right !important;
    margin-top: 3px !important;
    transform: rotate(-315deg) !important;
  }

  &[aria-expanded="true"]::before,
  &[aria-selected="true"]::before {
    transform: rotate(-135deg) !important;
    margin-top: 10px !important;
  }

  &:before {
    float: right !important;
    margin-top: 3px !important;
    transform: rotate(45deg) !important;
  }

  &:hover {
    background-color: #fff;
  }
}

.accordion__panel {
  font-size: 14px !important;
  animation: fadein 0.35s ease-in !important;
  background-color: #f9f9f9 !important;
  padding: 0;
}

.accordion__button {
  font-size: $text__body-base !important;
  font-weight: 800 !important;
  background-color: #fff !important;
  color: #444 !important;
  cursor: pointer !important;
  padding: $space-standard !important;
  width: 100% !important;
  text-align: left !important;
  border-left: 4px solid #9230a8 !important;
}
.accordion__button:hover,
.pop-inner-accordion li:hover {
  color: #540265 !important;
}
.accordion__button a {
  font-size: $text__body-base !important;
  font-weight: 800 !important;
  background-color: #fff !important;
  color: #444 !important;
  cursor: pointer !important;
}

.accordion__item + .accordion__item {
  border-top: 1px solid rgba(0, 0, 0, 0.1);
}

.accordion-menu .button-signout {
  margin: $space-md auto $space-xl ;
  display: block;

  padding: $space-standard $space-sm $space-standard $space-sm  ;
  margin: $space-md $space-sm $space-md $space-standard ;
  width: 90% ;
  margin-left: auto ;
}

.accordion-menu .accordion-location__button{
  background-color: white;
  border-radius: 0px $space-sm $space-sm 0px;
  background-color: white;
  border-radius: 0px $space-sm $space-sm 0px;
  margin: auto !important;
} 

.accordion-menu-zip {
  border-left: $space-xxxs solid $color__gray-lightest;
  padding-bottom: $space-standard !important;
  &__border{
    border-left: $space-xxxs solid $color__gray-lightest;
  }

  p{
    button{
      color: $color__primary;
      font-weight: bold;
    }
  }


	&__location {
    display: flex;
    -webkit-flex-wrap: wrap;
    margin-bottom: $space-sm;
    justify-content: space-between;

		p {
      color: $color__black !important;
		}
    &__btn{
      display: flex;
      justify-content: space-between;

      button {
        font-size: $space-standard;
        font-weight: bold;
        letter-spacing: 0;
        line-height: $space-standard;
        display: flex;
        margin-left: $space-lg;
        justify-content: space-between;
        padding-right: 0;
      }
  
      &-usemy {
        color: $color__primary ;
        margin-left: $space-xxxs;
        display: flex;
        justify-content: space-between;
  
        &-animated {
          border: $space-xxxxs solid $color__primary ;
          color: $color__primary ;
          background-color: $color__white ;
          border-top: $space-xxxxs solid $color__white ; 
          transition: width height .2s ;
          border-radius: 50% ;
          width: $space-standard ;
          height: $space-standard ;
          color: transparent ;
          animation: spin 0.75s linear infinite;
        }
      }
    }

		&__text{
      color: $color__primary;
      font-weight: bold;
      cursor: pointer;
      text-decoration: underline;
    }

    &-empty {
      display: flex;
      padding: $space-standard $space-sm !important;
      border-left: $space-xxxs solid $color__gray-lightest;

      button{
        color: $color__primary;
        font-weight: bold;
        font-size: $text__body-sm;
      }
    }
	
	}

  &__zipcode {
    display: flex;
    height: 50px;
    justify-content: space-between;
    width: 100%;

    &-set-text{
      margin-left: $space-md;
      color: $color__white;
      font-size: $text__body-sm;
      font-weight: bold;
      letter-spacing: $letter__spacing-sm;
      line-height: $line-height-xxl;
      text-align: center;
      text-transform: uppercase;
    }
    
    input {
      border: 1px solid $color__gray-lightest;
      width: 70%;
      -webkit-border-radius: $space-xxs;
      -moz-border-radius: $space-xxs;
      border-radius: $space-xxs;
      padding: $space-standard $space-sm;
      -webkit-appearance: none;
      background-color: $color__white;
      color: $color__gray-dark;
      font-size: $text__body-md;
      padding-bottom: $space-standard;
      box-shadow: 0 $space-xxs $space-md 1px $color__gray-lightest;
    }
    button{
      @extend .btn__primary;
      font-size: $text__body-sm;
      position: relative;
      width: 30%;
      padding-left: $space-xs;
      display: flex;
      margin-left: $space-xxs;
    }
  }

	&-error {
    color: $color__red;
    padding-left: $space-standard;
    padding-top: $space-standard;
	}

	
}

.pop_hide_caret .accordion__button:before {
  display: none;
}

.icon {
  width: 30px;
  height: 30px;
  display: inline-block;
  -webkit-border-radius: 6px;
  -moz-border-radius: 6px;
  border-radius: 6px;
  // margin-right: 15px;
  background-repeat: no-repeat;
  background-position: center;
  vertical-align: middle;
  cursor: pointer;
}
.icon-alert {
  background-size: contain;
  background-image: url(/images/icons/icon-circle-alert.png);
  width: 25px;
  height: 25px;
  margin-left: 10px;
  background-color: transparent !important;
}

.pop__menu {
  &-flex {
    display: flex;
    align-items: center;
  }

  &-text {
    color: #444 !important;
  }
}

.pop-divider {
  border-bottom: 1px solid #e7e7e7;
  cursor: pointer;
}

.pop-inner-accordion {
  ul {
    padding-top: 0px;
  }

  li {
    font-size: $text__body-base !important;
    list-style-type: none !important;
    font-weight: bold !important;
    line-height: $line-height-xxxxl;
  }
}
.pop-inner-accordion {
  color: #540265 !important;
}
.pop-divider.gray-border .accordion__button {
  border-left: 4px solid #cbcbcb !important;
}

.pop-divider-account {
  width: 100% !important;
  height: 10px !important;
  background-color: #dabae1 !important;
}

.filter-accordion-menu {
  margin-bottom: 10px;
  .accordion__button {
    font-size: $text__body-base !important;
    font-weight: bold !important;
    background-color: #fff !important;
    color: #000 !important;
    cursor: pointer !important;
    padding:  $space-xs $space-standard $space-xs !important;
    width: 100% !important;
    text-align: left !important;
    border-left: none !important;
    text-transform: capitalize !important;
  }

  .accordion__panel {
    background-color: #fff !important;
    padding: 0px $space-standard 0px;
    &:last-of-type {
      margin-bottom: $space-standard;
    }
    .pop-inner-accordion {
      padding-left: 0;
      margin: 0;
      li {
        font-size: $text__body-base;
        .input-wrap label {
          cursor: pointer;
        }
      }
    }
  }

  .email-checkmark { 
    display: inline-flex;
    align-items: flex-start;
    label {
      font-size: $text__body-base !important;
      font-weight: bold !important;

      color: #000 !important;
      }
    }

  .input-wrap.ratio-select label {
    font-weight: 500 !important;
    color: #000000 !important;
    margin-bottom: 10px;
    display: block !important;
    font-size: $text__body-base !important;
    font-weight: bold !important;
    margin-left: 0px !important;
  }
}

@media (max-width: 576px) {
  .filter-accordion-menu {
    margin-bottom: 60px;
  }
}

.filters-cta {
  padding: 20px;
  width: 100%;
  bottom: 0;
  left: 0;
  background: #fff;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
}

.filters {
  &__radio {
    &-text {
      color: $color__black !important;
      margin-bottom: 16px !important;
      // display: block !important;
      font-size: $text__body-base !important;
      font-weight: bold !important;
      margin-left: 0px !important;
      display: flex !important;
      align-items: baseline;
    }
  }
}

.filter__labels {
  line-height: $line-height-base;
  cursor: pointer;
  margin-bottom: auto  !important;
  padding-top: 3px;
  padding-left: 8px;
  // padding-bottom: 10px !important;
}

.pop__menu-text {
  font-size: $text__body-base !important;
  list-style-type: none !important;
  font-weight: 700!important;
  color: $color__gray-darkest !important;
  display: block;
  outline: none !important;
  cursor: pointer;
}

.pop__menu-text:hover {
color: #540265 !important;
}

.accordion-index {
  font-size: $text__body-base !important;
  font-weight: bold !important;
  background-color: $color__white !important;
  color: $color__gray-darker !important;
  cursor: pointer !important;
  padding: $space-standard !important;
  width: 100% !important;
  text-align: left !important;
  border-left: 4px solid $color__primary !important;
}

