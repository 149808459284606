.about {
  &__container {
    background-image: url(/images/bubbles-bg.svg);
    background-size: 100%;
  }


  &__header {
    max-width: 1800px;
    margin: 0 auto;
    background: linear-gradient(to bottom, transparent 50%, #B572C2 50%);
    margin-bottom: $space-xxxl;

    section {
      @include breakpoint(lg) {
        display: none
      }
      .swiper-slide {
        margin-right: 0 !important;
        img {
          object-fit: contain;
        }
      }
    }

    &-logo {
      display: flex;
      flex-direction: row;
      align-items: flex-end;
      justify-content: center;
      margin: $space-xxxxl $space-xxl $space-xl;

      &-text {
        display: flex;
        flex-direction: column;
        margin-right: $space-sm;

        h1 {
          text-align: left;
          font-size: 51px;
          font-weight: bold;
          color: $color__gray-darker;

          span {
            color: $color__primary;

            span {
              position: relative;
              top: -10px;
            }
          }
        }

        p {
          text-align: right;
          font-size: $text__body-lg;
          color: #842B91;
          font-weight: bold;
        }
      }
    }

    &-cards {
      display: flex;
      position: relative;
      justify-content: center;
      gap: $space-standard;

      img {
        @include breakpoint-max(lg) {
          display: none
        }
      }
    }
  }

  &__detail {
    @include page-container(lg);
    padding-bottom: $space-xxxl;

    &-heading {
      padding-bottom: $space-md;

      h3 {
        color: $color__primary;
        font-size: $text__header-xxxl;
        line-height: 50px;
        font-weight: bold;
        margin-top: $space-sm;
      }
    }

    &-content {
      display: flex;

      @include breakpoint-max(lg) {
        display: block;
        margin-left: $space-md;
        margin-right: 30px;

        div:nth-child(3) {
          width: 321px;
        }
      }

      div:nth-child(1),
      div:nth-child(2) {
        margin-right: $space-xl;

        @include breakpoint-max(lg) {
          margin-bottom: $space-md;
          width: 321px;
        }

      }
    }
  }

  &__explore {
    @include page-container(lg);
    padding-bottom: $space-xxxl;


    &-heading {
      padding-bottom: $space-md;

      div {
        position: relative;
        padding-top: $space-sm;
        h3:first-of-type {
          color: $color__primary;
          font-size: $text__header-xxxl;
          line-height: $line-height-xxxxxxl;
          font-weight: bold;
          margin-top: $space-sm;
          display: inline;
          padding-right: 20px;
        }

        span {
          position: absolute;
          bottom: 11px;
          left: 68px;
          color: $color__primary;
          font-size: $text__header-xxxl;
          font-weight: bold;
        }

        h3:last-of-type {
          color: $color__primary;
          font-size: $text__header-xxxl;
          font-weight: bold;
          margin-top: $space-sm;
          display: inline;
        }
      }

    }

    &-content {
      display: flex;
      justify-content: center;
      flex-direction: row;
      flex-wrap: wrap;


      div {
        width: 16%;

        @include breakpoint-max(lg) {
          width: 33%;
        }

        a {
          @include breakpoint-max(lg) {
            display: flex;
            align-items: center;
            flex-direction: column;
          }
        }

        img {
          height: 134px;
          width: 134px;
          border-radius: $radius-xxl;
          border: 7px white solid;
          filter: drop-shadow(0px 2px 5px rgba(0, 0, 0, 0.10)) drop-shadow(0px 9px 9px rgba(0, 0, 0, 0.09));
          stroke-width: 10px;
          stroke: #FFF;

          @include breakpoint-max(lg) {
            height: 85px;
            width: 85px;
          }
        }

        p {
          font-size: $text__body-md;
          line-height: $line-height-xxl;
          width: 142px;
          text-align: center;
        }
      }
    }

    &-button {
      display: flex;
      justify-content: center;
      margin-top: $space-standard;

      button {
        @include breakpoint-max(lg) {
          width: auto;
        }
      }
    }
  }


  &__stores {
    @include page-container(lg);
    margin-bottom: 25px;

    >button {
      background-color: $color__background-purple;
      padding: $space-xs $space-standard ;
      border-radius: $radius-xxl;
      color: $color__primary;
      border: 1px solid rgba(139, 27, 156, 0.20);
    }

    .about__stores-heading {
      padding-bottom: $space-md;

      h3 {
        color: $color__primary;
        font-size: $text__header-xxxl;
        line-height: $line-height-xxxxxxl;
        font-weight: bold;
        margin-top: $space-sm;

        @include breakpoint-max(lg) {
          font-size: 40px;
          line-height: 40px;
        }
      }
    }

    &-bg {
      position: relative;
      padding-bottom: $space-xxxxl + $space-xxxxl;
      background-image: url('/images/us-background.png');
      background-color: rgba(255, 255, 255, 0.897);
      background-blend-mode: lighten;
      background-size: contain;
      background-repeat: no-repeat;
      background-position: center;

      .about__stores-content {
        .about__stores-carousel {
          @include breakpoint(lg) {
            display: none;
          }

          .carousel__color {
            a {
              height: 100px !important;
              width: 100px !important;
              display: flex;
              flex-direction: column;
              justify-content: space-between;
              height: 100%;


              p:first-of-type {
                color: $color__white;
                font-size: $text__header-xxl;
                line-height: $line-height-xl;
                padding: $space-xxs;
              }

              p:last-of-type {
                color: $color__white;
                padding: $space-xxs;

              }
            }

            &-tan {
              background: #F0D5A9;
              @include about__stores-carousel();
            }

            &-purple {
              background: #89199F;
              @include about__stores-carousel();
            }

            &-blue {
              background: #9ECDF9;
              @include about__stores-carousel();
            }

            &-green {
              background: #90D6B4;
              @include about__stores-carousel();
            }

            &-gray {
              background: #989AA9;
              @include about__stores-carousel();
            }
          }

          .swiper {
            overflow: visible;

            &-pagination {
              display: none;
            }

            &-slide {
              margin-right: 0 !important;
            }
          }
        }

        .about__stores-table {
          @include breakpoint-max (lg) {
            display: none;
          }

          margin-bottom: $space-md;

          div:nth-child(1) {
            @include about__stores-table-rows();

            span:nth-child(1) {
              background: #F0D5A9;
              @include about__stores-table();
            }

            span:nth-child(2) {
              background: #89199F;
              @include about__stores-table();
            }

            span:nth-child(3) {
              background: #9ECDF9;
              @include about__stores-table();
            }

            span:nth-child(4) {
              background: #989AA9;
              @include about__stores-table();
            }

            span:nth-child(5) {
              background: #90D6B4;
              @include about__stores-table();
            }

            span:nth-child(6) {
              background: #F0D5A9;
              @include about__stores-table();
            }

            span:nth-child(7) {
              background: #89199F;
              @include about__stores-table();
            }

            span:nth-child(8) {
              background: #9ECDF9;
              @include about__stores-table();
            }
          }

          div:nth-child(2) {
            @include about__stores-table-rows();

            span:nth-child(1) {
              background: #89199F;
              @include about__stores-table();
            }

            span:nth-child(2) {
              background: #9ECDF9;
              @include about__stores-table();
            }

            span:nth-child(3) {
              background: #989AA9;
              @include about__stores-table();
            }

            span:nth-child(4) {
              background: #90D6B4;
              @include about__stores-table();
            }

            span:nth-child(5) {
              background: #F0D5A9;
              @include about__stores-table();
            }

            span:nth-child(6) {
              background: #89199F;
              @include about__stores-table();
            }

            span:nth-child(7) {
              background: #9ECDF9;
              @include about__stores-table();
            }

            span:nth-child(8) {
              background: #989AA9;
              @include about__stores-table();
            }
          }

          div:nth-child(3) {
            @include about__stores-table-rows();

            span:nth-child(1) {
              margin-right: $space-sm + $space-xs;
            }

            span:nth-child(2) {
              margin: 0 $space-sm + $space-xs;

            }

            span:nth-child(3) {
              background: #90D6B4;
              @include about__stores-table();
            }

            span:nth-child(4) {
              background: #F0D5A9;
              @include about__stores-table();
            }

            span:nth-child(5) {
              background: #89199F;
              @include about__stores-table();
            }

            span:nth-child(6) {
              background: #9ECDF9;
              @include about__stores-table();
            }

            span:nth-child(7) {
              margin: 0 $space-xs + $space-sm;

            }

            span:nth-child(8) {
              margin-left: $space-xs + $space-sm;
            }
          }
        }
      }

      .about__stores-button {
        display: flex;
        justify-content: center;

        button {
          @include breakpoint-max(lg) {
            width: auto;
          }
        }
      }
    }
  }

  &__link {
    background-color: $color__background-purple;
    padding: $space-xxs $space-sm ;
    border-radius: $radius-xxl;
    color: $color__primary;
    border: 1px solid rgba(139, 27, 156, 0.20);
    margin: $space-md 0;
  }

  .responsive__large {
    &-desktop {
      @include breakpoint-max(lg) {
        display: none;
      }
    }

    &-mobile {
      margin-bottom: $space-lg;

      @include breakpoint(lg) {
        display: none;
      }
    }
  }
}