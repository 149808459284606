.loyalty-points-earned {
        display: flex;
        flex-direction: column;
        justify-content: center !important;
        width: 380px;
        height: 67px;
        align-self: center;
        padding: $space-standard;
        border: $outline__heavy;
        border-radius: $radius-sm;
        background: linear-gradient(0deg, #FDF4FF 0%, $color__white 100%);
        box-shadow: 0px -1px 6px 0px #00000033;
        margin-top: $space-standard + $space-xxxs;

        @include breakpoint-max(sm) {
            max-width: 100%;
        }
        &__points {
            color: $color__primary;
            font-size: $text__body-base;
            text-align: right;
            margin-bottom: $space-xxxs;
            line-height: $line-height-base;

            span {
                font-size: $text__body-xxl;
                font-weight: bold;
                color: $color__primary;
                line-height: $line-height-xxl;
                padding: 0 $space-xxxxs;
            } 
        }
        &__tc {
            color: $color__primary;
            font-size: $text__body-xxs;
            font-style: italic;
            line-height: $line-height-lg;
            text-align: right;
        }
}