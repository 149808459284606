.collage {
  &__wrapper {
    padding-bottom: $space-md;
    @include breakpoint(md) {
      flex-direction: row;
      padding-bottom: $space-xxl; padding-top: $space-xxl;
    }
    display: flex;
    flex-direction: column;
    @include page-container(lg);
  }

  &__image {
    flex-grow: 1;
    margin-left: -$space-page-gutters;
    margin-right: -$space-page-gutters;
    margin-bottom: $space-standard;
    position: relative;
    @include breakpoint(md) {
      margin-right: $space-standard;
      margin-left: 0;
      margin-bottom: 0;
    }

    &-wrapper {
      position: sticky;
      top: 0;
    }

    img {
      width: 100%;
      object-fit: fill;
    }
  }

  &__products {
    flex-shrink: 0;
    @include breakpoint(md) {
      width: 380px;
    }

    h1 {
      font-size: $text__body-base;
      margin-bottom: $space-xs;
    }

    &-details {
      display: flex;
      justify-content: space-between;

      &-total {
        font-size: $text__body-sm;
        font-weight: bold;
        margin-bottom: $space-standard;

        span {
          font-weight: bold;
          font-size: $text__body-lg;
        }
      }
    }

    &-card {
      margin-bottom: $space-standard;
    }

    .btn__primary {
      max-width: 100%;
      width: 100%;
    }
  }
}

.location-cirle {
  width: 24px;
  z-index: 100;
  height: 24px;
  background-color: $color__primary;
  color: #fff;
  text-align: center;
  -webkit-border-radius: 20px;
  -moz-border-radius: 20px;
  border-radius: 20px;
  position: absolute;
  font-size: 14px;
  line-height: 16px;
  font-weight: 800;
  padding-top: 2px;
  cursor: pointer;
}

.location-cirle-details {
  width: 135px;
  height: auto;
  text-align: left;
  position: relative;
  font-size: 13px;
  line-height: 16px;
  font-weight: bold;
  padding: 5px;
  left: 50%;
  top: 8px;
  transform: translateX(-50%);
  &::before {
    content: "";
    width: 100%;
    height: 100%;
    background-color: $color__primary;
    position: absolute;
    left: 0;
    top: 0;
    opacity: 0.8;
    border-radius: 6px;
  }
  
  label {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    height: 17px;
  }

  &.open {
    display: block;
    opacity: 1;
    &::after {
      content: "";
      width: 6px;
      height: 6px;
      display: block;
      position: absolute;
      background-color: $color__primary;
      top: -6px;
      left: 50%;
      opacity: 0.8;
      transform: translateX(-50%);
    }
  }
  &.closed {
    display: none;
    opacity: 0.1;
  }
}
.location-cirle-details label,
.location-cirle-details span {
  color: #fff !important;
  position: relative;
  z-index: 100;
}

