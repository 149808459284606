.privacy-policy {
  margin-bottom: $space-standard;
  a:focus {
    outline: $outline__normal;
    outline-offset: $space-xxxxs;
  }
  &__hero-ribbon {
    background-color: $color__primary;
    text-align: center;
    width: 100%;
    display: block;
    padding-top: $space-sm;
    padding-bottom: $space-standard;
    margin-bottom: $space-lg;

    h1 {
      font-size: $text__body-lg;
      line-height: $line-height-xl;
      font-weight: bold;
      color: $color__white;
      margin: $space-xxxxs;
      text-transform: lowercase;
    }
  }

  &__row {
    h2 {
      width: 100%;
      margin-bottom: $space-xs;
    }
  }

  &__row {
    h4 {
      font-weight: bold;
      padding-top: $space-xs;
      padding-bottom: $space-xs;
    }

    ul {
      padding-left: $space-standard;
    }

    @include page-container(lg);

    &-content {
      flex: 0 0 100%;
      max-width: 100%;

      &--bold {
        font-weight: bold;
      }

      &--underline {
        text-decoration: underline;
      }

      ul {
        padding-left: $space-standard;
      }
    }

    div {
      margin-bottom: $space-standard;
    }

    table {
      @include breakpoint-max(sm) {
        display: block;
        overflow-x: auto;
      }

      th {
        font-weight: bold;
        padding: $space-xxs;
        padding-bottom: $space-xs;
        background: $color__gray-lighter;
        vertical-align: middle;
      }

      td {
        padding: $space-xxs;
      }

      th,
      td {
        border: 1px solid black;
      }

      margin-bottom: $space-standard;
    }
  }

  p {
    overflow-wrap: break-word;
    word-wrap: break-word;
    margin-bottom: $space-xxs;
    width: 100%;
  }

  ul {
    li {
      margin-bottom: $space-standard;
      line-height: $space-md;
    }
  }

  ol {
    list-style-position: inside;

    li {
      margin-bottom: $space-standard;
      line-height: $space-md;
    }

    ol {
      margin-left: $space-lg;
    }
  }

  &__title {
    width: 100%;
    text-align: center;
  }

  &__date {
    text-align: right;
    margin-bottom: $space-xs;
  }
}