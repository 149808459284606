.button-container {
  display: flex;
  justify-content: center;
}
.back-to-top-wrapper {
  position: relative;
}

.back-to-top {
  -webkit-transition: opacity 150ms ease-in-out;
  transition: opacity 150ms ease-in-out;
  height: 40px;
  width: 100%;
  bottom: 40px;
  visibility: hidden;
  @include z-index(secondary);
  &__button {
    display: flex;
    align-items: center;
    background-color: $color__primary;
    right: 128px;
    border-radius: 6px;
    height: 40px;
    > div {
      width: 45% !important;
      img {
        width: 50% !important;
      }
    }

    svg {
      margin-right: $space-xs;
      transform: rotate(90deg);
    }

    span {
      font-size: 14px;
      color: #ffffff;
    }
  }

  &-show {
    display: flex;
    justify-content: flex-end;
    position: sticky;
    bottom: 20px;
    margin-bottom: 24px;
    opacity: 1;
    visibility: visible;
    @include z-index(secondary);
  }
}
.back-to-top__button {
  right: -100px;
}
@media (max-width: 1350px) {
  .back-to-top__button {
    right: 0px;
    bottom: 20px;
  }
}
