.add-payment-method {
    &__card {
        display: flex;
        align-items: center;

        span.card-number {
            font-weight: bold;
        }

        &:last-of-type {
            margin-bottom: $space-md;
        }
    }
}