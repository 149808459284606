.collection {
    &__container {
        @include page-container(lg);
    }

    .swiper {
        @include breakpoint(lg) {
            margin: 0 !important;
        }
    }

    .carousel__parent {
        @include breakpoint(lg) {
            margin: 0;
            margin-bottom: $space-md;
        }
    }

    &__header {
        margin: 0 calc(-1 * $space-md);

        @include breakpoint(lg) {
            margin: 0;
            margin-top: $space-md;
        }

        .general-image-hero__wrapper-margin {
            margin: 0 calc(-1 * $space-page-gutters);

            @include breakpoint(lg) {
                margin: 0;
            }
        }
    }

    &__head {
        display: flex;
        flex-wrap: wrap;
        margin-bottom: $space-md;
    }

    &__selectors {
        width: 100%;

        @include breakpoint(sm) {
            width: 50%;
        }
    }

    &__subcategories {
        h2 {
            font-size: $text__body-xl;
            line-height: $line-height-xxxl;
            font-weight: bold;
            text-align: center;
            margin-bottom: $space-md;
        }

        &-carousel {
            @include breakpoint(lg) {
                margin: 0;
                margin-bottom: $space-standard;
            }
        }
    }

    &__filters {
        &-button {
            flex: 1;
            display: flex;
            align-items: center;
            justify-content: flex-end;
        }
    }

    &__icons {
        &--horizontal {
            .collection__grid-icon span {
                border: $border__purple;
            }
        }


        &--vertical {
            .collection__list-icon span {
                border: $border__purple;
            }
        }
    }

    &__grid-icon {
        width: $space-md;
        height: $space-md;
        background-color: $color__white;
        margin-right: $space-sm;
        margin-top: $space-xs;
        padding: 0;

        @include breakpoint(sm) {
            display: none;
        }

        span {
            border: 2px solid #636363;
            width: 9px;
            height: 9px;
            margin-right: 1px;
            margin-bottom: 1px;
            display: block;
            float: left;
            -webkit-border-radius: 2px;
            -moz-border-radius: 2px;
            border-radius: 2px;
        }
    }

    &__list-icon {
        width: $space-md;
        height: $space-md;
        background-color: $color__white;
        margin-top: $space-xxs;
        padding: 0;

        @include breakpoint(sm) {
            display: none;
        }

        span {
            border: 2px solid #636363;
            width: 20px;
            height: 6px;
            margin-bottom: 1px;
            display: block;
            -webkit-border-radius: 2px;
            -moz-border-radius: 2px;
            border-radius: 2px;
        }
    }

    &__view-more {
        width: 100%;
        text-align: center;

        @include breakpoint(md) {
            width: 33.3333%;
            margin: auto;
        }
    }
}

.pop-filter-count {
    display: flex;
    align-items: baseline;

    .filters__button-container {
        text-align: right;
    }

    h5 {
        font-size: $text__body-base;
        width: 100%;
        line-height: $space-lg;
        display: inline-block;
        font-weight: bold;
        margin-top: 0;

        span {
            color: $color__primary;
        }
    }
}