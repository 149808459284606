.rewards-badge-section {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: $space-md $space-xl;

    h3 {
        font-size: $text__body-xxl;
        font-weight: bold;
        line-height: $line-height-xxl;
        text-align: center;
        margin-top: $space-standard;
        margin-bottom: $space-md;
        max-width: 295px;
    }

    &__details {
       display: flex;
       flex-direction: column;
       padding-top: $space-standard;
       padding-bottom: $space-lg;
        p {
            width: 295px;
            font-size: $text__body-base;
            line-height: $line-height-base;
            text-align: left;
            color: $color__black-light;
        }
        p:first-of-type {
            margin-bottom: $space-standard;
        }
    }
    &__badge-image {
        display: flex;
        align-items: center;
        margin-top: $space-md;
        margin-bottom: $space-md;
        width: 240px;
        height: 240px;
    }
    &__heading {
        font-size: $text__header-xxl;
        width: 240px;
        font-weight: bold;
        line-height: $line-height-base + $line-height-base;
        text-align: center;
        color: $color__black-light;
        margin-bottom: $space-md
    }

    &__button-complete-profile {
        width: 279px;
        padding: $space-xs $space-standard;
        margin-top: $space-md;
        border-radius: $radius-md + $radius-sm;
        border: $outline__normal;
        color: $color__primary;
        font-weight: bold;
    }
      
}