.news-recents {
  margin-top: $space-md;
  width: 100%;
  padding-right: $space-standard;
  padding-left: $space-standard;
  margin-right: auto;
  margin-left: auto;
  
  &__card {
    margin: 0 auto !important;
    margin-bottom: $space-standard;
    float: none !important;

    a {
      display: flex;
      flex-direction: row;
      align-items: center;
      @include breakpoint(xs) {
        display: flex;
        flex-direction: column;
      }
      div {
        position: relative !important;
        height: 60px !important;
        width: 90px;

        @include breakpoint(xs) {
          height: 230px !important;
          width: 100%;
        }

        span {
          height: 100% !important;
        }
        
        img {
          object-fit: cover;
          border-radius: $radius-sm;
          @include breakpoint(sm) {
            position: static !important;
            height: auto !important;
            width: 100% !important;
          }
        }
      }
      & > * {
        flex-grow: 1;
      }
      p {
        padding-left: $space-sm;
        width: 50%;
        @include breakpoint(xs) {
          width: 100%;
          padding-left: 0;
        }
      }
    }
  }
  &__grid {
    width: 100%;
    @include breakpoint(xs) {
      display: inline-grid;
      grid-template-columns: repeat(2, 1fr);
      column-gap: 2.5%;
    }
    @include breakpoint(md) {
      display: inline-grid;
      grid-template-columns: repeat(3, 1fr);
      grid-column-gap: 2.5%;
    }
    @include breakpoint(lg) {
      display: inline-grid;
      grid-template-columns: repeat(4, 1fr);
      grid-column-gap: 2.5%;
    }
    &-item {
      width: 100%;
      margin: $space-standard 0;
      border-bottom: $border__gray-lighter;
      padding-bottom: $space-standard;
      @include breakpoint(xs) {
        border-bottom: none;
        padding-bottom: none;
      }
    }
  }

  &__card-row {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: $space-standard;
  }

  &__load-button {
    border-radius: $radius-xs;
    background-color: $color__primary;
    font-size: $text__body-sm;
    font-weight: bold;
    letter-spacing: $letter__spacing-sm;
    line-height: $line-height-lg;
    text-align: center;
    text-transform: uppercase;
    padding: $space-sm $space-xxxxl;
    color: $color__white;
    width: 100%;
    transition: all 0.2s;
    margin: auto;

    &:hover {
      background-color: $color__primary-dark;
    }

    @include breakpoint(xs) {
      max-width: fit-content;
    }
  }

  &__load-button {
    align-items: center;
    margin: auto;
  }

  &__text {
    font-size: $text__body-sm;
    color: $color__black;
    padding: $space-sm 0 $space-sm 0;
    @include breakpoint(xs) {
      padding: $space-sm 0;
    }
  }
}
