.store-flyout {
    @include page-container(lg);
    display: flex;
    flex-direction: column;
    height: 100%;
    overflow: auto;

    &__close {
        margin-top: $space-standard;
        align-self: flex-end;
        outline-offset: 2px;
        padding: 0;
        display: flex;
    }

    &__info {

        h1 {
            font-weight: bold;
            font-size: $text__body-lg;
        }

        h2 {
            font-size: $text__body-lg;
            margin-bottom: $space-standard;
        }

        &-direction {
            display: flex;
            &-distance {
                background-color: $color__gray-progress-bar;
                border-radius: $radius-sm;
                padding: $space-xxxxs $space-sm;
                padding: $space-xxxs $space-sm $space-xxs $space-sm;
                display: inline-block;
                line-height: 100%;
            }
            a {
                padding-left: $space-xs;
                &:hover {
                    text-decoration: underline;
                }
            }
        }
        

        &-store-selection-button {
            margin-top: $space-md;

            .store-search__message-text {
                text-align: center;
            }
        }

        h2+p {
            background-color: $color__gray-progress-bar;
            border-radius: $radius-sm;
            padding: $space-xxs $space-sm $space-xxxs $space-sm;
            display: inline-block;
            line-height: 100%;
        }

        h2+span {
            background-color: $color__gray-progress-bar;
            border-radius: $radius-sm;
            padding: $space-xxxs $space-sm $space-xxs $space-sm;
            display: inline-block;
            line-height: 100%;
        }

        a {
            font: $color__primary;
            cursor: pointer;
            display: block;
        }

        a:focus {
            outline: $outline__normal;
        }

        &-closing {
            margin: $space-sm 0 $space-standard 0;

            span {
                &::after {
                    content: '';
                    background: $color__primary;
                    width: $space-sm;
                    height: $space-sm;
                    border-radius: $radius-round;
                    position: relative;
                    display: inline-block;
                }
            }

            p {
                display: inline;
                padding-left: $space-xxs;
                color: $color__gray;
            }
        }

        &-number {
            display: flex;
            align-items: center;
            justify-content: space-between;
            font-size: $text__body-md;

            p {
                color: $color__black;
                font-size: $text__body-base;
                font-weight: bold;
                display: inline;
            }

            button {
                padding: $space-sm;
                height: $space-xl;
            }

        }

        &-details {
            color: $color__primary;
            text-align: center;
            margin: $space-md 0;
        }
    }

    .store-flyout__map {
        .map-area {
            margin: 0 auto;
        }
    }

    .schedule {
        padding-top: $space-standard;

        &__table {
            &-time {
                margin-left: $space-xxl;
            }
        }
        .bold-style {
            font-weight: bold;
        }
    }
}