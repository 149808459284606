.gigya {
    &__error {
        &-container {
            width: 100%;
            display: flex;
            justify-content: center;
        }

        &-content {
            width: 100%;
            max-width: 500px;
            text-align: center;
        }

        &-tag {
            margin: 35px 0px;
        }
    }
}