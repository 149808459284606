.product-tabs {
  padding: $space-md 0 0;
}

.tabs__tab-list {
  display: flex;
  border-bottom: 1px solid #e7e7e7;
  list-style: none;
  margin: 0;
  padding: 0;
}

#pr-reviewdisplay a:focus {
  box-shadow: 0 0 0 2px $color__primary !important;
  border: 2px solid $color__primary;
}

.tabs__trigger {
  font-size: $text__body-base;
  color: $color__gray-dark;
  display: block;
  margin: 0 5px;
  padding: 15px 20px;
  text-decoration: none;
  &.is-selected {
    border-bottom: 3px solid #000;
    color: #000;
  }
}

.tabs__panel {
  display: inherit;
  padding: 20px;
  &.is-hidden {
    display: none;
  }
}
.tabs_trigger {
  :focus {
    box-shadow: none;
    border-color: hsl(290, 73%, 35%);
    outline: none;
  }
}
.testimonial-total-wrap {
  p {
    margin: 0 0 5px 0;
    font-size: 15px;
    display: block;
    font-weight: bold;
  }
}
.testimonial-total-wrap .pop-review .stars,
.testimonial-total-wrap .pop-review .stars-empty {
  width: 15px;
  height: 15px;
  background-size: contain;
  margin-right: 3px;
}
.testimonial-wrap,
.testimonial-total-wrap {
  padding: 20px 0;
}
.testimonial-wrap {
  border-top: 1px solid #dedede;
  label {
    font-size: 15px;
    margin-bottom: 8px;
    display: block;
    font-weight: bold;
  }
  .verified {
    display: inline-block;
    font-size: 13px;
    font-weight: bold;
    .checkmark {
      width: 15px;
      height: 15px;
      background-image: url(/images/icons/check.svg);
      background-repeat: no-repeat;
      background-position: center;
      display: inline-block;
      background-size: contain;
      margin-right: 3px;
      vertical-align: middle;
      border: none;
      background-color: transparent;
    }
  }
  .testimonial-who {
    margin-bottom: 8px;
    margin-top: 8px;
    .thumb {
      width: 18px;
      height: 18px;
      background-repeat: no-repeat;
      background-position: center;
      display: inline-block;
      background-size: contain;
      margin-right: 3px;
      background-color: transparent;
    }
    span {
      font-size: 13px;
      font-weight: 500;
      color: #636363;
      padding: 0;
      margin: 0;
      a {
        float: right;
      }
    }
  }
  p {
    font-size: 13px;
    margin-bottom: 0px;
    margin-top: 5px;
    line-height: 22px;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 6;
    -webkit-box-orient: vertical;
  }
  a {
    font-weight: bold;
  }
}
.testimonial-wrap .pop-review .stars,
.testimonial-wrap .pop-review .stars-empty {
  width: 15px;
  height: 15px;
  background-size: contain;
  margin-right: 3px;
}
.tab-details p {
  font-size: 14px;
  margin-bottom: 0px;
  margin-top: 5px;
  line-height: 22px;
}

.testimonial-wrap-full p {
  font-size: 13px;
  margin-bottom: 0px;
  margin-top: 5px;
  line-height: 22px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: inherit;
  -webkit-box-orient: vertical;
}
.reviews-empty-state {
  padding: 20px 0;
  p {
    margin-bottom: 10px;
  }
}
