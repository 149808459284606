//general
button {
  background-color: transparent;

  &:focus {
    outline: $outline__normal !important;
    outline-offset: $space-xxxxs;
  }

  &.close {
    &:focus {
      outline: $outline__normal;
      outline-offset: $space-xxxxs;
    }
  }

  &.back-arrow {
    &:focus {
      outline: $outline__white !important;
      outline-offset: $space-xxxxs;
    }
  }
}

input:focus {
  outline: $outline__normal;
  outline-offset: $space-xxxxs;
}

a:focus {
  outline: $outline__normal;
}

select:focus {
  outline: $outline__normal;
}

.close:focus

///

.filters {
  ion-item:focus {
    border: $outline__normal;
    --background-focused: white;
  }

  &__item {
    span.svg:focus, input:focus {   
      outline: $outline__normal !important;
    }
  }
}

//sprinklr
.swe_post_meta_container {
  a:focus {
    outline: $outline__black !important;
    outline-offset: -1px !important;
  }
}

.featured-tile__container:focus-within {
  outline: $outline__normal;
  a {
    outline: none;
  }
}

//checkout

//checkout date
.checkout-first-step__pickup-date-selector {
  &:focus-within {
    outline: $outline__normal;
    outline-offset: $space-xxxxs;
  }
}

//checkout times
.checkout-first-step__select-pickup-time {
  &:focus-within {
    outline: $outline__normal;
  }
}

// news-media
.news-gallery__grid-item-image {
  &:focus {
    outline: $outline__normal;
    outline-offset: $space-xxxxs;
  }
}
