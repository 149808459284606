.diversity {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: $space-xl+$space-xs;

    @include breakpoint-max(xs) {
        flex-direction: column;
        gap: $space-md;
        width: 100%;
    }

    &__section {
        width: 100%;
        max-width: $space-max-width-xl;

        &__hero-image {
            display: flex;
            width: 100%;
            height: auto;
            justify-content: center;
            align-items: center;

            @include breakpoint-max(xs) {
                display: none;
            }
        }

        &__hero-image-mobile {
            display: none;

            @include breakpoint-max(xs) {
                display: flex;
                width: 100%;
                height: auto;
                justify-content: center;
                align-items: center;
            }
        }
    }

    &__container {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: $space-xl+$space-xs;
        max-width: $space-max-width-lg;

        @include breakpoint-max(xs) {
            flex-direction: column;
            gap: $space-md;
            max-width: 100%;
        }

        &__content {
            display: flex;
            justify-content: center;
            align-items: flex-start;
            gap: $space-md;
            align-self: stretch;
            padding: 0 $space-lg;

            &__wrapper-left {
                display: flex;
                justify-content: center;
                align-items: flex-start;
                flex-direction: column;
                gap: $space-md;
                width: 50%;

                h1 {
                    align-self: stretch;
                    color: $color__primary;
                    font-size: $text__header-xxxl+$text__body-lg;
                    font-weight: bold;
                    line-height: normal;

                    @include breakpoint-max(xs) {
                        font-size: $text__header-xxl;
                    }
                }
                
                &-text {
                    display: flex;
                    flex-direction: column;
                    p {
                        color: $color__black;
                        font-weight: $font-weight__medium;
                        line-height: 140%; /* 33.6px */
                    }

                    p:not(:last-child) {
                        margin-bottom: $space-md;
                    }
                }
            }

            &__bottom-image-up{
                display: none;
            }

            &__wrapper-right {
                display: flex;
                justify-content: center;
                align-items: flex-start;
                flex-direction: column;
                gap: $space-md;
                width: 50%;

                h1 {
                    align-self: stretch;
                    color: $color__primary;
                    font-size: $text__header-xxxl+$text__body-lg;
                    font-weight: bold;
                    line-height: normal;

                    @include breakpoint-max(xs) {
                        font-size: $text__header-xxl;
                    }
                }
                
                &-text {
                    display: flex;
                    flex-direction: column;
                    p {
                        color: $color__black;
                        font-weight: $font-weight__medium;
                        line-height: 140%; /* 33.6px */
                    }

                    p:not(:last-child) {
                        margin-bottom: $space-md;
                    }
                }
            }
        }

        @include breakpoint-max(xs) {
            &__content {
                flex-direction: column;
                padding: 0;

                &__wrapper-left {
                    padding: 0 $space-lg;
                    width: 100%;
                    gap: $space-standard;
                }

                &__bottom-image-up {
                    display: block;
                    width: 100%;
                    height: auto;
                }

                &__wrapper-right {
                    padding: 0 $space-lg;
                    width: 100%;
                    gap: $space-standard;
                }
            }
        }

        &__button-row{
            display: flex;
            justify-content: center;
            align-items: center;
            gap: $space-xl;
            padding: 0 $space-lg;

            @include breakpoint-max(xs) {
                flex-direction: column;
                gap: $space-standard;
                align-self: stretch;
                margin-bottom: $space-md;
            }

            &-btn-element{
                width: 278px;
                max-width: unset;

                @include breakpoint-max(xs) {
                    width: 100%;
                }
            }
        }

        &__bottom-image-down{
            display: flex;
            width: 100%;
            height: auto;
            justify-content: center;
            align-items: center;
            margin-bottom: $space-xl+$space-xs;

            @include breakpoint-max(xs) {
                display: none;
            }
        }
    }
}