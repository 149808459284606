.home {
  background-image: url(/images/bubbles-bg.svg);
  background-size: 100%;
  padding-bottom: 50px;

  //main styling   here
  &__content {
    @include page-container(lg);

    &-group {
      margin-bottom: $space-md;
      @include breakpoint (xs){
        margin-bottom: $space-xl;
      }

      .carousel__card {
        min-width: 260px;
      }
    }

    h2 {
      width: 100%;
      font-weight: bold;
      text-align: left;
      margin-bottom: $space-standard;
      text-transform: lowercase;
      font-size: $text__body-lg;


      @include breakpoint(sm) {
        margin-left: 0;
        text-align: center;
        font-size: $text__body-lg;
      }
    }

    &-categories {
      margin-top: $space-sm;
      margin-bottom: $space-md;
      display: grid;
      grid-template-columns: repeat(auto-fit, minmax(144px, max-content));
      justify-content: center;
      padding: 0 $space-standard;
      gap: 0 $space-standard;

      &-button {
        height: 52px;
        display: flex;
        justify-content: center
      }

      &-link {
        @extend .btn__primary;
        padding: $space-standard $space-xxl;
        line-height: $line-height-sm;
        
        &:hover {
          color: $color__white;
        }
      }

      @include breakpoint(lg) {
        margin-top: $space-lg;
      }
    }
  }

  &__bopis-tile {
    max-width: $space-max-width;
    padding: 0 $space-standard;
    margin: auto;
  }
}