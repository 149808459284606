.reward-badge {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: $space-md $space-xl;

    h3 {
        font-size: $text__body-xxl;
        font-weight: bold;
        line-height: $line-height-xxl;
        text-align: center;
        margin-bottom: $space-md;
        max-width: 295px;
    }

    &__details {
       display: flex;
       flex-direction: column;
       padding-bottom: $space-md;
        p {
            width: 295px;
            font-size: $text__body-base;
            line-height: $line-height-base;
            text-align: left;
            color: $color__black-light;
        }
    }
    &__badge-image {
        display: flex;
        align-items: center;
        margin-top: $space-md;
        margin-bottom: $space-md;
        width: 213px;
        height: 211px;
    }
    &__heading {
        font-size: $text__header-xxl;
        font-weight: bold;
        line-height: $line-height-base + $line-height-base;
        text-align: center;
        color: $color__black-light;
        margin-bottom: $space-md
    }
    &__qrcode-wrapper {
        width: 40%;
    }
    &__qrcode {
        margin-bottom: $space-md;
        border: 1px solid $color__primary-light;
        cursor: pointer;
        align-items: center;
        p {
            max-width: $space-xl;
            padding-top: $space-xs; 
        }
        svg {
            margin-bottom: $space-xxs;
        }
    }
    &__heading1 {
        font-size: 42px !important;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        text-transform: uppercase;
        font-size: $text__header-xxl;
        font-weight: bold;
        line-height: $line-height-base + $line-height-base;
        text-align: center;
        color: $color__black-light;
        margin-bottom: $space-md
    }
    &__date-expire {
        font-size: $text__body-sm;
        text-align: center;
        color: #636363 !important;
    }
}