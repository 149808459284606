.order-history {
    &-btn {
        height: 100px;
        h2 {
            font-size: $text__body-base;
            margin-bottom: $space-xxxxs;
        }
        p {
            font-size: $text__body-md;
            line-height: $line-height-xxl;
            margin-bottom: $space-xxxs;
            span {
                font-size: $text__body-xs;
                color: $color__gray-dark;
                line-height: $line-height-lg;
                margin-left: $space-xxxs;
            }
        }
        h3 {
            font-size: $text__body-sm;
            color: $color__gray-dark;
        }
        svg {
            right: 20px;
            top: 50%;
            transform: translateY(-50%);
            cursor: pointer;
            position: absolute;
            width: 20px;
            height: $space-standard;
        }
    }
}