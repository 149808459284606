////////////////////////
// Input
////////////////////////
.input-wrapper { // Wrap your input in a <div> with this class
    position: relative;
    width: 100%;

    label { // Placeholder text
        color: $color__gray;
        margin-bottom: $space-xxxs;
        font-size: $text__body-sm;
        line-height: $line-height-xl;
        font-weight: 300;
        display: block;
    }

    input { // Input container
        border: 1px solid $color__gray-lightest-border;
        border-radius: $radius-xs;
        box-shadow: $shadow__standard;
        padding: $space-sm;
        padding-right: $space-md;
        outline: none;
        transition: all .2s;
        width: 100%;

        &:focus {
            border: 1px solid $color__gray-light;
        }

        &:disabled {
            cursor: not-allowed;
            background-color: $color__input-disabled;
            color: $color__input-disabled-text;
        }

        &.invalid {
            border: 1px solid $color__red;
            color: $color__red;

            & + p { // required
                color: $color__red;
                & + p { // invalid format
                    color: $color__red;
                }
            }
        }
    }

    .suffix { // Submit, apply, icons, etc
        position: absolute;
        bottom: $space-sm;
        right: $space-xs;
        margin: 0;
        padding: 0;
        border: none;
        background-color: transparent;
    }
}

////////////////////////
// CHECKBOX
////////////////////////
.checkbox-wrapper {
    // Wrap your checkbox in a <div> with this class
    display: flex;
    justify-content: flex-start;
    align-items: center;
    cursor: pointer;

    input {
        // visually hide input without setting display: none -- maintains input accessibility features
        position: absolute !important;
        height: 1px;
        width: 1px;
        overflow: hidden;
        clip: rect(1px 1px 1px 1px); /* IE6, IE7 */
        clip: rect(1px, 1px, 1px, 1px);

        &:focus {
            // Adjust styles based on checked state
            & ~ .checkbox-wrapper__check {
                border: 1px solid $color__gray-dark;
            }
        }


        &:checked {
            // Adjust styles based on checked state
            & ~ .checkbox-wrapper__check {
                background-color: $color__primary;

                svg {
                    opacity: 1;
                }
            }
        }

        &:disabled {
            // Adjust styles based on disabled state

            & ~ .checkbox-wrapper__check {
                box-shadow: none;
                cursor: not-allowed;

                background: -webkit-gradient(linear,left top,right bottom,from($color__gray-progress-bar),color-stop(47%,$color__gray-progress-bar),color-stop(47%,$color__gray-light),color-stop(53%,$color__gray-light),color-stop(53%,$color__gray-progress-bar),to($color__gray-progress-bar));
                svg {
                    display: none;
                }
            }

            &__label-text {
                cursor: not-allowed;
            }
        }
    }

    &__check {
        display: flex;
        position: relative;
        width: $space-sm + $space-xxs;
        height: $space-sm + $space-xxs;
        background-color: $color__gray-progress-bar;
        flex-shrink: 0;
        border: 1px solid $color__gray-light;
        svg {
            opacity: 0;
            margin: auto;
            color: $color__white;
            width: $space-sm;
            height: $space-sm
        }
    }

    &__label-text {
        margin-bottom: 0;
        margin-left: $space-sm;
    }
}

////////////////////////
// RADIO BUTTON
////////////////////////
.radio-wrapper {
    // Wrap your radio button in a <div> with this class
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-shrink: 0;
    cursor: pointer;

    input {
        // visually hide input without setting display: none -- maintains input accessibility features
        position: absolute !important;
        height: 1px;
        width: 1px;
        overflow: hidden;
        clip: rect(1px 1px 1px 1px); /* IE6, IE7 */
        clip: rect(1px, 1px, 1px, 1px);

        &:checked {
            & ~ .radio-wrapper__dot {
                & .radio-wrapper__dot__inner {
                    opacity: 1;

                    &:hover {
                        background-color: $color__primary-dark;
                    }
                }
            }
        }

        &:focus {
            & ~ .radio-wrapper__dot {
                border: 1px solid $color__gray-dark;
            }
        }

        &:disabled {
            & ~ .radio-wrapper__dot {
                border-color: $color__gray-progress-bar;
                box-shadow: none;
                cursor: not-allowed;
                &:hover {
                    border-color: $color__gray-light;
                }
                & .radio-wrapper__dot__inner {
                    background-color: $color__gray-light;
                    &:hover {
                        background-color: $color__gray-light;
                    }
                }
            }

            & ~ .radio-wrapper__label-text {
                cursor: not-allowed;
            }
        }
    }

    &__dot {
        display: flex;
        width: 18px;
        height: 18px;
        background-color: $color__gray-progress-bar;
        border-radius: $radius-round;
        border: 1px solid $color__gray-light;

        &:hover {
            border-color: $color__primary-dark;
        }

        &__inner {
            margin: auto;
            width: $space-sm;
            height: $space-sm;
            background-color: $color__primary;
            border-radius: $radius-round;
            opacity: 0;
        }
    }

    &__label-text {
        margin-bottom: 0;
        margin-left: $space-sm;
    }
}

////////////////////////
// Selects
////////////////////////
.select-wrapper { // Wrap your input in a <div> with this class
    position: relative;
    width: 100%;

    label { // Placeholder text
        color: $color__gray;
        margin-bottom: $space-xxxs;
        font-size: $text__body-sm;
        line-height: $line-height-xl;
        font-weight: 300;
        display: block;
    }

    select,
    input { // Input container
        border: 1px solid $color__gray-lightest-border;
        border-radius: $radius-xs;
        box-shadow: $shadow__standard;
        padding: $space-sm;
        outline: none;
        transition: all .2s;
        width: 100%;
        cursor: pointer;
        -webkit-appearance: none;
        -moz-appearance: none;
        appearance: none;

        &:invalid {
            color: $color__input-disabled-text;
         }
         
        &:disabled {
            cursor: not-allowed;
            background-color: $color__input-disabled;
            color: $color__input-disabled-text;
        }

        &:focus {
            border-color: $color__gray-light;
        }
    }

    .suffix { // Submit, apply, icons, etc
        @include center(false, true);
        right: $space-xs;
        margin: 0;
        padding: 0;
        border: none;
        background-color: transparent;
        display: flex;
        align-items: center;
    }
}