.forgot-password__page {
  margin: 0 auto;
  margin-top: $space-lg;
  .input-wrapper {
    padding-bottom: $space-standard;
    input {
      border: 1px solid $color__input-border;
      &:focus {
        outline: $outline__normal;
        outline-offset: $space-xxxxs;
      }
    }
  }

  .error-label {
    padding-bottom: calc($space-xs + $space-sm);
    padding-top: calc($space-xs + $space-sm);
    margin-top: calc($space-xxxxs + $space-xs);;
    text-align: center;
    display: block;
    font-size: $text__body-base;
    color: $color__red;
  }

  .link-sent {
    font-size: $text__body-sm;
    text-align: center;
    font-weight: 700;
    padding: calc($space-xs + $space-sm) 0;
    padding-top: calc($space-xxs + $space-md);
    color: $color__black;
  }

  h1 {
    padding-top: calc($space-xs + $space-sm);
    font-size: $space-lg;
    font-weight: 800;
    text-transform: lowercase;
    margin-bottom: $space-lg;
  }

  p {
    font-size: $text__body-sm;
    line-height: $space-standard;
    font-weight: 500;
    margin-bottom: $space-md;
  }
}
