.image-picker {
    &__oos {
        &-wrapper {
            position: relative;
            cursor: pointer;
        }
        &-line {
            height: 75px !important;
            width: 1px;
            top: -9px;
            left: 28px;
            border: 1px solid #d2c7c7;
            position: absolute;
            cursor: pointer;
            transform: rotate(135deg);
            z-index: 9999;
        }
    }

    &__image {
        height: 57px;
        width: 57px;
        border: 1px solid #e2d8d8;
        border-radius: 5px;
        margin-right: 5px;
        margin-bottom: 5px;

        &:hover {
            cursor: pointer;
        }

        &-selected {
            border: 2px solid #87189d;
        }
    }
}
