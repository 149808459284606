.checkbox {
    &__instoreonly {
        &:disabled {
            display: none;
            & + .checkbox__instoreonly-label {
                display: inline-block !important;
            }
        }

        &-label {
            display: none !important;
            width: 18px !important;
            height: 18px;
            vertical-align: middle;
            border: 1px solid #ccc;
            background: linear-gradient(to bottom right, white, white 47%, #ccc 47%, #ccc 53%, white 53%, white)
        }
    }
}
