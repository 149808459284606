.swiper {
    &-wrapper {
        padding-bottom: $space-md;
        box-sizing: border-box;
        padding-top: $space-xxxs !important;

        @include breakpoint(lg) {
            padding: 0;
        }

        .swiper-slide {
            width: auto;

            &:last-of-type {
                margin-right: 0px !important;
            }
        }
    }

    &-pagination {
        bottom: -4px !important;

        &-bullet {
            height: $space-xs !important;
            width: $space-xs !important;
            border-radius: 50% !important;
            background-color: $color__gray-progress-bar !important;

            &-active {
                background-color: $color__primary !important;
            }
        }

        @include breakpoint(lg) {
            display: none;
        }
    }

    &__section {
        position: relative;
        display: flex;
        justify-content: center;
    }

    &-navbutton {
        @include center(false, true);
        height: $space-xl;
        width: $space-xl;
        background-color: $color__primary;
        border-radius: 50%;
        z-index: 100;
        display: none;

        @include breakpoint(lg) {
            display: flex;
            align-items: center;
        }

        cursor: pointer;

        &:hover {
            background-color: $color__primary-dark;
        }

        &.swiper-button-disabled {
            background-color: $color__gray-disabled;
            cursor: not-allowed;
        }

        &:before {
            @include center(true, true);
        }

        &-prev {
            left: 0;

            &:before {
                content: url('/images/icons/navigation-arrow-prev.svg');
                margin-top: $space-xxxxs;
                margin-left: -$space-xxxxs;
            }
        }

        &-next {
            right: 0;

            &:before {
                content: url('/images/icons/navigation-arrow-next.svg');
                margin-left: $space-xxxxs;
                margin-top: $space-xxxxs;
            }
        }
    }

    .product-card {
        width: 200px;
    }
}

.swiper-button-lock {
    display: none;
}

.carousel .carousel-status {
    display: none;
}