.store-detail {
    &__wrapper {
        padding-top: $space-xl;
        padding-bottom: $space-xl;
        padding-left: $space-standard;
        @include page-container(lg);
    }
    &__items {
        display: flex;
        flex-direction: column;
        height: auto;
        width: 100%;
        padding-right: $space-standard;
        margin-bottom: $space-sm;
        &-name {
            display: flex;
            flex-direction: column;
            margin-bottom: $space-md;
        }
        &-phone {
            margin-bottom: $space-md;
        }  
        &-address {
            margin-bottom: $space-md;
            a {
                padding: $space-xs;
                &:hover {
                    text-decoration: underline;
                }
            }
        }
        &-coming-soon {
            margin-bottom: $space-standard;
            font-size: $text__body-xl;
            font-weight: bold;
            width: 100%;
            text-align: center;
            float: left;
            > label.error-color {
                color: $color__red;
            }
        }
        &-select-store {
            > span.green-checkmark__container {
                display: inline-block;
                margin-right: $space-xxs;
            }
            > label.select-store__label {
                font-size: $text__body-sm;
            }
        }
        label {
            font-size: $text__body-base;
            font-weight: bold;
            color: $color__black;
            padding: 0;
            margin: 0 0 $space-xxs;
        } 
        span {
            font-size: $text__body-sm;
            font-weight: bold;
            color: $color__gray-order;
            padding: 0;
            margin: 0;
        } 
        &-header {
            font-weight: bold;
        }
        .map-area {
            height: 200px;
            width: 100%;
            margin-bottom: $space-sm;
            background-size: cover;
            background-position: center;
        }
    }
}
