//Font Size
$text__body-xxs: 10px;
$text__body-xs: 12px;
$text__button-sm: 13px;
$text__body-sm: 14px;
$text__body-base: 16px;
$text__body-md: 18px;
$text__body-lg: 20px;
$text__body-xl: 22px;
$text__body-xxl: 24px;
$text__header-xxl: 32px;
$text__header-xxxl: 36px;
$text-h1: 56px;

//Line Height
$line-height-xs: 12px;
$line-height-sm: 14px;
$line-height-base: 16px;
$line-height-md: 18px;
$line-height-lg: 20px;
$line-height-xl: 22px;
$line-height-xxl: 24px;
$line-height-xxxl: 28px;
$line-height-xxxxl: 35px;
$line-height-xxxxxl: 41px;
$line-height-xxxxxxl: 58px;
$line-height-auto: auto;

//Letter Spacing
$letter__spacing-none: 0;
$letter__spacing-xxxxs: 0.1px;
$letter__spacing-xxxs: 0.2px;
$letter__spacing-xxs: 0.3px;
$letter__spacing-xs: 0.4px;
$letter__spacing-sm: 0.5px;
$letter__spacing-md: 1px;
$letter__spacing-lg: 2px;
$letter__spacing-xl: 4px;

//Font Weight
$font-weight__light: 300;
$font-weight__medium: 400;

////////////////////////
// Global Fonts
////////////////////////
* {
    font-family: "sofia-pro", sans-serif;
    font-weight: 500;
    font-style: normal;
    color: #000;
}

////////////////////////
// Font Imports
////////////////////////
@import url("https://p.typekit.net/p.css?s=1&k=nfk4tgh&ht=tk&f=24553.24554.24558.24559.24539.24540.24547.24548.24549.24580.24581.24588.24589&a=14083559&app=typekit&e=css");

@font-face {
    font-family: "sofia-pro-soft";
    src: url("https://use.typekit.net/af/9b5307/0000000000000000000157b7/27/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n7&v=3")
            format("woff2"),
        url("https://use.typekit.net/af/9b5307/0000000000000000000157b7/27/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n7&v=3")
            format("woff"),
        url("https://use.typekit.net/af/9b5307/0000000000000000000157b7/27/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n7&v=3")
            format("opentype");
    font-display: swap;
    font-style: normal;
    font-weight: bold;
}

@font-face {
    font-family: "sofia-pro-soft";
    src: url("https://use.typekit.net/af/2c3915/0000000000000000000157b8/27/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i7&v=3")
            format("woff2"),
        url("https://use.typekit.net/af/2c3915/0000000000000000000157b8/27/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i7&v=3")
            format("woff"),
        url("https://use.typekit.net/af/2c3915/0000000000000000000157b8/27/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i7&v=3")
            format("opentype");
    font-display: swap;
    font-style: italic;
    font-weight: bold;
}

@font-face {
    font-family: "sofia-pro-soft";
    src: url("https://use.typekit.net/af/5c3c84/0000000000000000000157bc/27/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3")
            format("woff2"),
        url("https://use.typekit.net/af/5c3c84/0000000000000000000157bc/27/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3")
            format("woff"),
        url("https://use.typekit.net/af/5c3c84/0000000000000000000157bc/27/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3")
            format("opentype");
    font-display: swap;
    font-style: normal;
    font-weight: normal;
}

@font-face {
    font-family: "sofia-pro-soft";
    src: url("https://use.typekit.net/af/ef7efe/0000000000000000000157bd/27/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i4&v=3")
            format("woff2"),
        url("https://use.typekit.net/af/ef7efe/0000000000000000000157bd/27/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i4&v=3")
            format("woff"),
        url("https://use.typekit.net/af/ef7efe/0000000000000000000157bd/27/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i4&v=3")
            format("opentype");
    font-display: swap;
    font-style: italic;
    font-weight: normal;
}

@font-face {
    font-family: "sofia-pro";
    src: url("https://use.typekit.net/af/30420e/00000000000000003b9b1a9e/27/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n7&v=3")
            format("woff2"),
        url("https://use.typekit.net/af/30420e/00000000000000003b9b1a9e/27/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n7&v=3")
            format("woff"),
        url("https://use.typekit.net/af/30420e/00000000000000003b9b1a9e/27/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n7&v=3")
            format("opentype");
    font-display: swap;
    font-style: normal;
    font-weight: bold;
}

@font-face {
    font-family: "sofia-pro";
    src: url("https://use.typekit.net/af/8948c6/00000000000000003b9b1a9d/27/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i7&v=3")
            format("woff2"),
        url("https://use.typekit.net/af/8948c6/00000000000000003b9b1a9d/27/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i7&v=3")
            format("woff"),
        url("https://use.typekit.net/af/8948c6/00000000000000003b9b1a9d/27/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i7&v=3")
            format("opentype");
    font-display: swap;
    font-style: italic;
    font-weight: bold;
}

@font-face {
    font-family: "sofia-pro";
    src: url("https://use.typekit.net/af/5855b2/00000000000000003b9b1a98/27/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3")
            format("woff2"),
        url("https://use.typekit.net/af/5855b2/00000000000000003b9b1a98/27/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3")
            format("woff"),
        url("https://use.typekit.net/af/5855b2/00000000000000003b9b1a98/27/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3")
            format("opentype");
    font-display: swap;
    font-style: normal;
    font-weight: normal;
}

@font-face {
    font-family: "sofia-pro";
    src: url("https://use.typekit.net/af/98f96f/00000000000000003b9b1a97/27/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i4&v=3")
            format("woff2"),
        url("https://use.typekit.net/af/98f96f/00000000000000003b9b1a97/27/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i4&v=3")
            format("woff"),
        url("https://use.typekit.net/af/98f96f/00000000000000003b9b1a97/27/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i4&v=3")
            format("opentype");
    font-display: swap;
    font-style: italic;
    font-weight: normal;
}

@font-face {
    font-family: "sofia-pro";
    src: url("https://use.typekit.net/af/7158ff/00000000000000003b9b1a9c/27/l?subset_id=2&fvd=n6&v=3") format("woff2"),
        url("https://use.typekit.net/af/7158ff/00000000000000003b9b1a9c/27/d?subset_id=2&fvd=n6&v=3") format("woff"),
        url("https://use.typekit.net/af/7158ff/00000000000000003b9b1a9c/27/a?subset_id=2&fvd=n6&v=3") format("opentype");
    font-display: swap;
    font-style: normal;
    font-weight: bold;
}

@font-face {
    font-family: "sofia-pro-condensed";
    src: url("https://use.typekit.net/af/22f12f/0000000000000000000157d2/27/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n7&v=3")
            format("woff2"),
        url("https://use.typekit.net/af/22f12f/0000000000000000000157d2/27/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n7&v=3")
            format("woff"),
        url("https://use.typekit.net/af/22f12f/0000000000000000000157d2/27/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n7&v=3")
            format("opentype");
    font-display: swap;
    font-style: normal;
    font-weight: bold;
}

@font-face {
    font-family: "sofia-pro-condensed";
    src: url("https://use.typekit.net/af/526238/0000000000000000000157d3/27/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i7&v=3")
            format("woff2"),
        url("https://use.typekit.net/af/526238/0000000000000000000157d3/27/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i7&v=3")
            format("woff"),
        url("https://use.typekit.net/af/526238/0000000000000000000157d3/27/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i7&v=3")
            format("opentype");
    font-display: swap;
    font-style: italic;
    font-weight: bold;
}

@font-face {
    font-family: "sofia-pro-condensed";
    src: url("https://use.typekit.net/af/8847eb/0000000000000000000157da/27/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3")
            format("woff2"),
        url("https://use.typekit.net/af/8847eb/0000000000000000000157da/27/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3")
            format("woff"),
        url("https://use.typekit.net/af/8847eb/0000000000000000000157da/27/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3")
            format("opentype");
    font-display: swap;
    font-style: normal;
    font-weight: normal;
}

@font-face {
    font-family: "sofia-pro-condensed";
    src: url("https://use.typekit.net/af/51ce34/0000000000000000000157db/27/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i4&v=3")
            format("woff2"),
        url("https://use.typekit.net/af/51ce34/0000000000000000000157db/27/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i4&v=3")
            format("woff"),
        url("https://use.typekit.net/af/51ce34/0000000000000000000157db/27/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i4&v=3")
            format("opentype");
    font-display: swap;
    font-style: italic;
    font-weight: normal;
}
