.staticpage {
  display: flex;
  justify-content: center;
  align-items: center;

  &__container {
    padding-top: 0;
    @include page-container(lg);

    @include breakpoint(lg) {
      padding-top: $space-xl;
    }

  }

  &__video {
    width: 100%;
    text-align: center;
    margin-top: 20px;
  }

  &__image {
    margin-top: 20px;
    max-width: 100%;
  }

  &__circle-image {
    border-radius: 50%;
  }

  &__page-title {
    @include breakpoint-max(lg) {
      margin-right: -$space-page-gutters;
      margin-left: -$space-page-gutters;
    }
  }

  &__header-image {
    @include breakpoint-max(lg) {
      margin-left: -$space-page-gutters;
      margin-right: -$space-page-gutters;
    }

    .general-image-hero__wrapper-margin {
      margin-top: 0;
    }
  }

  &__content-group {
    margin-bottom: $space-md;

    @include breakpoint (xs) {
      margin-bottom: $space-xl;
    }
  }
}